<section>

  <div class="card-title">
    <span><h2>Reports</h2></span>
  </div>

  <div class="banner-style">
    <app-sweet-loader
            type="SPINNER"
            [message]="loaderMessage"
            fullScreen="true"
            *ngIf="submittingReport"
    ></app-sweet-loader>
    <!-- Remove After slm3 Decommission-->
    <app-temp-banner class="layout-padding, banner-style"></app-temp-banner>
    <!-- -->
  </div>

  <div class="layout-padding">
    <mat-card appearance="outlined" style="padding: inherit" >
      <mat-card-content>
        <div class="layout-padding">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title>List of Reports</mat-card-title>
            <div (click)="onHelpClick()" aria-label="Report Help">
              <button mat-icon-button>
                <mat-icon>help</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <app-table
          [headerStream]="reportsHeadersStream"
          [data]="reportsDataStream"
          [pageSize]="reportingSvc.paginatorConfig?.pageSize"
          [paginatorConfig]="reportingSvc.paginatorConfig"
          [showFilter]="false"
          [loading]="loadingReports"
          [actionsTemplate]="reportsActionsTemplate"
          (events)="onRowClick($event)"
          #reportsTable
          [headerPaginator]="true"
          (paginationChange)="handlePaginationChange($event)"
        >
<!--          <mat-form-field fxFlex>-->
<!--            <input-->
<!--              matInput-->
<!--              [value]="this.dashboardId"-->
<!--              (keyup)="reportsTable.applyFilter($event.target.value)"-->
<!--              placeholder="Filter through results. Ex: Phishing Traffic Detected"-->
<!--            />-->
<!--          </mat-form-field>-->
          <ng-template #reportsActionsTemplate let-report>
            <div>
              <button
                *ifUserCan="reportActions.readDashboard"
                mat-menu-item
                [routerLink]="'/dashboard/' + report.related_dashboard_id"
              >
                <mat-icon>dashboard</mat-icon>View Origin Dashboard
              </button>
              <button mat-menu-item (click)="runReport(report)">
                <mat-icon>run_circle</mat-icon>Run Report Now
              </button>
              <button
                *ifUserCan="reportActions.editReport"
                mat-menu-item
                (click)="onEdit(report)"
              >
                <mat-icon>edit</mat-icon>Edit Report
              </button>
              <ng-container *ifUserCan="reportActions.deleteReport">
                <button mat-menu-item (click)="onDelete(report)">
                  <mat-icon>delete</mat-icon>Delete Report
                </button>
              </ng-container>
            </div>
          </ng-template>
        </app-table>
      </mat-card-content>
    </mat-card>
  </div>
</section>
