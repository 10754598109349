<section class="report-general">
  <form [formGroup]="form">
    <!-- Customer picker -->
    <mat-form-field appearance="outline">
      <mat-label>Customer ID</mat-label>
      <mat-select formControlName="company">
        <mat-option *ngFor="let company of companies" [value]="company.id">{{
          company.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Title -->
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" />
    </mat-form-field>

    <!-- Description -->
    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        formControlName="description"
        class="no-resize"
      ></textarea>
    </mat-form-field>

    <!-- Input variables -->
    <h3>Input Variables</h3>
    <section
      class="input-variables"
      formGroupName="input_variables"
      *ngIf="inputWidgets.length > 0"
    >
      <div
        class="input-variable"
        *ngFor="let widget of inputWidgets; trackBy: trackByMethod"
      >
        <mat-form-field appearance="outline">
          <mat-label>{{ widget.params.inputName }}</mat-label>
          <!-- select input variables -->
          <mat-select
            [formControlName]="widget.params.inputName"
            [multiple]="widget.params.isMultiSelect"
          >
            <mat-option
              *ngFor="let option of widget.options"
              [value]="option.value"
              >{{ option.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </section>

    <!-- Report data frame -->
    <section class="data-frame">
      <h3>Report Data Time Frame</h3>
      <mat-form-field appearance="outline">
        <mat-label>Select an option</mat-label>
        <mat-select
          formControlName="data_time_frame"
          [compareWith]="compareWith"
        >
          <mat-option
            *ngFor="let option of dateOptions"
            [value]="option.value"
            >{{ option.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </section>
  </form>
  <form class="custom-maya" [formGroup]="customDate">
    <mat-form-field appearance="outline">
      <mat-label>Start Date</mat-label>
      <input matInput formControlName="start" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>End Date</mat-label>
      <input matInput formControlName="end" />
    </mat-form-field>
  </form>
</section>
