import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './components/layout/layout.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { PortalLoaderComponent } from './components/portal-loader/portal-loader.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FilterComponent } from './components/filter/filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from './components/table/table.component';
import { SweetLoaderComponent } from './components/sweet-loader/sweet-loader.component';
import { IncidentDisplayComponent } from './components/incident-display/incident-display.component';
import { ModalComponent } from './components/modal/modal.component';
import { FormBuilderComponent } from './components/form-builder/form-builder.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { IfUserCanDirective } from './directives/if-user-can.directive';
import { DisableUnlessUserCanDirective } from './directives/disable-unless-user-can.directive';
import { QueryBuilderComponent } from './components/query-builder/query-builder.component';
import { SupportDialogComponent } from './components/support-dialog/support-dialog.component';
import { GridsterModule } from 'angular-gridster2';
import { ClipboardModule } from 'ngx-clipboard';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { WidgetFormComponent } from './components/widgets/widget-forms/widget-form/widget-form.component';
import { LineCreateFormComponent } from './components/widgets/widget-forms/line-create-form/line-create-form.component';
import { BarCreateFormComponent } from './components/widgets/widget-forms/bar-create-form/bar-create-form.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OWL_DATE_TIME_FORMATS, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserModule } from '@angular/platform-browser';
import { NotProdReadyDirective } from './directives/not-prod-ready.directive';
import { MatRadioModule } from '@angular/material/radio';
import { PieCreateFormComponent } from './components/widgets/widget-forms/pie-create-form/pie-create-form.component';
import { WidgetHeaderComponent } from './components/widgets/widget-header/widget-header.component';
import { SingleMetricCreateFormComponent } from './components/widgets/widget-forms/single-metric-create-form/single-metric-create-form.component';
import { SingleGaugeFormComponent } from './components/widgets/widget-forms/single-gauge-form/single-gauge-form.component';
import { NgxLoadingModule } from 'ngx-loading';
import { RedirectModalComponent } from './components/redirect-modal/redirect-modal.component';
import { WidgetFormBuilderComponent } from './components/widgets/widget-form-builder/widget-form-builder.component';
import { FormlyModule } from '@ngx-formly/core';
import { RepeatTypeComponent } from './components/widgets/widget-form-builder/repeat-section.type';
import { TableWidgetHeaderComponent } from './components/widgets/table-widget-header/table-widget-header.component';
import { MatStepperModule } from '@angular/material/stepper';
import { AvatarModule } from 'ngx-avatar';
import { FullScreenWidgetComponent } from './components/widgets/full-screen-widget/full-screen-widget.component';
import { WidgetInfoComponent } from './components/widgets/widget-info/widget-info.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MultiSelectTypeComponent } from '../pages/dashboards/dashboard-detail-page/components/multi-select-type/multi-select-type.component';
import { MayaDatePickerComponent } from './components/maya-date-picker/maya-date-picker.component';
import { UserPrefDatePipe } from './pipes/userPrefDate.pipe';
import { InputSearchPipe } from './pipes/input-search.pipe';
import { CustomDateRangePickerComponent } from './components/custom-date-range-picker/custom-date-range-picker.component';
import { BottomPageBorderComponent } from './components/bottom-page-border/bottom-page-border.component';
import { WhatsNewDialogComponent } from './components/whats-new-dialog/whats-new-dialog.component';
import { MarkdownModule } from 'ngx-markdown';
import { TableWidgetComponent } from './components/table-widget/table-widget.component';
import { WidgetMenuComponent } from './components/widget-menu/widget-menu.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { WidgetBarComponent } from './components/widget-bar/widget-bar.component';
import { WidgetViewComponent } from './components/widget-view/widget-view.component';
import { SelectComponent } from './inputs/select/select.component';
import { HelpDialogModalComponent } from './components/help-dialog-modal/help-dialog-modal.component';
import { RelativeDateComponent } from './components/relative-date/relative-date.component';
import { DatetimePickerModule } from './datetime-picker/datetime-picker.module';
import { EmailVerificationModule } from '@sweet-shared/email-verification/email-verification.module';
import { TempBannerComponent } from './components/temp-banner/temp-banner.component';
import { RedirectDialogComponent } from './components/redirect-dialog/redirect-dialog.component';


@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidenavComponent,
    PortalLoaderComponent,
    FilterComponent,
    TableComponent,
    SweetLoaderComponent,
    IncidentDisplayComponent,
    SnackbarComponent,
    ModalComponent,
    FormBuilderComponent,
    IfUserCanDirective,
    UserPrefDatePipe,
    DisableUnlessUserCanDirective,
    QueryBuilderComponent,
    SupportDialogComponent,
    ConfirmationDialogComponent,
    WidgetFormComponent,
    LineCreateFormComponent,
    BarCreateFormComponent,
    AutoCompleteComponent,
    InputSearchPipe,
    NotProdReadyDirective,
    WidgetHeaderComponent,
    SingleMetricCreateFormComponent,
    PieCreateFormComponent,
    SingleGaugeFormComponent,
    RedirectModalComponent,
    WidgetFormBuilderComponent,
    RepeatTypeComponent,
    FullScreenWidgetComponent,
    TableWidgetHeaderComponent,
    WidgetInfoComponent,
    MayaDatePickerComponent,
    CustomDateRangePickerComponent,
    WidgetInfoComponent,
    BottomPageBorderComponent,
    WhatsNewDialogComponent,
    TableWidgetComponent,
    WidgetMenuComponent,
    WidgetBarComponent,
    SearchSelectComponent,
    WidgetViewComponent,
    SelectComponent,
    HelpDialogModalComponent,
    RelativeDateComponent,
    TempBannerComponent,
    QueryBuilderComponent,
    RedirectDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DatetimePickerModule,
    MatCheckboxModule,
    MatInputModule,
    MatToolbarModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatListModule,
    MatIconModule,
    DragDropModule,
    FlexLayoutModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatBadgeModule,
    GridsterModule,
    ClipboardModule,
    MatRippleModule,
    MatAutocompleteModule,
    BrowserModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatRadioModule,
    NgxLoadingModule,
    FormlyModule,
    MatStepperModule,
    MatTableModule,
    MatOptionModule,
    FormlyModule.forRoot({
      types: [
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'multi-select', component: MultiSelectTypeComponent }
      ],
    }),
    AvatarModule,
    NgxMatSelectSearchModule,
    MarkdownModule,
    EmailVerificationModule,
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    PortalLoaderComponent,
    FilterComponent,
    TableComponent,
    SweetLoaderComponent,
    IncidentDisplayComponent,
    DragDropModule,
    DatetimePickerModule,
    FormBuilderComponent,
    MatButtonModule,
    IfUserCanDirective,
    UserPrefDatePipe,
    InputSearchPipe,
    SupportDialogComponent,
    WidgetFormComponent,
    AutoCompleteComponent,
    NotProdReadyDirective,
    PieCreateFormComponent,
    SingleGaugeFormComponent,
    WidgetHeaderComponent,
    FullScreenWidgetComponent,
    TableWidgetHeaderComponent,
    MatSelectModule,
    MatIconModule,
    MayaDatePickerComponent,
    CustomDateRangePickerComponent,
    TableWidgetHeaderComponent,
    BottomPageBorderComponent,
    TableWidgetComponent,
    SearchSelectComponent,
    MatTableModule,
    WidgetBarComponent,
    WidgetViewComponent,
    SelectComponent,
    RelativeDateComponent,
    TempBannerComponent,
    QueryBuilderComponent
  ],
})
export class SweetSharedModule {
}
