import { Action, createReducer, on } from '@ngrx/store';
import * as StoreActions from '../actions/portal-cache.actions';
import { IStoreMap, StoreEntryFunction, StoreEntryType, StoreUpdateFunction } from '../models/auxiliary';

export const storeFeatureKey = 'portalCache';
export const dataStoreKey = 'data';
export const errorStoreKey = 'errors';
export const statusStoreKey = 'status';

export interface dataStore {
    [key: string]: any;
}

export interface PortalCache {
    [dataStoreKey]: IStoreMap<any>;
    [errorStoreKey]: IStoreMap<Error>;
    [statusStoreKey]: IStoreMap<any>;
}

export const initialState: PortalCache = {
    [dataStoreKey]: {},
    [errorStoreKey]: {},
    [statusStoreKey]: {}
};

export const reducer = createReducer(
    initialState,
    on(StoreActions.putData, (state, action) => {
        const key = action.key;
        const prevState = state[dataStoreKey];
        const params = action.data;
        return {
            ...state,
            [dataStoreKey]: processData(prevState, action)
        };
    }),
    on(StoreActions.putError, (state, action) => ({
        ...state,
        [errorStoreKey]: {
            ...state[errorStoreKey],
            [action.key]: action.error
        }
    })),
    on(StoreActions.putStatus, (state, action) => ({
        ...state,
        [statusStoreKey]: {
            ...state[statusStoreKey],
            [action.key]: action.status
        }
    })),
);

const processData = function(prevState: IStoreMap<any>, action: (Action & any)): IStoreMap<any> {
    const key = action.key;
    const params = action.data;
    const compute = params.compute;
    const value = params.value;

    if (!!!params?.type) {
        return {
            ...prevState,
            [key]: params
        };
    }

    const newState = {
        ...prevState,
        [key]: params.compute(key, prevState[key])
    };

    return newState;
};
