<div fxLayout="column" fxFlex>
  <form [formGroup]="form" fxLayout="column" fxFlex>
    <!-- customer id -->
    <!--<mat-form-field appearance="outline">-->
    <!--  <mat-label>Customer ID</mat-label>-->
    <!--  <mat-select formControlName="customer_id">-->
    <!--    <mat-option-->
    <!--      *ngFor="let company of data.companies"-->
    <!--      [value]="company.value"-->
    <!--      >{{ company.friendly }}</mat-option-->
    <!--    >-->
    <!--  </mat-select>-->
    <!--</mat-form-field>-->



    <!-- Company Filter -->
    <mat-form-field appearance="outline">
      <mat-label>Customer ID</mat-label>
      <mat-select formControlName="customer_id">
        <mat-form-field class="select-with-search">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="filterCompany($event.target.value)" />
        </mat-form-field>
        <mat-option
          *ngFor="let option of filteredCompaniesOptions"
          [value]="option.value"
        >{{ option.friendly }}</mat-option
        >
      </mat-select>
    </mat-form-field>



    <!-- title of the report -->
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Title</mat-label>
      <input matInput placeholder="title" formControlName="title" />
    </mat-form-field>

    <!-- description of the report -->
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Description</mat-label>
      <textarea
        matInput
        placeholder="Description"
        formControlName="description"
      ></textarea>
    </mat-form-field>
  </form>

  <form
    *ngIf="showInputVariableForm"
    fxFlex
    [formGroup]="inputVariableForm"
    fxLayout="column"
  >
    <h3>Input Variables</h3>
    <div *ngFor="let inputVariable of inputVariables">
      <app-select
        [isMultiple]="inputVariable.isMultiSelect"
        [label]="inputVariable.input_variable_name"
        [options]="inputVariable.options"
        [formControlName]="inputVariable.input_variable_name"
      ></app-select>
    </div>
  </form>

  <!-- date frame -->
  <h3>Report Data Time Frame</h3>
  <app-datetime-picker
    [defaultValue]="defaultSelectedDate"
    [options]="dateOptions"
    (onDateSelected)="handleDateframeChanged($event)"
    emitType="maya"
  ></app-datetime-picker>
</div>
