<form>
  <div fxLayout="row">
    <mat-form-field appearance="outline">
      <mat-label>{{ widget.title }}</mat-label>
      <mat-select
        [formControl]="dropdownControl"
        [multiple]="widget.params.isMultiSelect"
      >
        <mat-option value="_Any_" *ngIf="widget.params.isMultiSelect"
          >Any
        </mat-option>
        <mat-option
          *ngFor="let option of dropdownOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button
      *ifUserCan="
        ['widget.refresh', 'widget.edit', 'widget.deletes'];
        any: true
      "
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="widget_menu"
      matTooltip="Filter   Options"
    >
      <mat-icon inline>settings</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        *ifUserCan="'widget.delete'"
        mat-menu-item
        (click)="deleteWidget()"
      >
        <mat-icon>delete_forever</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </div>
</form>
