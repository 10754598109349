import {Action, createReducer, on} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {Event} from './event.model';
import * as EventActions from './event.actions';

export const eventsFeatureKey = 'events';

export interface State extends EntityState<Event> {
  // additional entities state properties
  loading: boolean;
  error: string;
  events: any [];

  selectedEvent: any;
}

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  error: null,
  events: [],
  selectedEvent: null
});

const eventReducer = createReducer(
  initialState,
  on(EventActions.fetchEvents,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),
  on(EventActions.loadEvents,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),
  on(EventActions.loadEventsSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        events: action.events
      };
    }
  ),
  on(EventActions.eventsError,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
        events: []
      };
    }
  ),
  on(EventActions.fetchEvent,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: null,
        selectedEvent: null
      };
    }
  ),
  on(EventActions.fetchEventError,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
        selectedEvent: null
      };
    }
  ),
  on(EventActions.fetchEventSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        selectedEvent: action.event
      };
    }
  ),
  on(EventActions.clearEvent,
    (state, action) => {
      return {
        ...state,
        selectedEvent: null
      };
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return eventReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
