import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-reports-warning',
  templateUrl: './reports-warning.component.html',
  styleUrls: ['./reports-warning.component.scss']
})
export class ReportsWarningComponent implements OnInit {

  @Output() events: EventEmitter<any> = new EventEmitter<any>();

  @Input() reports: any[];

  constructor() { }

  ngOnInit(): void {
  }

  onProceed() {
    this.events.emit('PROCEED');
  }

  onCancel() {
    this.events.emit('CANCEL');
  }

  onDuplicate() {
    this.events.emit('DUPLICATE');
  }

}
