<section>
    <app-portal-loader [loadingStatus]="loadingCompanies"></app-portal-loader>
    <div class="layout-padding">
        <button mat-stroked-button type="button" routerLink="/admin" class="admin_sections--back">
            <mat-icon>keyboard_arrow_left</mat-icon>
            Back to Admin Page
        </button>
        <mat-card appearance="outlined" class="card-style" fxFill>
            <mat-card-title>Filters</mat-card-title>
            <mat-card-content>
                <app-form-builder
                        *ngIf="userFilterDetails.length"
                        [fieldDetails]="userFilterDetails"
                        (actionEvents)="onCompanyChanged($event)"
                        #externalUsersForm
                >
                </app-form-builder>
            </mat-card-content>
        </mat-card>
        <br>
        <mat-card appearance="outlined" class="card-style">
            <!-- Page title -->
            <mat-card-title>{{pageTitle}}</mat-card-title>

            <!-- add button for new accounts -->
            <mat-card-actions>
                <button *ifUserCan="'external-account.create'" mat-stroked-button color="primary" (click)="addExternalAccount()">
                    <mat-icon>add</mat-icon>
                    <span>Create New External Account</span>
                </button>
            </mat-card-actions>
        </mat-card>
        <br>
        <mat-card appearance="outlined">
            <app-portal-loader [loadingStatus]="externalAccountsLoading$ | async"></app-portal-loader>
            <app-table
                    [data]="externalListData"
                    [headerStream]="externalListHeaderData"
                    [pageSize]="10"
                    [showFilter]="true"
                    (events)="onRowClick($event)"
                    [loading]="loading"
                    #externalTable
            >
            </app-table>
        </mat-card>
    </div>
</section>


