import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, pluck, switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


import { State } from '@app/reducers';
import { AuthService } from '@app/pages/auth/services/auth.service';
import { SnackbarService } from '@shared-services/snackbar.service';
import * as userActions from '@app/pages/admin/store/user/user.actions';
import { User, UserStatusEnum } from '@app/pages/admin/store/user/user.model';
import { AccountsService } from '@app/pages/admin/services/accounts.service';
import { AddUserComponent } from '@app/pages/admin/components/add-user/add-user.component';
import { AppService } from '@app/app.service';

@UntilDestroy()
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {
  @Input('ifUserCan') requiredPermission: string | Array<string>;
  accountId: string;
  roles: any = {};
  permissions: any;
  userInfo: User = null;
  userRoles: string[];

  systemRoles: any[] = [];
  userStatusEnum = UserStatusEnum;
  loading: boolean;
  editPermission = 'account.edit';
  dialogReference: any = null;

  user: User = null;
  companies$: Observable<any>;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private accountsServices: AccountsService,
    private accountService: AppService<any>,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit() {
    this.route.params.pipe(
      // extract id from route for use in switchMap
      pluck('id'),
      switchMap(val => {
        this.store.dispatch(userActions.loadUser({ username: val }));
        this.accountId = val;
        return this.store.select(state => state.users.currentUser)
          .pipe(
            filter(values => !!values),
            untilDestroyed(this));
      }))
      .subscribe(userData => {
        this.userInfo = userData;
        if (userData && userData.Attributes) {
          this.user = userData;
          this.userRoles = (userData.Attributes.gender as unknown as string).split(',');
        } else {
          this.userRoles = null;
        }

      });

    // to show portal-loader
    this.store.select(state => state.users.currentUserLoading).pipe(
      untilDestroyed(this))
      .subscribe(val => this.loading = val);
    this.companies$ = this.store.select(state => state.companies.companies);
    this.authService.getAuthenticatedUser().pipe(
      take(1),
      untilDestroyed(this)).subscribe(user => {
        this.store.dispatch(userActions.loadUsers({ companyFilter: [user.profile] }));
      });
  }


  onDeleteUser() {
    this.accountsServices.deleteUser(this.userInfo, '/admin/users');
  }

  accountActions(actionName, userData) {
    this.store.dispatch(userActions.editUser({ user: userData, path: actionName }));
  }

  onResetPassword(event) {
    this.accountsServices.resetUserPassword(event.Username);
    this.accountsServices.getResetMessage().pipe(
      filter(data => !!data),
      untilDestroyed(this))
      .subscribe(message => { this.snackbarService.open(message, 'Ok'); });

    this.accountsServices.resetError().pipe(
      filter(data => !!data),
      untilDestroyed(this))
      .subscribe(error => {
        this.snackbarService.open(error, 'Ok');
      });
  }

  onEditUser(user) {
    this.dialog.open(AddUserComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        loggedUser: user,
        title: 'Edit User',
        edit: true,
        companies$: this.companies$
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.accountService.put('user', this.userInfo.Username, null, { ...data, sub: this.userInfo.sub })
          .then((res) => {
            this.snackbarService.open('Your update was sucessfull');
            this.userInfo = res;
            this.store.dispatch(userActions.editUserSuccess({ user: res }));
          })
          .catch((error) => {
            this.store.dispatch(userActions.editUserError({ error: error.message }));
            this.snackbarService.open(error.message);
          });
      }
    });
  }

  // function is used to make a call to another function which will re-send a temp code
  resendTempCode(event) {
    this.accountsServices.resendConfirmationCode(event.Username);
    this.accountsServices.getResentConfirmationCode().pipe(
      filter(data => !!data),
      untilDestroyed(this)).subscribe(message => { this.snackbarService.open(message, 'Ok'); });

    this.accountsServices.resentConfirmationCodeError().pipe(
      filter(data => !!data),
      untilDestroyed(this)).subscribe(error => { this.snackbarService.open(error, 'Ok'); });
  }

  canResetUserPassword(): boolean {
    if ('Attributes' in this.userInfo) {
      const userAttributesKey = Object.keys(this.userInfo.Attributes);
      return userAttributesKey.includes('email_verified');
    } else {
      return (this.userInfo as any).email_verified;
    }
  }

  ngOnDestroy() {
    this.store.dispatch(userActions.loadUserSuccess({ user: null }));
  }

}
