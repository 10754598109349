// import { User } from './../../../pages/auth/services/auth.service';
import {Component, Input, OnInit} from '@angular/core';
import {LayoutConfig} from '../../models/layout-config';
import {FooterLink} from '../../models/footer-link';
import {User} from '../../../pages/admin/store/user/user.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  // Input with the configuration of the layout.
  @Input() config: LayoutConfig = {
    customHeader: false,
    customFooter: false,
    showFooter: true,
    showSidenav: false,
    loadingStatus: false,
    sideNavOpened: JSON.parse(localStorage.getItem('sideNavOpened') || 'true')
  };
  @Input() isAuthenticated = false;
  @Input() user: User = null;
  authenticatedHeader: boolean;

  // Input for the footer links
  @Input() footerLinks: FooterLink[] = [
    {text: 'About CenturyLink', url: ''},
    {text: 'About MSS', url: ''},
    {text: 'Legal', url: ''},
    {text: 'Legal Notices', url: ''},
    {text: 'Privacy Policy', url: ''}
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
