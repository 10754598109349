<form>
  <header>Verify Email</header>
  <section class="content">
    <p>A code has been sent to your email.</p>
    <p>Please don't leave this screen until you have received the code.</p>
    <mat-form-field appearance="outline">
      <mat-label>Enter Verification Code</mat-label>
      <input matInput [formControl]="code">
    </mat-form-field>
  </section>
  <footer>
    <button mat-button color="warn" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="code.invalid">Confirm</button>
  </footer>
</form>
