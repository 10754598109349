<mat-card appearance="outlined">
  <mat-card-title>Search</mat-card-title>
  <mat-card-content>
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" id="searchForm">
      <div fxLayout="row wrap">
        <ng-container *ngFor="let control of configOptions">
          <ng-container [ngSwitch]="control.type">
            <mat-form-field [fxFlex]="control.width || 'auto'" *ngSwitchCase="'select'">
              <mat-label>{{ control.label }}</mat-label>
              <mat-select [required]="control.required" [formControlName]="control.controlName" [multiple]="control.isMultiple">
                <mat-option *ngFor="let option of control.options" [value]="option.raw" [ngStyle]="control.style">
                  {{ option.friendly }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field [fxFlex]="control.width || 'auto'" *ngSwitchCase="'input'">
              <input [required]="control.required" [formControlName]="control.controlName" matInput
                     placeholder="{{control.placeHolder}}">
              <button mat-button type="reset" *ngIf="searchForm.controls['query'].dirty" matSuffix mat-icon-button
                      aria-label="Clear Incident Query" (click)="searchForm.controls['query'].reset()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

          </ng-container>
        </ng-container>
      </div>

    </form>
  </mat-card-content>
  <mat-card-actions fxLayout="row">
    <button fxFlex.lt-sm="100" mat-stroked-button color="primary" type="submit"
            form="searchForm" [disabled]="searchForm.invalid || searchForm.pristine"
            aria-label="Search Incidents">
      <mat-icon>search</mat-icon>
      <span>Search</span>
    </button>
  </mat-card-actions>

</mat-card>
