import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { WidgetFormBuilderService } from './widget-form-builder.service';
import { Subject } from 'rxjs';
import { QueryData } from '../../../../pages/incidents/components/incidents-page/incidents-page.component';
import { QueryBuilderComponent } from '../../query-builder/query-builder.component';

@Component({
  selector: 'app-widget-form-builder',
  templateUrl: './widget-form-builder.component.html',
  styleUrls: ['./widget-form-builder.component.scss']
})
export class WidgetFormBuilderComponent implements OnInit {
  @Input() categoryField: any;
  @Input() formLoadingIndicator: Subject<boolean> = new Subject<boolean>();
  @Output() onWidgetFormBuilderSubmit = new EventEmitter();
  @Output() onWidgetFormBuilderCancel = new EventEmitter();

  currentFormType: string = null;

  qbData: QueryData = {
    rules: [{ field: '', operator: '=', value: '' }],
    formatWithColon: false,
    page: 'dashboard',
    ruleGroups: [{ condition: 'AND', rules: [{ field: '', operator: '=', value: '' }] }]
  };

  @ViewChild('queryBuilder') queryBuilder: QueryBuilderComponent;

  constructor(private widgetFormBuilderService: WidgetFormBuilderService,

  ) {
  }


  // Form, Model, and Fields for widget field items that are shared with all widgets
  commonWidgetForm = new UntypedFormGroup({});
  commonWidgetModel: any = {};
  commonWidgetFields: FormlyFieldConfig[] = this.widgetFormBuilderService.commonWidgetFields;
  finalWidgetFormValid = false;

  finalWidgetForm = new UntypedFormGroup({});
  finalWidgetModel: any = {};
  finalWidgetFields: FormlyFieldConfig[] = null;


  onCancel() {
    this.onWidgetFormBuilderCancel.emit(true);
  }

  onSubmit() {
    switch (this.commonWidgetModel.type) {
      case 'pie':
        let pieFinalForm: any = {
          ...this.commonWidgetModel,
          index_name: this.commonWidgetModel.index.value,
          params: {
            ...this.finalWidgetModel,
            field: {
              ...this.finalWidgetModel.field,
              alias: this.finalWidgetModel.alias,
              quantifier: ''
            },
          }
        };
        delete pieFinalForm.params.alias;
        delete pieFinalForm.params.interval;
        delete pieFinalForm.params.yAxisAction;
        delete pieFinalForm.index
        return this.onWidgetFormBuilderSubmit.emit(pieFinalForm);
      case 'solid_gauge':
        let solidGaugeFinalForm: any = {
          ...this.commonWidgetModel,
          index_name: this.commonWidgetModel.index.value,
          params: {
            ...this.finalWidgetModel,
            field: {
              ...this.finalWidgetModel.field,
              alias: this.finalWidgetModel.alias,
              quantifier: ''
            },
          }
        };
        delete solidGaugeFinalForm.params.alias;
        delete solidGaugeFinalForm.index

        return this.onWidgetFormBuilderSubmit.emit(solidGaugeFinalForm);

      case 'line':
        let lineFinalForm: any = {
          ...this.commonWidgetModel,
          index_name: this.commonWidgetModel.index.value,
          params: {
            ...this.finalWidgetModel,
            query: this.finalWidgetForm.controls.query.value,
            field: {
              ...this.finalWidgetModel.field,
              alias: this.finalWidgetModel.alias,

            },
            yAxisField: {
              ...this.finalWidgetModel.field,
              alias: this.finalWidgetModel.alias,
              quantifier: '',
            },
          }
        };
        delete lineFinalForm.index;
        delete lineFinalForm.params.alias;
        return this.onWidgetFormBuilderSubmit.emit(lineFinalForm);

      case 'bar':
        let barFinalForm: any = {
          ...this.commonWidgetModel,
          index_name: this.commonWidgetModel.index.value,
          params: {
            ...this.finalWidgetModel,
            query: this.finalWidgetForm.controls.query.value,
            field: {
              ...this.finalWidgetModel.field,
              alias: this.finalWidgetModel.alias,
            },
            yAxisField: {
              ...this.finalWidgetModel.field,
              alias: this.finalWidgetModel.alias,
              quantifier: '',
            },
          }
        };
        delete barFinalForm.params.alias;
        delete barFinalForm.index
        return this.onWidgetFormBuilderSubmit.emit(barFinalForm);

      case 'table':
        let tableFinalForm: any;
        const tempVal = this.finalWidgetModel
        const fields = this.finalWidgetModel.fields.map(val => {
          return {
            alias: val.alias,
            cast_as: val.cast_as,
            fx_params: val.fx_param,
            fx: val.yAxisAction,
            friendly: val.field.label,
            name: val.field.value,
            type: val.field.type,
            quantifier: ''
          };
        })
        tableFinalForm = {
          ...this.commonWidgetModel,
          index_name: this.commonWidgetModel.index.value,
          params: {
            query: tempVal.query,
            limit: tempVal.limit,
            fields
          }
        }
        delete tableFinalForm.index
        return this.onWidgetFormBuilderSubmit.emit(tableFinalForm);


      case 'single_metric':
        let singleMetricFinalForm: any = {
          ...this.commonWidgetModel,
          index_name: this.commonWidgetModel.index.value,
          params: {
            ...this.finalWidgetModel,
            query: this.finalWidgetForm.controls.query.value,
            field: {
              ...this.finalWidgetModel.field,
              alias: this.finalWidgetModel.alias,
              fx: this.finalWidgetModel.fx,
              cast_as: this.finalWidgetModel.cast_as

            },
          }
        };
        delete singleMetricFinalForm.params.alias;
        delete singleMetricFinalForm.params.fx;
        delete singleMetricFinalForm.params.cast_as;
        delete singleMetricFinalForm.params.ordering;
        delete singleMetricFinalForm.params.yAxisAction;
        delete singleMetricFinalForm.params.interval;
        delete singleMetricFinalForm.params.fieldTypeAction;
        delete singleMetricFinalForm.params.sliceCount;
        delete singleMetricFinalForm.params.limit;
        delete singleMetricFinalForm.index;
        return this.onWidgetFormBuilderSubmit.emit(singleMetricFinalForm);
      default:
        return {};

    }
  }


  ngOnInit(): void {
    this.commonWidgetForm.valueChanges.subscribe((value) => {
      if (value && value.type && this.currentFormType !== value.type) {
        this.currentFormType = value.type;
        this.finalWidgetFields = null;
        this.finalWidgetFields = this.widgetFormBuilderService.getWidgetFields(value.type, this.categoryField);
      }
    });
    this.widgetFormBuilderService.fieldOption = {
      value: 'val.name',
      label: 'val.friendly'
    }
    this.finalWidgetForm.valueChanges.subscribe((value) => {
      // If the widget type has a query field
      if (this.queryBuilder) {
        // 'pristine' is also valid, since an empty query is allowed
        this.finalWidgetFormValid = (this.finalWidgetForm.status === 'VALID' || this.queryBuilder.ngForm.form.pristine === true);
      } else {
        this.finalWidgetFormValid = (this.finalWidgetForm.status === 'VALID');
      }
    });



  }

  patchQuery(event) {
    if (event.name === 'QUERY_UPDATED') {
      this.finalWidgetForm.get('query').setValue(event.value);
    } else if (event.name === 'INVALID_QUERY') {
      this.finalWidgetForm.get('query').setErrors({ invalidQuery: true });
      this.finalWidgetForm.updateValueAndValidity();
    } else if (event.name === 'RESET_QUERY') {
      this.finalWidgetForm.get('query').setValue(event.value);
      this.finalWidgetForm.updateValueAndValidity();
    }
  }





}
