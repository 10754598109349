import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface WidgetBarEvent {
  type: string;
  payload?: any;
}

@Component({
  selector: 'app-widget-bar',
  templateUrl: './widget-bar.component.html',
  styleUrls: ['./widget-bar.component.scss']
})
export class WidgetBarComponent implements OnInit {

  @Input() title = '';
  @Input() enableDownload = false;
  @Input() dataHeadings = [];
  @Input() widgetType = '';
  @Input() widgetSource = '';
  @Output() eventBus: EventEmitter<WidgetBarEvent> = new EventEmitter();
  hideEdit: boolean;

  constructor() {}

  ngOnInit(): void {
    this.hideEdit = !(this.widgetSource === 'incidents' && this.widgetType === 'table');
  }
}
