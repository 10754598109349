import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProfileRoutingModule} from './profile-routing.module';
import {ProfileComponent} from './components/profile/profile.component';
import {StoreModule} from '@ngrx/store';
import * as fromProfile from './store/profile.reducer';
import {EffectsModule} from '@ngrx/effects';
import {ProfileEffects} from './store/profile.effects';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {UserDisplayComponent} from './components/user-display/user-display.component';
import {AvatarModule} from 'ngx-avatar';
import {HttpClientModule} from '@angular/common/http';
import {ProfileFormComponent} from './forms/profile-form/profile-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SweetSharedModule} from '../../sweet-shared/sweet-shared.module';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import {MatChipsModule} from '@angular/material/chips';
import {ClipboardModule} from 'ngx-clipboard';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [ProfileComponent, UserDisplayComponent, ProfileFormComponent, ChangePasswordComponent],
  exports: [
    UserDisplayComponent,
    ProfileFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetSharedModule,
    MatTabsModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    HttpClientModule,
    MatTooltipModule,
    MatInputModule,
    ProfileRoutingModule,
    AvatarModule,
    StoreModule.forFeature(fromProfile.profilesFeatureKey, fromProfile.reducer),
    EffectsModule.forFeature([ProfileEffects]),
    ReactiveFormsModule,
    FlexLayoutModule,
    MatGridListModule,
    MatDialogModule,
    MatListModule,
    MatExpansionModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MatChipsModule,
    MatCheckboxModule,
    ClipboardModule
  ]
})
export class ProfileModule {
}
