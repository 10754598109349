import { Injectable } from '@angular/core';
import {AppService} from '@app/app.service';



@Injectable({
  providedIn: 'root'
})
export class WidgetDrilldownService {
  private currentCompany: string;
  private currentIndex: string;
  private dropDownValues: [];

  constructor(
      private appService: AppService<any>
    ) {
    this.currentCompany = 'undefined';
    this.currentIndex = 'undefined';
    this.dropDownValues = [];
  }

  setDropDown(value) {
    this.dropDownValues.push();
  }

  getDropDown() {
    return this.dropDownValues;
  }

  setCompany(value) {
    this.currentCompany = value;
  }

  getCompany() {
    return this.currentCompany;
  }

  setIndex(value) {
    this.currentIndex = value;
  }

  getIndex() {
    return this.currentIndex;
  }
}
