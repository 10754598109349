import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextInputResult, TextInputResultData } from '../interface';

@Component({
  selector: 'app-text-input-result-form',
  templateUrl: './text-input-result-form.component.html',
  styleUrls: ['./text-input-result-form.component.scss']
})
export class TextInputResultFormComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();

  // Input variable needed for this component
  @Input() data: TextInputResult = null;

  // Output on value changes
  @Output() onChange: EventEmitter<TextInputResultData> = new EventEmitter(null);

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    // Generate the default form
    this.form = this.fb.group({
      default_value: ['', []],
      output_variable: [null, [Validators.required]],
      validation_regex: ['.*', [Validators.required]]
    });
  }

  ngOnInit(): void {
    // Path the form if input is provided.
    if (this.data) {
      this.form.patchValue(this.data);
    }

    // hook up the output on value changes
    this.form.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe((data: TextInputResult) => {
      this.onChange.emit({ data, isValid: this.form.valid });
    });
  }

  ngOnDestroy(): void {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }
}
