import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DropdownOption } from '@app/pages/incidents/services/incidents.service';
import { combineLatest, forkJoin, Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PreferencesService } from '../services/preferences.service';
import { EventsService } from '@modules/events/services/events.service';
import { AppService } from '@app/app.service';


@Component({
  selector: 'app-account-preferences',
  templateUrl: './account-preferences.component.html',
  styleUrls: ['./account-preferences.component.scss']
})
export class AccountPreferencesComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  private userPreferences: any = null;

  form: UntypedFormGroup;
  loading = true;
  error: string = null;
  newDate = new Date();

  // choices
  fieldOptions: DropdownOption[] = [];
  dateRangeOptions: DropdownOption[] = [];
  dateFormatOptions: string[] = [];
  refreshIntervalOptions: DropdownOption[] = [];

  constructor(
    private preferenceService: PreferencesService,
    private eventService: EventsService,
    private httpService: AppService<any>
  ) {
    this.form = new UntypedFormGroup({
      searchRefreshInterval: new UntypedFormControl('', [Validators.required]),
      dateFormat: new UntypedFormControl('', [Validators.required]),
      searchFields: new UntypedFormControl([], [Validators.required]),
      dateRange: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    combineLatest([
      this.preferenceService.userPreferences,
      this.eventService.fetchEventParams().toPromise(),
    ]).pipe(
      takeUntil(this.destroyer$),
      filter(val => !!val[0] && !!val[1])
    ).subscribe(response => {
      this.userPreferences = response[0];
      const eventParameters = response[1];

      // set the fields option
      this.fieldOptions = eventParameters.fields.map(field => { return { value: field.name, label: field.friendly } });
      this.refreshIntervalOptions = eventParameters.searchIntervals.map(val => { return { value: val.name, label: val.name } });
      this.dateRangeOptions = eventParameters.eventDateRanges.map(val => { return { value: val.value, label: val.name } })
      this.dateFormatOptions = eventParameters.dateFormats;

      this.form.setValue({
        searchRefreshInterval: this.userPreferences.searchRefreshInterval,
        dateFormat: this.userPreferences.dateFormat,
        searchFields: this.userPreferences.searchFields,
        dateRange: this.userPreferences.dateRange
      });
      timer(1000).toPromise().then(() => {
        this.loading = false;
      })
    });
  }

  dateRangeComparison(option, value): boolean {
    return option.from === value.from && option.to === value.to && option.type === value.type;
  }

  updateUserPreference(): void {
    const payload = {
      ...this.userPreferences,
      ...this.form.value
    };
    this.loading = true;
    this.error = null;
    this.httpService.put('userPreferences', '', null, payload)
      .then(response => this.preferenceService.setUserPreferences(response))
      .catch(error => this.error = error.message)
      .finally(() => {
        if (!this.error) {
          // set the form pristine
          this.form.markAsPristine()
        }
      })
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
