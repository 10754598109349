import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { AppService } from 'src/app/app.service';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import * as ReportActions from '../store/report.actions';



@Injectable()
export class ReportEffects {

  loadReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.loadReports),
      switchMap(() => {
        return this.reportService.get('reports', '', null)
          .then((reports: any[]) => ReportActions.loadReportsSuccess({reports}))
          .catch((error: any) => ReportActions.loadReportsFailure({error: error.message}));
      })
    );
  });
  createReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.createReport),
      switchMap((report) => {
        return this.reportService.post('reports', '', null, report)
          .then((res: any[]) => ReportActions.createReportSuccess({report: res}))
          .catch((err: any) => ReportActions.createReportFailure({error: err.message}));
      })
    );
  });
  deleteReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.deleteReport),
      switchMap((report) => {
        return this.reportService.delete('reports', `${report.reportId}`, null, null)
          .then((_) => ReportActions.deleteReportSuccess({reportId: report.reportId}))
          .catch((err: any) => ReportActions.deleteReportFailure({error: err.message}));
      })
    );
  });

  constructor(private actions$: Actions, private reportService: AppService<any[]>) {}

}
