import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';


export interface Rule {
  field: string;
  operator: string;
  value: string;
}

export interface RuleGroup {
  condition: 'AND' | 'OR' | 'NOT';
  rules: Rule[];
}

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss']
})
export class QueryBuilderComponent implements OnInit {
  @Output() queryBody = new EventEmitter();
  @Input() fields: any[];
  @ViewChild('queryForm') ngForm: NgForm;
  operators = ['AND', 'OR', 'NOT'];
  ruleGroups: RuleGroup[];
  comparators = ['=', '!=', '<', '<=', '>', '>=', 'is null', 'between', 'like', 'not like'];
  queryString: string;
  queryPreview: string;
  eventQuery: any[];
  formatWithColon: boolean;
  page: 'incident' | 'event';
  searchText = {};

  @Input() inputData: any;
  @Input() isDialog = true;

  static queryStringBuilder(arr: RuleGroup[], formatWithColon: boolean) {
    return arr.reduce((accumulator, currentItem, index, arr) => {
      if (currentItem.rules.length) {
        if (currentItem.rules.length > 1) {
          accumulator += '(';
        }
        currentItem.condition === 'NOT' ? accumulator += 'NOT ' : null;
        currentItem.rules.forEach((rule, ruleIndex, ruleArr) => {
          if (formatWithColon) {
            accumulator +=
              `${rule.operator === '!=' ? 'NOT' : ''} ${rule.field} ${(rule.operator === '=' || rule.operator === '!=') ? ':' : rule.operator} ${rule.value}`;
          } else {
            accumulator += `${rule.field} ${rule.operator} '${rule.value}'`;
          }
          if (ruleArr.length - 1 > ruleIndex) {
            accumulator += ` ${currentItem.condition === 'NOT' ? 'AND NOT' : currentItem.condition} `;
          }
        });
        if (currentItem.rules.length > 1) {
          accumulator += ')';
        }
        if (arr.length - 1 > index) {
          accumulator += ' AND ';
        }
      }
      return accumulator;
    }, '');
  }

  constructor(
    public dialogRef: MatDialogRef<QueryBuilderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.ruleGroups = this.inputData.ruleGroups;
    this.formatWithColon = this.inputData.formatWithColon;
    this.page = this.inputData.page;
    this.queryPreview = this.buildQueryString1(this.inputData.formatWithColon);
    this.eventQuery = JSON.parse(sessionStorage.getItem('event-query') || '[]');
  }

  setFilter(event, formatWithColon) {
    this.queryPreview = this.buildQueryString1(formatWithColon);
    if (this.ngForm.valid) {
      this.queryBody.emit({ name: 'QUERY_UPDATED', value: this.ruleGroups });
    } else {
      this.queryBody.emit({ name: 'INVALID_QUERY', value: true });
    }
  }

  clearQuery() {
    this.ruleGroups = [{ condition: 'AND', rules: [{ field: '', operator: '=', value: '' }] }];
    this.ngForm.resetForm();
    this.ngForm.form.updateValueAndValidity();
    this.eventQuery = [];
    this.queryBody.emit({ name: 'RESET_QUERY', value: [] });
    sessionStorage.removeItem('event-query');
  }

  addRule(groupIndex) {
    this.ruleGroups[groupIndex].rules.push({ field: '', operator: '=', value: '' });
    this.ngForm.form.markAsPristine();
  }

  addGroup() {
    this.ruleGroups.push({ condition: 'AND', rules: [{ field: '', operator: '=', value: '' }] });
  }

  removeGroup(groupIndex) {
    this.ruleGroups = this.ruleGroups.filter((group, i) => i !== groupIndex);
    this.queryPreview = this.buildQueryString1(false)
  }

  removeRule(ruleIndex, groupIndex) {
    let group = this.ruleGroups[groupIndex].rules;
    group = group.filter((rule, i) => i !== ruleIndex);
    this.ruleGroups[groupIndex].rules = group;
    this.queryPreview = this.buildQueryString1(false)
  }

  buildQueryString(formatWithColon: boolean) {
    this.queryString = QueryBuilderComponent.queryStringBuilder(this.ruleGroups, formatWithColon);
  }

  buildQueryString1(formatWithColon: boolean) {
    return QueryBuilderComponent.queryStringBuilder(this.ruleGroups, formatWithColon);
  }

  onSubmit(values: NgForm, formatWithColon) {
    if (!values.invalid) {
      sessionStorage.setItem(`${this.page}-query`, JSON.stringify(this.ruleGroups));
      this.buildQueryString(formatWithColon);
      this.queryBody.emit({ name: 'submit', data: this.queryString, ruleGroups: this.ruleGroups });
    }
  }

  // assign input search value to a global variable. this is used for the pipe component
  onTextInput(value, groupIndex, ruleIndex) {
    this.searchText[`${groupIndex}-${ruleIndex}`] = value;
  }
}
