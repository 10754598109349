import { UserPreference } from './../../user-preference/store/user-preference.model';
import { IncidentAttribute, IncidentUpdateInterface, IncidentWorklog } from './incident.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { AppService } from 'src/app/app.service';
import { Incident, Worklog } from './incident.model';
import { switchMap, map, concatMap, tap, withLatestFrom, take, flatMap, filter } from 'rxjs/operators';
import * as incidentActions from './incident.actions';
import * as userPreferenceActions from '../../user-preference/store/user-preference.actions';
import { selectAllIncidentAttributes, selectAllIncidents } from './incident.selector';
import { State } from '../../../reducers';
import { of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { SnackbarService } from '../../../shared-services/snackbar.service';


@Injectable()
export class IncidentEffects {


  constructor(
    private actions$: Actions,
    private incidentService: AppService<Incident>,
    private store: Store<State>,
    private worklogService: AppService<Worklog>,
    private incidentAttributesService: AppService<string[]>,
    private snackbarService: SnackbarService
  ) {
  }


  @Effect({ dispatch: true })
  fetchIncident$ = this.actions$.pipe(
    ofType(incidentActions.searchIncidents),
    switchMap((action: { searchParams: object }) => {
      return this.incidentService.get('investigation', '', action.searchParams)
        .then(
          (res: { source: Incident[], total: number }) => {
            return incidentActions.loadIncidentsSuccess({ incidents: res.source, total: res.total });
          }
        )
        .catch(
          (error) => {
            return incidentActions.loadIncidentsError({ error: error.response.message });
          }
        );
    })
  );

  @Effect({ dispatch: true })
  clearIncidents$ = this.actions$.pipe(
    ofType(incidentActions.clearIncidents),
    map(() => incidentActions.loadIncidentsSuccess({ incidents: [], total: 0 }))
  );

  @Effect({ dispatch: true })
  getIncident$ = this.actions$.pipe(
    ofType(incidentActions.getIncident),
    withLatestFrom(this.store.pipe(select(selectAllIncidents))),
    switchMap(([action, allIncidents]) => {
      const incident = allIncidents.find(inc => inc.sys_id === action.incidentId);

      if (incident) {
        return of(incidentActions.getIncidentSuccess({ incident }));
      } else {
        return this.incidentService.get('investigation', `${action.incidentId}`, null)
          .then((inc: Incident) => incidentActions.getIncidentSuccess({ incident: inc }))
          .catch((err) => incidentActions.getIncidentError({ error: err.response.message }));
      }
    })
  );

  @Effect({ dispatch: true })
  fetchWorklogs$ = this.actions$.pipe(
    ofType(incidentActions.loadWorklog),
    switchMap((action: { incidentId: string }) => {
      return this.worklogService.get('investigation', `${action.incidentId}/worklog`, null)
        .then((worklogs: Worklog[]) => incidentActions.loadWorklogSuccess({ incidentId: action.incidentId, worklogs }))
        .catch((error) => incidentActions.loadWorklogError({ error: error.message }));
    })
  );

  @Effect({ dispatch: true })
  incidentAttributes$ = this.actions$.pipe(
    ofType(incidentActions.loadIncidentAttributes),
    withLatestFrom(this.store.pipe(select(selectAllIncidentAttributes))),
    switchMap(([action, allIncidentAttributes]) => {
      if (Object.keys(allIncidentAttributes).length === 0) {
        return this.incidentAttributesService.get('incidentAttributes', '', null)
          .then((res: IncidentAttribute) => incidentActions.incidentAttributesSuccess({ attributes: res }))
          .catch((error: any) => incidentActions.incidentAttributesError({ error: error.response.message }));
      } else {
        return of(incidentActions.incidentAttributesSuccess({ attributes: allIncidentAttributes }));
      }
    }),
  );


  @Effect({ dispatch: true })
  incidentUpdate$ = this.actions$.pipe(
    ofType(incidentActions.updateIncident),
    switchMap((action: { incidentId: string, value: IncidentUpdateInterface, userPreferences?: UserPreference }) => {
      return this.incidentService.put('investigation', action.incidentId, { action: 'update' }, { value: action.value })
        .then((res: { id: string, status: string }) => incidentActions.updateIncidentStatus({
          statusId: res.id,
          userPreferences: action.userPreferences
        }))
        .catch((error: any) => incidentActions.updateIncidentError({ error: error.message }));
    })
  );

  @Effect({ dispatch: true })
  incidentCreate$ = this.actions$.pipe(
    ofType(incidentActions.incidentCreate),
    switchMap((action: { incident: any }) => {
      return this.incidentService.post('incidents', '', null, action.incident)
        .then((res: Incident) => {
          return incidentActions.incidentCreateSuccess({ incident: res });
        })
        .catch((err) => incidentActions.incidentCreateError({ error: err.message }));
    })
  );


  @Effect({ dispatch: true })
  incidentCreateSuccess$ = this.actions$.pipe(
    ofType(incidentActions.incidentCreateSuccess),
    filter(action => action.makeActive === true),
    // @ts-ignore
    switchMap((action: { incident: any, makeActive: boolean, userPreferences: UserPreference }) => {
      // TODO - when userName is available from state, won't need to call this
      return Auth.currentAuthenticatedUser()
        .then(user => {
          const params = {
            incidentId: action.incident.sys_id,
            value: {
              category: action.incident.category,
              company: action.incident.company,
              u_queue: action.incident.u_queue,
              incident_state: action.incident.incident_state,
              priority: action.incident.priority,
              classification: action.incident.classification,
              u_unlisted_affected_user: user.username,
              assignment_group: action.incident.assignmentGroup
            },
            userPreferences: action.makeActive ? {
              ...action.userPreferences,
              activeInvestigation: action.incident.sys_id,
              owner_id: user.attributes.sub
            } : null
          };
          return incidentActions.updateIncident({ ...params });
        })
        .catch(error => {
          // Here the user is not authenticated.
          this.snackbarService.open(error.message);
        });
    })
  );

  @Effect({ dispatch: true })
  incidentUpdateStatus$ = this.actions$.pipe(
    ofType(incidentActions.updateIncidentStatus),
    switchMap((action: { statusId: string, userPreferences?: UserPreference }) => {
      return this.incidentService.get('investigation', `${action.statusId}/update-status`, null).then(
        (res: any) => {
          if (res && res.message && res.message === 'Not Yet Completed') {
            return incidentActions.updateIncidentStatus({ statusId: action.statusId });
          } else {
            // check to see if there is a username then we will update the user preferences
            if (action.userPreferences) {
              // We need to update the user preference
              return userPreferenceActions.updateUserPreferences({ userPreferences: action.userPreferences, notify: true, incident: res });
            } else {
              return incidentActions.updateIncidentSuccess({ incident: res });
            }
          }
        }
      ).catch(
        (error: any) => incidentActions.updateIncidentError({ error: error.message })
      );
    })
  );

}
