<form [formGroup]="optionForm" *ngIf="optionForm">
  <div>

    <div >
      <div>
        <mat-form-field appearance="outline" fxFlexFill>
          <input  formControlName="inputName" matInput placeholder="Custom Value Name">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>Value Type</mat-label>
          <mat-select formControlName="type" (valueChange)="onChangeSelect($event)">
            <mat-option  *ngFor="let option of fieldOption" [value]="option.value">
              {{option.friendly}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div formArrayName="staticData" >
        <div *ngFor="let item of optionForm.get('staticData')['controls']; let i = index;">
          <div [formGroupName]="i" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" fxFlex="45">
              <input formControlName="label" matInput placeholder="Value Name">
            </mat-form-field>

            <mat-form-field appearance="outline"  *ngIf="!isQuery" fxFlex="45">
              <input formControlName="value" matInput placeholder="Value Key">
            </mat-form-field>

            <!--<query-builder formControlName="value" [config]='filterInformationAvailableFields'-->
            <!--               (ngModelChange)='formatQueryForAthena($event) ' *ngIf="isQuery"></query-builder>-->
          </div>
          <div class="layout-padding"  fxLayout='row' fxLayoutAlign='end end'>
            <button color="warn" mat-stroked-button type="button" (click)="removeAddress(i)" *ngIf="optionForm.get('staticData')['controls'].length !==1">
              <mat-icon>remove</mat-icon>
              Remove</button>
          </div>
        </div>

      </div>

    </div>

    <div  class="layout-padding">
      <button color="primary" mat-stroked-button type="button" (click)="addAnotherForm()">
        <mat-icon>add_box</mat-icon>
        Add Item</button>
    </div>


  </div>

</form>
