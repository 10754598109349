import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentSearchPreferenceComponent } from './incident-search-preference/incident-search-preference.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AccountPreferencesComponent } from './account-preferences/account-preferences.component';

@NgModule({
  declarations: [
    IncidentSearchPreferenceComponent,
    AccountPreferencesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    IncidentSearchPreferenceComponent,
    AccountPreferencesComponent
  ]
})
export class UserPreferencesModule { }
