import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { SnackbarService } from 'src/app/shared-services/snackbar.service';
import { GeneralInfoFormConfig } from '../../report.model';

export const REPORT_STATUSES = [
  {label: 'Done', value: 'done'},
  {label: 'Running', value: 'running'},
  {label: 'Queued', value: 'queued'},
  {label: 'Failed', value: 'failed'},
  {label: 'Cancelled', value: 'cancelled'},
  {label: 'Scheduled', value: 'scheduled'},
  {label: 'Parsing', value: 'parsing'}
];

const COMPLETED_STATUSES = ['FAILED', 'CANCELLED', 'COMPLETED', 'ERROR'];
const PULLING_TIME = 5000;
export interface ReportHistoryMessageInterface {
  date: string;
  message: string;
  status: string;
}
export interface ReportHistoryInterface {
  report_history_id: string;
  messages: ReportHistoryMessageInterface[];
  owner_id?: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface ReportTrack {
  report_id: string;
  report_history_id: string;
};
@Injectable()
export class ReportingService {
  private cancelRequestSubject = new Subject();
  private reportHistoriesSubject: BehaviorSubject<ReportHistoryInterface[]> = new BehaviorSubject([]);
  private loadingReportHistoriesSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private reportHistoryErrorSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  private reportHistoryTableHeadersSubject: BehaviorSubject<any[]> = new BehaviorSubject([
    {name: 'createdAt', friendly: 'Created At'},
    {name: 'status', friendly: 'Status'},
    {name: 'report_id', friendly: 'Report ID'},
    {name: 'actions', friendly: 'Actions'}
  ]);

  private allReportHistories: ReportHistoryInterface[] = [];
  private generalInfoForm: UntypedFormGroup;
  private defaultRelatedDashboard$ = new BehaviorSubject('');
  public useRelatedDashBoard = true;
  public disableRelatedDashBoard = false;
  public disableEmails = true;
  public disableEmailNotifications = true;
  public paginatorConfig = {
    pageSize: 25,
    length: 0,
    pageIndex: 0,
    previousPageIndex: 0,
  };

  get historyTableHeaders$(): Observable<any[]> {
    return this.reportHistoryTableHeadersSubject.asObservable();
  }

  get reportHistories$(): Observable<ReportHistoryInterface[]> {
    return this.reportHistoriesSubject.asObservable();
  }

  get loadingReportHistories$(): Observable<boolean>  {
    return this.loadingReportHistoriesSubject.asObservable();
  }

  get reporthistoryError$(): Observable<string> {
    return this.reportHistoryErrorSubject.asObservable();
  }

  constructor(
    private fb: UntypedFormBuilder,
    private httpService: AppService<any>,
    private snackbarService: SnackbarService
  ) { }

  private adaptStatus(reportHistory: any): ReportHistoryInterface {
    if (reportHistory && COMPLETED_STATUSES.includes(reportHistory.status)) {
      return reportHistory;
    }
    const statuses = reportHistory.messages;
    const currentStatus = statuses.at(-1).status;
    reportHistory.status = currentStatus;
    return reportHistory;
  }

  getReportHistory(reportId: string): void {
    this.loadingReportHistoriesSubject.next(true);
    this.httpService.get('reports', `${reportId}/history`, null)
      .then(reportHistories => {
        const reportHistoriesResult = reportHistories.map(reportHistory => this.adaptStatus(reportHistory));
        this.reportHistoriesSubject.next(reportHistoriesResult);
        this.allReportHistories = reportHistoriesResult;
        reportHistoriesResult.filter(rh => !COMPLETED_STATUSES.includes(rh.status)).map(reportHistory => this.trackReportStatuses({report_id: reportHistory.report_id, report_history_id: reportHistory.report_history_id}));
      })
      .catch(err => {
        this.reportHistoryErrorSubject.next(err);
      })
      .finally(() => this.loadingReportHistoriesSubject.next(false));
  }

  private trackReportStatuses(reportToTrack: ReportTrack): void {
    this.httpService.get('reports', `${reportToTrack.report_id}/history/${reportToTrack.report_history_id}/status`, null)
      .then(res => {
        const reportStatus = this.adaptStatus(res);
        if (COMPLETED_STATUSES.includes(reportStatus.status)) {
          this.allReportHistories = this.allReportHistories.map(r => {
            if (r.report_history_id === reportStatus.report_history_id) {
              return reportStatus;
            }
            return r;
          });
          this.reportHistoriesSubject.next(this.allReportHistories);
        } else {
          setTimeout(() => this.trackReportStatuses(reportToTrack), PULLING_TIME)
        }
      })
      .catch(err => {
        this.allReportHistories = this.allReportHistories.map(r => {
          if (r.report_history_id === reportToTrack.report_history_id) {
            r.status = 'ERROR';
          }
          return r;
        });
        this.reportHistoriesSubject.next(this.allReportHistories);
      }
    );
  }

  cancelStatusCheck(): void {
    this.cancelRequestSubject.next(false);
    this.cancelRequestSubject.complete();
  }

  getGeneralInfoForm(): UntypedFormGroup {
    return this.generalInfoForm;
  }

  unsetRelatedDashboardId(): void {
    this.defaultRelatedDashboard$.next(null);
  }

  makeGeneralInfoForm(report?: GeneralInfoFormConfig) {
    const authenticatedUser = JSON.parse(sessionStorage.getItem('authenticatedUser')).profile;
    this.generalInfoForm = this.fb.group({
      customer_id: [report.customer_id ?? authenticatedUser, [Validators.required]],
      title: [report.title ?? '', [Validators.required]],
      description: [report.description ?? '', [Validators.required]],
      related_dashboard_id: [{value: report.related_dashboard_id ?? '', disabled: report ?? false}, [Validators.required]],
      dataFrame: this.fb.group({
        start: [report?.dataFrame?.start ?? null, [Validators.required]],
        end: [report?.dataFrame?.end ?? null, [Validators.required]]
      })
    });
  }

  get defaultRelatedDashBoard$() {
    return this.defaultRelatedDashboard$.asObservable();
  }

  setDefaultRelatedDashBoard(input: string) {
    this.defaultRelatedDashboard$.next(input);
  }

  getInputVariables(relatedDashboardId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.get("dashboards", relatedDashboardId, null)
        .then((dashboard) => {
          const inputVariables = dashboard.widgets.reduce((prev, next) => {
            if (next && next.input_variables) {
              prev = next.input_variables.reduce((p, n) => {
                if (!p.hasOwnProperty(n.input_variable_name)) {
                  p[n.input_variable_name] = n.default_value;
                }
                return p;
              }, prev);
            }
            return prev;
          }, {});
          return resolve(inputVariables);
        })
        .catch(err => {
          reject(err);
        });
    })
  }

  downloadReport(reportStyle: string, row: any): void {
    this.httpService.get('reports', `${row.report_id}/history/${row.report_history_id}/get-data`, null)
      .then(data => {
        if (data.csv || data.html) {
          // Check for desired report styled csv or html
          if (reportStyle === 'csv') {
            // Confirm csv is not empty
            if (data.csv !== '' && data.csv !== null){
              // Calls function to trigger the download flow for the provided url
              if (reportStyle === 'csv') {
                this.createDownloadReportWindows(data.csv.toString());
              }
            }
          } else if (reportStyle === 'html') {
            // Confirm html is not empty
            if (data.html !== '' && data.html !== null){
              this.createDownloadReportWindows(data.html.toString());
            }
          } else {
            this.snackbarService.open('There Was a Problem Downloading Your Report.  Please Try Again.  If It Fails Again, Please Reach Out For Support.');
          }
        } else {
          this.snackbarService.open('No Data to Download');
        }
      })
      .catch(err => {
        this.snackbarService.open(err.message + ': Could Not Download File.');
      });
  }

  createDownloadReportWindows(url: string, filePrefix?: string, fileName?: string){
    // Briefly opens up a new window to download the target url link in the browser
    const date = new Date().toLocaleDateString();
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);

    // Optional params, if no fileName is passed in, use default name provided by url/backend
    if (fileName && filePrefix) {
      link.setAttribute('download', `${filePrefix}_${date}_${fileName}`);
    }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  filterReportbyStatus(status: string): void {
    if (!status) {
      this.reportHistoriesSubject.next(this.allReportHistories);
      return;
    }
    this.reportHistoriesSubject.next(this.allReportHistories.filter(r => r.status.includes(status)));
  }
}
