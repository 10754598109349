import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DevicesComponent} from './devices/devices.component';
import {AuthGuardService} from '../../guards/auth-gaurd.service';
import {PermissionsGuardService} from '../../guards/permissions-guard.service';
import {DeviceDetailPageComponent} from './device-detail-page/device-detail-page.component';

const routes: Routes = [
  {
    path: 'devices',
    canActivate: [AuthGuardService],
    data: {
      requiredPermission: ['portal-access.enable-devices-section'],
      any: false
    },
    children: [
      {
        path: '',
        canActivate: [PermissionsGuardService],
        component: DevicesComponent,
      },
      {
         path: ':customerId/:deviceId',
        component: DeviceDetailPageComponent,
        canActivate: [PermissionsGuardService],
        data: {
          requiredPermission: ['dashboard.read'],
          any: false
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DevicesRoutingModule { }
