import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Auth } from 'aws-amplify';
import { BehaviorSubject, forkJoin, Subject, timer } from 'rxjs';
import { finalize, takeUntil, filter, skip, debounceTime, distinctUntilChanged, tap, take, timeout } from 'rxjs/operators';

import { DatetimeService } from '@services/datetime.service';
import { Company } from '@modules/companies/interfaces/companies';
import { EventsService } from '@modules/events/services/events.service';
import { EventField } from '@app/shared-stores/event-params/event-params.model';
import { CompaniesService } from '@modules/companies/services/companies.service';
import { CompanyIndex, DateRange, FilterAction, IndexField, QueryData } from '@modules/events/interfaces/events';
import { ColumnManagerComponent } from '../column-manager/column-manager.component';
import { HelpDialogModalComponent } from '@sweet-shared/components/help-dialog-modal/help-dialog-modal.component';
import { QueryWidgetComponent } from '@modules/widgets/components/query-widget/query-widget.component';
import { AppService } from '@app/app.service';
import { CUSTOM_DATE, RELATIVE_DATE } from '@sweet-shared/datetime-picker/datetime-picker.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss']
})
export class EventFilterComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  private allColumns: EventField[] = [];
  @ViewChild('queryString') queryString: ElementRef;

  loading = false;
  defaultSelectedDate = '';
  eventDateOptions = [];
  dateRange$ = new BehaviorSubject(null);
  private _filters = null;
  customDate$ = new BehaviorSubject(null);

  // Input for the loader
  @Input() searching = false;
  @Input() error = null;
  @Input() activateColumnManager = new EventEmitter();

  // Output for the action
  @Output() action: EventEmitter<FilterAction> = new EventEmitter();


  // Filters options
  dateRangeOptions: DateRange[] = [];
  columnOptions: IndexField[] = [];
  companiesOptions: Company[] = [];
  filteredCompaniesOptions: Company[] = [];
  indexOptions: CompanyIndex[] = [];
  filteredIndexOptions: CompanyIndex[] = [];
  dateRange: { from: string, to: string } = null;

  // selected cols
  selectedColumns: string[] = [];
  selectedSortColumns: any[] = [];
  queryRuleGroups: any[];
  queryRuleGroups$ = new BehaviorSubject<{ ruleGroups: any[], data: string }>(null);

  // Filter form
  form: UntypedFormGroup;
  queryFilterValue: UntypedFormControl = new UntypedFormControl('');

  // flag
  flagValue = null;

  constructor(
    private fb: UntypedFormBuilder,
    private eventService: EventsService,
    private companyService: CompaniesService,
    private datetimeService: DatetimeService,
    private dialog: MatDialog,
    private httpClient: AppService<any>,
    private route: ActivatedRoute
  ) {
    // Get the event filter from the session storage if any
    this.form = this.fb.group({
      dateRange: [null, [Validators.required]],
      company: [null, [Validators.required]],
      index: [null, [Validators.required]],
      selectedFields: [[], []]
    });
    this.route.queryParams.pipe(takeUntil(this.destroyer$)).subscribe(params => {
      if (params && Object.keys(params).length > 0) {
        const { query } = params;
        sessionStorage.setItem('event-query', query ?? null);
        this.flagValue = params;
      }
    })

    this.form.controls.company.valueChanges.pipe(takeUntil(this.destroyer$), filter(c => !!c)).subscribe(company => {

      this.eventService.fetchIndexes(company).pipe(takeUntil(this.destroyer$)).subscribe((indexes: CompanyIndex[]) => {
        this.indexOptions = indexes.sort((a, b) => a.friendly.toLowerCase() > b.friendly.toLowerCase() ? 1 : -1);
        this.filterIndex('');
        const selectedIndex = this.form.controls.index.value;
        if (selectedIndex) {
          this.updateAvailableColumns(selectedIndex);
        }
      });
    });

    this.form.controls.dateRange.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(value => this.dateChangeHandler(value));
    this.queryFilterValue.valueChanges
      .pipe(
        filter(Boolean), // Skip null value and only accept valid string.
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroyer$)
      ).subscribe(text => {
        if (!text) {
          sessionStorage.removeItem('event-query');
          this.prepopulateQueryFilter();
          return;
        }
        this.httpClient.post('query-translator', '', null, {
          index: this.form.controls.index.value,
          value: text
        }).then((resp) => {
          let obj = {}
          const keys = Object.keys(resp);
          if (keys.length) {
            obj = { condition: keys[0], rules: this.parseRules(resp[keys[0]]) };
            sessionStorage.setItem('event-query', JSON.stringify(obj));
            this.queryFilterValue.setErrors(null);
            this.prepopulateQueryFilter();
          }
        }).catch((error) => {
          this.queryFilterValue.setErrors({ invalid: true });
        })
      });

  }

  private parseRules(rules: any[]): any[] {
    return rules.reduce((prev, next) => {
      if (Object.keys(next).includes('field')) {
        prev.push(next);
      } else {
        const conditions = Object.keys(next);
        prev.push({ condition: conditions[0], rules: this.parseRules(next[conditions[0]]) });
      }
      return prev;
    }, []);
  }

  private updateAvailableColumns(indexId: string): void {
    let indexData = this.indexOptions.find(index => index.id === indexId);
    if (!indexData) {
      indexData = this.indexOptions.find(index => index.id === 'default');
    }
    if (indexData) {
      this.columnOptions = indexData.fields;
      this.updateSelectedColumns(indexId, indexData.default_headers);

      // emit all available cols
      this.action.emit({ name: 'AVAILABLE_COLUMNS', data: indexData.fields });
    }
  }

  private updateSelectedColumns(indexId: string, defaultHeaders): void {
    this.eventService.fecthUserFieldSet(indexId).pipe(takeUntil(this.destroyer$)).subscribe({
      next: (response: any) => {
        this.selectedColumns = response.headers;
        this.selectedSortColumns = response.sort_by ? [response.sort_by] : [];
        this.action.emit({ name: 'COLUMN', data: response.headers });
        this.action.emit({ name: 'UPDATE_FIELD', data: response });
      },
      error: (error) => {
        // not found create one
        this.eventService.updateUserFieldSet(indexId, defaultHeaders).pipe(takeUntil(this.destroyer$)).subscribe({
          next: (res) => {
            this.selectedColumns = res.headers;
            this.action.emit({ name: 'COLUMN', data: res.headers });
            this.action.emit({ name: 'UPDATE_FIELD', data: res });
          },
          error: (er) => {
            this.selectedColumns = defaultHeaders;
            this.action.emit({ name: 'COLUMN', data: defaultHeaders });
            this.action.emit({ name: 'UPDATE_FIELD', data: { headers: defaultHeaders } });
          }
        });
      }
    });
  }

  private setupIndexChangeHandler(): void {
    this.form.controls.index.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(indexId => this.updateAvailableColumns(indexId));
    this.form.updateValueAndValidity();
  }

  private prepopulateQueryFilter(): void {
    this.queryRuleGroups = JSON.parse(sessionStorage.getItem('event-query') || 'null');
    if (this.queryRuleGroups) {
      this.queryFilterValue.setValue(QueryWidgetComponent.buildQuery(this.queryRuleGroups));
      this.queryRuleGroups$.next({ ruleGroups: this.eventService.formatQueryForAthena(this.queryRuleGroups as any) as any, data: this.queryFilterValue.value });
    } else {
      this.queryRuleGroups$.next({ ruleGroups: null, data: this.queryFilterValue.value });
    }
  }

  ngOnInit(): void {
    const eventFilters = JSON.parse(sessionStorage.getItem('event-filters') || 'null');
    forkJoin({
      eventParams: this.eventService.fetchEventParams(),
      user: Auth.currentAuthenticatedUser(),
      companies: this.companyService.fetchCompanies(),
      userPreferences: this.eventService.fetchUserPreference(),
    }).pipe(
      takeUntil(this.destroyer$),
      finalize(() => this.loading = false)
    ).subscribe({
      next: (response) => {

        this.dateRangeOptions = response.eventParams.eventDateRanges;
        this.allColumns = response.eventParams.fields;
        this.companiesOptions = response.companies.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        this.filteredCompaniesOptions = this.companiesOptions;
        const sessionDateRange = JSON.parse(sessionStorage.getItem('event-dateRange') || 'null');
        // Set the default value of the form
        if (this.flagValue) {
          this.customDate$.next({
            type: this.flagValue.dateType === 'maya' ? RELATIVE_DATE : CUSTOM_DATE, from: this.flagValue.dateFrom, to: this.flagValue.dateTo
          });
        }

        this.form.patchValue({
          company: this.flagValue?.companyFilter ?? eventFilters?.company ?? response.user.attributes.profile,
          index: this.flagValue?.index_name ?? eventFilters?.index ?? 'default',
          dateRange: eventFilters?.dateRange ?? sessionDateRange ?? response.userPreferences.dateRange,
        });
        this.setupIndexChangeHandler();

        // trigger the search
        setTimeout(() => this.performSearch(), 500);
      },
      error: (error) => {
        this.error = 'Failed to initialize the page';
      }
    });

    this.queryRuleGroups$.pipe(takeUntil(this.destroyer$)).subscribe(value => {
      if (value) {
        this.queryFilterValue.setValue(value.data);
        this.queryRuleGroups = value.ruleGroups;
      }
    });

    this.prepopulateQueryFilter();

    // subscribe on opening the column manager
    this.activateColumnManager.pipe(takeUntil(this.destroyer$)).subscribe(_ => this.openColumnManager());

    // subscribe to the event
    this.eventService.event$.pipe(
      takeUntil(this.destroyer$),
      filter(evt => ['UPDATED_QUERY_FILTER', 'SEARCHING'].includes(evt.name)),
    ).subscribe(evt => {
      const { name, data } = evt;
      if (name === 'UPDATED_QUERY_FILTER') {
        this.queryRuleGroups$.next({ ruleGroups: data, data: QueryWidgetComponent.buildQuery(data) });
      } else if (name === 'SEARCHING') {
        if (data) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      }
    });
  }

  filterCompany(searchTerm: string): void {
    this.filteredCompaniesOptions = this.companiesOptions.filter(c => (c.name + ' ( ' + c.id + ' )').toLowerCase().includes(searchTerm.toLowerCase()));
  }

  filterIndex(searchTerm: string): void {
    this.filteredIndexOptions = this.indexOptions.filter(i => i.friendly.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  openHelpDialog(): void {
    this.dialog.open(HelpDialogModalComponent,
      {
        height: '80vh',
        data: {
          articleTitle: 'Events Section Overview...'
        }
      }
    );
  }

  // comparator for date time range
  dateTimeComparator(option, value): boolean {
    return option.type === value.type && option.from === value.from && option.to === value.to;
  }

  dateChangeHandler(value): void {
    if (value) {
      forkJoin({
        from: this.datetimeService.validateMayTime(value.from),
        to: this.datetimeService.validateMayTime(value.to)
      }).pipe(takeUntil(this.destroyer$)).subscribe({
        next: (response) => {
          this.dateRange = { from: new Date(response.from.date).toISOString(), to: new Date(response.to.date).toISOString() };

          // persist it in session storage
          sessionStorage.setItem('event-dateRange', JSON.stringify(value));
        },
        error: (error) => {
          this.error = error.message;
        }
      });
    }
  }

  updateColumnOrSorting(evt): void {
    const { name, type, data } = evt;
    if (name === 'UPDATE') {
      if (type === 'COLUMN') {
        this.selectedColumns = data;
        this.action.emit({ name: 'COLUMN', data });
      }
    } else if (name === 'UPDATE_FIELD') {
      this.action.emit({ name: 'UPDATE_FIELD', data });
    }
  }

  openColumnManager(): void {
    const dialogRef = this.dialog.open(ColumnManagerComponent, {
      width: '80%',
      height: '70vh',
      disableClose: true,
    });

    // set the input and output
    dialogRef.componentInstance.availableColumns = this.columnOptions;
    dialogRef.componentInstance.selectedColumns = this.selectedColumns;
    dialogRef.componentInstance.indexId = this.form.controls.index.value;
    dialogRef.componentInstance.action.pipe(takeUntil(this.destroyer$)).subscribe(evt => this.updateColumnOrSorting(evt));
  }

  openQueryBuilder() {
    const data: QueryData = {
      rules: [{ field: '', operator: '=', value: '' }],
      formatWithColon: false,
      page: 'event'
    };
    let sessionRules: any = sessionStorage.getItem(`${data.page}-query`);
    sessionRules = sessionRules ? JSON.parse(sessionRules) : sessionRules = [];
    (sessionRules && sessionRules[0] && sessionRules[0].rules && sessionRules[0].rules.length) ? data.ruleGroups = sessionRules : data.ruleGroups = [{ condition: 'AND', rules: data.rules }];
    const dialogRef = this.dialog.open(QueryWidgetComponent, {
      disableClose: false,
      panelClass: 'ctl-panel-class',
      minWidth: '60vw',
      data
    });
    dialogRef.componentInstance.widget = {
      index_name: this.form.controls.index.value,
    };
    dialogRef.componentInstance.queryName = 'event-query';
    dialogRef.afterClosed().pipe(takeUntil(this.destroyer$)).subscribe(val => {
      if (val?.name === 'QUERY_WIDGET_SET') {
        this.queryRuleGroups$.next({ ruleGroups: val.value, data: val.queryString });
      }
    });
  }

  performSearch(): void {
    const dateRange = this.dateRange$.getValue();
    const payload = {
      query: this.queryRuleGroups,
      dateFrom: dateRange?.from,
      dateTo: dateRange?.to,
      dateType: dateRange?.type,
      index_name: this.form.controls.index.value,
      companyFilter: this.form.controls.company.value,
      size: 500,
    };
    this.eventService.setEventFilters({ ...payload, dateRange: this.form.controls.dateRange.value });
    this.action.emit({ name: 'SEARCH', data: payload });
  }

  handleDateSelected(payload: any) {
    this.dateRange$.next(payload?.selected);
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
