import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {QueryBuilderConfig} from 'angular2-query-builder';

@Component({
  selector: 'app-option-static-dropdown',
  templateUrl: './option-static-dropdown.component.html',
  styleUrls: ['./option-static-dropdown.component.scss']
})
export class OptionStaticDropdownComponent implements OnInit {
  @Output() formatQueryAthena: EventEmitter<any> = new EventEmitter();
  @Output() optionFormValue: EventEmitter<any> = new EventEmitter();
  operators: any = {
    '=': 'string',
    '!=': 'string',
    '<': 'string',
    '<=': 'string',
    '>': 'string',
    '>=': 'string',
    contains: 'string',
    like: 'string',
    in: 'array',
    incidr: 'array',
    'is null': null,
    'is not null': null,
    between: 'array'
  };

  optionForm: UntypedFormGroup;
  optionItems: UntypedFormArray;
  isQuery = false;

  selectedEvent = '';
  filterInformationModel: {};
  filterInformationAvailableFields: QueryBuilderConfig = {
    fields: {}
  };
  @Input() filterInformationAvailable;
  inputVariablesModel: any = {};

  fieldOption: any[] = [
    {value: 'string', friendly: 'String'},
    // {value: 'query', friendly: 'Query'}
  ];

  constructor(private formBuilder: UntypedFormBuilder) {
    this.optionForm =  this.createForm();

    this.optionForm.get('type').valueChanges.subscribe(v => {
      this.isQuery = v === 'query';
      if (v === 'query') {
        this.optionForm.patchValue({
          staticData: [
            {
              key: '',
              model: {}
            }
          ]
        });
      }
    });
    this.onChangesForm();
  }

  ngOnInit(): void {
    this.filterInformationAvailableFields.fields = this.filterInformationAvailable;
  }

  onChangeSelect(event) {
    this.selectedEvent = event;
  }

  get optionControl() {
    return this.optionForm.get('staticData');
  }

  createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      inputName: ['', []],
      type: ['string', []],
      staticData: this.formBuilder.array(
        [this.createStringForm()]
      )
    });
  }

  createStringForm(): UntypedFormGroup {
    return this.formBuilder.group({
      label: ['', []],
      value: ['', []]
      // value: [this.isQuery ? {} : '', []]
    });
  }

  addAnotherForm() {
    this.optionItems = this.optionForm.get('staticData') as UntypedFormArray;
    this.optionItems.push(this.createStringForm());
  }

  removeAddress(i: number) {
    this.optionItems.removeAt(i);
  }

  formatQueryForAthena(queryModel: any): void {
    this.formatQueryAthena.emit({name: 'queryModel', data: queryModel});
  }

  onChangesForm() {
    this.optionForm.valueChanges.subscribe(val => {
      this.optionFormValue.emit({name: 'optionFormValue', data: val});
    });
  }
}
