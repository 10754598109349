import { SweetSharedModule } from 'src/app/sweet-shared/sweet-shared.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';
import {LoginComponent} from './components/login/login.component';
import {AmplifyAngularModule} from 'aws-amplify-angular';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SplashScreenComponent} from './components/splash-screen/splash-screen.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MfaVerifierComponent } from './components/mfa-verifier/mfa-verifier.component';
import { ForceChangePasswordComponent } from './components/force-change-password/force-change-password.component';


@NgModule({
  declarations: [
    LoginComponent,
    LoginFormComponent,
    SplashScreenComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MfaVerifierComponent,
    ForceChangePasswordComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    AmplifyAngularModule,
    FlexLayoutModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    SweetSharedModule,
    FormsModule,
    MatSnackBarModule,
    MatDividerModule,
    MatIconModule,
    MatProgressBarModule
  ]
})
export class AuthModule {}
