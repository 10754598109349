<app-portal-loader
  [loadingStatus]="indexesOptions.length === 0 || loading"
></app-portal-loader>
<div class="dialog-wrapper">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <h2 mat-dialog-title>{{ data.title }}</h2>
    </div>
  </div>

  <mat-dialog-content>
    <p class="error" *ngIf="errorMessage">
      {{ errorMessage }}. Please try again later
    </p>
    <mat-horizontal-stepper
      linear="true"
      #stepper
      *ngIf="indexesOptions.length > 0"
    >
      <!-- Step 1 - Widget Title, Description, Type, Source, Index -->
      <mat-step
        [stepControl]="generalInformationForm"
        [editable]="!loading"
        [completed]="
          !data.isNew ||
          (generalInformationForm.valid && generalStep.interacted)
        "
        #generalStep
      >
        <ng-template matStepLabel>General</ng-template>
        <form [formGroup]="generalInformationForm" class="layout-padding">
          <formly-form
            [model]="generalInformationModel"
            [fields]="generalInformationFields"
            [form]="generalInformationForm"
          ></formly-form>
        </form>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button color="warn" matDialogClose>Cancel</button>
          <button
            mat-stroked-button
            matStepperNext
            [disabled]="!generalInformationForm.valid"
            *ngIf="
              !generalStepSourceOnly.includes(generalInformationModel.source)
            "
          >
            Next
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <button
            mat-stroked-button
            matStepperNext
            [disabled]="!generalInformationForm.valid"
            *ngIf="
              generalStepSourceOnly.includes(generalInformationModel.source)
            "
            (click)="createIngestionWidget()"
          >
            Submit
          </button>
        </mat-dialog-actions>
      </mat-step>

      <!-- Step 2 - Date Range information. Static and Relative. -->
      <mat-step
        *ngIf="
          !['query_builder', 'string_dropdown', 'map', 'text_input'].includes(
            generalInfoType
          ) &&
          generalInformationForm.value.source !== 'list' &&
          !generalStepSourceOnly.includes(generalInformationModel.source)
        "
        [stepControl]="dateRangeInformationForm"
        [editable]="!loading"
        [completed]="
          !data.isNew ||
          (dateRangeInformationForm.valid && dateRangeStep.interacted)
        "
        #dateRangeStep
      >
        <ng-template matStepLabel>Date Range</ng-template>

        <form [formGroup]="dateRangeInformationForm" class="layout-padding">
          <div>
            <div>
              <app-datetime
                placeholder="Start Date"
                formControlName="dateFrom"
              ></app-datetime>
            </div>
            <div>
              <app-datetime
                [minDate]="maxDate"
                placeholder="End Date"
                formControlName="dateTo"
              ></app-datetime>
            </div>
          </div>
        </form>

        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button color="warn" matDialogClose>Cancel</button>
          <button mat-stroked-button matStepperPrevious>Prev</button>
          <button
            mat-stroked-button
            matStepperNext
            [disabled]="!dateRangeInformationForm.valid"
          >
            Next
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </mat-dialog-actions>
      </mat-step>

      <!--      Step 3 - Field selection. From field type, name, quantifiers, etc.-->
      <mat-step
        *ngIf="
          !['query_builder', 'string_dropdown', 'map', 'text_input'].includes(
            generalInfoType
          ) &&
          generalInformationForm.value.source !== 'list' &&
          !generalStepSourceOnly.includes(generalInformationModel.source)
        "
        [stepControl]="dropdownWidgetQueryForm"
        [editable]="!loading"
        [completed]="
          !data.isNew ||
          (dropdownWidgetQueryForm.valid && fieldsStep.interacted)
        "
        #fieldsStep
      >
        <ng-template matStepLabel>Field(s)</ng-template>
        <form [formGroup]="dropdownWidgetQueryForm" class="layout-padding">
          <p>Select the field(s) for your widget.</p>
          <formly-form
            [model]="dropdownWidgetQueryModel"
            [fields]="dropdownWidgetQueryFields"
            [form]="dropdownWidgetQueryForm"
          ></formly-form>
        </form>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button color="warn" matDialogClose>Cancel</button>
          <button mat-stroked-button matStepperPrevious>Prev</button>
          <button mat-stroked-button matStepperNext>Next</button>
        </mat-dialog-actions>
      </mat-step>

      <!--      Step * - Option selection.-->
      <mat-step
        *ngIf="
          generalInfoType === 'string_dropdown' &&
          !['map', 'text_input'].includes(generalInfoType) &&
          generalInformationForm.value.source !== 'list' &&
          !generalStepSourceOnly.includes(generalInformationModel.source)
        "
        [stepControl]="optionInformationForm"
        [editable]="!loading"
        [completed]="
          !data.isNew || (optionInformationForm.valid && fieldsStep.interacted)
        "
        #fieldsStep
      >
        <ng-template matStepLabel>Option Field</ng-template>

        <app-option-static-dropdown
          [filterInformationAvailable]="
            this.filterInformationAvailableFields.fields
          "
          (formatQueryAthena)="optionFormDataEvent($event)"
          (optionFormValue)="optionFormDataEvent($event)"
        >
        </app-option-static-dropdown>

        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button color="warn" matDialogClose>Cancel</button>
          <button mat-stroked-button matStepperPrevious>Prev</button>
          <button mat-stroked-button matStepperNext>Next</button>
        </mat-dialog-actions>
      </mat-step>

      <!--      Step 4 - Query Filter. User builds custom query here-->
      <mat-step
        *ngIf="
          !['query_builder', 'string_dropdown', 'map', 'text_input'].includes(
            generalInfoType
          ) &&
          generalInformationForm.value.source !== 'list' &&
          !generalStepSourceOnly.includes(generalInformationModel.source)
        "
        [stepControl]="filterInformationForm"
        [editable]="!loading"
        [completed]="
          !data.isNew || (filterInformationForm.valid && filterStep.interacted)
        "
        #filterStep
      >
        <ng-template matStepLabel>Filters</ng-template>
        <query-builder
          [(ngModel)]="filterInformationModel"
          [config]="filterInformationAvailableFields"
          *ngIf="showFilterQueryBuilder"
          (ngModelChange)="
            formatQueryForAthena($event);
            validateInputVariables($event);
            validateQueryVariable($event)
          "
          #qbRef
        ></query-builder>
        <br />
        <mat-checkbox
          *ngIf="data.settingWidgets?.length"
          (change)="includeInputs($event)"
          [checked]="showInputVariables"
          >Include Input Variables?</mat-checkbox
        >
        <ng-container *ngIf="showInputVariables">
          <form>
            <formly-form
              [model]="inputVariablesModel"
              [form]="inputVariablesForm"
              [fields]="inputVariablesFormFields"
            >
            </formly-form>
          </form>
        </ng-container>
        <br />
        <mat-checkbox
          *ngIf="data?.queryWidgets?.length"
          (change)="includeQueryInputs($event)"
          [checked]="showQueryVariables"
          >Include Query Builder?</mat-checkbox
        >
        <ng-container *ngIf="showQueryVariables">
          <form>
            <formly-form
              [model]="queryVariablesModel"
              [form]="queryVariablesForm"
              [fields]="queryVariablesFormFields"
            >
            </formly-form>
          </form>
        </ng-container>

        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button color="warn" matDialogClose>Cancel</button>
          <button mat-stroked-button matStepperPrevious>Prev</button>
          <button
            mat-stroked-button
            matStepperNext
            [disabled]="!isFilterStepValid()"
          >
            Next
          </button>
        </mat-dialog-actions>
      </mat-step>

      <!--      Step 5 - Field Grouping.-->
      <!-- TODO Update to a function and call to avoid console log errors popping up -->
      <mat-step
        *ngIf="
          ![
            'query_builder',
            'string_dropdown',
            'map',
            'single_metric',
            'text_input'
          ].includes(generalInfoType) &&
          generalInformationForm.value.source !== 'list' &&
          !generalStepSourceOnly.includes(generalInformationModel.source)
        "
        [stepControl]="groupingInformationForm"
        [editable]="!loading"
        [completed]="
          !data.isNew ||
          (generalInformationForm.valid && groupingStep.interacted)
        "
        #groupingStep
      >
        <ng-template matStepLabel>Grouping</ng-template>
        <form [formGroup]="groupingInformationForm" class="layout-padding">
          <formly-form
            [model]="groupingInformationModel"
            [fields]="groupingInformationAvailableFields"
            [form]="groupingInformationForm"
            *ngIf="showGroupingInformation"
          ></formly-form>
        </form>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button color="warn" matDialogClose>Cancel</button>
          <button mat-stroked-button matStepperPrevious>Prev</button>
          <button
            mat-stroked-button
            matStepperNext
            [disabled]="!groupingInformationForm.valid"
          >
            Next
          </button>
        </mat-dialog-actions>
      </mat-step>

      <!--      Step 6  - Ordering-->
      <mat-step
        *ngIf="
          !['query_builder', 'map', 'single_metric', 'text_input'].includes(
            generalInfoType
          ) &&
          generalInformationForm.value.source !== 'list' &&
          !generalStepSourceOnly.includes(generalInformationModel.source)
        "
        [stepControl]="orderByInformationForm"
        [editable]="!loading"
        [completed]="
          !data.isNew ||
          (orderByInformationForm.valid && orderByStep.interacted)
        "
        #orderByStep
      >
        <ng-template matStepLabel>Order By</ng-template>
        <form [formGroup]="orderByInformationForm" class="layout-padding">
          <formly-form
            [model]="orderByInformationModel"
            [fields]="orderByInformationFields"
            [form]="orderByInformationForm"
            *ngIf="showGroupingInformation"
          ></formly-form>
        </form>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button color="warn" matDialogClose>Cancel</button>
          <button mat-stroked-button matStepperPrevious>Prev</button>
          <button
            mat-stroked-button
            matStepperNext
            [disabled]="!orderByInformationForm.valid"
          >
            Next
          </button>
        </mat-dialog-actions>
      </mat-step>

      <!--      Step 7 - Final step, limit-->
      <mat-step
        [stepControl]="resultInformationForm"
        [editable]="!loading"
        [completed]="
          !data.isNew || (resultInformationForm.valid && resultsStep.interacted)
        "
        #resultsStep
        *ngIf="!generalStepSourceOnly.includes(generalInformationModel.source)"
      >
        <ng-template matStepLabel>Results</ng-template>
        <form
          [formGroup]="resultInformationForm"
          class="layout-padding"
          *ngIf="
            !['text_input'].includes(generalInfoType) &&
            generalInformationForm.value.source !== 'list'
          "
        >
          <formly-form
            [model]="resultInformationModel"
            [fields]="resultInformationFields"
            [form]="resultInformationForm"
            *ngIf="showGroupingInformation"
          ></formly-form>
        </form>

        <!-- Template for the result step -->
        <template #resultContainer></template>

        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button color="warn" matDialogClose>Cancel</button>
          <button mat-stroked-button matStepperPrevious>Prev</button>
          <button
            mat-raised-button
            color="primary"
            (click)="createWidget()"
            [disabled]="loading || !canCreate"
          >
            Submit
          </button>
        </mat-dialog-actions>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
</div>
