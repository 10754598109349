<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="dialog-wrapper">
  <h2 mat-dialog-title>Edit User</h2>
  <mat-dialog-content>
    <form [formGroup]="userForm">
      <mat-form-field class="example-full-width" fxFlexFill>
        <mat-label>Profile</mat-label>
        <input matInput formControlName="profile" name="profile">
      </mat-form-field>

      <mat-form-field class="example-full-width" fxFlexFill>
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" name="username">
      </mat-form-field>

      <mat-form-field class="example-full-width" fxFlexFill>
        <mat-label>First Name</mat-label>
        <input formControlName="given_name" matInput name="given_name">
      </mat-form-field>

      <mat-form-field class="example-full-width" fxFlexFill>
        <mat-label>Last Name</mat-label>
        <input formControlName="family_name" matInput name="family_name">
      </mat-form-field>

      <mat-form-field fxFlexFill>
        <mat-label>Role(s)</mat-label>
        <mat-select multiple formControlName="gender">
          <mat-option *ngFor="let role of roles" [value]="role.value">
            {{role.friendly}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlexFill *ngIf="false">
        <mat-label>Permission(s)</mat-label>
        <mat-select multiple formControlName="gender">
          <mat-option *ngFor="let permission of permissions" [value]="permission.value">
            {{permission.friendly}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ifUserCan="'account.edit-permissions'">
        <app-granular-permissions [userDetails]='externalDetailData' [editMode]='true' (change)='onPermissionsChanged($event)'></app-granular-permissions>
      </ng-container>

      <!-- Action Buttons-->
      <mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button mat-button color="warn" type="submit" (click)="onSubmit('cancel')">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>
          <button mat-raised-button color="primary" type="submit" (click)="onSubmit('save')">
            <mat-icon>done</mat-icon>
            Update
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>
