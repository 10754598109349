import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { REALM, DOMAIN, GENDER } from '@modules/permission/constants/permission.constants';
import { Gender, Realm } from '@modules/permission/interfaces/permission';
import { UserService } from '@modules/user/services/user.service';
import { ExternalUser } from '@modules/user/interfaces/external-user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountsService} from '@app/pages/admin/services/accounts.service';

@Component({
  selector: 'app-add-external-user',
  templateUrl: './add-external-user.component.html',
  styleUrls: ['./add-external-user.component.scss']
})
export class AddExternalUserComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  addUserForm: UntypedFormGroup;
  error: string = null;
  panelOpenState = false;
  attributes: any[] = ['US Resident'];
  loading = false;

  @Input() companies: any[] = [];

  gender: Gender[] = [...GENDER];
  realm: Realm[] = [...REALM];
  domain: string[] = [...DOMAIN];
  // controller to show and hide permissions selector
  showPermissions = false;
  userRoles$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddExternalUserComponent>,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private accountService: AccountsService
  ) { }

  ngOnInit() {

    if (this.data.loggedUser) {
      this.addUserForm = this.fb.group({
        profile: [{value: '', disabled: true}, [Validators.required]],
        domain: [{value: '', disabled: true}, [Validators.required]],
        realm: [{value: '', disabled: true}, [Validators.required]],
        username: [{value: '', disabled: true}, [Validators.required]],
        given_name: ['', [Validators.required]],
        family_name: ['', [Validators.required]],
        gender: [[], [Validators.required]],
        access_attributes: [[], []],
        blocked_permissions: [[], []],
        allowed_permissions: [[], []]
      });
    } else {
      this.addUserForm = this.fb.group({
        profile: [{ value: this.accountService.getCompanyFilter(), disabled: true }, [Validators.required]],
        domain: ['', [Validators.required]],
        realm: ['', [Validators.required]],
        username: ['', [Validators.required]],
        given_name: ['', [Validators.required]],
        family_name: ['', [Validators.required]],
        gender: [[], [Validators.required]],
        access_attributes: [[], []],
        blocked_permissions: [[], []],
        allowed_permissions: [[], []]
      });
    }

    if (this.data.loggedUser) {
      this.addUserForm.patchValue({
        profile: this.accountService.getCompanyFilter(),
        username: this.data.loggedUser.username,
        realm: this.data.loggedUser.realm,
        given_name: this.data.loggedUser.given_name,
        family_name: this.data.loggedUser.family_name,
        domain: this.data.loggedUser.domain,
        access_attributes: this.data.loggedUser.access_attributes,
        gender: this.data.loggedUser.gender,
        allowed_permissions: Object.assign([], this.data.loggedUser.allowed_permissions),
        blocked_permissions: Object.assign([], this.data.loggedUser.blocked_permissions)
      });
      this.userRoles$.next(this.data.loggedUser.gender);
    }


    this.addUserForm.get('gender').valueChanges.subscribe(selectedRole => this.userRoles$.next(selectedRole));
  }

  updatePermissions(evt: { allowed_permissions: string[], blocked_permissions: string[] }): void {
    this.addUserForm.patchValue(evt);
  }

  onPermissionChanges(evt): void {
    this.addUserForm.patchValue(evt);
  }

  onSubmit() {
    if (this.addUserForm.valid) {
      this.loading = true;
      if (this.data.loggedUser) {
        // here we are updating
        this.userService.updateExternalUser(this.addUserForm.getRawValue())
          .subscribe({
            next: (user: ExternalUser) => this.dialogRef.close(user),
            error: (error) => this.snackbar.open('An error occurred. Please try again later.')
          });
      } else {
        // create user
        this.userService.createExternalAccount(this.addUserForm.getRawValue())
          .subscribe({
            next: (user: ExternalUser) => this.dialogRef.close(user),
            error: (error) => this.snackbar.open('An error occurred. Please try again later.')
          });
      }

    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}



