<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="dialog-wrapper">
  <h2 mat-dialog-title>Create Dashboard(s)</h2>
  <mat-dialog-content>
    <form [formGroup]="dashboardForm" *ngFor="let dashboardForm of dashboardForms; index as i;">
      <div fxLayout="column">
        <h3 *ngIf='dashboardForms.length>1'><strong>#{{i + 1}}</strong></h3>
      </div>
      <mat-form-field fxFlexFill>
        <mat-label>Dashboard Template</mat-label>
        <mat-select formControlName="template_name" required>
          <mat-optgroup *ngFor="let group of dashboardTemplate" [label]="group.groupName | titlecase" [disabled]="group.disabled">
            <mat-option *ngFor="let template of group.option" [value]="template.value">
              {{template.friendly}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlexFill class="example-full-width">
        <mat-label>Title</mat-label>
        <input formControlName="title" matInput type="text" placeholder="Title" required>
      </mat-form-field>
      <mat-form-field fxFlexFill class="example-full-width">
        <mat-label>Description</mat-label>
        <textarea formControlName="description" matInput placeholder="Description"></textarea>
      </mat-form-field>
      <div fxLayout='column'>
        <button mat-stroked-button color="warn" *ngIf="dashboardForms.length !==1" (click)="delete(i)">
          <mat-icon>remove</mat-icon>
          Remove
        </button>
      </div>
      <mat-divider inset class="dashboard_divider"></mat-divider>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
      <button style="margin-top:8px" (click)=addDashboard() type="button" mat-stroked-button>
        <mat-icon>add</mat-icon>
        Add Another
      </button>
      <div>
        <button mat-button color="warn" (click)="onSubmit('cancel')">
          <mat-icon>close</mat-icon>
          Cancel
        </button>
        <button mat-raised-button color="primary" [disabled]="!!!valid || loading" (click)="onSubmit('submit')">
          <mat-icon>done</mat-icon>
          Submit
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</div>



