import { UserPreference } from './../../user-preference/store/user-preference.model';
import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Incident, Worklog, IncidentAttribute, IncidentUpdateInterface, IncidentWorklog} from './incident.model';


// Get all incidents
export const searchIncidents = createAction(
  '[Incident/API] Search Incidents',
  props<{ searchParams: object }>()
);

export const clearIncidents = createAction(
  '[Incident/API] Clear Incidents',
  props<{}>()
);

export const loadIncidentsSuccess = createAction(
  '[Incident/API] Load Incidents Success',
  props<{ incidents: Incident[], total: number }>()
);

export const loadIncidentsError = createAction(
  '[Incident/API] Load Incidents Error',
  props<{ error: string }>()
);

// Get single incident
export const getIncident = createAction(
  '[Incident/API] Get Single Incident',
  props<{ incidentId: string }>()
);

export const getIncidentSuccess = createAction(
  '[Incident/API] Get Single Incident Success',
  props<{ incident: Incident }>()
);

export const getIncidentError = createAction(
  '[Incident/API] Get Single Incident Error',
  props<{ error: string }>()
);

export const clearSelectedIncident = createAction(
  '[Incident/API] Clear Selected  Single Incident'
);

// Investigation Queue changed.
export const updateIncident = createAction(
  '[Incident/API] Incident Update Incident',
  props<{incidentId: string, value: IncidentUpdateInterface, userPreferences?: UserPreference}>()
);

export const updateIncidentSuccess = createAction(
  '[Incident/API] Incident update Success',
  props<{ incident: Incident }>()
);

export const dismissIncidentSuccess = createAction(
  '[Incident/API] Incident dismiss Success',
  props<{ incident: Incident }>()
);


export const dismissIncidentError = createAction(
  '[Incident/API] Incident dismiss Error',
  props<{ error: string }>()
);

export const updateIncidentError = createAction(
  '[Incident/API] Incident update Error',
  props<{ error: string }>()
);

export const updateIncidentStatus = createAction(
  '[Incident/API] Incident Update Status',
  props<{ statusId: string, userPreferences?: UserPreference}>()
)


// Worklog Actions
export const loadWorklog = createAction(
  '[Incident/API] Load Worklogs',
  props<{ incidentId: string }>()
);

export const loadWorklogSuccess = createAction(
  '[Incident/API] Load Worklog Success',
  props<{ incidentId: string, worklogs: Worklog[] | IncidentWorklog[]}>()
);

export const loadWorklogError = createAction(
  '[Incident/API] Load Worklog Error',
  props<{ error: string }>()
);

export const loadingWorklogs = createAction(
  '[Incident/API] Loading Worklogs',
  props<{loading: boolean}>()
);

// Worklog Assign
export const worklogAssign = createAction(
  '[Incident/API] Assign Worklog',
  props<{ username: string, incidentId: string}>()
);

export const worklogAssignSuccess = createAction(
  '[Incident/API] Assign Worklog Success',
  props<{ incident: Incident }>()
);

export const worklogAssignError = createAction(
  '[Incident/API] Assign Worklog Error',
  props<{ error: string }>()
);

// Investigation Attributes
export const loadIncidentAttributes = createAction(
  '[Incident/API] Load Incident Attributes'
);

export const incidentAttributesSuccess = createAction(
  '[Incident/API] Load Incident Attributes Success',
  props<{ attributes: IncidentAttribute }>()
);

export const incidentAttributesError = createAction(
  '[Incident/API] Load Incident Attributes Error',
  props<{ error: string }>()
);

export const incidentCreate = createAction(
  '[Incident/API] - Create an incident',
  props<{incident: any}>()
);

export const incidentCreateSuccess = createAction(
  '[Incident/API] - Create an incident Success',
  props<{incident: any, makeActive?: boolean, userPreferences?: UserPreference}>()
);

export const incidentCreateError = createAction(
  '[Incident/API] - Create an incident Error',
  props<{error: string}>()
);
