import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Preferences } from '../interfaces/preferences';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  private userPreferenceSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  userPreferences: Observable<any> = this.userPreferenceSubject.asObservable();

  private errorSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  error$: Observable<string> = this.errorSubject.asObservable();

  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(private httpClient: AppService<any>) {
    this.httpClient.get('userPreferences', '', null)
      .then(response => this.userPreferenceSubject.next(response))
      .catch(error => this.errorSubject.next(error.message))
      .finally(() => this.loadingSubject.next(false));
  }

  setUserPreferences(value): void {
    localStorage.setItem('userPreferences', JSON.stringify(value));
    this.userPreferenceSubject.next(value);
  }

  updateUserPreference(payload: Preferences): void {
    // check to make sure active investigation is not null
    payload = { ...payload, activeInvestigation: payload.activeInvestigation ?? "-" };
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.httpClient.put('userPreferences', '', null, payload)
      .then(response => {
        localStorage.setItem('userPreferences', JSON.stringify(response));
        const incidentSearchPayload = JSON.parse(localStorage.getItem('incidentSearchPayload') || 'null');
        if (incidentSearchPayload) {
          // update the search payload as well
          localStorage.setItem('incidentSearchPayload', JSON.stringify({
            ...incidentSearchPayload,
            company: response.incidentsSearch.companies ? response.incidentsSearch.companies : incidentSearchPayload.company
          }));
        }
      })
      .catch(error => this.errorSubject.next(error.message))
      .finally(() => this.loadingSubject.next(false));
  }
}
