import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-input-variable-dialog-form',
  templateUrl: './input-variable-dialog-form.component.html',
  styleUrls: ['./input-variable-dialog-form.component.scss']
})
export class InputVariableDialogFormComponent implements OnInit {
  @Input() inputVariables: any;

  form: UntypedFormGroup;
  variables: string[] = [];

  constructor(private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<InputVariableDialogFormComponent>) { }

  ngOnInit(): void {
    this.variables = Object.keys(this.inputVariables)
    this.generateInputVariableForm();
  }

  private generateInputVariableForm(): void {
    this.form = this.fb.group({});
    Object.keys(this.inputVariables).forEach(variableName => {
      this.form.addControl(variableName, new UntypedFormControl(this.inputVariables[variableName], [Validators.required, Validators.minLength(1)]));
    });
  }

  submit(): void {
    if (this.form.valid) {
      this.dialogRef.close({submit: true, data: this.form.value});
    }
  }

  cancel(): void {
    this.dialogRef.close({submit: false});
  }
}
