<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="card-title">
    <span><h2>Log Streams</h2></span>
</div>
<section>
    <div class="layout-padding">
        <mat-card appearance="outlined" class="card-style">
            <mat-card-title>Filters</mat-card-title>
            <div class="search">
              <mat-form-field>
                <mat-label>Company</mat-label>
                <mat-select (selectionChange)="onSelectChange($event)">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="searchCompany" noEntriesFoundLabel="'No results found'"
                                           placeholderLabel="Search Company" [preventHomeEndKeyPropagation]="true"></ngx-mat-select-search>
                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                  </mat-option>
                  <mat-option *ngFor="let company of companies | inputSearch:searchCompany?.value"
                              [value]="company">{{company.friendly}}</mat-option>
                </mat-select>
              </mat-form-field>
                <button mat-stroked-button (click)="searchFilterActionHandler(selectedCompany)">Search</button>
            </div>
        </mat-card>
        <br>
        <mat-card appearance="outlined" class="card-style">
            <mat-card-title>  Streams: {{this.shownCompany}}</mat-card-title>
            <div fxLayout="row" fxLayoutAlign="end center">
                <div (click)="onHelpClick()" aria-label="Log Stream Help">
                <button mat-icon-button>
                    <mat-icon>help</mat-icon>
                </button>
                </div>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="buttons" (click)="logStreamModal()">
                        <mat-icon>add_box</mat-icon>
                        <span>Create Logstream</span>
                    </button>

                </mat-menu>
            </div>
            <div>
                <app-table
                        [data]="logStreamList"
                        [headerStream]="lostStreamHeader"
                        (events)=onRowClick($event)
                        pageSize="10"
                        #logStreamTable>
                </app-table>
            </div>
        </mat-card>
    </div>
</section>
