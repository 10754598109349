import {Action, createReducer, on} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {Company} from './company.model';
import * as CompanyActions from './company.actions';

export const companiesFeatureKey = 'companies';

export interface State extends EntityState<Company> {
  loadingCompanies: boolean;
  error: string;
  companies: Company[];
  companiesMap: {[key: string]: string};
}

export const adapter: EntityAdapter<Company> = createEntityAdapter<Company>();

export const initialState: State = adapter.getInitialState({
  loadingCompanies: false,
  error: null,
  companies: null,
  companiesMap: null
});

const companyReducer = createReducer(
  initialState,
  on(CompanyActions.loadCompanies,
    (state, action) => {
      return {
        ...state,
        loadingCompanies: true,
        error: null
      };
    }
  ),
  on(CompanyActions.loadCompaniesSuccess,
    (state, action) => {
    const companiesMap = action.companies.reduce((prev, curr) => {
      prev[curr.id] = curr.name;
      return prev;
    }, {});
    return {
      ...state,
      loadingCompanies: false,
      error: null,
      companies: action.companies,
      companiesMap
    };
    }
  ),
  on(CompanyActions.loadCompaniesError,
    (state, action) => {
      return {
        ...state,
        loadingCompanies: false,
        error: action.error,
        companies: [],
        companiesMap: {}
      };
    }
  ),
  on(CompanyActions.editCompany,
    (state, action) => {
      return {
        ...state,
        loadingCompanies: true
      };
    }
  ),
  on(CompanyActions.editCompanySuccess,
    (state, action) => {
      const companiesCopy = !!state.companies ? [...state.companies] : [];
      const index = companiesCopy.findIndex(company => company.id === action.company.id);
      companiesCopy.splice(index, 1, action.company);
      return {
        ...state,
        loadingCompanies: false,
        companies: companiesCopy,
        companiesMap: {
          ...state.companiesMap,
          [action.company.id]: action.company.name
        }
      };
    }
  ),
  on(CompanyActions.editCompanyError,
    (state, action) => {
      return {
        ...state,
        loadingCompanies: false,
        error: action.error
      };
    }
  ),
  on(CompanyActions.createCompanySuccess,
    (state, action) => {
      const companiesCopy = !!state.companies ? [...state.companies] : [];
      return {
        ...state,
        loadingCompanies: false,
        companies: [action.company, ...companiesCopy],
        companiesMap: {
          ...state.companiesMap,
          [action.company.id]: action.company.name
        }
      };
    }
  ),
  on(CompanyActions.createCompanyError,
    (state, action) => {
      return {
        ...state,
        loadingCompanies: false,
        error: action.error
      };
    }
  ),
  on(CompanyActions.clearCompanyStore,
    (state, action) => {
      return {
        ...state,
        loadingCompanies: false,
        error: null,
        companies: null,
        companiesMap: null
      };
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return companyReducer(state, action);
}
