export interface UserAttributes {
  sub?: string;
  email_verified?: boolean;
  updated_at?: number;
  gender?: string[];
  profile: string;
  given_name: string;
  family_name: string;
  email: string;
  allowed_permissions?: any[];
  blocked_permissions?: any[];
  access_attributes?: string[];
}

export interface User {
  UserCreateDate?: string;
  UserLastModifiedDate?: string;
  Enabled?: boolean;
  UserStatus?: UserStatusEnum;
  Attributes: UserAttributes;
  sub?: string;
  Username: string;
  challengeName?: string;
  username: string;
  phone_number_verified: boolean;
  phone_number: string;
  email_verified: boolean;
  profile: string;
  given_name?: string;
  family_name?: string;
  email?: string;
}

export enum UserStatusEnum {
  Unconfirmed = 'UNCONFIRMED',
  Confirmed = 'CONFIRMED',
  Archived = 'ARCHIVED',
  Compromised = 'COMPROMISED',
  Unknown = 'UNKNOWN',
  ResetRequired = 'RESET_REQUIRED',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD'
}

