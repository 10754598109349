import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import * as eventActions from '../../store/event.actions';
import * as eventParamsAction from '../../../../shared-stores/event-params/event-params.actions';
import { MatDialog } from '@angular/material/dialog';
import { IncidentsService } from '../../../incidents/services/incidents.service';
import { UrlService } from '../../../../shared-services/url.service';
import { CreateIncidentComponent } from '@modules/events/components/create-incident/create-incident.component';
import { EventsService } from '@modules/events/services/events.service';
import * as moment from 'moment';


@Component({
  selector: 'app-analysis-details',
  templateUrl: './event-detail-page.component.html',
  styleUrls: ['./event-detail-page.component.scss']
})

export class EventDetailPageComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  event: any;
  fields: any[];
  error: string = null;
  loading = false;
  previousUrl: string;
  currentUrl: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<State>,
    public matDialog: MatDialog,
    private urlService: UrlService,
    private router: Router,
    private eventService: EventsService
  ) {
  }

  ngOnInit() {
    this.getEvent();
    this.currentUrl = this.urlService.setCurrentUrl(this.router.url);
    this.previousUrl = this.urlService.getPreviousUrl();
  }

  goBack(): void {
    this.router.navigateByUrl(this.urlService.getPreviousUrl());
  }

  private getEvent() {
    // attached to the router params
    this.activatedRoute.queryParamMap.subscribe((qsp: any) => {
      this.eventService.fetchEventParams().pipe(takeUntil(this.destroyed$)).subscribe({
        next: (params) => {
          this.fields = params.fields.map(field => {
            return {
              ...field,
              name: field.name.toLowerCase()
            };
          })
          this.eventService.selectedEvent$.pipe(takeUntil(this.destroyed$)).subscribe(val => {
            if (!val) {
              const payload = {
                query: {
                  'and': [
                    { field: 'Event Unique Id', comparison: '=', value: qsp.params.eventId }
                  ]
                },
                dateType: 'custom',
                dateFrom: moment(qsp.params.isoDate).subtract(1, 'day').toISOString(),
                dateTo: moment().add(1, 'day').toISOString(),
                index_name: qsp.params.index_name,
                size: 1,
                companyFilter: qsp.params.companyFilter
              };
              this.eventService.search(payload).pipe(takeUntil(this.destroyed$)).subscribe({
                next: (event: any) => this.fetchEventData(event.id, qsp.params.companyFilter),
                error: (_) => {
                  this.error = 'Could not find event';
                  this.loading = false;
                }
              });
            } else {
              this.event = val;
              this.loading = false;
            }
          })
        }
      });
    });
  }

  private fetchEventData(id: string, company: string): void {
    this.eventService.searchResults(id, company).pipe(takeUntil(this.destroyed$)).subscribe({
      next: (resp: any) => {
        if (resp && resp.url) {
          // set the response
          this.eventService.fetchDataFromS3(resp.url, true);
        } else {
          this.fetchEventData(id, company);
        }
      },
      error: (error) => {
        this.error = 'Could not find event';
        this.loading = false;
      }
    })
  }

  onCreateIncident() {
    this.matDialog.open(CreateIncidentComponent, {
      disableClose: true,
      width: '80%',
      data: {
        company: this.event.customerid,
        element: this.event
      }
    }).afterClosed().subscribe(val => {
      if (val) {
        this.router.navigate(['/incidents/incident', val.sys_id]);
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(eventActions.clearEvent());
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.eventService.setSelectedEvent(null);
  }

  // Used to compare eventParams FIELDS list to event RAW name to find friendly name for view
  getFriendly(rawName) {
    return this.fields.find((field) => field.name === rawName
    ).friendly;
  }

  // Checks if event value is timestamp, convert to short date format
  getValueType(key, value) {
    if (key === 'ISODATE') {
      return new Date(value);
    } else {
      return value;
    }

  }

}
