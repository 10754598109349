import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import {UserPreference} from '../../pages/user-preference/store/user-preference.model';
import * as moment from 'moment';

@Pipe({name: 'userDate'})
export class UserPrefDatePipe extends DatePipe implements PipeTransform {
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    if (typeof value === 'string') {
      value = value.replace(/\s/g, 'T');
    }
    if (!moment(value).isValid()) {
      return;
    }
    const userPreferences: UserPreference = JSON.parse(localStorage.getItem('userPreferences') || '{}');
    userPreferences && userPreferences.dateFormat ?
      format = userPreferences.dateFormat
      :
      format = 'short';
    return super.transform(value, format);
  }
}
