import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LIST_MANAGEMENT_TABLE_ACTIONS } from '../constants/list-management.constants';
import { ListManagements, TableHeader } from '../interfaces/list-managements';

@Injectable({
  providedIn: 'root'
})
export class ListManagementsService {
  private listManagementData: BehaviorSubject<ListManagements[]> = new BehaviorSubject([]);
  listManagementData$: Observable<ListManagements[]> = this.listManagementData.asObservable();

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean> = this.loading.asObservable();

  private error: BehaviorSubject<string> = new BehaviorSubject(null);
  error$: Observable<string> = this.error.asObservable();

  private columns: BehaviorSubject<TableHeader[]> = new BehaviorSubject([]);
  columns$: Observable<TableHeader[]> = this.columns.asObservable();

  private companies: BehaviorSubject<any[]> = new BehaviorSubject([]);
  companies$: Observable<any[]> = this.companies.asObservable();

  actionPermissions = LIST_MANAGEMENT_TABLE_ACTIONS;

  constructor(
    private httpClient: AppService<any>
  ) {
    this.init();
  }

  private init(): void {
    this.httpClient.get('company', '', null)
      .then(companies => this.companies.next(companies))
      .catch(err => this.error.next(err.message))
  }

  fetchCustomerList(customer_id: string): void {
    this.loading.next(true);

    this.httpClient.get('list-management', `list/${customer_id}`, null)
      .then(response => this.listManagementData.next(response))
      .catch(err => this.error.next(err.message))
      .finally(() => this.loading.next(false));
  }

  private convertListDetails(response): any {
    const headers = response.columns;
    response.data = response.data.map((data, id) => {
      return {
        pk: id, ...headers.reduce((prev, next, index) => {
          prev[next.id] = data[index]
          return prev;
        }, {})
      }
    });
    return response;
  }

  fetchCustomerListDetails(customer_id: string, list_id: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.get('list-management', `list/${customer_id}/${list_id}`, null)
        .then(response => resolve(this.convertListDetails(response)))
        .catch(error => reject(error.message))
    })
  }

  updateCustomerList(customer_id: string, list_id: string, payload) {
    return new Promise((resolve, reject) => {
      this.httpClient.post('list-management', `list/${customer_id}/${list_id}`, null, payload)
        .then(response => resolve(this.convertListDetails(response)))
        .catch(err => reject(err.message))
    })
  }
}
