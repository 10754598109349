<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="dialog-wrapper layout-padding">
  <button *ngIf="!createLogStream" class="layout-padding" mat-stroked-button routerLink="/log-stream" type="button">
    <mat-icon>keyboard_arrow_left</mat-icon>
    Back to LogStream List
  </button>
  <h2 mat-dialog-title>{{createLogStream ? 'Create Logstream' : 'Logstream Details'}}</h2>
  <ng-container *ngIf="logStreamForm">
    <mat-dialog-content *ngIf="createLogStream">
      <ng-container [ngTemplateOutlet]="logStreamFormTemplate"></ng-container>
    </mat-dialog-content>
    <div *ngIf="!createLogStream">
      <ng-container [ngTemplateOutlet]="logStreamFormTemplate"></ng-container>
    </div>

    <ng-template #logStreamFormTemplate>
      <form [formGroup]="logStreamForm">
        <mat-card appearance="outlined">
          <mat-card-content>
            <section class="gap-top gap-bottom">
              <mat-slide-toggle
                [color]="color"
                formControlName="enabled"
                labelPosition="after">Accumulated Data
              </mat-slide-toggle>
            </section>

            <section class="gap-top gap-bottom">
              <mat-slide-toggle
                [color]="color"
                formControlName="streamData"
                labelPosition="after">Stream Data
              </mat-slide-toggle>
            </section>

            <br>

            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
              <mat-form-field fxFlex>
                <input
                  autocomplete="off"
                  formControlName="concurrentConnections"
                  matInput
                  min="1"
                  max="5"
                  placeholder="Concurrent Connection(s)"
                  required
                  type="number"/>
                <mat-hint>Concurrent Connection(s) must be between 1 and 5</mat-hint>
              </mat-form-field>

              <mat-form-field fxFlex>
                <mat-label>Company</mat-label>
                <mat-select formControlName="customerId" required>
                  <mat-option *ngFor="let company of companyList " [value]="company.value">
                    {{company.friendly}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
              <mat-form-field fxFlex>
                <mat-label>TLS Version</mat-label>
                <mat-select formControlName="tlsVersion" required>
                  <mat-option *ngFor="let tlsVersion of tlsVersions" [value]="tlsVersion.value">
                    {{tlsVersion.friendly}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex>
                <mat-label>Transport Method</mat-label>
                <mat-select formControlName="transport" required>
                  <mat-option *ngFor="let transportMethod of transportMethods" [value]="transportMethod.value">
                    {{transportMethod.friendly}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex>
                <mat-label>Source</mat-label>
                <mat-select formControlName="source" required>
                  <mat-option *ngFor="let source of sources" [value]="source.value">
                    {{source.friendly}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
              <mat-form-field fxFlex>
                <input
                  autocomplete="off"
                  formControlName="targetHost"
                  matInput
                  placeholder="Target Host"
                  required
                />
              </mat-form-field>
              <mat-form-field fxFlex>
                <input
                  type="number"
                  min="6514"
                  max="6519"
                  autocomplete="off"
                  formControlName="targetPort"
                  matInput
                  placeholder="Target Port"
                  required
                />
                <mat-hint>Target Port must be between 6514 to 6519</mat-hint>
              </mat-form-field>
            </div>

            <div fxLayout="column" *ngIf="sampleLogPreviewText">
              <span mat-subheader>Sample Log Format</span>
              <code>
                {{sampleLogPreviewText}}
              </code>
            </div>

          </mat-card-content>
        </mat-card>

        <br>

        <mat-card appearance="outlined">
          <mat-card-title>Certificates</mat-card-title>
          <mat-card-subtitle>Please add each certificate in the certificate chain. This includes the destination
            server's certificate, any intermediary certificates, and the CA certificate.
          </mat-card-subtitle>
          <div *ngFor="let item of getControls(); let i = index;" formArrayName="certificates">
            <mat-card-content [formGroupName]="i">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex fxLayout="column">
                  <mat-form-field fxFlex>
                    <mat-label>Certificate Title</mat-label>
                    <input autocomplete="off" formControlName="title" matInput placeholder="Certificate Title" required>
                  </mat-form-field>
                  <mat-form-field fxFlexFill>
                    <mat-label>Certificate Body</mat-label>
                    <textarea autocomplete="off" formControlName="body" matInput placeholder="Certificate Body"
                              required></textarea>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-between center">
                  <button (click)="removeCert(i)" mat-icon-button matTooltip="Remove Certificate"
                          matTooltipPosition="left">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <button *ngIf="!createLogStream" [cdkCopyToClipboard]="getCertBody(i)" mat-icon-button
                          matTooltip="Copy Certificate"
                          matTooltipPosition="left"
                  >
                    <mat-icon>content_copy</mat-icon>
                  </button>
                </div>
              </div>

            </mat-card-content>
          </div>
          <button (click)="addFieldTrustedCertificate()" mat-stroked-button type="button">
            <mat-icon>add</mat-icon>
            Certificate
          </button>
        </mat-card>
        <div *ngIf="!createLogStream" class="update-actions-row" fxLayout="row" fxLayoutAlign="end end">
          <button (click)="onDelete()" color="warn" mat-button>Delete</button>
          <button
            type="button"
            (click)="onUpdateLogStream()"
            [disabled]="logStreamForm.invalid || loading"
            color="primary"
            mat-raised-button
          >Save
          </button>
          <button (click)="initForm()" mat-button>Revert</button>
        </div>
      </form>
      <br>

    </ng-template>
    <mat-dialog-actions *ngIf="createLogStream" fxLayout="row" fxLayoutAlign="end end">
      <button type="button"
              (click)="onSubmit('cancel')"
              color="warn"
              mat-button
      >Cancel
      </button>
      <button type="button"
              (click)="onSubmit('submit')"
              [disabled]="logStreamForm.invalid || loading"
              color="primary"
              mat-raised-button
      >Submit
      </button>
    </mat-dialog-actions>
  </ng-container>

</div>
