import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionService} from '../services/permission.service';

@Directive({
  selector: '[ifUserCan]'
})
export class IfUserCanDirective implements OnInit {
  @Input('ifUserCan') requiredPermission: string | Array<string>;
  @Input('ifUserCanAny') any: boolean;
  // To use both of the above inputs for this directive
  // you would do something like
  // `*ifUserCan="['profile.edit', 'made up', 'another one!']; any: true"`
  permissions: any;

  constructor(
    private templateRef: TemplateRef<any>,
    private permissionService: PermissionService,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit() {
    // stores the list of users permissions
    this.permissions = this.permissionService.permissions;
    if (this.permissionService.hasPermission(this.requiredPermission, this.any)) {
      // adds element to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // does not add element to DOM
      this.viewContainer.clear();
    }
  }

}
