import {EventEmitter, Injectable} from '@angular/core';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

/*using this service class as an emitter where you use it to subscribe*/
export interface EmitterEventInterface {
  name: string;
  data: any | any[];
}

@Injectable({
  providedIn: 'root'
})
export class AppEmitterService {
  private _emitter: EventEmitter<EmitterEventInterface> = new EventEmitter<EmitterEventInterface>()

  get emitter() {
    return this._emitter;
  }
  emit(val: EmitterEventInterface) {
    this._emitter.emit(val);
  }

  on(eventName: string, action: any, destroyed: Subject<any>) {
    return this._emitter.pipe(
      filter(e => e.name === eventName),
    ).pipe(
      takeUntil(destroyed)
    )
      .subscribe(action);
  }

  constructor() { }
}
