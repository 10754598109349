import { PieWidgetInterface } from '../../../sweet-shared/components/widgets/widgets';
import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AppService} from '../../../app.service';
import {Dashboard} from './dashboard.model';
import * as dashboardActions from './dashboard.actions';
import {switchMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {SnackbarService} from '../../../shared-services/snackbar.service';
import {of} from 'rxjs';


@Injectable()
export class DashboardEffects {

  constructor(
    private actions$: Actions,
    private dashboardService: AppService<Dashboard>,
    private widgetService: AppService<PieWidgetInterface>,
    private snackbarService: SnackbarService
  ) {
  }

  @Effect({dispatch: true})
  loadDashboardTemplate$ = this.actions$.pipe(
    ofType(dashboardActions.getAllDashboardTemplate),
    switchMap(() => {
      return this.dashboardService.get('dashboardTemplate', '', null)
        .then((res: any[]) => dashboardActions.getAllDashboardTemplateSuccess({templateTags: _.sortBy(res, [(o) => o.tag])}))
        .catch((error: any) => dashboardActions.getAllDashboardTemplateError({error: error.message}));
    })
  );

  @Effect({dispatch: true})
  loadDefaultDashboard$ = this.actions$.pipe(
    ofType(dashboardActions.loadDashboard),
    switchMap(() => {
      return this.dashboardService.get('dashboards', '', null)
        .then((res: any[]) => dashboardActions.loadDashboardSuccess({dashboards: res}))
        .catch((error: any) => dashboardActions.loadDashboardFailure({error: error.message}));
    })
  );

  @Effect({dispatch: true})
  createDashboard$ = this.actions$.pipe(
    ofType(dashboardActions.dashboardCreate),
    switchMap((action: { dashboards: any }) => {
      return this.dashboardService.post('dashboards-bulk', '', null, action.dashboards)
        .then((res: any) => dashboardActions.dashboardCreateSuccess({dashboards: res}))
        .catch((err) => dashboardActions.dashboardCreateFailure({error: err.message}));
    })
  );

  @Effect({dispatch: true})
  getDashboard$ = this.actions$.pipe(
    ofType(dashboardActions.getDashboard),
    switchMap((action: { dashboardId: any }) => {
      return this.dashboardService.get('dashboards', `${action.dashboardId}`, null)
        .then((res: any) => dashboardActions.getDashboardSuccess({selectedDashboard: res}))
        .catch((err) => dashboardActions.getDashboardFailure({error: err.message}));
    })
  );

  @Effect({dispatch: true})
  editDashboard$ = this.actions$.pipe(
    ofType(dashboardActions.editDashboard),
    switchMap((action: { dashboard: Dashboard }) => {
      return this.dashboardService.put('dashboards', `${action.dashboard.dashboard_id}`, null, action.dashboard)
        .then((res: any) => dashboardActions.editDashboardSuccess({dashboard: res}))
        .catch((err) => dashboardActions.editDashboardFailure({error: err.message}));
    })
  );

  @Effect({dispatch: true})
  editDashboardFailure$ = this.actions$.pipe(
    ofType(dashboardActions.editDashboardFailure),
    switchMap((action: { error: string }) => {
      this.snackbarService.open(action.error);
      return of({type: 'noop'});
    })
  );
}
