
<mat-card appearance="outlined" class="card-style">
  <app-portal-loader [loadingStatus]="loading"></app-portal-loader>
  <mat-card-title>{{this.articleTitle}}</mat-card-title>

  <mat-card-content>
    <div *ngIf="emptyArticle" fxFill markdown>Loading Data...</div>
    <div *ngIf="!emptyArticle" fxFill markdown>{{this.helpArticle}}</div>
  </mat-card-content>
    <!-- End of content to update-->
    <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="onNoClick()">Got It!</button>
    </mat-card-actions>
</mat-card>

