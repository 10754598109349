<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="dialog-wrapper">
  <h2 mat-dialog-title>Create New Device</h2>
  <mat-dialog-content *ngIf="deviceForm">
    <form [formGroup]="deviceForm">
      <mat-card appearance="outlined">
        <mat-card-content>
          <mat-form-field  fxFlexFill>
            <mat-label>Appliance Name (Alpha Numeric and - Only)</mat-label>
            <input matInput placeholder="Appliance name" formControlName="appliance_name" required>
          </mat-form-field>

          <mat-form-field fxFlexFill>
            <mat-label>Company</mat-label>
            <mat-select formControlName="customerId" required>
              <mat-option *ngFor="let company of companies " [value]="company.value">
                {{company.friendly}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br>

          <section class="layout-padding">
            <mat-slide-toggle
              (toggleChange)="showCertificate = !showCertificate"
              labelPosition="after"
              formControlName="tls"
              [color]="color">Use TLS
            </mat-slide-toggle>
          </section>
          <section class="layout-padding">
            <mat-slide-toggle
              labelPosition="after"
              formControlName="build_nxlog"
              [color]="color">Use NXLog
            </mat-slide-toggle>
          </section>
          <section class="layout-padding">
            <mat-slide-toggle
              labelPosition="after"
              formControlName="NetFlow"
              [color]="color">Use NetFlow
            </mat-slide-toggle>
          </section>
          <section class="layout-padding">
            <mat-slide-toggle
              (toggleChange)="showProxyUrl = !showProxyUrl"
              formControlName="proxy_url"
              labelPosition="after"
              [color]="color">Use Proxy URL
            </mat-slide-toggle>
          </section>

          <mat-form-field *ngIf="showCertificate" fxFlexFill>
            <mat-label>LCA Public Certificate</mat-label>
            <textarea formControlName="lca_cert" matInput>
            </textarea>
            <mat-error style="border: 3px solid red" *ngIf="deviceForm.controls.lca_cert.hasError('pattern') ||
              (deviceForm.controls.lca_cert.dirty || deviceForm.controls.lca_cert.touched)">
              Certificate should start with -----BEGIN CERTIFICATE-----
             </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="showProxyUrl" fxFlexFill>
            <mat-label>Proxy Url</mat-label>
              <textarea placeholder="http://example.com:3128" formControlName="proxy_input" matInput></textarea>
            <mat-error style="border: 3px solid red" *ngIf="deviceForm.controls.proxy_input.hasError('pattern') ||
            (deviceForm.controls.proxy_input.dirty || deviceForm.controls.proxy_input.touched)">
              input should look like: http://example.com:3128
            </mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>

      <mat-card  appearance="outlined"  class="certificate" *ngIf="showCertificate && (deviceForm.controls.lca_cert.dirty && !deviceForm.controls.lca_cert.value.length === false &&
      !deviceForm.controls.lca_cert.hasError('pattern'))">
        <div formArrayName="lca_trusted_certs"
        *ngFor="let item of deviceForm.get('lca_trusted_certs')['controls']; let i = index;">
          <mat-card-content [formGroupName]="i">
            <mat-form-field  fxFlexFill>
              <mat-label>Certificate Title</mat-label>
              <input matInput placeholder="Certificate Title" formControlName="title">
            </mat-form-field>

            <mat-form-field fxFlexFill>
            <mat-label>LCA Public Certificate</mat-label>
            <textarea formControlName="body" matInput>
            </textarea>
            <mat-error style="border: 3px solid red" *ngIf="deviceForm.controls.lca_cert.hasError('pattern') ||
              (deviceForm.controls.lca_cert.dirty || deviceForm.controls.lca_cert.touched)">
              Certificate should start with -----BEGIN CERTIFICATE-----
             </mat-error>
          </mat-form-field>
        </mat-card-content>
        </div>
        <button (click)="addFieldTrustedCertificate()" type="button" mat-stroked-button>
          <mat-icon>add</mat-icon>FIELD</button>

      </mat-card>
      <p>
        <mat-checkbox formControlName="acknowledge" required></mat-checkbox>
        I acknowledge that by creating this appliance, the YAML configuration file generated will contain sensitive information.
      </p>

    </form>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
    <button mat-button color="warn"  (click)="onSubmit('cancel')">Cancel</button>
    <button mat-raised-button color="primary"
            [disabled]="deviceForm.invalid"
            (click)="onSubmit('submit')"
           >Submit
    </button>
  </mat-dialog-actions>

</div>
