<form [formGroup]="form" (submit)="createIncident()">
  <h1>Create Incident</h1>
  <mat-form-field appearance="outline">
    <mat-label>Title</mat-label>
    <input matInput formControlName="title" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Message</mat-label>
    <textarea matInput formControlName="message"></textarea>
  </mat-form-field>

  <!-- Company Filter -->
  <mat-form-field>
    <mat-label>Company Filter</mat-label>
    <mat-select formControlName="customerId">
      <mat-option>
        <ngx-mat-select-search
          #sText
          [formControl]="searchFormControl"
          [placeholderLabel]="'Search Company'"
          noEntriesFoundLabel="No results found"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let option of filteredCompaniesOptions | inputSearch: sText.value"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Priority</mat-label>
    <mat-select formControlName="priority">
      <mat-option
        *ngFor="let priority of priorityOptions$ | async"
        [value]="priority.value"
        >{{ priority.label }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-checkbox formControlName="status"
    >Set as active investigation</mat-checkbox
  >

  <div class="actions-container">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="form.invalid || loading"
    >
      Create
    </button>
    <button mat-stroked-button color="warn" (click)="dismissDialog()">
      Cancel
    </button>
  </div>
</form>
