<div class="admin">
    <div fxLayout="row" class="padding-all--small">
      <h1>Admin Console</h1>
    </div>
  <div class="admin-ib">
    <div class='admin-options' fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <div class="option" *ngFor="let option of adminOptions" fxFlexAlign="center center">
        <ng-container *appNotProdReady="option.notProdReady">
          <button *ifUserCan="option.permission" class="option"  mat-button [routerLink]="option.url">
            <mat-icon class='icon-display'>{{option.icon}}</mat-icon>
            <h2>{{option.friendly}}</h2>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
