<app-portal-loader [loadingStatus]="loading$ | async"></app-portal-loader>
<div class="dialog-wrapper">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content>
    <mat-horizontal-stepper linear #stepper (selectionChange)="goToStep($event)">

        <!-- - Present the user with 3 options-->
        <!--     - Preset (Last 4 hours, Last X Y) - Get options from the issue-->
        <!--     - Custom (Calendar daterange picker) specific dates-->
        <!--     - Relative (MAYA) - Make sure we validate it with the backend-->
        <!-- - Stick the date frame picker at the bottom just right below the input variables.-->

       -
      <mat-step [stepControl]="stepControllers[0]" [completed]="stepControllers[0].valid">
        <ng-template matStepLabel>General</ng-template>
        <app-report-general-information (formValid)="generalStepHandler($event)"></app-report-general-information>
      </mat-step>

      <!-- TODO - Make sure when use select a preset show the cron expression and make it go thru the validation and show next 5 run as usual.
        Also allow the user to modify the cron expression and once edited, auto switch to CUSTOM
      -->
      <mat-step [stepControl]="stepControllers[1]" [completed]="stepControllers[0].valid">
        <ng-template matStepLabel>Scheduling</ng-template>
        <app-report-scheduler-information (formValid)="schedulerStepHandler($event)" [data]="data.report"
        ></app-report-scheduler-information>
      </mat-step>

      <!-- Email notification form -->
      <mat-step [completed]="stepControllers[0].valid">
        <ng-template matStepLabel>Emails</ng-template>
        <app-report-notification-information (formValid)="notificationStepHandler($event)" [data]="data.report"></app-report-notification-information>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>

  <!-- Action buttons-->
  <mat-dialog-actions class="gap-top" fxLayout="row" fxLayoutAlign="end center">
    <div>
      <button mat-button color="warn" (click)="cancel()">
        <mat-icon>close</mat-icon>
        Cancel
      </button>
    </div>

    <div>
      <button
        mat-raised-button color="primary" (click)="previous()" *ngIf="currentStep > 0">
        <mat-icon>arrow_back</mat-icon>Prev
      </button>

      <button
        mat-raised-button color="primary" [disabled]="!stepValid" (click)="next()" *ngIf="currentStep + 1 < maxStep">
        <mat-icon>arrow_forward</mat-icon>Next
      </button>

      <button
        mat-raised-button color="primary" [disabled]="!stepValid" (click)="onSubmit()" *ngIf="currentStep + 1 === maxStep" >
        <mat-icon>done</mat-icon>
        {{ isNew ? "Create" : "Save" }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
