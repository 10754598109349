import {ActivatedRoute} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import uniq from 'lodash/uniq';
import {forkJoin, Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {AppService} from '@app/app.service';

import {CompaniesService} from '@modules/companies/services/companies.service';
import {Attribute, CompanyDetails} from '@modules/companies/interfaces/companies';
import {AddCompanyComponent} from '@modules/companies/components/add-company/add-company.component';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  companyDetails: CompanyDetails = null;
  companyId: string = null;
  error: string = null;
  loading = true;
  allAttributes: string[] = [];
  attributes: { [key: string]: Attribute };

  // Vars used to build products and attributes displayed under details
  dataSource1: any;
  dataSource2: any;
  productAttributes: any;
  data: any;
  productsList: any[] = [];

  constructor(
      private appService: AppService<any>,
      private activatedRoute: ActivatedRoute,
      private companyService: CompaniesService,
      private dialog: MatDialog
  ) {
    // get the company ID
    this.activatedRoute.params.pipe(takeUntil(this.destroyer$)).subscribe(
      (params) => {
        if (params && params.id) {
          this.companyId = params.id;
          // fetch the company details
          this.fetchCompanyDetails(params.id);
        }
      }
    );
  }

  ngOnInit(): void {
    this.load_attributes();
  }


  fetchCompanyDetails(companyId: string): void {
    this.loading = true;
    this.error = null;
    forkJoin({
      details: this.companyService.fetchCompanyDetails(companyId),
      attributes: this.companyService.fetchCompanyAttributes()
    }).pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false)).subscribe({
      next: (resp) => {
        const { details, attributes } = resp;
        this.companyDetails = details;
        this.attributes = attributes;
        this.allAttributes = uniq(Object.keys(attributes));
      },
      error: (error) => {
        this.error = error.message;
      }
    });
  }

  editCompany(): void {
    this.dialog.open(AddCompanyComponent, {
      disableClose: true,
      width: '70vw',
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'Edit Company',
        companyDetails: this.companyDetails,
        newCompany: false
      }
    }).afterClosed().pipe(takeUntil(this.destroyer$)).subscribe(data => {
      if (data) {
        this.companyDetails = data;
      }
    });
    this.clear_attributes();
    this.load_attributes();
  }

  // Methods for products & attributes details
  clear_attributes() {
    this.productAttributes = null;
    this.dataSource1 = null;
    this.dataSource2 = null;
    this.productAttributes = null;
    this.data = null;
    this.productsList = [];
  }

  load_attributes() {
    this.appService.get('company', this.companyId.toString(), null).then(response => {
          this.productAttributes = response;
          this.build_products_list(this.productAttributes.product_tree);
          this.create_columns();
        }
    );
  }

  create_columns() {

    // Order product list by name
    this.productsList = this.productsList.sort((a, b) => (a.name < b.name) ? -1 : 1);

    // Calculate the size of each column
    const columnSize = this.productsList.length;
    const halfSize = Math.ceil(columnSize / 2);
    this.dataSource1 = this.productsList.slice(0, halfSize);
    this.dataSource2 = this.productsList.slice(halfSize);
  }

  build_products_list(dataIn: object[]) {
    dataIn.forEach((value: any) => {
      // build temp obj to add to list
      const tempName = value.friendly;
      const tempSelected = value.selected;
      const tempValue = [];

      // check for empty values, set default
      if (value.attributes.length === 1) {
        tempValue.push({valueName: value.attributes[0].friendly, value: value.attributes[0].value});
      } else if (value.attributes.length > 1) {
        value.attributes.forEach( (attVal: any) => {
          tempValue.push({valueName: attVal.friendly, value: attVal.value});
        });
      }

      const tempObj = {name: tempName, selected: tempSelected, value: tempValue};
      // add to list
      if (tempObj.selected === true) {
        this.productsList.push(tempObj);
      }
      // check to see if children exist
      if (value.children.length >= 1) {
        this.recursive_loop(value.children);
      }
    });
  }

  recursive_loop(dataIn: object[]) {
    dataIn.forEach((value: any) => {
      // build temp obj to add to list
      const tempName = value.friendly;
      const tempSelected = value.selected;
      const tempValue = [];

      // check for empty values, set default
      if (value.attributes.length === 1) {
        tempValue.push({valueName: value.attributes[0].friendly, value: value.attributes[0].value});
      } else if (value.attributes.length > 1) {
        value.attributes.forEach( (attVal: any) => {
          tempValue.push({valueName: attVal.friendly, value: attVal.value});
        });
      }

      const tempObj = {name: tempName, selected: tempSelected, value: tempValue};
      // add to list
      if (tempObj.selected === true) {
        this.productsList.push(tempObj);
      }
      // check to see if children exist
      if (value.children.length >= 1) {
        this.recursive_loop(value.children);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
