<app-portal-loader [loadingStatus]="formLoadingIndicator | async"></app-portal-loader>
<div class="dialog-wrapper">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <h2 mat-dialog-title>New Widget</h2>
    </div>
  </div>
  <mat-dialog-content>
    <mat-horizontal-stepper>
      <mat-step [stepControl]="commonWidgetForm">
        <ng-template matStepLabel>Widget Basics</ng-template>
        <form [formGroup]="commonWidgetForm" class="layout-padding">
          <formly-form [form]="commonWidgetForm" [fields]="commonWidgetFields" [model]="commonWidgetModel"></formly-form>
        </form>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button color="warn" (click)="onCancel()">Cancel</button>
          <button type="button" mat-stroked-button matStepperNext>Next<mat-icon>keyboard_arrow_right</mat-icon></button>
        </mat-dialog-actions>
      </mat-step>
      <mat-step [stepControl]="finalWidgetForm">
        <ng-template matStepLabel>Chart Settings</ng-template>
        <form [formGroup]="finalWidgetForm" class="layout-padding" *ngIf="finalWidgetForm!==null">
          <formly-form [form]="finalWidgetForm" [fields]="finalWidgetFields" [model]="finalWidgetModel"></formly-form>
        </form>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50">
            <button type="button" mat-stroked-button matStepperPrevious>
              <mat-icon>keyboard_arrow_left</mat-icon>Back
            </button>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" fxFlex="50">
            <button mat-button color="warn" (click)="onCancel()">Cancel</button>
            <button  *ngIf="commonWidgetModel.type === 'pie' || commonWidgetModel.type === 'solid_gauge'; else nextButton"
                     mat-raised-button color="primary"
                     (click)="onSubmit()"
                     [disabled]="false">Submit
            </button>
            <ng-template #nextButton>
              <button type="button" mat-stroked-button matStepperNext>Next<mat-icon>keyboard_arrow_right</mat-icon></button>
            </ng-template>
          </div>
        </mat-dialog-actions>
      </mat-step>
      <mat-step *ngIf="commonWidgetModel.type === 'bar' || commonWidgetModel.type === 'line' || commonWidgetModel.type === 'single_metric' || commonWidgetModel.type === 'table'">
        <ng-template matStepLabel>Build Query</ng-template>
        <app-query-builder [inputData]="qbData" [isDialog]="false" [fields]="categoryField" (queryBody)="patchQuery($event)" #queryBuilder></app-query-builder>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
          <button mat-button color="warn" (click)="onCancel()">Cancel</button>
          <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="(formLoadingIndicator | async) || (!commonWidgetForm.valid) || !finalWidgetFormValid"
            (click)="onSubmit()"
          >Submit
          </button>
        </mat-dialog-actions>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
</div>
