import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@app/guards/auth-gaurd.service';
import { SecretComponent } from './components/secret/secret.component';

const routes: Routes = [
  {
    path: 'admin/secret',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: SecretComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SecretRoutingModule { }
