<mat-form-field fxFlexFill>
  <input matInput
         [value]="dateValue"
         [min]="minDate"
         [ngxMatDatetimePicker]="picker"
         (dateInput)="addEvent($event)" [placeholder]="placeholder">

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker
    #picker
    [showSpinners]="showSpinners"
    [showSeconds]="showSeconds"
    [color]="color"
    [enableMeridian]="enableMeridian">
  </ngx-mat-datetime-picker>

</mat-form-field>


