import {Component, OnDestroy, OnInit} from '@angular/core';
import {LogstreamService} from '../services/logstream.service';
import {Store} from '@ngrx/store';
import {State} from '@app/reducers';
import * as companyActions from '../../admin/store/company.actions';
import {delay, filter, map, pluck, take, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {Company} from '../../admin/store/company.model';
import {AuthService} from '../../auth/services/auth.service';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {LogStreamCreateComponent} from '../log-stream-create/log-stream-create.component';
import {AppService} from '@app/app.service';
import {SnackbarService} from '@shared-services/snackbar.service';
import {Router} from '@angular/router';
import * as userPreferenceActions from '../../user-preference/store/user-preference.actions';
import {UserPreference} from '../../user-preference/store/user-preference.model';
import {CompaniesService} from '@shared-services/companies.service';
import {HelpDialogModalComponent} from "@sweet-shared/components/help-dialog-modal/help-dialog-modal.component";

@Component({
    selector: 'app-log-stream',
    templateUrl: './log-stream.component.html',
    styleUrls: ['./log-stream.component.scss']
})
export class LogStreamComponent implements OnInit, OnDestroy {
    private destroyer$: ReplaySubject<boolean> = new ReplaySubject();
    lostStreamHeader: BehaviorSubject<any[]> = new BehaviorSubject([]);
    companies$: Observable<Company[] | { label: string, value: string }[]>;
    logStreamList: BehaviorSubject<any[]> = new BehaviorSubject([]);
    shownCompany: string;
    searchCompany = new UntypedFormControl('');
    selectedCompany: any;
    userPreferences: UserPreference;
    companies: any[] = [];
    params: any;

    loading = false;

    constructor(
        private appService: AppService<any>,
        private logstreamService: LogstreamService,
        private store: Store<State>,
        private dialog: MatDialog,
        private router: Router,
        private snackbarService: SnackbarService,
        private logStreamAppService: AppService<any>,
        private authService: AuthService,
        private companiesService: CompaniesService) {
    }

    ngOnInit(): void {
        this.setupPage();
        this.authService.getAuthenticatedUser().pipe(take(1)).subscribe(user => {
            this.params = {
                companyFilter: user.profile,
            };
        });
        this.getUserPreference();
    }

    onHelpClick() {
      // Opens up the Incident KB dialog with how to article
      const dialogRef = this.dialog.open(HelpDialogModalComponent);
    }

    searchFilterActionHandler(event) {
        this.authService.getAuthenticatedUser()
            .pipe(take(1), pluck('profile'))
            .subscribe(userCompany => {
                this.logstreamService.logstreamCompany(event.value);
            });
        this.shownCompany = event.value.toString();
    }

    private getUserPreference() {
        this.store.dispatch(companyActions.loadCompanies());
        this.store.dispatch(userPreferenceActions.loadUserPreferences());
        // Get the user preferences
        this.store.select(state => state.userPreferences.userPreferences)
            .pipe(
                filter(val => Object.keys(val).length > 0),
                takeUntil(this.destroyer$)
            ).subscribe((userPreferences: UserPreference) => {
            this.userPreferences = userPreferences;
        });
        this.store.select(state => state.companies.companies).pipe(
            filter(c => !!c),
            takeUntil(this.destroyer$)
        ).subscribe(companies => {
            this.companies = this.companiesService.companyNameAndFriendly(companies);
        });
    }

    onSelectChange(event) {
        this.selectedCompany = event.value;
    }

    setupPage() {
        this.logstreamService.getLoading().pipe(
            delay(0),
            takeUntil(this.destroyer$)
        ).subscribe(isLoading => {
            this.loading = isLoading;
        });
        // get companies for options to filter table
        this.store.dispatch(companyActions.loadCompanies());
        this.companies$ = this.store.select(state => state.companies.companies)
            .pipe(
                filter(data => !!data),
                // formly select needs options in following shape
                map(companies => companies.map(company => {
                    return {label: company.name, value: company.id};
                })),
                takeUntil(this.destroyer$)
            );

        this.logstreamService.getCompanyLogstream().pipe(
            filter(data => !!data),
            takeUntil(this.destroyer$)
        ).subscribe(res => {
            this.logStreamList.next(res);
        });

        // build table headers
        this.logstreamService.headerBuilder();
        this.logstreamService.getHeaderList().pipe(
            filter(data => !!data),
            takeUntil(this.destroyer$)
        ).subscribe(header => {
            this.lostStreamHeader.next(header);
        });
    }

    logStreamModal() {
        const dialogRef = this.dialog.open(LogStreamCreateComponent, {
            width: '800px',
            disableClose: true,
            autoFocus: false,
            maxHeight: '100vh',
            panelClass: 'ctl-panel-class'
        });
        dialogRef.componentInstance.companies$ = this.companies$;
        dialogRef.componentInstance.createLogStream = true;
        dialogRef.componentInstance.actionEvents.pipe(takeUntil(this.destroyer$)).subscribe(event => {
            if (event.name === 'submit') {
                this.logstreamService.createLogStream(event.data, dialogRef);
            } else if (event.name === 'cancel') {
                dialogRef.close();
            }
        });

    }

    onRowClick(event) {
        this.router.navigate([`/log-stream/${event.data.customerId}/${event.data.id}`]);
    }

    ngOnDestroy() {
        this.destroyer$.next(true);
        this.destroyer$.complete();
    }

}
