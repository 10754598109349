<section class="input-widget">
  <mat-form-field appearance="outline">
    <mat-label>{{ inputCtrlSettings.output_variable }}</mat-label>
    <input matInput [formControl]="inputCtrl" />
  </mat-form-field>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="icon-display">settings</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="delete()">
      <mat-icon>delete_forever</mat-icon>
      <span>Delete</span>
    </button>
  </mat-menu>
</section>
