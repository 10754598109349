import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LogstreamService} from "../services/logstream.service";
import {ReplaySubject} from "rxjs";

@Component({
  selector: 'app-log-stream-detail',
  templateUrl: './log-stream-detail.component.html',
  styleUrls: ['./log-stream-detail.component.scss']
})
export class LogStreamDetailComponent implements OnInit, OnDestroy {
  private destroyer$: ReplaySubject<boolean> = new ReplaySubject();

  logStreamId: any;

  constructor(private activatedRoute: ActivatedRoute, private logstreamService: LogstreamService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(paramMap => {
      if (paramMap.id) {

        this.logstreamService.selectLogstream(paramMap.customerId, paramMap.id);
      }
    });
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
