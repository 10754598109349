<form [formGroup]="singleGaugeForms">
  <mat-form-field fxFlexFill>
    <mat-label>Source</mat-label>
    <mat-select formControlName="source">
      <mat-option *ngFor="let source of sources" [value]="source.value">
        {{source.friendly}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div formArrayName="fields" *ngFor="let item of getControls(); let i = index;">
    <div [formGroupName]="i">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field fxFlex="40">
          <mat-label>Field Type</mat-label>
          <mat-select (selectionChange)="fieldFunctionHandler($event, i)" [formControlName]="'function' + i" required>
            <mat-option *ngFor="let category of categoryField" [value]="category.value">
              {{category.friendly}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="40">
          <mat-label>Function</mat-label>
          <mat-select [formControlName]="'fx' + i">
            <mat-option *ngFor="let fxParamOption of fxOptionsMap[i]" [value]="fxParamOption.value">
              {{fxParamOption.friendly}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-stroked-button color="warn" *ngIf="singleGaugeForms.get('fields').length !==1" (click)="delete(i)">
          <mat-icon>remove</mat-icon>
          Remove
        </button>
      </div>

    </div>
  </div>

</form>
<button (click)="addField()" type="button" mat-stroked-button>
  <mat-icon>add</mat-icon>
  FIELD
</button>
