<div class="layout-padding">
  <app-portal-loader [loadingStatus]="loading || searching"></app-portal-loader>
  <div>
    <app-incident-display
      *ngIf="activeInvestigation"
      [loadingSubject]="updatingUserPreferences"
      [incidentData]="assignedIncidentSubject"
      (eventHandler)="incidentActionHandler($event)"
      [computeIncidentGrade]="computeIncidentGrade"
      [classificationOptions]="classificationOptions"
      [assignmentGroupOptions]="assignmentGroupOptions"
      [tierOptions]="tierOptions"
      [stateOptions]="stateOptions"
      [priorityOptions]="priorityOptions"
      [closureStateOptions]="closureOptions"
      [users]="activeInvestigationUserGroup"
      [companies]="allCompanyOptions"
      [userPreferences]="userPreferences"
      [showDetailsButton]="showIncidentDisplayDetailsButton"
    >
    </app-incident-display>
    <mat-button-toggle-group
      name="toggleSearch"
      aria-label="Font Style"
      [value]="currentSelectedTab"
    >
      <mat-button-toggle
        class="page-toggle-button"
        routerLink="/incidents"
        (click)="onToggleClick('incidents')"
        value="incidents"
        >Search Incidents</mat-button-toggle
      >
      <mat-button-toggle
        class="page-toggle-button"
        routerLink="events"
        value="events"
        (click)="onToggleClick('events')"
        >Search Events
      </mat-button-toggle>
    </mat-button-toggle-group>
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="currentSelectedTab === 'incidents'">
    <mat-card appearance="outlined" *ifUserCan="['response.run-search']">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Search</mat-card-title>
        <div (click)="onHelpClick()" aria-label="Incident Search Help">
          <button mat-icon-button>
            <mat-icon>help</mat-icon>
          </button>
        </div>
      </div>
      <mat-card-content>
        <form [formGroup]="searchForm" class="incident-search">
          <!-- date range -->
          <div class="full" *ngIf="defaultDatetime">
            <app-datetime-picker
              label="Created At"
              [defaultValue]="defaultDatetime"
              appearance="outline"
              [options]="datetimeOptions"
              (onDateSelected)="updateDatePicker($event)"
            ></app-datetime-picker>
          </div>

          <!-- Companies -->
          <mat-form-field appearance="outline">
            <mat-label>Company</mat-label>
            <mat-select multiple="true" formControlName="company">
              <mat-option>
                <ngx-mat-select-search
                  #sText
                  [formControl]="companyFilterCtrl"
                  [placeholderLabel]="'Search Company'"
                  noEntriesFoundLabel="No results found"
                ></ngx-mat-select-search>
              </mat-option>
              <div class="select-search-filter">
                <mat-checkbox (change)="toggleAllSelection($event, 'company')"
                >Select All</mat-checkbox
                >
              </div>
              <mat-option
                *ngFor="let option of companyOptions"
                [value]="option.value"
              >
                {{ option.label }} ( {{option.value}} )
              </mat-option>
            </mat-select>
          </mat-form-field>


          <!-- Assignee -->

          <mat-form-field appearance="outline">
            <mat-label>Assignee</mat-label>
            <mat-select formControlName="u_unlisted_affected_user" [multiple]="true">
              <ngx-mat-select-search [formControl]="userFilterCtrls"></ngx-mat-select-search>
                  <div class="select-search-filter">
                    <mat-checkbox (change)="toggleAllSelection($event, 'assignee')"
                      >Select All</mat-checkbox
                    >
                  </div>
              <mat-optgroup *ngFor="let optionGroup of userOptions" [label]="optionGroup.groupName">
                <mat-option *ngFor="let option of optionGroup.option | inputSearch: userFilterCtrls.value" [value]="option.value">{{ option.label }}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>








          <!-- classification -->
          <mat-form-field appearance="outline">
            <mat-label>Classification</mat-label>
            <mat-select multiple="true" formControlName="category">
              <div class="select-search-filter">
                <mat-checkbox
                  (change)="toggleAllSelection($event, 'classifications')"
                  >Select All</mat-checkbox
                >
              </div>
              <mat-option
                *ngFor="let option of classificationOptions"
                [value]="option.value"
                >{{ option.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- Queue -->
          <mat-form-field appearance="outline">
            <mat-label>Queue</mat-label>
            <mat-select multiple="true" formControlName="u_queue">
              <div class="select-search-filter">
                <mat-checkbox (change)="toggleAllSelection($event, 'tier')"
                  >Select All</mat-checkbox
                >
              </div>
              <mat-option
                *ngFor="let option of tierOptions"
                [value]="option.value"
                >{{ option.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- State -->
          <mat-form-field appearance="outline">
            <mat-label>State</mat-label>
            <mat-select multiple="true" formControlName="state">
              <div class="select-search-filter">
                <mat-checkbox (change)="toggleAllSelection($event, 'state')"
                  >Select All</mat-checkbox
                >
              </div>
              <mat-option
                *ngFor="let option of stateOptions"
                [value]="option.value"
                >{{ option.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- Priority -->
          <mat-form-field appearance="outline">
            <mat-label>Priority</mat-label>
            <mat-select multiple="true" formControlName="priority">
              <div class="select-search-filter">
                <mat-checkbox (change)="toggleAllSelection($event, 'priority')"
                  >Select All</mat-checkbox
                >
              </div>
              <mat-option
                *ngFor="let option of priorityOptions"
                [value]="option.value"
                >{{ option.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- Closure State -->
          <mat-form-field appearance="outline">
            <mat-label>Closure State</mat-label>
            <mat-select multiple="true" formControlName="close_code">
              <div class="select-search-filter">
                <mat-checkbox
                  (change)="toggleAllSelection($event, 'closureState')"
                  >Select All</mat-checkbox
                >
              </div>
              <mat-option
                *ngFor="let option of closureOptions"
                [value]="option.value"
                >{{ option.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- Assignment Group -->
          <mat-form-field appearance="outline">
            <mat-label>Assignment Group</mat-label>
            <mat-select multiple="true" formControlName="assignment_group">
              <div class="select-search-filter">
                <mat-checkbox
                  (change)="toggleAllSelection($event, 'assignmentGroup')"
                  >Select All</mat-checkbox
                >
              </div>
              <mat-option
                *ngFor="let option of assignmentGroupOptions"
                [value]="option.value"
                >{{ option.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- event search -->
          <mat-form-field class="full" appearance="outline">
            <mat-label>Text Search</mat-label>
            <input
              matInput
              formControlName="query"
              placeholder="INC1141* or INC1141689"
            />
          </mat-form-field>

          <section class="action-container">
            <button
              mat-stroked-button
              color="primary"
              (click)="performSearch()"
              [disabled]="loading || searching"
            >
              <mat-icon>search</mat-icon>
              Search
            </button>
            <div class="refresh-timer" *ngIf="!timerStopped">
              <h5>Time to refresh: {{ formatTime(timeLeft) }}</h5>
            </div>
          </section>
        </form>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card appearance="outlined">
      <mat-card-actions>
        <button
          *ifUserCan="'response.create-inv'"
          mat-stroked-button
          color="primary"
          (click)="onCreateIncident()"
        >
          <mat-icon>add</mat-icon>
          Create Incident
        </button>
        <ng-container *ifUserCan="'response.view-inv'">
          <button
            mat-button
            color="primary"
            (click)="downloadAsCSV()"
            *ngIf="incidentsDataStream.value.length"
          >
            <mat-icon>get_app</mat-icon>
            Download Results
          </button>
        </ng-container>
      </mat-card-actions>
      <mat-card-content *ifUserCan="'response.view-inv'">
        <app-table
          [pageSize]="incidentsService.paginatorConfig?.pageSize"
          [paginatorConfig]="incidentsService.paginatorConfig"
          (paginationChange)="handlePaginationChange($event)"
          [cellDecorator]="computeIncidentGrade.bind(this)"
          [newRow]="highlightNewOrUpdatedIncidents.bind(this)"
          [urgency]="{ header: 'u_queue', run: urgency }"
          [headerStream]="incidentsHeadersSubject"
          [data]="incidentsDataStream"
          [showFilter]="!loading && incidentsDataStream.getValue().length > 0"
          [loading]="searching"
          [greyOut]="isClosed"
          [actionsTemplate]="incidentActionsTemplate"
          (events)="onTableRowClick($event)"
          #incidentsTable
          *ngIf="incidentsHeadersSubject.value.length > 0"
        >
          <ng-template #incidentActionsTemplate let-inc>
            <button
              *ifUserCan="'response.add-worklog'"
              mat-menu-item
              (click)="onAddWorklog(inc)"
            >
              <mat-icon>add</mat-icon>
              Add Worklog
            </button>
          </ng-template>
        </app-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
