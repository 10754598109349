import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import {MatButtonModule} from '@angular/material/button';
import { EmailVerificationCodeDialogComponent } from './email-verification-code-dialog/email-verification-code-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';



@NgModule({
  declarations: [
    EmailVerificationComponent,
    EmailVerificationCodeDialogComponent
  ],
  exports: [
    EmailVerificationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})
export class EmailVerificationModule { }
