import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import * as am4charts from '@amcharts/amcharts4/charts';
import { AppService } from '../../../../app.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../../../pages/dashboards/services/dashboard.service';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { ReportsWarningComponent } from '../../../../pages/dashboards/reports-warning/reports-warning.component';
import { DrawChartService } from '../../../services/draw-chart.service';
import { DateService } from '../../../../shared-services/date.service';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { FullScreenWidgetComponent } from '../full-screen-widget/full-screen-widget.component';
import * as am4plugins_wordCloud from '@amcharts/amcharts4/plugins/wordCloud';
import { WidgetInfoComponent } from '../widget-info/widget-info.component';
import { AthenaFilterStatement, WidgetModel } from '../../../models/widget.model';
import { Dashboard } from '../../../../pages/dashboards/store/dashboard.model';
import * as am4maps from '@amcharts/amcharts4/maps';
import { InputVariableService } from 'src/app/sweet-shared/services/input-variable.service';
import { WidgetViewComponent } from '../../widget-view/widget-view.component';
import { WidgetService } from 'src/app/sweet-shared/services/widget-service.service';
import { RefreshService } from 'src/app/sweet-shared/services/refresh.service';


@Component({
  selector: 'app-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss']
})
export class WidgetHeaderComponent extends WidgetViewComponent implements OnInit, OnDestroy {
  private requiredInputVariables: any[] = [
    { input_variable_name: 'company', default_value: null },
    { input_variable_name: 'dateFrom', default_value: null },
    { input_variable_name: 'dateTo', default_value: null }
  ];
  @Input() widget: WidgetModel = null;
  @Input() height: number;
  @Input() parentDashboard: Dashboard | any;
  @Input() eventDateRange: any;
  // @Input() inputVariableService: InputVariableService;

  @Input() filterAction: EventEmitter<any> = new EventEmitter();
  @Input() newWidgetAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() widgetAction: EventEmitter<any> = new EventEmitter();
  loading = true;
  error: string = null;
  chart: am4charts.XYChart | am4charts.PieChart | am4plugins_wordCloud.WordCloud | am4maps.MapChart = null;
  filters = null;
  prevFilters: any;

  constructor(
    public widgetService: WidgetService,
    public httpService: AppService<any[]>,
    private zone: NgZone,
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    private drawChartService: DrawChartService,
    private dateService: DateService,
    public inputVariableService: InputVariableService,
    public http: HttpClient,
    private cdr: ChangeDetectorRef,
    public refreshSrv: RefreshService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    super(widgetService, inputVariableService, httpService, http, refreshSrv)
  }

  ngOnInit() {

    super.ngOnInit();

    this.widgetDetail$.next(this.widget);

    // setup loaders
    this.loading$.subscribe(l => {
      this.loading = l;
    });

    // tune in to error values from fetch data
    this.error$.pipe(filter(e => !!e)).subscribe(e => {
      this.getDataErrorHandler(e);
    })


    this.data$.pipe(filter((d: any) => !!d)).subscribe((data: any) => {
      this.chart = this.drawWidgetTypeChart(this.widget.type, data);
      // console.log("the chart ref is: ", this.chart)
    });

    // forward widget actions to parent
    this.widgetAction$.subscribe((action: any) => {
      // this.widgetAction.emit(action)
    })

    // when new widget is created
    this.newWidgetAction.pipe(
      filter(event => event.widgetId === this.widget.widget_id),
      takeUntil(this.destroyer$)
    ).subscribe(newWidgetAction => {
      if (newWidgetAction.name === 'NEW_WIDGET') {
        const company = newWidgetAction.data.company;
        const data = this.dateService.dataRange(newWidgetAction.data.dateRange, this.eventDateRange);
        const dateFrom = data.dateFrom;
        const dateTo = data.dateTo;
        const newWidgetParams = { company, dateFrom, dateTo };
        this.filters = { company, dateFrom, dateTo };
      } else if (newWidgetAction.name === 'EDITED_WIDGET') {
        this.widget = {
          ...this.widget,
          ...newWidgetAction.data
        };
      } else if (newWidgetAction.name === 'DELETED_WIDGET') {
        this.widgetAction.emit(newWidgetAction);
      }
    });
  }

  appendQuery(filterQuery) {
    if (!filterQuery || !this.widget.input_variables) {
      return;
    }
    const key = Object.keys(filterQuery)[0];
    let queryInput;
    if (key) {
      queryInput = this.widget.input_variables.find(input => input.input_variable_name === key);
    } else {
      return;
    }
    if (filterQuery[key]?.and && queryInput?.default_value) {
      filterQuery[key].and.push(queryInput.default_value);
    } else if (filterQuery[key]?.or && queryInput?.default_value) {
      filterQuery[key].or.push(queryInput.default_value);
    }
    return filterQuery;
  }

  pullWidgetData(url: string): Promise<any | any[]> {
    return this.http.get(url).pipe(takeUntil(this.destroyer$)).toPromise();
  }

  drawWidgetTypeChart(widgetType: string, data): any {
    if (widgetType === 'line') {
      return this.drawChartService.drawLineChart(data, this.chart, this.widget);
    } else if (widgetType === 'bar') {
      return this.drawChartService.drawBarChart(data, this.chart, this.widget);
    } else if (widgetType === 'pie') {
      return this.drawChartService.drawPieChart(data, this.chart, this.widget);
    } else if (widgetType === 'single_metric') {
      return this.drawChartService.drawSingleMetric(data, this.chart, this.widget);
    } else if (widgetType === 'solid_gauge') {
      return this.drawChartService.drawSolidGaugeChart(data, this.chart, this.widget);
    } else if (widgetType === 'multi_line') {
      return this.drawChartService.drawMultiLineChart(data, this.chart, this.widget);
    } else if (widgetType === 'multi_bar') {
      return this.drawChartService.drawMultiBarChart(data, this.chart, this.widget);
      // return null;
    } else if (widgetType === 'map') {
      return this.drawChartService.drawMapWidget(data, this.chart, this.widget);
    }
  }


  // Passing default parameter so that if shape of error changes or null passed in, will still be handled
  getDataErrorHandler(error: any): void {
    if (!error) {
      error = { errorType: 'DEFAULT_ERROR' };
    }
    switch (error.errorType) {
      case 'MISSING_INPUT_VARIABLES':
        this.error = 'Waiting for Inputs';
        break;
      case 'SERVER_ERROR':
        this.error = 'Server Error. Please try again later';
        break;
      case 'FAILED':
      case 'BAD_REQUEST':
        this.error = 'Failed to get data';
        break;
      case 'NO_SUPPORTED':
        this.error = 'Widget not yet supported.';
        break;
      case 'ACCESS_DENIED':
        this.error = 'Access Denied. If you think this is a mistake please contact your administrator.';
        break;
      default:
        this.error = 'An error occurred while getting the data. Please try again later.';
        break;
    }
    this.loading = false;
  }


  handleDelete() {
    this.widgetAction.emit({ name: 'DELETED_WIDGET', data: this.widget })
  }

  editWidget() {
    this.dashboardService.editWidget(this.widget, this.parentDashboard, this.newWidgetAction);
  }

  isNotResults() {
    if (this.widget.type === 'single_metric') {
      return !this.loading && !this.chart?.data;
    }
    return !this.loading && !this.chart?.data?.length;
  }

  isFullScreenOption() {
    if (this.widget.type === 'single_metric') {
      return !this.loading && this.chart?.data;
    }
    return !this.loading && this.chart?.data?.length;
  }

  // To determine if a bar chart should have the option of being rendered with a break axis
  needsBreakAxis(): boolean {
    // check for data
    if (!this.chart?.data?.length || this.chart.data.length < 2) {
      return false;
    }
    // check for y-axis param
    if (!this.widget || !this.widget.params || !this.widget.params.yAxisAction) {
      return false;
    }
    const yAxisProp = this.widget.params.yAxisAction;

    // store original index and value, since we're going to re-order it
    const mapped = this.chart.data.map((item, index) => {
      return {
        index,
        value: item[yAxisProp]
      };
    });

    // sort from least to greatest, so that we can find the biggest step increase between bars/data points
    mapped.sort((a, b) => a.value - b.value);
    // this returns the indices of the items in the chart data that have the biggest gap between them
    // which is where we want the axis break
    const { lowerStep, higherStep } = this.drawChartService.findBiggestDiff(mapped);
    // Set desired ratio, i.e. the higher step should be how many times bigger than the lower step to have he option of changing to
    // an axis break chart?
    const TIMES_BIGGER = 10;
    const lowerStepValue = this.chart.data[lowerStep][yAxisProp];
    const higherStepValue = this.chart.data[higherStep][yAxisProp];
    if (higherStepValue / lowerStepValue > TIMES_BIGGER) {
      return true;
    }
    return false;
  }

  showAsFullScreen() {
    const dialogRef: MatDialogRef<FullScreenWidgetComponent> = this.dialog.open(FullScreenWidgetComponent, {
      width: '95vw',
      height: '95vh',
      maxWidth: '95vw',
      data: this.widget
    });
    dialogRef.componentInstance.chartData = this.chart.data;
    dialogRef.componentInstance.chartType = this.widget.type;
  }

  openWidgetInfoDialog() {
    this.dialog.open(WidgetInfoComponent, {
      width: '40vw',
      maxWidth: '95vw',
      data: this.widget
    });
  }
  ngOnDestroy() {
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }
}
