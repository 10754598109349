import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {environment} from '../../../environments/environment';


@Directive({
  selector: '[appNotProdReady]'
})
export class NotProdReadyDirective implements OnInit {
  @Input('appNotProdReady') notReady: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }
  /*
    <p *appNotProdReady="true">WILL BE HIDDEN FROM PRODUCTION</p> <<---- Will NOT be on page
    <p *appNotProdReady="ANY_FALSEY_VALUE">WILL NOT BE HIDDEN FROM PRODUCTION</p> <<------ Will BE on page
   */

  ngOnInit() {
    // if a value is not supplied to directive, this will coerce to boolean
    this.notReady = !!this.notReady;
    // if in production and element is not ready
    if (environment.production && this.notReady) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
