import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AthenaFilterAndStatement,
  AthenaFilterOrStatement,
  AthenaFilterStatement,
  QueryWidgetModel, WidgetModel
} from '../../../../sweet-shared/models/widget.model';
import { QueryBuilderConfig, Rule, RuleSet } from 'angular2-query-builder';
import * as eventParamsActions from '../../../../shared-stores/event-params/event-params.actions';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from '../../../../app.service';
import { EventsService } from '@modules/events/services/events.service';

type WidgetWithoutParams = Omit<QueryWidgetModel, 'params'>;

@Component({
  selector: 'app-query-widget',
  templateUrl: './query-widget.component.html',
  styleUrls: ['./query-widget.component.scss']
})
export class QueryWidgetComponent implements OnInit {
  @Input() widget: QueryWidgetModel | WidgetWithoutParams;
  @Input() queryName: string = null;
  indexName: string;
  error: string = null;
  indexDetails: any = null;
  formReady = false;
  queryBuilderModel: RuleSet = {
    condition: 'and',
    rules: []
  };
  queryAthenaStatement: AthenaFilterStatement;
  queryBuilderConfig: QueryBuilderConfig = {
    fields: {}
  };
  operators: any = {
    '=': 'string',
    '!=': 'string',
    '<': 'string',
    '<=': 'string',
    '>': 'string',
    '>=': 'string',
    contains: 'string',
    like: 'string',
    in: 'array',
    incidr: 'array',
    'is null': null,
    'is not null': null,
    between: 'array'
  }
  queryString: string = '';

  constructor(
    private store: Store<State>,
    private dialogRef: MatDialogRef<QueryWidgetComponent>,
    private widgetAppService: AppService<WidgetModel>,
    private eventService: EventsService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(eventParamsActions.loadEventFields());
    this.widgetAppService.get('index', '', null)
      .then(res => {
        this.indexName = this.widget.index_name || 'default';
        this.indexDetails = res.find(index => index.id === this.indexName);
        this.indexDetails.fields.forEach(field => {
          this.queryBuilderConfig.fields[field.value] = {
            name: field.friendly,
            value: field.value,
            type: 'string',
            operators: Object.keys(this.operators),
          }
        });
        setTimeout(() => this.formReady = true);
      })
      .catch(err => {
        this.formReady = true;
        this.error = err.message;
      });
    if (this.queryName) {
      const storedQuery = JSON.parse(sessionStorage.getItem(this.queryName) || 'null');
      if (storedQuery) {
        this.queryBuilderModel = storedQuery;
      }
    }
  }

  setQueryAthenaStatement(queryWidgetEvent: RuleSet) {
    this.queryAthenaStatement = this.eventService.formatQueryForAthena(queryWidgetEvent);
    this.queryString = QueryWidgetComponent.buildQuery(queryWidgetEvent);
    if (this.queryName) {
      sessionStorage.setItem(this.queryName, JSON.stringify(queryWidgetEvent))
    }
  }

  static buildQuery(queryModel: any): any {
    const queryParts = queryModel.rules.reduce((prev, next) => {
      if (next.hasOwnProperty('condition') && next.hasOwnProperty('rules')) {
        return next.rules.length > 1 ? [`(${prev.join(` ${queryModel.condition} `)})`, this.buildQuery(next)] : [this.buildQuery(next)];
      }

      const comp = next?.operator || next?.comparison;

      if (['between', 'in'].includes(comp)) {
        return [...prev, `('${next.field}' ${comp} (${next?.value ?? ''}))`]
      }
      return [...prev, `('${next.field}'${comp}'${next?.value ?? ''}')`]

    }, []);
    const rslt = queryParts.length > 1 ? `(${queryParts.join(' ' + queryModel.condition + ' ')})` : `${queryParts.join(' ' + queryModel.condition + ' ')}`;

    return rslt;
  }

  saveWidgetQuery() {
    this.dialogRef.close({ name: 'QUERY_WIDGET_SET', value: this.queryAthenaStatement, 'queryString': this.queryString });
  }

  clear(): void {
    this.queryBuilderModel = null;
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

}
