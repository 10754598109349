import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityPostureComponent } from './components/security-posture/security-posture.component';
import {SecurityPostureRoutingModule} from './security-posture-routing.module';
import {SweetSharedModule} from '../../sweet-shared/sweet-shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {FormlyModule} from '@ngx-formly/core';
import {FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
  declarations: [SecurityPostureComponent],
  imports: [
    CommonModule,
    SecurityPostureRoutingModule,
    SweetSharedModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    FormlyModule,
    FlexModule,
    ReactiveFormsModule,
    MatIconModule,
    MatRadioModule,
    MatButtonModule
  ]
})
export class SecurityPostureModule { }
