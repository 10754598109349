import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { filter } from 'rxjs/operators';

import { AppService } from '@app/app.service';
import { WidgetService } from '@sweet-shared/services/widget-service.service';
import { InputVariableService } from '@sweet-shared/services/input-variable.service';
import { WidgetViewComponent } from '@sweet-shared/components/widget-view/widget-view.component';
import { RefreshService } from '@sweet-shared/services/refresh.service';

export interface TableHeaders {
  alias?: string;
  value: string;
}

@Component({
  selector: 'app-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.scss']
})
export class TableWidgetComponent extends WidgetViewComponent implements OnInit, OnDestroy {
  @Input() widgetDetails: any;
  @Output() widgetAction: EventEmitter<any> = new EventEmitter();

  loading = false;
  error: string = null;

  // private variables collecting all the input variables
  inputVariables: any = {
    company: null,
    dateFrom: null,
    dateTo: null
  };
  displayedColumns: string[] = [];
  rawDisplayColumns: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dateRange: any = {};

  constructor(
    public widgetService: WidgetService,
    public inputVarService: InputVariableService,
    public httpService: AppService<any[]>, public http: HttpClient,
    public refreshSrv: RefreshService
  ) {
    super(widgetService, inputVarService, httpService, http, refreshSrv);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.widgetDetail$.next(this.widgetDetails);

    if (this.widgetDetails?.source === 'incidents' && this.widgetDetails?.type === 'table') {
      this.displayedColumns = this.extractIncidentTableHeadings();
    } else {
      this.displayedColumns = this.extractTableHeadings();
    }

    this.data$.pipe(filter((d: any) => !!d)).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    });

    // forward widget actions to parent
    this.widgetAction$.subscribe((action: any) => {
      this.widgetAction.emit(action);
    });

    // get date range
    this.inputVarService.inputVariables$().subscribe(vars => {
      this.dateRange = {
        from: vars?.dateFrom,
        to: vars?.dateTo
      };
    });

    this.inputVarService.refetch$
      .pipe(
        filter((note: any) => note?.widget_id === this.widgetDetails?.widget_id)
      )
      .subscribe(_ => {
        this.refresh();
      });
  }

  private extractIncidentTableHeadings(): string[] {
    const widgetDetails = this.widgetDetail$.getValue();

    const selectStatement = widgetDetails?.params?.athenaStatement?.standard[0].select_statement;
    return selectStatement?.reduce((prev, next) => {
      prev.push(next.select_field.column_field);
      return prev;
    }, []);
  }

  private extractTableHeadings(): string[] {
    const widgetDetails = this.widgetDetail$.getValue();

    const selectStatement = widgetDetails?.params?.athenaStatement?.standard[0].select_statement;
    return selectStatement?.reduce((prev, next) => {
      prev.push(next.alias);
      return prev;
    }, []);
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
