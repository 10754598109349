<mat-card appearance="outlined" class="layout-padding">
  <mat-card-title>Reports Affected</mat-card-title>
  <mat-card-content>
    <p>Just a heads up! By modifying this dashboard, the below reports could be affected.</p>
    <mat-list *ngIf="reports.length">
      <mat-list-item *ngFor="let report of reports; let i = index">{{i + 1}}.) {{report.name}}</mat-list-item>
    </mat-list>
    <p>If that's ok, click proceed. If you don't want those reports affected, please click "Duplicate Dashboard"</p>
  </mat-card-content>
  <mat-card-actions>
    <div  fxLayout="row" fxLayoutAlign="end center">
      <button mat-button color="warn" (click)="onCancel()">Cancel</button>
      <button mat-stroked-button (click)="onDuplicate()">Duplicate Dashboard</button>
      <button mat-raised-button color="primary" (click)="onProceed()">Proceed</button>
    </div>
  </mat-card-actions>
</mat-card>

