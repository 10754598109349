<section class="company-list">
  <!-- Back button and add company -->
  <div class="btn-container">
    <button
            mat-stroked-button
            type="button"
            routerLink="/admin"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
      Back to Admin Page
    </button>

    <!-- Add company button -->
    <button mat-stroked-button class="primary" (click)="addCompany()" *ifUserCan="'company.create'">
      <mat-icon>add</mat-icon>
      Company
    </button>
  </div>

  <div class="table-container">
    <mat-card appearance="outlined" class="card-style">
      <!-- Company filter field -->
      <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <input matInput type="text" [formControl]="filterCtrl" />
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearFilter()"
          *ngIf="filterCtrl.value"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- companies tables -->
      <table mat-table matSort [dataSource]="companiesDataSource">
        <ng-container
          [matColumnDef]="column.key"
          *ngFor="let column of tableColumns"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-header"
            mat-sort-header
          >
            {{ column.friendly }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element[column.key] }}</td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="table-row"
          (click)="goToDetails(row)"
        ></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" *ngIf="!loading; else loadingState">
            No data matching the filter "{{ filterCtrl.value }}"
          </td>
          <ng-template #loadingState>
            <div class="loader-container">
              <mat-spinner></mat-spinner>
            </div>
          </ng-template>
        </tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        aria-label="Select page of users"
      ></mat-paginator>
    </mat-card>
  </div>
</section>
