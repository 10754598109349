import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';


import { PermissionListComponent } from './components/permission-list/permission-list.component';


@NgModule({
  declarations: [
    PermissionListComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTooltipModule
  ],
  exports: [
    PermissionListComponent
  ]
})
export class PermissionModule { }
