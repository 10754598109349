<app-portal-loader [loadingStatus]="!!loading"></app-portal-loader>
<div class="card-title">
  <span><h2>Device Details</h2></span>
</div>
<div class="layout-padding">
  <button mat-stroked-button type="button" routerLink="/devices" class="device_back">
    <mat-icon>keyboard_arrow_left</mat-icon>
    Back to Devices
  </button>
  <div fxFill *ngIf="deviceData && !loading">
    <mat-card-title class="layout-padding">{{deviceData.name}}</mat-card-title>

    <mat-tab-group mat-align-tabs="start" [selectedIndex]="1">
      <!-- POLICY TAB      -->
      <mat-tab label="POLICY">
        <!--<mat-tab *ngIf="deviceTypeWithPolicyTab.indexOf(deviceData.deviceType) > -1" label="POLICY">-->
        <ng-template matTabContent>
          <mat-card appearance="outlined" class="card-style">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-card-title>Device Policy and Rules</mat-card-title>
              <mat-slide-toggle [checked]="showPolicyCards" (toggleChange)="showPolicyCards = !showPolicyCards"></mat-slide-toggle>
            </div>
            <mat-card-content>
              <app-table
                *ngIf="!showPolicyCards else: policyCards"
                [headerStream]="panPolicyHeadersSubject"
                [data]="panPolicyDataStream"
                [pageSize]="25"
                [showFilter]="!loading && panPolicyDataStream.getValue().length > 0"
                [loading]="false"
              ></app-table>
              <ng-template #policyCards>
                <mat-card appearance="outlined" *ngFor="let policy of devicePolicy?.policy?.pan_policy" class="policy-card"
                          [ngClass]="{'blue-border': policy.action === 'allow', 'red-border': policy.action === 'drop'}">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-card-title-group>
                      <mat-card-title>{{policy.ruleName}}</mat-card-title>
                      <mat-card-subtitle>{{policy.description || 'No description available'}}</mat-card-subtitle>
                    </mat-card-title-group>
                    <h3>{{policy.action | uppercase}}</h3>
                  </div>
                  <mat-card-content fxLayout="row" fxLayoutAlign="space-around stretch">
                    <div>
                      <h3>Source</h3>
                      <p>{{policy.source}}</p>
                    </div>
                    <div>
                      <h3>Destination</h3>
                      <p>{{policy.destination}}</p>
                    </div>
                    <div>
                      <h3>Category</h3>
                      <p>{{policy.category}}</p>
                    </div>
                    <div>
                      <h3>Source User</h3>
                      <p>{{policy.sourceUser}}</p>
                    </div>
                    <div>
                      <h3>Applications</h3>
                      <p>{{policy.applications}}</p>
                    </div>
                    <div>
                      <h3>Service</h3>
                      <p>{{policy.service}}</p>
                    </div>
                  </mat-card-content>
                </mat-card>
              </ng-template>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" class="card-style">
            <mat-card-title>MISC</mat-card-title>
            <mat-card-content>
              <pre>{{devicePolicy?.policy?.asa_policy}}</pre>
              <p *ngIf="!devicePolicy?.policy?.asa_policy">No miscellaneous data available</p>
            </mat-card-content>
          </mat-card>
        </ng-template>
      </mat-tab>

      <!-- INFO TAB      -->
      <mat-tab label="INFO">
        <div class="info-cards">
          <form [formGroup]="basicInfoForm">
            <mat-card appearance="outlined" fxFlex class="card-style">
              <mat-card-title>Basic Info</mat-card-title>
              <h2>Device ID: <input matInput type="text" formControlName="deviceId"></h2>
              <h2>Device Name: <input matInput type="text" formControlName="deviceName"></h2>
              <mat-card-content>
                <div fxLayout="column">
                  <mat-form-field fxFlexFill>
                    <mat-label>Friendly Name</mat-label>
                    <input matInput type="text" formControlName="friendlyName">

                  </mat-form-field>
                  <mat-form-field fxFlexFill>
                    <mat-label>Address</mat-label>
                    <input matInput type="text" formControlName="physicalAddress">

                  </mat-form-field>
                  <mat-form-field fxFlexFill>
                    <mat-label>Latitude</mat-label>
                    <input matInput type="text" formControlName="latitude">
                  </mat-form-field>
                  <mat-form-field fxFlexFill>
                    <mat-label>Longitude</mat-label>
                    <input matInput type="text" formControlName="longitude">
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
          </form>

          <form [formGroup]="additionalInfoForm">
            <mat-card appearance="outlined" fxFlex class="card-style">
              <mat-card-title>Additional Info</mat-card-title>
              <h2>Last Updated: <input matInput type="text" formControlName="updatedAt"></h2>
              <mat-card-content>
                <div class="add-info">
                  <section>
                    <div class="displayFlex">
                      <span>Created ID:</span>
                      <span><input matInput type="text" formControlName="createdBy"></span>
                    </div>
                    <div class="displayFlex">
                      <span>Created At:</span>
                      <span><input matInput type="text" formControlName="createdAt"></span>
                    </div>
                    <div class="displayFlex">
                      <span>Updated By:</span>
                      <span><input matInput type="text" formControlName="updatedBy"></span>
                    </div>
                    <div class="displayFlex">
                      <span>Updated At:</span>
                      <span><input matInput type="text" formControlName="updatedAt"></span>
                    </div>
                    <div class="displayFlex">
                      <span>Version:</span>
                      <span><input matInput type="text" formControlName="version"></span>
                    </div>
                    <div class="displayFlex">
                      <span>Customer Id:</span>
                      <span><input matInput type="text" formControlName="customerId"></span>
                    </div>

                  </section>

                  <section>
                    <div class="displayFlex">
                      <span>Service:</span>
                      <span><input matInput type="text" formControlName="service"></span>
                    </div>
                    <div class="displayFlex">
                      <span>Device Customer ID:</span>
                      <span><input matInput type="text" formControlName="customerId"></span>
                    </div>
                    <div class="displayFlex">
                      <span>Health:</span>
                      <span><input matInput type="text" formControlName="healthInformation"></span>
                    </div>
                    <div class="displayFlex">
                      <span>Device Type:</span>
                      <span><input matInput type="text" formControlName="deviceType"></span>
                    </div>
                    <div class="displayFlex">
                      <span>Device Customer ID:</span>
                      <span><input matInput type="text" formControlName="deviceCustomerId"></span>
                    </div>

                  </section>

                </div>
                <div fxLayoutAlign="end center">
                  <button mat-button color="primary" (click)="onDownload(deviceData)">
                    <mat-icon>download</mat-icon>
                    DOWNLOAD <span *ngIf="deviceData.deviceType === 'CTL Docker LCA'"> DOCKER COMPOSE FILE</span>
                    <span *ngIf="deviceData.deviceType !== 'CTL Docker LCA'"> XML CONFIGURATION FILE</span>
                  </button>
                </div>
              </mat-card-content>
            </mat-card>
          </form>

        </div>
        <div class="buttons">
          <div fxLayout="row" fxLayoutAlign="start center" class="info-buttons">
            <button *ngIf="isEdit" mat-stroked-button (click)="cancelBasicInfo()">{{'CANCEL'}}</button>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center" class="info-buttons">
            <button mat-flat-button color="warn" (click)="onDelete(deviceData)">DELETE</button>
            <button (click)="editBasicInfo()" mat-stroked-button>{{isEdit ? 'UPDATE' : 'EDIT'}}</button>
          </div>

        </div>

      </mat-tab>
    </mat-tab-group>
    <!-- END OF TABS    -->
  </div>
</div>

