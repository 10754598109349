import { Component, OnInit } from '@angular/core';
import { RedirectBoardService } from '@services/redirect-board.service';

@Component({
  selector: 'app-redirect-dialog',
  templateUrl: './redirect-dialog.component.html',
  styleUrls: ['./redirect-dialog.component.scss']
})
export class RedirectDialogComponent implements OnInit {

  message$ = this.redirectBoardService.message$;

  constructor(public redirectBoardService: RedirectBoardService) { }

  ngOnInit(): void {
  }

}
