<div class="multi-bar" [ngStyle]="{height: height + 'px'}">
    <!-- div of the chart -->
    <div [id]="widgetDetails.widget_id" class="chart-container"></div>

    <!-- div of the legend -->
    <div class="legend-wrapper">
        <div id="{{widgetDetails.widget_id}}-legend" class="chart-legend"></div>
    </div>
    <div class="loader-pane" *ngIf="loading">
        <ngx-loading [show]="true"></ngx-loading>
    </div>
</div>
<template #tooltip>
    <table type="button" onclick="alert('Hi Beau!')">
    <tr>
        <strong><td>test</td></strong>
    </tr>
    <tr>
        <strong><td>Test</td></strong>
    </tr>
    </table>
</template>

