import { selectAllEvents } from './event.selector';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppService } from '../../../app.service';
import * as eventActions from './event.actions';
import { Store, select } from '@ngrx/store';
import { State } from '../../../reducers';
import { switchMap, withLatestFrom, map, catchError, tap } from 'rxjs/operators';
import { EventRequest, QueryParams } from './event.model';
import { EventRequestResponse } from './event.model';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { SnackbarService } from '@shared-services/snackbar.service';


@Injectable()
export class EventEffects {

  constructor(
    private actions$: Actions,
    private eventService: AppService<Event>,
    private router: Router,
    private store: Store<State>,
    private http: HttpClient,
    private snackbarService: SnackbarService,
  ) {
  }

  @Effect({ dispatch: true })
  fetchEvents$ = this.actions$.pipe(
    ofType(eventActions.fetchEvents),
    switchMap((action: { queryParams: QueryParams, eventRequest: EventRequest, forEventDetail?: boolean, isoDate?: string }) => {
      return this.eventService.post('events', '', action.queryParams, action.eventRequest)
        .then((res: EventRequestResponse) => {
          if (action.queryParams.companyFilter) {
            // if action was from detail page this would endlessly exececute navigate.  This condition prevents that
            if (!action.forEventDetail) {
              this.router.navigate([], { queryParams: { id: res.id, companyFilter: action.queryParams.companyFilter, size: res.size }, queryParamsHandling: 'merge' });
            }
            return eventActions.loadEvents({
              eventBatchId: res.id,
              queryParams: { companyFilter: action.queryParams.companyFilter, size: res.size },
              forEventDetail: action.forEventDetail
            });
          } else {
            return Auth.currentAuthenticatedUser().then((user) => {
              return eventActions.loadEvents({
                eventBatchId: res.id,
                queryParams: {
                  companyFilter: user.attributes.profile,
                  size: res.size
                },
                forEventDetail: action.forEventDetail
              });
            })
              .catch((error) => eventActions.eventsError({
                error: error.message
              }));
          }
        })
        .catch((error) => {
          return eventActions.eventsError({
            error: error.message
          });
        });
    })
  );

  @Effect({ dispatch: true })
  fetchSearchResults$ = this.actions$.pipe(
    ofType(eventActions.fetchSearchResults),
    switchMap((action: { url: string, forEventDetail?: boolean }) => {
      return this.http.get(action.url).pipe(
        map((res: any) => {
          if (!action.forEventDetail) {
            return eventActions.loadEventsSuccess({ events: res.results.map((item: any) => item._source) });
          } else if (res.results.length === 1) {
            // if is from detail page, we only want the one result
            return eventActions.fetchEventSuccess({ event: res.results[0] });
          } else {
            this.router.navigate(['events']);
            return eventActions.eventsError({ error: 'Event not found' });
          }
        }),
        catchError((err: any) => of(eventActions.eventsError({ error: err.message }))));
    }
    )
  );

  @Effect({ dispatch: true })
  loadEvents$ = this.actions$.pipe(
    ofType(eventActions.loadEvents),
    switchMap((action: { eventBatchId: string, queryParams: QueryParams, forEventDetail?: boolean }) => {
      return this.eventService.get('events', `${action.eventBatchId}/results`, action.queryParams)
        .then((res: any | any[]) => {
          // Check to make sure that the query has been completed.
          if (res && res.message && res.message === 'Search Query Not Yet Complete') {
            return eventActions.loadEvents(action);
          } else {
            return eventActions.fetchSearchResults({ url: res.url, forEventDetail: action.forEventDetail });
          }
        })
        .catch((error) => eventActions.eventsError({ error: error.message }));
    }
    )
  );

  @Effect({ dispatch: true })
  fetchSelectedEvent$ = this.actions$.pipe(
    ofType(eventActions.fetchEvent),
    withLatestFrom(this.store.pipe(select(selectAllEvents))),
    switchMap(([action, allEvents]) => {
      const event = allEvents.find(evt => evt.JCEF_evtUId === action.eventId || evt.jcef_evtuid === action.eventId);
      if (event) {
        return of(eventActions.fetchEventSuccess({ event }));
      } else {
        return of(eventActions.fetchEvents({
          queryParams: {
            size: 1,
            companyFilter: action.companyId,
          },
          eventRequest: {
            query: [{ condition: 'AND', rules: [{ field: 'Event Unique Id', operator: '=', value: `${action.eventId}` }] }],
            dateRange: 'custom',
            dateFrom: moment(action.isoDate).subtract(1, 'day').toISOString(),
            dateTo: moment().add(1, 'day').toISOString()
          },
          forEventDetail: true
        }));
      }
    })
  );


  @Effect({ dispatch: true })
  investigateEvent$ = this.actions$.pipe(
    ofType(eventActions.investigateEvent),
    switchMap((p: any) => {
      return from(this.eventService.post('macaw', 'investigate', null, p)).pipe(
        map(msg => {
          this.snackbarService.open('Event sent to automated analysis')
          return eventActions.investigateEventSuccess(msg)
        }),
        catchError(e => [eventActions.investigateEventError(e)])
      )
    })
  );
}
