import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyFilter, ListManagementComponent } from './components/list-management/list-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SweetSharedModule } from '@sweet-shared/sweet-shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ListManagementsRoutingModule } from './list-management-routing.module';
import {FlexModule} from "@angular/flex-layout";


@NgModule({
  declarations: [
    CompanyFilter,
    ListManagementComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        MatCardModule,
        MatSelectModule,
        MatButtonModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        SweetSharedModule,
        MatDialogModule,
        MatMenuModule,
        MatIconModule,
        ReactiveFormsModule,
        ListManagementsRoutingModule,
        FlexModule
    ],
  exports: [
    ListManagementComponent
  ]
})
export class ListManagementsModule { }
