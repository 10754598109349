import { Injectable } from '@angular/core';
import { Company } from '../pages/admin/store/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor() { }

  // this function returns the comnay name and it id in parentheses
  companyNameAndFriendly(allCompanies: any[]) {
    const newCompanies = [];
    allCompanies.map(company => {
      newCompanies.push({
        value: company.id,
        friendly: `${company.id} (${company.name})`
      }
      );
    });
    return newCompanies;
  }
}
