<section class="incident-preference">
  <h2>Incident Search Preferences</h2>
  <form [formGroup]="form" (submit)="updateIncidentPreference()">

    <!-- Company select -->
    <mat-form-field appearance="outline">
      <mat-label>Companies</mat-label>
      <mat-select multiple="true" formControlName="companies">
        <div class="select-search-filter">
          <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput [formControl]="companyFilterCtrl" />
          </mat-form-field>
        </div>
        <div class="select-search-filter">
          <mat-checkbox (change)="toggleAllSelection($event, 'company')"
          >Select All</mat-checkbox
          >
        </div>
        <mat-option
          *ngFor="let option of companyOptions"
          [value]="option.value"
        >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>



    <!-- Assignee select -->
    <mat-form-field appearance="outline">
      <mat-label>Assignee</mat-label>
      <mat-select multiple="true" formControlName="assignee">
        <div class="select-search-filter">
          <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput [formControl]="userFilterCtrl" />
          </mat-form-field>
        </div>
        <div class="select-search-filter">
          <mat-checkbox (change)="toggleAllSelection($event, 'assignee')"
            >Select All</mat-checkbox
          >
        </div>
        <mat-optgroup
          *ngFor="let optionGroup of assigneeOptions"
          [label]="optionGroup.groupName"
        >
          <mat-option
            *ngFor="let option of optionGroup.option"
            [value]="option.value"
            >{{ option.label }}</mat-option
          >
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <!-- classification -->
    <mat-form-field appearance="outline">
      <mat-label>Classification</mat-label>
      <mat-select multiple="true" formControlName="classifications">
        <div class="select-search-filter">
          <mat-checkbox (change)="toggleAllSelection($event, 'classifications')"
            >Select All</mat-checkbox
          >
        </div>
        <mat-option
          *ngFor="let option of classificationOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- Queue -->
    <mat-form-field appearance="outline">
      <mat-label>Queue</mat-label>
      <mat-select multiple="true" formControlName="tier">
        <div class="select-search-filter">
          <mat-checkbox (change)="toggleAllSelection($event, 'tier')"
            >Select All</mat-checkbox
          >
        </div>
        <mat-option *ngFor="let option of tierOptions" [value]="option.value">{{
          option.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- State -->
    <mat-form-field appearance="outline">
      <mat-label>State</mat-label>
      <mat-select multiple="true" formControlName="state">
        <div class="select-search-filter">
          <mat-checkbox (change)="toggleAllSelection($event, 'state')"
            >Select All</mat-checkbox
          >
        </div>
        <mat-option
          *ngFor="let option of stateOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- Priority -->
    <mat-form-field appearance="outline">
      <mat-label>Priority</mat-label>
      <mat-select multiple="true" formControlName="priority">
        <div class="select-search-filter">
          <mat-checkbox (change)="toggleAllSelection($event, 'priority')"
            >Select All</mat-checkbox
          >
        </div>
        <mat-option
          *ngFor="let option of priorityOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- Closure State -->
    <mat-form-field appearance="outline">
      <mat-label>Closure State</mat-label>
      <mat-select multiple="true" formControlName="closureState">
        <div class="select-search-filter">
          <mat-checkbox (change)="toggleAllSelection($event, 'closureState')"
            >Select All</mat-checkbox
          >
        </div>
        <mat-option
          *ngFor="let option of closureOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- Assignment Group -->
    <mat-form-field appearance="outline">
      <mat-label>Assignment Group</mat-label>
      <mat-select multiple="true" formControlName="assignment_group">
        <div class="select-search-filter">
          <mat-checkbox
            (change)="toggleAllSelection($event, 'assignment_group')"
            >Select All</mat-checkbox
          >
        </div>
        <mat-option
          *ngFor="let option of assignmentGroupOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <div class="action-container">
      <button
        mat-stroked-button
        color="primary"
        type="submit"
        [disabled]="form.pristine || (loading$ | async)"
      >
        <mat-icon>save</mat-icon>
        Update
      </button>
    </div>
  </form>
</section>
