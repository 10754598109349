<form  [formGroup]="form" class="report-scheduler">
  <!-- Control to decide weither or not this is enabled -->
  <mat-slide-toggle formControlName='is_active'>Is Active</mat-slide-toggle>

  <session class="scheduler" *ngIf="form.get('is_active').value">
    <!-- Schedule time frame -->
    <h3>Schedule Time Frame</h3>
    <div formGroupName="schedule_rate">
      <!-- Control for Run forever -->
      <mat-checkbox formControlName="run_forever">Run forever</mat-checkbox>
  
      <!-- control for date_from and date_to -->
      <br />
      <mat-form-field appearance="outline" *ngIf="!form.value.schedule_rate.run_forever" class="time-frame">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" formControlName="date_from">
          <input matEndDate placeholder="End date" formControlName="date_to">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    

    <h3>Frequency</h3>
    <!-- DateRange -->
    <mat-form-field appearance="outline">
      <mat-label>Options</mat-label>
      <mat-select (selectionChange)="updateCronExpression($event)" #frequencySelect>
        <mat-option *ngFor="let option of presetCronValues" [value]="option.cron">{{ option.friendly }}</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field appearance="outline" *ngIf="showCustomField">
      <mat-label>Cron Expression</mat-label>
      <input matInput placeholder="30 5 * * *" formControlName="frequency">
    </mat-form-field>
    <div class="next-runs" *ngIf="nextRunSchedules.length > 0 && !badCronExpression; else cronExpressionError">
      <p><strong>Next {{ maxCronExpressionRuns }} runs:</strong></p>
      <p *ngFor="let nextRun of nextRunSchedules">{{ nextRun | date : 'MMMM dd, yyyy @ HH:mm:ss a'}}</p>
    </div>

    <ng-template #cronExpressionError>
      <span class="error" *ngIf="form.dirty">Invalid Cron Expression</span>
    </ng-template>

  </session>
</form>
