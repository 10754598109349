<div *ngIf="loading">Loading user preferences...</div>
<div class="layout-padding" fxLayout.gt-xs="row wrap" fxLayout.xs="column">
  <mat-card class="card-padding" appearance="outlined" fxFlex>
    <mat-card-title>MFA</mat-card-title>
    <mat-card-subtitle>Adjust your MFA settings</mat-card-subtitle>
    <mat-card-content fxLayout="column">
      <div class="user-preference--error" *ngIf="error">{{ error }}</div>
      <div>
        <div *ngIf="!mfaEnabled">
          <button mat-raised-button color="primary" (click)="enableMfa()">
            MFA Registration
          </button>
        </div>
        <div *ngIf="mfaEnabled">
          <div *ngIf="!enableMFAInput">
            <mat-slide-toggle
              color="primary"
              [(ngModel)]="mfaEnabled"
              (change)="toggleMFAStatus($event)"
              labelPosition="before"
              [disabled]="mfaLoading"
              >MFA Enabled
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions></mat-card-actions>
  </mat-card>

  <mat-card class="card-padding" appearance="outlined" fxFlex>
    <mat-card-title>Password</mat-card-title>
    <mat-card-subtitle>Change your password</mat-card-subtitle>
    <mat-card-content>
      <form
        [formGroup]="changePasswordForm"
        (submit)="submitPasswordChange(changePasswordForm)"
        fxLayout="column"
      >
        <mat-form-field>
          <input
            type="password"
            matInput
            formControlName="currentPassword"
            placeholder="Current Password"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            type="password"
            matInput
            formControlName="newPassword"
            placeholder="New Password"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            type="password"
            matInput
            formControlName="confirmPassword"
            placeholder="Confirm Password"
          />
        </mat-form-field>

        <button
          mat-raised-button
          [disabled]="changePasswordForm.invalid"
          color="primary"
        >
          <mat-icon>save</mat-icon>
          Update
        </button>
      </form>
    </mat-card-content>
    <mat-card-actions> </mat-card-actions>
  </mat-card>

  <mat-card class="card-padding" appearance="outlined" fxFlex fxFlex.sm="100">
    <mat-card-title>Account Preferences</mat-card-title>
    <mat-card-subtitle
      >Actions applicable to the user preferences.</mat-card-subtitle
    >
    <mat-card-content>
      <app-account-preferences></app-account-preferences>
    </mat-card-content>
  </mat-card>

  <mat-card
    appearance="outlined"
    class="incident-search-preference card-padding"
    *ifUserCan="'portal-access.enable-response-section'"
  >
    <app-incident-search-preference></app-incident-search-preference>
  </mat-card>
</div>
