import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ClipboardService, IClipboardResponse} from 'ngx-clipboard';
import {SnackbarService} from '../../../shared-services/snackbar.service';

export interface SupportInfo {
  email: string;
}

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent implements OnInit {
  linkCopied = '';

  constructor(
    private clipboardService: ClipboardService,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<SupportDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: SupportInfo) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  copied(event) {
    this.clipboardService.copyResponse$.subscribe((res: IClipboardResponse) => {
      if (res.isSuccess) {
        this.snackBarService.open('Email Copied!');
        this.linkCopied = 'accent';
      } else {
        this.snackBarService.open('Error Copying Link. Please Try Again.');
      }
    });
  }

  ngOnInit() {
  }

}
