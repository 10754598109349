import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {ReplaySubject} from 'rxjs';
import {filter, pluck} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  dashboardId: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.pipe(
      filter(val => !!val && !!val.id),
      pluck('id'),
      untilDestroyed(this)
    ).subscribe(dashboardId => {
      this.dashboardId = dashboardId;
    });
  }
}
