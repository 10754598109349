import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

import { Permissions, RolePermission } from '@modules/permission/interfaces/permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private _systemPermissions: BehaviorSubject<Permissions> = new BehaviorSubject(null);
  systemPermissions$: Observable<Permissions> = this._systemPermissions.asObservable();

  private _loadingPermissions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingPermissions$ = this._loadingPermissions.asObservable();

  private _systemPermissionNames: BehaviorSubject<string[]> = new BehaviorSubject([]);
  systemPermissionNames$: Observable<string[]> = this._systemPermissionNames.asObservable();

  private _roleBasePermissions: BehaviorSubject<RolePermission[]> = new BehaviorSubject([]);
  roleBasePermissions$: Observable<RolePermission[]> = this._roleBasePermissions.asObservable();

  constructor(
    private httpService: AppService<any[]>
  ) {
    this.fetchPermissions();
  }

  fetchPermissions(): void {
    this._loadingPermissions.next(true);
    forkJoin({
      permissions: this.httpService.get('granularPermissions', '', null),
      rolePermissions: this.httpService.get('permissionRoles', '', null)
    }).pipe(
      finalize(() => this._loadingPermissions.next(false))
    ).subscribe({
      next: (resp: { permissions: Permissions, rolePermissions: RolePermission[] }) => {
        this._systemPermissionNames.next(resp.permissions ? Object.keys(resp.permissions) : []);
        this._systemPermissions.next(resp.permissions);
        this._roleBasePermissions.next(resp.rolePermissions);
      },
      error: (error) => {
        console.log('error ', error);
      }
    });
  }

  getUserRoleBasePermissions(roles: string[]): string[] {
    const roleBasePermission = this._roleBasePermissions.value;
    return roleBasePermission.reduce((acc: string[], next: RolePermission) => {
      if (roles.includes(next.name)) {
        acc = acc.concat(next.permissions);
      }
      return acc;
    }, []);

  }
}
