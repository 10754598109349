import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevicesRoutingModule } from './devices-routing.module';
import { DevicesComponent } from './devices/devices.component';
import {SweetSharedModule} from '../../sweet-shared/sweet-shared.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {FormlyModule} from '@ngx-formly/core';
import { DeviceDetailPageComponent } from './device-detail-page/device-detail-page.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatListModule} from '@angular/material/list';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {GridsterModule} from 'angular-gridster2';
import { DeviceCreateComponent } from './device-create/device-create.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@NgModule({
  declarations: [DevicesComponent, DeviceDetailPageComponent, DeviceCreateComponent],
  imports: [
    CommonModule,
    SweetSharedModule,
    DevicesRoutingModule,
    MatCardModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    SweetSharedModule,
    MatMenuModule,
    FormlyModule,
    MatTabsModule,
    MatListModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    GridsterModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    DevicesComponent
  ]
})
export class DevicesModule { }
