import {Injectable} from '@angular/core';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {AppService} from '../../../app.service';
import {Company} from './company.model';
import * as companyActions from './company.actions';
import {switchMap, take, withLatestFrom} from 'rxjs/operators';
import * as _ from 'lodash';
import {select, Store} from '@ngrx/store';
import {State} from '../../../reducers';
import {selectCompanies} from './company.selector';
import {of} from 'rxjs';


@Injectable()
export class CompanyEffects {


  constructor(
    private actions$: Actions,
    private companyService: AppService<Company[]>,
    private store: Store<State>
  ) {
  }

  @Effect({dispatch: true})
  loadCompanies$ = this.actions$.pipe(
    ofType(companyActions.loadCompanies),
    withLatestFrom(this.store.pipe(select(selectCompanies))),
    switchMap(([action, companies]) => {
      if (!companies) {

        return this.companyService.get('company', '', null)
          .then((res: Company[]) => companyActions.loadCompaniesSuccess({companies: _.sortBy(res, (c) => c.id?.toLowerCase().trim())}))
          .catch((error: any) => companyActions.loadCompaniesError({error: error.message}));
      } else {
        return of(companyActions.loadCompaniesSuccess({companies}));
      }
    })
  );
}
