<form [formGroup]='form'>
  <!-- Control to decide weither or not this is enabled -->
  <mat-slide-toggle formControlName='enabled'>{{form?.value?.enabled ? 'Emails Enabled' : 'Emails Disabled'}}</mat-slide-toggle>
  <div class='mat-elevation-z3 email-controls' *ngIf='showNotificationChoices'>
    <mat-form-field>
      <!-- TODO - Add validation for email entered. -->
      <mat-label>Emails</mat-label>
      <mat-chip-grid #chipList formArrayName='emails'>
        <mat-chip-row *ngFor="let value of form.get('emails').controls; let i=index;" [formGroupName]='i'
                  selectable="true" removable="true" (removed)="removeEmail(form, i, 'emails')"> {{value.value}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input placeholder="Add email..." [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="addEmail($event, notificationForm, 'emails')">
      </mat-chip-grid>
    </mat-form-field>

    <!-- TODO - When on, atleast one of the options must be True/On -->
    <h4>Email on:</h4>
    <mat-slide-toggle formControlName='on_cancelled'>Report Cancellation</mat-slide-toggle>
    <mat-slide-toggle formControlName='on_completion'>Report Completion</mat-slide-toggle>
    <mat-slide-toggle formControlName='on_failure'>Report Failure</mat-slide-toggle>
  </div>
</form>
