import { Component, OnInit, EventEmitter, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { filter, map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@app/reducers';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { GridsterComponent } from 'angular-gridster2';

import * as dashboardActions from '../store/dashboard.actions';
import { FieldInterface } from '@sweet-shared/components/form-builder/form-builder.component';
import * as fromCompany from '../../admin/store/company.actions';
import * as eventParamsActions from '../../../shared-stores/event-params/event-params.actions';
import * as userPreferenceActions from '../../user-preference/store/user-preference.actions';
import { dashboardGridsterConfig } from '../../../../environments/environment';
import { AppService } from '@app/app.service';
import { Widget } from '../store/dashboard.model';
import { AuthService } from '../../auth/services/auth.service';
import { SnackbarService } from '@shared-services/snackbar.service';
import { DashboardService } from '../services/dashboard.service';
import { DateService } from '@shared-services/date.service';
import { SettingWidgetComponent } from './components/setting-widget/setting-widget.component';
import { CompaniesService } from '@shared-services/companies.service';
import { AthenaFilterStatement, QueryWidgetModel, WidgetModel } from '@sweet-shared/models/widget.model';
import { QueryWidgetComponent } from '../../../modules/widgets/components/query-widget/query-widget.component';
import { CreateReportComponent } from '../../reporting/components/create-report/create-report.component';
import { ReportingService } from '../../reporting/services/forms/reporting.service';
import { InputVariableService } from 'src/app/sweet-shared/services/input-variable.service';
import { WidgetService } from 'src/app/sweet-shared/services/widget-service.service';
import { IDateRangeConfig } from 'src/app/sweet-shared/components/relative-date/relative-date.component';
import { ActionTypeValue } from '@sweet-shared/sweet-widgets/menu-bar/menu-bar.component';
import { ActionData, SettingWidgetFormComponent } from './components/setting-widget-form/setting-widget-form.component';
import { FormBuilderComponent } from '@sweet-shared/components/form-builder/form-builder.component';
import { WidgetDrilldownService } from '@shared-services/widget-drilldown.service';
import { ModalComponent } from '@sweet-shared/components/modal/modal.component';
import {IncidentTableWidgetComponent} from '@app/pages/dashboards/dashboard-detail-page/components/incident-table-widget/incident-table-widget.component';


@UntilDestroy()
@Component({
  selector: 'app-dashboard-detail-page',
  templateUrl: './dashboard-detail-page.component.html',
  styleUrls: ['./dashboard-detail-page.component.scss'],
})
export class DashboardDetailPageComponent implements OnInit {

  selectedDashboard$: Observable<any>;
  queryWidgetType = ['query_builder'];
  selectedDate$ = new Subject();

  constructor(
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private createReportDialogRef: MatDialog,
    private authService: AuthService,
    private httpService: AppService<Widget>,
    private snackbarService: SnackbarService,
    private dashboardService: DashboardService,
    private dateService: DateService,
    private companiesService: CompaniesService,
    public reportingFormService: ReportingService,
    public inputVariableService: InputVariableService,
    private widgetService: WidgetService,
    private widgetDrillDown: WidgetDrilldownService
  ) {
  }


  // used to create add widget form
  eventFields$: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
  hide: BehaviorSubject<{ flag: boolean, typ: string }> = new BehaviorSubject({ flag: true, typ: '' });

  private actionEvent$: Subject<any> = new Subject();
  private settingWidgetTypes: string[] = ['dropdown', 'query_builder', 'string_dropdown', 'text_input'];

  error: string = null;
  loading = true;
  dashboardFilterLoading = false;
  dashboardFilterDetails: FieldInterface[] = [];
  formReady = false;
  gridsterItems: any[] = [];
  authenticatedUser: any;
  userPreference: any = null;
  eventParams: any;
  currentDateFrom: any = null;
  currentDateTo: any = null;
  currentCompany: any = null;
  dateOptions: any[] = [];
  dateRangeControl = new UntypedFormControl('', [Validators.required]);
  companyOptions: any[] = [];
  companyControl = new UntypedFormControl('', [Validators.required]);
  searchFormControl = new UntypedFormControl('');
  customDateRangeFG = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  defaultSelectedDate = null;

  // Array housing all input variables currently in use in current dashboard
  inputVariablesInUse: string[] = [];
  customDate = false;
  relativeDate = false;
  relativeDateVal = '';

  filterAction: EventEmitter<any> = new EventEmitter();
  newWidgetAction: EventEmitter<any> = new EventEmitter<any>();
  dialogRef: MatDialogRef<any> = null;
  dialogReference: any = null;
  selectedDashboard: any = null;
  dashboardId: string;
  dashboardTitle: string;
  isUpdating = false;
  gridsterOptions = {
    ...dashboardGridsterConfig,
    itemResizeCallback: this.onResize.bind(this),
    itemChangeCallback: this.onItemChange.bind(this),
    itemInitCallback: this.onItemInit.bind(this)
  };
  gridsterContainerHeight: number;
  gridsterRows: number;
  actions = [
    {
      value: 'count',
      friendly: 'Count',
      type: ['String', 'TimeStamp', 'FloatingPoint', 'IPv6 address', 'Long', 'IPv4 Address',
        'MAC Address', 'Integer']
    },
    { value: 'avg', friendly: 'Average', type: ['FloatingPoint', 'Long', 'Integer'] },
    { value: 'min', friendly: 'Minimum', type: ['FloatingPoint', 'Long', 'Integer', 'TimeStamp'] },
    { value: 'max', friendly: 'Maximum', type: ['FloatingPoint', 'Long', 'Integer', 'TimeStamp'] },
    { value: 'sum', friendly: 'Sum', type: ['FloatingPoint'] }
  ];
  categoryField: any[] = [];
  eventDateRange: any[] = [];

  @ViewChild('dashboardFilterForm') dashboardFilterForm: FormBuilderComponent;
  gridsterLocked = new BehaviorSubject(true);
  @ViewChild('gridster') gridsterElement: GridsterComponent;
  settingWidgets: WidgetModel[] = [];
  dropdownWidgets: WidgetModel[];
  queryWidgets: QueryWidgetModel[];
  queryWidgetValue: { [key: string]: AthenaFilterStatement };

  relDateConfig: IDateRangeConfig = {
    appearance: 'standard',
    label: 'Relative Date',
    placeholder: '',
    selected: '',
    style: null
  };

  @ViewChildren(SettingWidgetComponent) settingWidgetComponents: QueryList<SettingWidgetComponent>;

  // Method is used to clean up widget payload according to required fields that can remain empty in the widget create form
  panelOpenState = false;

  ngOnInit() {
    sessionStorage.clear();

    this.setupWidgetFilters();
    this.pageSetup();

    this.selectedDate$.pipe(
      take(1),
      filter((d: any) => !!d?.valid),
      map((pkg: any) => pkg?.data),
    ).subscribe((selDate: any) => {
      this.inputVariableService.initializeInputVariables(this.selectedDashboard?.widgets, {
        company: this.currentCompany,
        dateFrom: selDate?.start,
        dateTo: selDate?.end
      });

    });

    this.showHideDateTime();
    this.gridsterContainerHeight = this.gridsterOptions.fixedRowHeight * this.gridsterOptions.minRows;


    this.eventFields$.pipe(
      filter(val => !!val),
      untilDestroyed(this)
    ).subscribe(list => {
      this.categoryField = list.map((c: any) => ({
        value: {
          friendly: c.friendly,
          name: c.name,
          type: c.type,
        },
        friendly: c.friendly,
      })
      );
    });

    // process data for custom date range widget
    this.customDateRangeFG.valueChanges.pipe(
      filter(d => !!d?.end),
      untilDestroyed(this)
    ).subscribe((data: any) => {
      const s = moment(data?.start).toDate().toISOString();
      const e = moment(data?.end).toDate().toISOString();

      this.inputVariableService.setSingleInputVariables('dateFrom', s);
      this.inputVariableService.setSingleInputVariables('dateTo', e);
    });
    this.hide.pipe(untilDestroyed(this)).subscribe(pkg => {
      if (!!this.dashboardFilterDetails) {
        this.dashboardFilterDetails = this.dashboardFilterDetails.map(s => {
          if (s.name === 'dateTime') {
            return { ...s, hide: pkg.flag };
          } else if (sessionStorage.getItem('customDateTime') !== null) {
            return { ...s, hide: false };
          }
          return s;
        });
      }
    });

    this.gridsterLocked.pipe(untilDestroyed(this))
      .subscribe(val => {
        this.gridsterOptions.draggable.enabled = !val;
        this.gridsterOptions.resizable.enabled = !val;
        if (this.gridsterElement) {
          this.gridsterOptions.api.optionsChanged();
        }
      });

    // register ops to refresh widgets after updates
    this.handleWidgetRefresh();
  }

  get initialFetch() {
    return this.inputVariableService.getInitialFetch();
  }

  unlockGridster() {
    this.gridsterLocked.next(false);
  }

  lockGridster() {
    this.gridsterLocked.next(true);
    const positions = this.gridsterElement.grid.map(item => {
      return item.item;
    });
    // Make copy of dashboard to not alter this.selectedDashboard
    const dashboardCopy = JSON.parse(JSON.stringify(this.selectedDashboard));
    dashboardCopy.positions = positions;
    // Must delete 'widgets' property in payload to make successful put request
    delete dashboardCopy.widgets;
    this.httpService.put(
      'dashboards',
      this.selectedDashboard.dashboard_id,
      null,
      dashboardCopy
    ).then(res => {
      // Update dashboard with new positions
      this.selectedDashboard = {
        ...this.selectedDashboard,
        ...res
      };
      this.snackbarService.open('Widget positions saved');
    }).catch(err => {
      this.snackbarService.open('Unable to save widget positions. Please try again later.');
    });
  }

  showHideDateTime() {
    this.actionEvent$.pipe(untilDestroyed(this)).subscribe(event => {
      if (event.name === 'VALUE_CHANGED' && event.data.value === 'custom') {
        this.hide.next({ flag: event.data.value === false, typ: event.data.value });
      }
    });
  }

  private onSettingWidgetChangeHandler(control: UntypedFormControl, name: string): void {
    if (name !== 'relativeDate') {
      control.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
        if (name === 'company') {
          this.inputVariableService.setSingleInputVariables(name, value);
          // Set temp value of the company dropdown widget used for the widget drill down service.
          this.widgetDrillDown.setCompany(value);
        } else if (name === 'dateRange') {
          const newDate = this.dateService.formatDate(value);
          this.inputVariableService.setSingleInputVariables('dateFrom', newDate.gte);
          this.inputVariableService.setSingleInputVariables('dateTo', newDate.lte);
        }
      });

      return;
    }

    const newestDate = this.dateService.formatDate(this.relativeDateVal);
    this.inputVariableService.setSingleInputVariables('dateFrom', newestDate.gte);
    this.inputVariableService.setSingleInputVariables('dateTo', newestDate.lte);
  }

  private setupWidgetFilters() {
    // dispatch user preferences, load event fields, and companies
    this.store.dispatch(userPreferenceActions.loadUserPreferences());
    this.store.dispatch(eventParamsActions.loadEventFields());
    this.store.dispatch(fromCompany.loadCompanies());

    // subscribe to the loader
    this.authService
      .getAuthenticatedUser()
      .pipe(
        filter((user) => !!user),
        take(1),
        untilDestroyed(this)
      )
      .subscribe((authenticatedUser) => {
        this.authenticatedUser = authenticatedUser;
        sessionStorage.setItem(`authenticatedUser`, JSON.stringify(authenticatedUser));
        this.store
          .select((state) => state.userPreferences)
          .pipe(
            filter((userPreference) => !!userPreference.userPreferences && !!userPreference.userPreferences.dateRange),
            take(1),
            untilDestroyed(this)
          )
          .subscribe((userPreference) => {
            this.userPreference = userPreference;
            // at this point we have the user preferences available, we need to wait for the companies
            this.store
              .select((state) => state.companies)
              .pipe(
                filter((c) => !!c.companies),
                take(1),
                untilDestroyed(this)
              )
              .subscribe((companies) => {
                // at this point we have the list of companies along with the user preferences
                this.store
                  .select((state) => state.eventParams)
                  .pipe(
                    filter((evtParams) => !!evtParams.eventParams),
                    take(1),
                    untilDestroyed(this)
                  )
                  .subscribe((eventParams) => {

                    this.eventDateRange = eventParams.eventParams.dashboardDateRanges;
                    // at this point we have the list of companies, userPreferences, and eventParams ready for us to consume.
                    this.eventFields$.next(eventParams.eventParams.fields);
                    this.eventParams = eventParams;

                    // Set the date range options and control
                    this.onSettingWidgetChangeHandler(this.dateRangeControl, 'dateRange');
                    this.dateOptions = eventParams.eventParams.dashboardDateRanges.map((d) => {
                      return { value: d.name, friendly: d.name };
                    });

                    // Set the company options and control
                    this.onSettingWidgetChangeHandler(this.companyControl, 'company');
                    this.companyOptions = this.companiesService.companyNameAndFriendly(companies.companies);
                    this.defaultSelectedDate = userPreference.userPreferences.dateRange;

                    this.formReady = true;
                    const data = this.dateService.dataRange(userPreference.userPreferences.dateRange, this.eventDateRange);
                    this.currentCompany = authenticatedUser.profile;
                    this.currentDateFrom = data?.dateFrom;
                    this.currentDateTo = data?.dateTo;

                    this.companyControl.patchValue(this.currentCompany, { emitEvent: true });
                  });
              });
          });
      });
  }

  private pageSetup() {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((routerParams) => {
      if (routerParams && routerParams.id) {
        // Set the dashboard ID
        this.dashboardId = routerParams.id;

        // Dispatch the action to fetch the dashboard details
        this.store.dispatch(dashboardActions.getDashboard({ dashboardId: routerParams.id }));

        // listen to the change for the loader
        this.store.select((state) => state.dashboard.loading).pipe(untilDestroyed(this)).subscribe((loading) => setTimeout(() => this.loading = loading));

        // list to the error
        this.store.select((state) => state.dashboard.error).pipe(untilDestroyed(this)).subscribe((error) => (this.error = error));

        // listen to the chaining of the dashboard selected
        this.selectedDashboard$ = this.store
          .select((state) => state.dashboard.selectedDashboard)
          .pipe(
            untilDestroyed(this),
            filter((dashboard) => !!dashboard),
            tap((sdb: any) => {
              this.selectedDashboard = { ...sdb };
              this.gridsterItems = sdb.widgets.filter((widget: any) => !this.settingWidgetTypes.includes(widget.type)).map((widget: any) => {
                return {
                  widget,
                  position: this.generateNewWidgetPosition(widget.widget_id),
                };
              });
            })
          );

      } else {
        // here the id is not present in the url, sending the user back to the dashboard list
        this.router.navigate(['/dashboard'], {});
      }
    });

    // so that all dashboards are available as options when duplicating dashboard
    this.store.dispatch(dashboardActions.loadDashboard());

  }


  filterByType(list: any[], shortList: any[]): any[] {
    if (!!!list) {
      return [];
    }

    if (!!!shortList) {
      return list;
    }

    return list.filter(l => shortList.includes(l?.type));
  }

  makeGridterItems(widgets: any[]): any[] {
    if (!!!widgets) {
      return [];
    }

    this.gridsterItems = widgets.filter((widget: any) => !this.settingWidgetTypes.includes(widget.type)).map((widget: any) => {
      return {
        widget,
        position: this.generateNewWidgetPosition(widget.widget_id),
      };
    });

    return this.gridsterItems;
  }

  applyFilter(): void {
    this.inputVariableService.emitInputVariables();
  }

  updateGridsteritems(widget) {
    // update the dashboard positions
    this.gridsterItems.push({
      widget,
      position: this.generateNewWidgetPosition(widget.widget_id),
    });

    // lets widget-header know that there's a new widget to fetch data for
    setTimeout(() => {
      this.newWidgetAction.emit({
        name: 'NEW_WIDGET',
        widgetId: widget.widget_id,
        data: this.dashboardFilterForm.form.value
      });
    }, 500);
  }

  private updateDashboardWidgetPositions(dashboardDetails): void {
    const updatedDashboard = { ...dashboardDetails };
    delete updatedDashboard.widgets;
    this.httpService.put('dashboards', updatedDashboard.dashboard_id, null, updatedDashboard);
  }

  private reRenderWidgets(widgetId): void {
    this.selectedDashboard = {
      ...this.selectedDashboard,
      widgets: this.selectedDashboard?.widgets?.filter(w => w.widget_id !== widgetId),
      positions: this.selectedDashboard?.positions?.filter(p => p.widget_id !== widgetId)
    };
    this.updateDashboardWidgetPositions(this.selectedDashboard);
    this.settingWidgets = this.selectedDashboard.widgets?.filter((widget: any) => this.settingWidgetTypes.includes(widget.type));
    this.dropdownWidgets = this.selectedDashboard.widgets?.filter((widget: any) => this.settingWidgetTypes.includes(widget.type));
    this.queryWidgets = this.selectedDashboard.widgets?.filter((widget: any) => widget.type === 'query_builder');
    this.gridsterItems = this.selectedDashboard.widgets?.filter((widget: any) => !this.settingWidgetTypes.includes(widget.type)).map((widget: any) => {
      return {
        widget,
        position: this.generateNewWidgetPosition(widget.widget_id),
      };
    });
  }

  widgetAction(event) {
    const { action, data } = event;
    if (action === ActionTypeValue.delete || event.name === ActionTypeValue.delete) {
      this.loading = true;
      this.widgetService.deleteWidget(data?.dashboard_id, data?.widget_id, data?.type)
        .then(response => {
          this.store.dispatch(dashboardActions.removeWidget({ widgetId: data.widget_id }));
          setTimeout(() => {
            this.snackbarService.open(response, 'OK');
            this.loading = false;
          }, 500);
        })
        .catch(error => {
          this.snackbarService.open(error, 'OK');
          this.loading = false;
        });
    }
    if (action === ActionTypeValue.edit || event.name === 'EDIT_WIDGET') {
      if (event.data.source === 'incidents' && event.data.type === 'table') {
        this.dashboardService.editWidgetIncidentTable(data, this.selectedDashboard, this.newWidgetAction);
      } else {
        this.dashboardService.editWidget(data, this.selectedDashboard, this.newWidgetAction);
      }
    }
  }

  private generateNewWidgetPosition(widgetId: string) {
    let defaultX = 0;
    let defaultY = 0;
    const defaultPositions = {
      widget_id: widgetId,
      rows: 2,
      cols: 2,
      x: 0,
      y: 0,
      maxItemRows: 4,
      maxItemCols: dashboardGridsterConfig.maxCols
    };
    if (!this.selectedDashboard.positions) {
      return defaultPositions;
    }
    // Find the saved position of the widget
    const position = this.selectedDashboard.positions.find(item => item.widget_id === widgetId);
    if (position) {
      return {
        ...defaultPositions,
        rows: position && position.rows || 2,
        cols: position && position.cols || 2,
        x: position && position.x || defaultX,
        y: position && position.y || defaultY,
      };
    } else {// Must be a new widget
      // find the last widget
      // find and set the largest Y position
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.selectedDashboard.positions.length; i++) {
        const item = this.selectedDashboard.positions[i];
        if (item.y > defaultY) {
          defaultY = item.y;
        }
      }
      // Get all items with Y position that is greatest
      const greatestYs = this.selectedDashboard.positions.filter(item => item.y === defaultY);
      // find and set the largest X position of items with largest Y position AND
      // same item will be the last widget
      let greatestXYItem;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < greatestYs.length; i++) {
        const item = greatestYs[i];
        if (item.x >= defaultX) {
          defaultX = item.x;
          // Found item from greatest Y's with greatest X
          greatestXYItem = item;
        }
      }
      // Set new item position based on cols rows x and y of found item
      if (greatestXYItem) {
        // if it extends past the 2nd column, new widget should be on a new row, and so have an X value of 0
        const x = greatestXYItem.x + greatestXYItem.cols > 2 ? 0 : greatestXYItem.x + greatestXYItem.cols;
        // if it extends past the 2nd column, new widget should be on a new row
        const y = greatestXYItem.x + greatestXYItem.cols > 2 ? greatestXYItem.y + 2 : greatestXYItem.y;
        return {
          ...defaultPositions,
          x,
          y
        };
      }
      // to catch all other cases
      return defaultPositions;
    }
  }

  onResize(item, scope) {
    if (this.gridsterItems && this.gridsterItems.length) {
      const gridsterItem = this.gridsterItems.find(i => i.widget.widget_id === item.widget_id);
      gridsterItem.height = scope.height;
    }
  }

  onItemChange(item, scope): void {
    // Only update if the item change changes the number of rows in grid
    if (this.gridsterRows !== scope.gridster.rows) {
      // set timeout needed to deal with change-detection error
      setTimeout(() => {
        this.gridsterContainerHeight = scope.gridster.rows * this.gridsterOptions.fixedRowHeight;
      });
    }
  }

  onItemInit(item, scope): void {
    this.gridsterRows = scope.gridster.rows;
    this.gridsterContainerHeight = scope.gridster.rows * this.gridsterOptions.fixedRowHeight;
  }

  viewReport() {
    this.router.navigate(['/reporting'], {
      queryParams: { filter: this.selectedDashboard.title },
      skipLocationChange: false
    });
  }

  reportDialog() {
    this.dialog.open(CreateReportComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '100vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'Create Report',
        companies: this.companyOptions,
        dashboardId: this.dashboardId,
        selectedCompany: this.companyControl.value,
        tags: this.selectedDashboard?.tags
      }
    });
    this.reportingFormService.setDefaultRelatedDashBoard(this.selectedDashboard.dashboard_id);
    this.reportingFormService.useRelatedDashBoard = true;
    this.reportingFormService.disableRelatedDashBoard = true;
  }

  onDuplicate() {
    this.dashboardService.onDuplicate(this.selectedDashboard);
  }

  addSettingWidget(): void {
    const dialogDestroyer$: ReplaySubject<boolean> = new ReplaySubject();
    const dialogRef = this.createReportDialogRef.open(SettingWidgetFormComponent, {
      width: '90%',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'New Widget / Filter',
        isNew: true,
        settingWidgets: this.settingWidgets,
        queryWidgets: this.queryWidgets
      }
    });

    dialogRef.componentInstance.crupdate.pipe(untilDestroyed(this)).subscribe((widgetDateEvent: ActionData) => {
      dialogRef.componentInstance.loading = true;
      const dashboardWigetData = {
        ...widgetDateEvent.data,
        dashboard_id: this.dashboardId
      };
      dashboardWigetData.params.company = this.currentCompany;
      if (widgetDateEvent.name === 'CREATE') {
        if (dashboardWigetData.type === 'text_input' || dashboardWigetData.source === 'ingestion') {
          delete dashboardWigetData.params.company;
        }
        this.widgetService.createWidget(dashboardWigetData)
          .then(widgetDetails => {
            this.store.dispatch(dashboardActions.addWidget({ widget: widgetDetails }));
            setTimeout(() => dialogRef.close(), 500);
          })
          .catch(error => {
            dialogRef.componentInstance.loading = false;
            this.error = error?.response?.message;
          });
      }
    });

    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(() => {
      dialogDestroyer$.next(false);
      dialogDestroyer$.complete();
    });
  }

  addIncidentTableWidget(): void {
    const dialogDestroyer$: ReplaySubject<boolean> = new ReplaySubject();
    const dialogRef = this.createReportDialogRef.open(IncidentTableWidgetComponent, {
      width: '90%',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'New Incident Table Widget',
        isNew: true,
        settingWidgets: this.settingWidgets,
        queryWidgets: this.queryWidgets
      }
    });

    dialogRef.componentInstance.crupdate.pipe(untilDestroyed(this)).subscribe((widgetDateEvent: ActionData) => {
      dialogRef.componentInstance.loading = true;
      const dashboardWigetData = {
        ...widgetDateEvent.data,
        dashboard_id: this.dashboardId
      };
      dashboardWigetData.params.company = this.currentCompany;
      if (widgetDateEvent.name === 'CREATE') {
        this.widgetService.createWidget(dashboardWigetData)
            .then(widgetDetails => {
              this.store.dispatch(dashboardActions.addWidget({ widget: widgetDetails }));
              setTimeout(() => dialogRef.close(), 500);
            })
            .catch(error => {
              dialogRef.componentInstance.loading = false;
              this.error = error?.response?.message;
            });
      }
    });

    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(() => {
      dialogDestroyer$.next(false);
      dialogDestroyer$.complete();
    });
  }
  isDashboardFilterLoading(event) {
    this.dashboardFilterLoading = event;
  }

  openQueryWidgetBuilder(queryName: string) {
    const dialogRef = this.dialog.open(QueryWidgetComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class'
    });
    const queryWidget = this.queryWidgets.find(item => item.params.variable_name === queryName);
    dialogRef.componentInstance.widget = queryWidget;
    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(result => {
      if (result.name === 'QUERY_WIDGET_SET') {
        this.queryWidgetValue = { [queryName]: result.value };
      }
    });
  }

  widgetTrackBy(index: number, item: any) {
    // return item.widget.widget_id;
    return item.widget.widget_id;
  }

  handleRelDate(sel: any) {
    this.relativeDateVal = sel.value;
    this.onSettingWidgetChangeHandler(null, 'relativeDate');
  }

  handleDateSelected(selected: any) {
    const maya = selected.data;
    this.selectedDate$.next(selected);
    this.inputVariableService.setSingleInputVariables('dateFrom', selected?.from);
    this.inputVariableService.setSingleInputVariables('dateTo', selected?.to);
  }

  handleWidgetRefresh() {
    this.dashboardService.toRefresh$.subscribe(w => {
      this.inputVariableService.refetch$.next(w);
    });
  }
}
