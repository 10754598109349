import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Frequency, ReportParameters } from '@modules/report/interfaces/report';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as cronParser from 'cron-parser';
import { ReportService } from '@modules/report/services/report.service';

@Component({
  selector: 'app-report-scheduling-form',
  templateUrl: './report-scheduling-form.component.html',
  styleUrls: ['./report-scheduling-form.component.scss']
})
export class ReportSchedulingFormComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  private cronRegExp = new RegExp(/^([1-5]?\d) (\*(\/([1-9]|1\d|2[0-3]))?|(([1]?\d|[2][0-3])(\,([1]?\d|[2][0-3]))*)) (\*(\/([1-9]|[1-2]\d|3[0-1]))?|(([1-9]|[1-2]\d|[3][0-1])(\,([1-9]|[1-2]\d|[3][0-1]))*)) (\*(\/([1-9]|[1][0-2]))?|(([1-9]|[1][0-2])(\,([1-9]|[1][0-2]))*)) (\*(\/[0-6])?|([0-6])(\,[0-6])*)$/);

  reportParametersLoading: Observable<boolean>;
  reportParameters: Observable<ReportParameters>;
  reportParametersError: Observable<string>;

  frequencyOptions: Frequency[] = [
    {
      id: 1,
      type: 'PRESET',
      friendly: 'Every three hours',
      cron: '0 */3 * * *'
    },
    {
      id: 2,
      type: 'PRESET',
      friendly: 'Every four hours',
      cron: '0 */4 * * *'
    },
    {
      id: 3,
      type: 'PRESET',
      friendly: 'Every six hours',
      cron: '0 */6 * * *'
    },
    {
      id: 4,
      type: 'PRESET',
      friendly: 'Every twelve hours',
      cron: '0 */12 * * *'
    },
    {
      id: 5,
      type: 'PRESET',
      friendly: 'Every day @ 12am',
      cron: '0 0 * * *'
    },
    {
      id: 6,
      type: 'PRESET',
      friendly: 'Every day @ 6am',
      cron: '0 6 * * *'
    },
    {
      id: 7,
      type: 'PRESET',
      friendly: 'Every day @ 12pm',
      cron: '0 12 * * *'
    },
    {
      id: 8,
      type: 'PRESET',
      friendly: 'Every day @ 6pm',
      cron: '0 18 * * *'
    },
    {
      id: 9,
      type: 'PRESET',
      friendly: 'Every Sunday @ 12pm',
      cron: '0 12 * * 0'
    },
    {
      id: 10,
      type: 'PRESET',
      friendly: 'Every Monday @ 12pm',
      cron: '0 12 * * 1'
    },
    {
      id: 11,
      type: 'PRESET',
      friendly: 'Every Tuesday @ 12pm',
      cron: '0 12 * * 2'
    },
    {
      id: 12,
      type: 'PRESET',
      friendly: 'Every Wednesday @ 12pm',
      cron: '0 12 * * 3'
    },
    {
      id: 13,
      type: 'PRESET',
      friendly: 'Every Thursday @ 12pm',
      cron: '0 12 * * 4'
    },
    {
      id: 14,
      type: 'PRESET',
      friendly: 'Every Friday @ 12am',
      cron: '0 12 * * 5'
    },
    {
      id: 15,
      type: 'PRESET',
      friendly: 'Every Saturday @ 12am',
      cron: '0 12 * * 6'
    },
    {
      id: 28,
      type: 'CUSTOM',
      friendly: 'Custom',
      cron: 'custom'
    }
  ];
  dateRangeOptions: any[] = [];

  form: UntypedFormGroup;
  cron: UntypedFormControl = new UntypedFormControl('');
  nextRunSchedules: string[] = [];
  maxCronExpressionRuns: number = 5;

  @Output() actionHandler: EventEmitter<any> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder, private reportService: ReportService) {
    this.form = this.fb.group({
      enabled: [true, []],
      schedule_rate: this.fb.group({
        date_from: ['', []],
        date_to: ['', []],
        run_forever: [false, []],
        frequency: ['', []]
      })
    });

    this.reportParametersLoading = this.reportService.reportParametersLoading;
    this.reportParametersError = this.reportService.reportParametersError;
    this.reportService.reportParameters.pipe(takeUntil(this.destroyer$), filter(v => !!v)).subscribe(val => this.dateRangeOptions = val.dashboardDateRanges);
  }

  ngOnInit(): void {
    this.form.controls.schedule_rate.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(scheduleRate => {
      if (scheduleRate.frequency !== this.cron.value && scheduleRate.frequency !== 'custom') {
        // set the cron to the value selected by user
        this.cron.setValue(scheduleRate.frequency);
      }
    });

    // when the cron expression is changed, we need to update the frequency option accordingly if it is one of the
    // value present. Otherwise, we set it to custom
    this.cron.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(cron => {
      const selectedFrequency = this.frequencyOptions.find(option => option.cron === cron);
      if (selectedFrequency) {
        // here we find the option selected so we need to set it properly
        this.form.controls.schedule_rate.patchValue({ frequency: selectedFrequency.cron })
      } else {
        this.form.controls.schedule_rate.patchValue({ frequency: 'custom' });
      }
      this.validateCronExpression(cron);
    });

    this.form.valueChanges.subscribe(val => this.actionHandler.emit(val));
  }

  validateCronExpression(cronExpression: string): void {
    this.cron.setErrors(null);
    if (this.cronRegExp.test(cronExpression)) {
      try {
        var interval = cronParser.parseExpression(cronExpression, { utc: true });
        this.nextRunSchedules = [...Array(this.maxCronExpressionRuns)].map(_ => {
          const nextRun = interval.next().toString();
          return nextRun;
        });
      } catch (e) {
        this.cron.setErrors({ invalid: true });
        this.nextRunSchedules = [];
      }
    } else {
      this.nextRunSchedules = [];
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
