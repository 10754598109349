import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify, Auth } from 'aws-amplify';

import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';
import { IEnvironment } from '@app/i-environment';


const env: IEnvironment = environment;

if (!!env.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).
  catch(err => console.error('init config error: ', err));

const requestRoute = `https://${window.location.hostname}`;
let region = env?.regionDomains[requestRoute]?.region || 'us-east-1';

localStorage.setItem('region', region);
const config = env.amplifyConfig[region];
Auth.configure(config);
Amplify.configure(config);
