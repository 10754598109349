import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EventParams, EventField } from './event-params.model';
import * as EventParamsActions from './event-params.actions';

export const eventParamsesFeatureKey = 'eventParams';

export interface State extends EntityState<EventParams> {
  // additional entities state properties
  eventParams: EventParams;
  loading: boolean;
  error: string;
}

export const adapter: EntityAdapter<EventParams> = createEntityAdapter<EventParams>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  eventParams: null,
  loading: false,
  error: null
});

const eventParamsReducer = createReducer(
  initialState,
  on(EventParamsActions.loadEventFields,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),
  on(EventParamsActions.loadEventFieldsSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        eventParams: action.eventParams
      };
    }
  ),
  on(EventParamsActions.loadEventFieldsError,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
        fields: []
      };
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return eventParamsReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
