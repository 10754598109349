import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type MENU_ACTION = 'DELETE' | 'REFRESH' | 'EDIT' | 'DOWNLOAD';

@Component({
  selector: 'app-widget-menu',
  templateUrl: './widget-menu.component.html',
  styleUrls: ['./widget-menu.component.scss']
})
export class WidgetMenuComponent implements OnInit {
  @Input() widgetId: string = null;
  @Input() refreshEnabled = true;
  @Input() editEnabled = true;
  @Input() downloadEnabled = true;
  @Input() deleteEnabled = true;

  @Output() onMenuAction: EventEmitter<{type: string, widgetId: string}> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onActionClick(actionType: MENU_ACTION): void {
    this.onMenuAction.emit({type: actionType, widgetId: this.widgetId});
  }

}
