import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor() { }

  get permissions(): Array<string> {
    // Get last user from local storage
    const lastUserKey = Object.keys(localStorage).find(item => item.endsWith('LastAuthUser'));
    const lastUser = localStorage[lastUserKey];
    // Get id token of last user from local storage
    // @ts-ignore
    const idKey = Object.keys(localStorage).find(item => item.endsWith(`${lastUser}.idToken`));
    const encoded = localStorage[idKey]?.split('.')[1] ?? '';
    // decode method taken from MotherMayI
    const newStr = encoded
      .replace('-', '+')
      .replace('_', '/');

    let decoded: any = decodeURIComponent(atob(newStr)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

    // return an array of granular permission strings
    decoded = JSON.parse(decoded)['custom:permissions'].split(',');
    return decoded;
  }

  hasPermission(requiredPermission: string | Array<string>, anyPermissionWillDo = false) {
    // If required permission is falsey
    if (!requiredPermission) {
      throw new Error(`requiredPermission is ${requiredPermission}`);
    }
    // if directive is set equal to a string, will check
    // if user has that string in his permissions
    if (typeof requiredPermission === 'string') {
      return this.permissions.includes(requiredPermission);
      // if directive is set equal to an array of strings,
    } else if (Array.isArray(requiredPermission)) {
      if (anyPermissionWillDo) {
        // will return true if any of the strings passed in
        // are present in the users permissions
        return requiredPermission.some(permission => this.permissions.includes(permission));
      }
      // will return true if all of the strings passed in
      // are present in the users permissions
      return requiredPermission.every(permission => this.permissions.includes(permission));
    }
  }

  // hasPermission(permissionName: string): boolean {
  //   // Get the prefix
  //   const prefix = localStorage.getItem('prefix') || null;
  //
  //   if (prefix) {
  //     // use the prefix to get the token
  //     const authUser = localStorage.getItem(`${prefix}.LastAuthUser`) || null;
  //
  //     if (authUser) {
  //       // get the token
  //       const tokenId = localStorage.getItem(`${prefix}.${authUser}.idToken`) || null;
  //
  //       // decode the token
  //       const decodedToken = jwt_decode(tokenId);
  //       const permissionList = decodedToken['custom:permissions'] || [];
  //       return permissionList.split(',').includes(permissionName);
  //     }
  //   } else {
  //     return false;
  //   }
  //
  // }
}
