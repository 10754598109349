<app-portal-loader [loadingStatus]="!templatesLoaded"></app-portal-loader>

<!-- Title & Search Bar-->
<div class="padding-column-card">
    <mat-card appearance="outlined" class="container1 padding-column-md" fxLayout fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
        <mat-card-title class="title align-center" fxFlex="grow">
            Knowledge Base & Glossary
        </mat-card-title>
        <div class="align-center padding-column-md" fxFlex="grow">

            <div class="form-group">
                <mat-form-field class="search-input">
                    <input matInput placeholder="Try Keywords on the Topic You Want Information on." [(ngModel)]="searchQuery">
                </mat-form-field>
            </div>
        </div>

        <div class="align-center padding-column-md" fxFlex="grow">
            <button mat-stroked-button color="primary" (click)="onSendInitQuery(searchQuery)">Search</button>
        </div>
    </mat-card>
</div>

<!-- Predefined Help Search / Section-->
<div *ngIf="!mainArticle" class="padding-column-card">
    <mat-card appearance="outlined" class="align-center container2 padding-column-md" fxLayout fxLayout.xs="row" fxLayoutGap="10px" fxLayoutGap.xs="0">
        <mat-card-title class="align-center padding-column" fxFlex="grow">HELP SECTIONS</mat-card-title>
        <div class="padding-column-sm" fxFlex="32">
            <button class=" predefined-button btn-primary big-btn" (click)="onSendInitQuery('dashboards')">Dashboards</button>
            <hr class="section-divider">
        </div>
        <div class="padding-column-sm" fxFlex="32">
            <button class=" predefined-button btn-primary big-btn" (click)="onSendInitQuery('incidents')">Incidents</button>
            <hr class="section-divider">
        </div>
        <div class="padding-column-sm" fxFlex="32">
            <button class=" predefined-button btn-primary big-btn" (click)="onSendInitQuery('events')">Events</button>
            <hr class="section-divider">
        </div>
        <div class="padding-column-sm" fxFlex="32">
            <button class=" predefined-button btn-primary big-btn" (click)="onSendInitQuery('reporting')">Reporting</button>
            <div class="padding-column-sm">
                <hr class="section-divider">
            </div>
        </div>
        <div class="padding-column-sm" fxFlex="32">
            <button class=" predefined-button btn-primary big-btn" (click)="onSendInitQuery('devices')">Devices</button>
            <div class="padding-column-sm">
                <hr class="section-divider">
            </div>
        </div>
        <div class="padding-column-sm" fxFlex="32">
            <button class=" predefined-button btn-primary big-btn" (click)="onSendInitQuery('log stream')">Log Stream</button>
            <div class="padding-column-sm">
                <hr class="section-divider">
            </div>
        </div>
        <div fxFlex="32">
            <button class=" predefined-button btn-primary big-btn" (click)="onSendInitQuery('security posture')">Security Posture</button>
        </div>
        <div fxFlex="32">
            <button class=" predefined-button btn-primary big-btn" (click)="onSendInitQuery('list management')">List Management</button>
        </div>
        <div fxFlex="32">
            <button class=" predefined-button btn-primary big-btn" (click)="onSendInitQuery('glossary')">Glossary</button>
        </div>
    </mat-card>
</div>

<!-- Main Article & Article List-->
<div *ngIf="!hitList; else searchList" class="align-center">
    <h1 class="empty-page">Want To Know More....?</h1>
</div>
<ng-template #searchList>
    <mat-card appearance="outlined" *ngIf="hitList" class="padding-column-article-list">
        <div>
            <p class="sub-title padding-column">
                Keyword(s): {{hitListKeywords}}
                <br><br>
                Results: {{hitListLen}}
            </p>
        </div>

        <!-- List of Summary Articles When Main Article is Not Selected-->
        <div *ngIf="!mainArticle; else mainArticleBody" class="padding-column-md container3">
            <mat-card appearance="outlined" class="padding-column-article-list articleCard article-button spacing-articles" *ngFor="let article of hitList"
                      (click)="loadMainArticle(article.id)">
                <mat-card-title>Article Title: {{article.title}}</mat-card-title>
                <mat-card-content markdown>{{article.excerpt}}</mat-card-content>
            </mat-card>
        </div>

        <!-- Main Article When Available-->
        <ng-template #mainArticleBody>
            <div class="padding-column-md container3">
                <button mat-stroked-button type="button" (click)="destroyMainArticle()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    Back to Article List
                </button>
                <mat-card appearance="outlined">
                    <mat-card-title>Article Title: {{mainArticle.title}}</mat-card-title>
                </mat-card>
                <div fxFill markdown>{{mainArticle.content}}</div>
            </div>
        </ng-template>
    </mat-card>
</ng-template>

<!-- Bottom of Page-->
<hr class="bottom-bar">
<p class="align-center padding-column-md">
    End of Page
</p>



