import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SecretService } from '@modules/secret/services/secret.service';
import { Observable } from 'rxjs';
import { SecretFormComponent } from '../secret-form/secret-form.component';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-secret',
  templateUrl: './secret.component.html',
  styleUrls: ['./secret.component.scss']
})
export class SecretComponent implements OnInit {
  private customerId: string;
  loading$: Observable<boolean>;
  error$: Observable<string | null>;
  secrets$: Observable<any[]>;


  constructor(
    private secretService: SecretService,
    private dialog: MatDialog
  ) {
    this.loading$ = this.secretService.loading$;
    this.error$ = this.secretService.error$;
    this.secrets$ = this.secretService.secrets$;
  }

  ngOnInit(): void {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.customerId = user.attributes.profile;
        // fetching the secrets
        this.secretService.fetchSecrets(this.customerId);
      })
  }

  add(): void {
    this.dialog.open(SecretFormComponent, {
      width: '90%',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'New Secret Key',
        payload: {
          customer_id: this.customerId
        }
      }
    }).afterClosed().subscribe();
  }

  edit(payload): void {
    this.dialog.open(SecretFormComponent, {
      width: '90%',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'Edit Secret Key',
        edit: true,
        payload
      }
    }).afterClosed().subscribe();
  }

  delete(payload): void {
    this.secretService.delete(this.customerId, payload.key_type);
  }

}
