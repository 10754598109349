// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GridsterConfig } from 'angular-gridster2/lib/gridsterConfig.interface';
import { Auth } from 'aws-amplify';
// @ts-ignore
import packageInfo from '../../package.json';

const API = {
  'us-east-1': {
    endpoints: [
      {
        name: 'list',
        endpoint: 'https://api.mrsp.io/v4/list/'
      },
      {
        name: 'company-secret',
        endpoint: 'https://api.mrsp.io/v4/company-secrets/'
      },
      {
        name: 'query-translator',
        endpoint: 'https://api.mrsp.io/v4/query-builder-translate/'
      },
      {
        name: 'reports',
        endpoint: 'https://api.mrsp.io/v4/reports/'
      },
      {
        name: 'validators',
        endpoint: 'https://api.mrsp.io/v4/validators/'
      },
      {
        name: 'investigation',
        endpoint: 'https://api.mrsp.io/v4/investigation/'
      },
      {
        name: 'userPreferences',
        endpoint: 'https://api.mrsp.io/v4/user-preferences/'
      },
      {
        name: 'incidentAttributes',
        endpoint: 'https://api.mrsp.io/v4/incident-attributes/'
      },
      {
        name: 'company',
        endpoint: 'https://api.mrsp.io/v4/company/'
      },
      {
        name: 'user',
        endpoint: 'https://api.mrsp.io/v4/users/'
      },
      {
        name: 'users',
        endpoint: 'https://api.mrsp.io/v4/users-search'
      },
      {
        name: 'search',
        endpoint: 'https://api.mrsp.io/v4/search/'
      },
      {
        name: 'eventParams',
        endpoint: 'https://api.mrsp.io/v4/event-params/'
      },
      {
        name: 'events',
        endpoint: 'https://api.mrsp.io/v4/search/'
      },
      {
        name: 'products',
        endpoint: 'https://api.mrsp.io/v4/company/products/'
      },
      {
        name: 'dashboardTemplate',
        endpoint: 'https://api.mrsp.io/v4/dashboard-templates-get-tags/'
      },
      {
        name: 'dashboards',
        endpoint: 'https://api.mrsp.io/v4/dashboards/'
      },
      {
        name: 'widgets',
        endpoint: 'https://api.mrsp.io/v4/widgets/',
        custom_header: async () => {
          const authUser = await Auth.currentSession();
          return { Authorization: authUser.getIdToken().getJwtToken() };
        }
      },
      {
        name: 'dashboards-bulk',
        endpoint: 'https://api.mrsp.io/v4/dashboard-bulk-create/'
      },
      {
        name: 'dashboard-duplicate',
        endpoint: 'https://api.mrsp.io/v4/dashboard-duplicate/'
      },
      {
        name: 'permissionRoles',
        endpoint: 'https://api.mrsp.io/v4/auth/role-permissions'
      },
      {
        name: 'roles',
        endpoint: 'https://api.mrsp.io/v4/role/'
      },
      {
        name: 'granularPermissions',
        endpoint: 'https://api.mrsp.io/v4/permissions'
      },
      {
        name: 'devices',
        endpoint: 'https://api.mrsp.io/v4/devices/'
      },
      {
        name: 'logstream',
        endpoint: 'https://api.mrsp.io/v4/logstream'
      },
      {
        name: 'security-posture',
        endpoint: 'https://api.mrsp.io/v4/company/' // {company_id} + 'preferences/security_posture_text'
      },
      {
        name: 'index',
        endpoint: 'https://api.mrsp.io/v4/index'
      },
      {
        name: 'companyIndexes',
        endpoint: 'https://api.mrsp.io/v4/indexes-by-company/'
      },
      {
        name: 'fieldSets',
        endpoint: 'https://api.mrsp.io/v4/field-sets'
      },
      {
        name: 'list-management',
        endpoint: 'https://api.mrsp.io/v4/'
      },
      {
        name: 'external-account',
        endpoint: 'https://api.mrsp.io/v4/user-external'
      },
      {
        name: 'apiKey',
        endpoint: 'https://api.mrsp.io/v4/'
      },
      {
        name: 'device-create',
        endpoint: 'https://api.mrsp.io/v4/appliance/compose'
      },
      {
        name: 'device-delete',
        endpoint: 'https://api.mrsp.io/v4/devices/'
      },
      {
        name: 'authentication',
        endpoint: 'https://api.mrsp.io/v4/auth/'
      },
      {
        name: 'device',
        endpoint: 'https://api.mrsp.io/v4/devices/'
      },
      {
        name: 'validators',
        endpoint: 'https://api.mrsp.io/v4/validators/'
      },
      {
        name: 'macaw',
        endpoint: 'https://api.mrsp.io/v4/macaw/'
      },
      {
        name: 'widgetDrillDown',
        endpoint: 'https://omt3zufycd.execute-api.us-east-1.amazonaws.com/2665-add-widget-drilldown-support-to-events-section-v4/widgets/'
      },
      {
        name: 'portal-metrics',
        endpoint: 'https://api.mrsp.io/v4/portal-metrics'
      },
      {
        name: 'widgetDrillDown',
        endpoint: 'https://omt3zufycd.execute-api.us-east-1.amazonaws.com/2665-add-widget-drilldown-support-to-events-section-v4/widgets/'
      },
      {
        name: 'query-translator',
        endpoint: 'https://omt3zufycd.execute-api.us-east-1.amazonaws.com/adding-new-service-for-translator-v4/query-builder-translate'
      },
      {
        name: 'product-details',
        endpoint: 'https://omt3zufycd.execute-api.us-east-1.amazonaws.com/2942-update-company-find-one-details-to-return-data-in-a-json-o-v4/company/products-tree'
      },
      {
        name: 'kb',
        endpoint: 'https://api.mrsp.io/v4/knowledge-base/'
      }
    ]
  }
};
const version = packageInfo.version;

export const environment = {
  name: 'dev',
  production: false,
  amplifyConfig: {
    'us-east-1': {
      Auth: {
        oauth: {
          domain: 'sso.mss.mrsp.io',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://sweet.mss.mrsp.io',
          redirectSignOut: 'http://localhost:3000/',
          responseType: 'code',
        },
        region: 'us-east-1',
        userPoolId: 'us-east-1_7VMky1KBI',
        userPoolWebClientId: '7u2eglhst8j3iceor8i1acf2f3'
      },
      API: API['us-east-1']
    },
    'eu-central-1': {
      Auth: {
        oauth: {
          domain: 'sso.eu1.mss.mrsp.io',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://sweet.eu1.mss.mrsp.io',
          redirectSignOut: 'http://localhost:3000/',
          responseType: 'code',
        },
        region: 'eu-central-1',
        userPoolId: 'eu-central-1_GeJWrMGtg',
        userPoolWebClientId: '2hvldfn2288ngaqsnq933btd4k'
      },
      API: API['eu-central-1']
    }
  },
  regions: [
    { name: 'us-east-1', value: 'US-DEV' },
    { name: 'eu-central-1', value: 'EU-DEV' }
  ],

  ssoOptions: [
    {
      label: 'My Level 3 - Env 1',
      value: 'mylevel3-env1',
      icon: 'vpn_lock'
    },
    {
      label: 'My Level 3 - Env 2',
      value: 'mylevel3-env2',
      icon: 'vpn_lock'
    },
    {
      label: 'My Level 3 - Env 3',
      value: 'mylevel3-env3',
      icon: 'vpn_lock'
    },
    {
      label: 'My Level 3 - Env 4',
      value: 'mylevel3-env4',
      icon: 'vpn_lock'
    },
  ],
  gradeColors: {
    grade1: '#B50D12 5px solid',
    grade2: '#C94218 5px solid',
    grade3: '#E6A243 5px solid',
    grade4: '#009054 5px solid',
    grade5: '#0080DC 5px solid'
  },
  landingPage: '/profile',

  hiddenFromProduction: [],
  hiddenFromProductionAdminLinks: [],
  regionDomains: {
    'https://sweet.eu1.mss.mrsp.io': {
      region: 'eu-central-1'
    },
    'https://sweet.mss.mrsp.io': {
      region: 'us-east-1'
    },
    'https://localhost': {
      region: 'us-east-1'
    },
  },
  version,
  flexmonsterLicense: 'Z7DF-XB410V-2Z4H1Q-3Q4N31',
  drillDown: true,
  domain: [
    'mylevel3-env1',
    'mylevel3-env2',
    'mylevel3-env3',
    'mylevel3-env4',
    'azureb2c'
  ]
};


export const dashboardGridsterConfig: GridsterConfig = {
  gridType: 'fit',
  fixedColWidth: 250,
  fixedRowHeight: 250,
  keepFixedHeightInMobile: false,
  keepFixedWidthInMobile: false,
  // setGridSize: boolean,
  compactType: 'none',
  mobileBreakpoint: 640,
  maxCols: 6,
  defaultItemCols: 2,
  defaultItemRows: 2,
  minRows: 1,
  maxItemCols: 50, // max item number of cols
  maxItemRows: 4, // max item number of rows
  minItemCols: 1, // min item number of columns
  minItemRows: 1, // min item number of rows
  minItemArea: 1,
  margin: 10,
  maxItemArea: 2500,
  outerMargin: true,
  outerMarginTop: null,
  outerMarginRight: null,
  outerMarginBottom: null,
  outerMarginLeft: null,
  useTransformPositioning: true,
  scrollSensitivity: 10,
  scrollSpeed: 10,
  initCallback: undefined,
  destroyCallback: undefined,
  itemChangeCallback: undefined,
  itemResizeCallback: undefined,
  itemInitCallback: undefined,
  itemRemovedCallback: undefined,
  draggable: {
    delayStart: 0,
    enabled: false,
    ignoreContentClass: 'gridster-item-content',
    ignoreContent: false,
    dragHandleClass: 'drag-handler',
    stop: undefined,
    start: undefined
  },
  resizable: {
    delayStart: 0,
    enabled: false,
    handles: {
      s: true,
      e: true,
      n: true,
      w: true,
      se: true,
      ne: true,
      sw: true,
      nw: true
    }
  },
  swap: true,
  pushItems: true,
  disablePushOnDrag: true,
  disablePushOnResize: false,
  // disableAutoPositionOnConflict?: boolean;
  pushDirections: { north: true, east: true, south: true, west: true },
  displayGrid: 'onDrag&Resize',
  disableWindowResize: false,
  disableWarnings: false,
  scrollToNewItems: false,
  emptyCellClickCallback: undefined,
  emptyCellContextMenuCallback: undefined,
  emptyCellDropCallback: undefined,
  emptyCellDragCallback: undefined,
  emptyCellDragMaxCols: 50,
  emptyCellDragMaxRows: 50,
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
