<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="dialog-wrapper">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
    <!-- CustomerID Display Field-->
    <mat-form-field class="example-full-width" fxFlexFill>
      <mat-label>Customer ID</mat-label>
      <input
              formControlName="profile"
              matInput
              name="profile"
              type="text"
              required
      />

    </mat-form-field>
    <br />

    <!-- User ID Input Field-->
    <mat-form-field class="example-full-width" fxFlexFill>
      <mat-label>Username</mat-label>
      <input
        formControlName="username"
        matInput
        name="username"
        type="text"
        required
      />
    </mat-form-field>
    <br />

    <!-- Domain Input Field-->
    <mat-form-field class="example-full-width" fxFlexFill>
      <mat-label>Domain</mat-label>
      <mat-select formControlName="domain" required>
        <mat-option *ngFor="let each of domain" [value]="each">{{
          each
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <br />

    <!-- Realm Input Field-->
    <mat-form-field class="example-full-width" fxFlexFill>
      <mat-label>Realm</mat-label>
      <mat-select formControlName="realm" required>
        <mat-option *ngFor="let each of realm" [value]="each.value">{{
          each.friendly
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <br />

    <!-- First Name Input Field-->
    <mat-form-field class="example-full-width" fxFlexFill>
      <mat-label>First Name</mat-label>
      <input
        formControlName="given_name"
        matInput
        name="given_name"
        type="text"
        required
      />
    </mat-form-field>
    <br />

    <!-- Last Name Input Field-->
    <mat-form-field class="example-full-width" fxFlexFill>
      <mat-label>Last Name</mat-label>
      <input
        formControlName="family_name"
        matInput
        name="family_name"
        type="text"
        required
      />
    </mat-form-field>

    <!-- Attributes Input Field-->
    <mat-form-field class="example-full-width" fxFlexFill>
      <mat-label>Access Attributes</mat-label>
      <mat-select formControlName="access_attributes" multiple>
        <mat-option *ngFor="let attribute of attributes" [value]="attribute">{{
          attribute
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Gender Input Field-->
    <mat-form-field class="example-full-width" fxFlexFill>
      <mat-label>Role(s)*</mat-label>
      <mat-select formControlName="gender" multiple required>
        <mat-option *ngFor="let role of gender" [value]="role.value">{{
          role.friendly
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ifUserCan="'account.edit-permissions'">
      <h2>Granular Permissions</h2>
      <mat-card-subtitle
        >Below you'll be able to modify user permissions on a granular
        level</mat-card-subtitle
      >
      <app-permission-list
        [user]="data.loggedUser"
        [userRoles]="userRoles$"
        (permissionChanged)="updatePermissions($event)"
      ></app-permission-list>
    </ng-container>

    <!-- Action Buttons-->
    <mat-dialog-actions>
      <div fxLayout="row" fxLayoutAlign="end center" fxFlex>
        <button mat-button color="warn" type="button" (click)="onCancel()">
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>
        <button
          *ngIf="!data.edit"
          mat-stroked-button
          color="primary"
          type="submit"
          [disabled]="addUserForm.invalid || loading"
        >
          <mat-icon>done</mat-icon>
          Submit
        </button>
        <button
          *ngIf="data.edit"
          mat-stroked-button
          color="primary"
          type="submit"
          [disabled]="addUserForm.invalid || loading"
        >
          <mat-icon>save</mat-icon>
          {{ edit ? "Update" : "Save" }}
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
