import { Gender, Realm } from '../interfaces/permission';
import { environment} from '../../../../environments/environment';

export const REALM: Realm[] = [
  { friendly: 'No Realm', value: 'norealm' },
  { friendly: 'Employee', value: 'employee' },
  { friendly: 'Partner', value: 'partner' }
];

export const DOMAIN: string[] = environment.domain;

export const GENDER: Gender[] = [
  { friendly: 'Admin', value: 'Admin' },
  { friendly: 'Analyst', value: 'Analyst' },
  { friendly: 'Auditor', value: 'Auditor' },
  { friendly: 'Compliance Officer', value: 'Compliance' },
  { friendly: 'Director', value: 'Director' },
  { friendly: 'Executive', value: 'Executive' },
  { friendly: 'Incident Worker', value: 'Incident' },
  { friendly: 'Investigator', value: 'Investigator' },
  { friendly: 'Manager', value: 'Manager' },
  { friendly: 'Provisioner', value: 'Provisioner' },
  { friendly: 'Researcher', value: 'Researcher' }
];
