export const LIST_MANAGEMENT_TABLE_ACTIONS = {
  deleteItemListPermission: 'list-mgmt.delete-item',
  editItemListPermission: 'list-mgmt.edit-item',
  createItemListPermission: 'list-mgmt.add-item',
  viewListPermission: 'list-mgmt.view-lists',
  viewItemListPermission: 'list-mgmt.view-items',
  clearListPermission: 'list-mgmt.clear-list'
}

export const TABLE_SETTINGS = {
  pageSizeOptions: [10, 20, 30, 50],
  filterExpression: (data, filter: string) => data.name.toLowerCase().includes(filter.trim().toLowerCase()),
  tableHeaders: [
    { friendly: 'Name', name: 'name', description: '' },
    { friendly: 'Description', name: 'description', description: '' }
  ],
  listDetailsHeaders: [
    { friendly: 'Event Signature ID', name: 'signature', description: '' },
    { friendly: 'Event Type', name: 'eventType', description: '' },
    { friendly: 'Action', name: 'actions', description: '' }
  ]
}