<section class="secret-container">
  <!-- Back button and add company -->
  <div class="btn-container">
    <button mat-stroked-button type="button" routerLink="/admin">
      <mat-icon>keyboard_arrow_left</mat-icon>
      Back to Admin Page
    </button>

    <!-- Add company button -->
    <button mat-stroked-button class="primary" (click)="add()">
      <mat-icon>add</mat-icon>
      Secret
    </button>
  </div>

  <section
    class="secret-list"
    *ngIf="
      (loading$ | async) === false && (error$ | async) === null;
      else loading
    "
  >
    <section
      class="secret-wrapper"
      *ngIf="(secrets$ | async).length > 0; else noSecrets"
    >
      <mat-list>
        <mat-list-item
          *ngFor="let secret of secrets$ | async"
          class="mat-elevation-z1"
        >
          <div class="secret-content">
            <div class="properties">
              <mat-label>Customer ID: {{ secret.customer_id }}</mat-label>
              <mat-label>Key Type: {{ secret.key_type }}</mat-label>
            </div>
            <div class="actions">
              <button mat-icon-button color="secondary" (click)="edit(secret)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete(secret)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </section>
  </section>

  <ng-template #loading>
    <section class="loader" *ngIf="loading$ | async; else error">
      <div class="loader-wrapper mat-elevation-z3">
        <mat-spinner mode="indeterminate"></mat-spinner>
        <p>Loading secret....</p>
      </div>
    </section>
  </ng-template>

  <ng-template #noSecrets>
    <section class="loader">
      <div class="loader-wrapper mat-elevation-z3" (click)="add()">
        <p>No Secret yet... Add one</p>
        <button mat-icon-button color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </section>
  </ng-template>

  <ng-template #error>
    <section class="error">
      <div class="error-wrapper mat-elevation-z3">
        <mat-icon>error</mat-icon>
        <p>
          Error loading company secret. Please contact your administrator for
          more details
        </p>
      </div>
    </section>
  </ng-template>
</section>
