import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ProductsActions from './products.actions';
import {Product} from './products.model';

export const productsFeatureKey = 'products';

export interface State extends EntityState<Product> {
  // additional entities state properties
  products: Product[];
  loading: boolean;
  error: string;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  products: null,
  loading: false,
  error: null
});

const productsReducer = createReducer(
  initialState,
  on(ProductsActions.loadProducts,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),
  on(ProductsActions.loadProductsSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        products: action.products
      };
    }
  ),
  on(ProductsActions.loadProductsError,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
        fields: []
      };
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return productsReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
