import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, FormArrayName, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Observable } from 'rxjs/internal/Observable';
import { Company } from '../../admin/store/company.model';

@Component({
  selector: 'app-device-create',
  templateUrl: './device-create.component.html',
  styleUrls: ['./device-create.component.scss']
})
export class DeviceCreateComponent implements OnInit {

  @Output() actionEvents: EventEmitter<any> = new EventEmitter();
  @Input() loading: boolean;
  companies$: Observable<Company[] | {label: string, value: string}[]>;
  deviceForm: UntypedFormGroup;
  lca_trusted_certs: UntypedFormArray;
  companies: Company[];


  companyList: any[];
  showCertificate = false;
  showProxyUrl = false;
  searchText = '';
  isChecked = false;
  isCheckbox: boolean;
  certificateValidate = '-----BEGIN CERTIFICATE-----\n + "/([a-zA-Z0-9])/"';
  proxyInputValidate = '/^(ftp|http|https):\\/\\/[^ "]+$/';

  color: ThemePalette = 'primary';


  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.deviceForm = this.fb.group({
      appliance_name: [[], Validators.pattern('^([a-zA-Z]+[a-zA-Z0-9.-]+)$')],
      customerId: [[], Validators.required],
      tls: new UntypedFormControl(false), // not in payload
      build_nxlog: new UntypedFormControl(false),
      NetFlow: new UntypedFormControl(false),
      proxy_url: new UntypedFormControl(''),
      lca_cert: ['', Validators.pattern('^-----BEGIN CERTIFICATE-----[\\/+a-zA-Z0-9=\\s]+-----END CERTIFICATE-----$')],
      proxy_input: [[],  Validators.pattern('^(http://)([^:@/]+:[^:@/]+@)?[0-9a-zA-Z]+[0-9a-zA-Z.-]+(:[0-9]{1,5})$')],
      use_ssl: new UntypedFormControl(false, Validators.required),
      // lca_trusted_certs: this.fb.array([this.createTrustedCertificate()]),
      lca_trusted_certs: this.fb.array([]),
      acknowledge: ['']
    });
  }

  createTrustedCertificate(): UntypedFormGroup {
    return this.fb.group({
      title: ['', []],
      body: ['', Validators.pattern('^-----BEGIN CERTIFICATE-----[\\/+a-zA-Z0-9=\\s]+-----END CERTIFICATE-----$')]
    });

  }

  // to be used in the HTML
  getControls() {
    return (this.deviceForm.get('lca_trusted_certs') as UntypedFormArray).controls;
  }

  addFieldTrustedCertificate(): void {
   this.lca_trusted_certs = this.deviceForm.get('lca_trusted_certs') as UntypedFormArray;
   this.lca_trusted_certs.push(this.createTrustedCertificate());
  }

  onSubmit(eventName) {
    if (eventName === 'submit') {
      // subscribe to TLS value to change use_ssl
      this.deviceForm.get('tls').valueChanges.subscribe(val => {
        this.deviceForm.get('use_ssl').setValue(val);
      });
      if (this.deviceForm.value.lca_cert === '' ||
      this.deviceForm.value.proxy_url === '' || this.deviceForm.value.tls === false ||
      this.deviceForm.value.acknowledge === '' ) {
        delete this.deviceForm.value.lca_cert;
        delete this.deviceForm.value.proxy_url;
        delete this.deviceForm.value.tls;
        delete this.deviceForm.value.acknowledge;
      }
      this.actionEvents.emit({name: eventName, data: this.deviceForm});
    } else  {
      this.actionEvents.emit({name: eventName, data: null});
    }
  }
}
