import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';


import _ from 'lodash';
import * as am4maps from '@amcharts/amcharts4/maps';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import { InputVariableService } from '@sweet-shared/services/input-variable.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppService } from '@app/app.service';
import { WidgetInfoComponent } from '@sweet-shared/components/widgets/widget-info/widget-info.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  private apiCall = null;

  private _height;
  loading = false;

  @Input() widgetDetails: any = null;
  @Input() set height(h) {
    this._height = h;
  }
  get height() {
    return this._height;
  }

  @Output() widgetAction = new EventEmitter();

  inputVariables: { [key: string]: any } = null;
  shouldFetch = false;
  errorMessage: string = null;

  // chart element
  chart: any = null;

  constructor(
    private inputVariablesService: InputVariableService,
    private widgetService: AppService<any>,
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Set the input variables
    this.setWidgetInputVariables();
    this.setInputVariableSubscription();
  }

  private setInputVariableSubscription(): void {
    this.inputVariablesService.inputVariables.pipe(takeUntil(this.destroyer$)).subscribe(inputVariables => {
      Object.keys(inputVariables).forEach(name => {
        if (this.inputVariables.hasOwnProperty(name)) {
          if (this.inputVariables[name] === null || this.inputVariables[name] !== inputVariables[name]) {
            this.inputVariables[name] = inputVariables[name];
            this.shouldFetch = true;
          }
        }
      });

      if (this.shouldFetch) {
        if (!Object.values(this.inputVariables).includes(null)) {
          this.fetchWidgetData();
        } else {
          this.shouldFetch = false;
        }
      }
    });
  }

  private setWidgetInputVariables(): void {
    const widgetInputVariables = this.widgetDetails?.input_variables ?? [];
    this.inputVariables = widgetInputVariables.reduce((acc, next) => {
      acc[next.input_variable_name] = next.default_value;
      return acc;
    }, { company: null, dateFrom: null, dateTo: null });
  }

  private fetchWidgetData(): void {
    let response = null;
    this.errorMessage = null;
    this.loading = true;
    this.apiCall = this.widgetService.put(`widgets`, `${this.widgetDetails.dashboard_id}/${this.widgetDetails.widget_id}/get-data`, null, this.inputVariables, true);
    this.apiCall.then(res => response = res)
      .catch(err => this.errorMessage = err)
      .finally(() => {
        if (!this.errorMessage) {
          // check the response and see if we get a 200 or 200 and handle he 202 properly
          if (response.status === 200) {
            const dataUrl = response.data.url;
            this.getWidgetDataResult(dataUrl);
          } else if (response.status === 202) {
            // The query is still running in the backend, let reping it
            return this.fetchWidgetData();
          }
        }
        this.shouldFetch = false;
        this.loading = false;
      }
      );
  }

  private getWidgetDataResult(url: string) {
    this.httpClient.get(url).pipe(takeUntil(this.destroyer$)).subscribe((res: any[]) => {
      const targetSVG = 'M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z';
      const interfaceColors = new am4core.InterfaceColorSet();

      if (!this.chart) {
        this.chart = am4core.create(this.widgetDetails.widget_id, am4maps.MapChart);

        // since it is a brand new chart, let set the legend
        this.chart.legend = new am4charts.Legend();

        // set map definition
        this.chart.geodata = am4geodata_worldLow;

        // Set projection
        this.chart.projection = new am4maps.projections.Mercator();

        // Add zoom control
        this.chart.zoomControl = new am4maps.ZoomControl();

        // Set initial zoom
        this.chart.homeZoomLevel = 1;
        this.chart.homeGeoPoint = { latitude: 35, longitude: 0 };
      }

      const lineSeries = this.chart.series.push(new am4maps.MapLineSeries());
      lineSeries.dataFields.multiGeoLine = 'multiGeoLine';

      const lineTemplate = lineSeries.mapLines.template;
      lineTemplate.nonScalingStroke = true;
      lineTemplate.arrow.nonScaling = true;
      lineTemplate.arrow.width = 4;
      lineTemplate.arrow.height = 6;
      lineTemplate.stroke = interfaceColors.getFor('alternativeBackground');
      lineTemplate.fill = interfaceColors.getFor('alternativeBackground');
      lineTemplate.line.strokeOpacity = 0.4;

      this.mapImageSeries(targetSVG, interfaceColors, res);
      this.mapLineSeries(res);

    });
  }

  private mapImageSeries(targetSVG, interfaceColors, res) {
    // Create map polygon series
    const polygonSeries = this.chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.exclude = ['AQ'];
    polygonSeries.useGeodata = true;
    polygonSeries.mapPolygons.template.nonScalingStroke = true;

    // Add images
    const imageSeries = this.chart.series.push(new am4maps.MapImageSeries());
    const imageTemplate = imageSeries.mapImages.template;
    imageTemplate.tooltipText = '{title}\nLatitude: {latitude}\nLongitude: {longitude}';
    imageTemplate.nonScaling = true;

    const marker = imageTemplate.createChild(am4core.Sprite);
    marker.path = targetSVG;
    marker.horizontalCenter = 'middle';
    marker.verticalCenter = 'middle';
    marker.scale = 0.7;
    marker.fill = interfaceColors.getFor('alternativeBackground');

    imageTemplate.propertyFields.latitude = 'latitude';
    imageTemplate.propertyFields.longitude = 'longitude';

    let tempImageSeries = _.flatten(_.map(res, 'imageSeriesData'));
    tempImageSeries = tempImageSeries.map((value) => ({
      ...value,
      svgPath: targetSVG,
      scale: 0.5
    }));

    imageSeries.data = tempImageSeries;
  }

  private mapLineSeries(res) {
    const lineSeries = this.chart.series.push(new am4maps.MapLineSeries());

    const tempLineSeries = _.map(res, 'lineSeriesData');
    lineSeries.data = tempLineSeries.map((value) => ({ multiGeoLine: [value] }));
    return lineSeries;
  }

  refresh(): void {
    this.fetchWidgetData();
  }

  info(): void {
    this.dialog.open(WidgetInfoComponent, {
      width: '40vw',
      maxWidth: '95vw',
      data: this.widgetDetails
    });
  }

  ngOnDestroy(): void {
    this.chart?.dispose();

    if (this.apiCall) {
      this.widgetService.cancel(this.apiCall);
    }
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
