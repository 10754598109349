import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { AppService } from '@app/app.service';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

export const PORTAL_METRIC_URI = 'portal-metrics';
export interface SessionMetric {
  id?: number;
  route: string;
  startTime: number;
  endTime: number;
  nextRoute: string;
}

export interface ISessionPkg {

}
@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  private _session$: BehaviorSubject<SessionMetric[]> = new BehaviorSubject([]);
  private metricsBuffer: SessionMetric | undefined = undefined;
  private company = '';
  private role = '';
  uuid = uuid();
  public session$ = this._session$.asObservable();

  constructor(
    public router: Router,
    private httpService: AppService<any>,
  ) {

    // listen for navigation start events and update metric
    this.router.events.pipe(filter((evt: RouterEvent) => evt instanceof NavigationStart))
      .subscribe((rEvent: RouterEvent) => {
        const nextSession = this.makeBuffer(this.metricsBuffer, rEvent);
        const prevMetric = [...this._session$.getValue(), nextSession];
        this._session$.next(prevMetric);
        this.metricsBuffer = this.makeBuffer(undefined, rEvent)
      });
  }

  private makeBuffer(buffer: SessionMetric, routerEvent: RouterEvent): SessionMetric {
    const curTime = Date.now();

    if (!!!buffer) {
      return { startTime: curTime, endTime: curTime, route: routerEvent?.url, nextRoute: null }
    }

    return { ...buffer, nextRoute: routerEvent?.url, endTime: Date.now() }
  }

  getSessionDuration(buffer: SessionMetric): number {
    const st = buffer?.startTime || 0;
    const et = buffer?.endTime || 0;
    return new Date(et - st).getSeconds();
  }

  getSessionMetrics(): any[] {
    return this._session$.getValue()
    .map((dat: any) => {
      return {
        start_time: `${dat?.startTime}`,
        end_time: `${dat?.endTime}`,
        route: dat?.route
      }
    });
  }

  submitMetrics(role: string, company: string): Observable<any> {
    const sm = this.getSessionMetrics();
    const n = sm?.length;
    const st = sm[0]?.start_time;
    const et = sm[n - 1]?.end_time;

    const pkg = {
      company,
      role,
      start_time: `${st}`,
      end_time: `${et}`,
      session_data: sm,
      uuid: this.uuid
    }

    const pr = this.httpService.post(
      PORTAL_METRIC_URI,
      '',
      null,
      pkg
    )

    return from(pr)
  }

}
