import { UserPreferenceComponent } from './components/user-preference/user-preference.component';
import { AuthGuardService } from './../../guards/auth-gaurd.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'user-preferences',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: UserPreferenceComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserPreferenceRoutingModule { }
