export interface CreateReportFormValueModel {
  name: string;
  dashboardName?: string;
  description: string;
  related_dashboard_id: string,
  rate: ReportRateEnum;
  onceDateTime: string;
  rangeStartTime: string;
  rangeStartEndTime: string;
  frequency: string;
  frequencyTime: string;
  frequencyDate: string;
  dayofWeek: string;
  company: string;
  data_range: string;
  customRangeUnit: string;
  customRangeValue: string;
  cronValue: string;
}

export interface ReportModel {
  // required for POST
  related_dashboard_id: string;
  name: string;
  description: string;
  is_active: boolean;
  params: ReportParamsModel;
  frequency: string;
  // Could be returned from GET
  report_id?: string;
  owner_id?: string;
  created_by?: string;
  created_at?: string;
  updated_by?: string;
  updated_at?: string;
  title?: string;
}

export interface ReportParamsModel {
  schedule_rate: ScheduleRateModel;
  data_range: DataRangeModel;
}

export interface ScheduleRateModel {
  dateFrom: string;
  dateTo: string;
}

export interface DataRangeModel {
  unit: DataRangeUnitEnum;
  value: number;
}

export enum ReportStatusEnum {
  DONE = 'done',
  RUNNING = 'running',
  QUEUED = 'queued',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  SCHEDULED = 'scheduled'
}

export enum ReportFrequencyEnum {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly'
}

export enum ReportRateEnum {
  ONCE = 'once',
  RANGE = 'range'
}

export enum DataRangeUnitEnum {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const daysOfWeekArray: string[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

export interface GeneralInfoFormConfig {
  customer_id: string;
  title: string;
  description: string;
  related_dashboard_id: string;
  dataFrame: {
    start: string;
    end: string;
  }
}