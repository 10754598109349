import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../guards/auth-gaurd.service';
import { PermissionsGuardService } from '../../guards/permissions-guard.service';
import { ListManagementComponent } from './components/list-management/list-management.component';

const routes: Routes = [{
  path: 'list',
  canActivate: [AuthGuardService],
  data: {
    requiredPermission: ['portal-access.enable-admin-lists-tab'],
    any: false
  },
  children: [
    {
      path: '',
      canActivate: [PermissionsGuardService],
      component: ListManagementComponent
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ListManagementsRoutingModule { }
