import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';


import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import { InputVariableService } from '@sweet-shared/services/input-variable.service';
import { WidgetInfoComponent } from '@sweet-shared/components/widgets/widget-info/widget-info.component';

@Component({
  selector: 'app-solid-gauge',
  templateUrl: './solid-gauge.component.html',
  styleUrls: ['./solid-gauge.component.scss']
})
export class SolidGaugeComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  private apiCall = null;
  private _height;
  loading = false;

  @Input() widgetDetails: any = null;
  @Input() set height(h) {
    this._height = h;
  }
  get height() {
    return this._height;
  }

  @Output() widgetAction = new EventEmitter();

  inputVariables: { [key: string]: any } = null;
  shouldFetch = false;
  errorMessage: string = null;

  // chart element
  chart: any = null;

  constructor(
    private inputVariablesService: InputVariableService,
    private widgetService: AppService<any>,
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Set the input variables
    this.setWidgetInputVariables();
    this.setInputVariableSubscription();
  }

  private setInputVariableSubscription(): void {
    this.inputVariablesService.inputVariables.pipe(takeUntil(this.destroyer$)).subscribe(inputVariables => {
      Object.keys(inputVariables).forEach(name => {
        if (this.inputVariables.hasOwnProperty(name)) {
          if (this.inputVariables[name] === null || this.inputVariables[name] !== inputVariables[name]) {
            this.inputVariables[name] = inputVariables[name];
            this.shouldFetch = true;
          }
        }
      });

      if (this.shouldFetch) {
        if (!Object.values(this.inputVariables).includes(null)) {
          this.fetchWidgetData();
        } else {
          this.shouldFetch = false;
        }
      }
    });
  }

  private setWidgetInputVariables(): void {
    const widgetInputVariables = this.widgetDetails?.input_variables ?? [];
    this.inputVariables = widgetInputVariables.reduce((acc, next) => {      acc[next.input_variable_name] = next.default_value;
      return acc;
    }, { company: null, dateFrom: null, dateTo: null });
  }

  private fetchWidgetData(): void {
    let response = null;
    this.errorMessage = null;
    this.loading = true;
    this.apiCall = this.widgetService.put(`widgets`, `${this.widgetDetails.dashboard_id}/${this.widgetDetails.widget_id}/get-data`, null, this.inputVariables, true);
    this.apiCall.then(res => response = res)
      .catch(err => this.errorMessage = err)
      .finally(() => {
        if (!this.errorMessage) {
          // check the response and see if we get a 200 or 200 and handle he 202 properly
          if (response.status === 200) {
            const dataUrl = response.data.url;
            this.getWidgetDataResult(dataUrl);
          } else if (response.status === 202) {
            // The query is still running in the backend, let reping it
            return this.fetchWidgetData();
          }
        }
        this.shouldFetch = false;
        this.loading = false;
        this.apiCall = null;
      }
      );
  }

  private getWidgetDataResult(url: string) {
    this.httpClient.get(url).pipe(takeUntil(this.destroyer$)).subscribe((res: any[]) => {
      if (!this.chart) {
        this.chart = am4core.create(this.widgetDetails.widget_id, am4charts.GaugeChart);
        this.chart.innerRadius = -15;

        const axis = this.chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
        axis.min = 0;
        axis.max = 100;
        axis.strictMinMax = true;

        // since it is a brand new chart, let set the legend
        this.chart.legend = new am4charts.Legend();

        // the legend has its own container, let move it there
        const legendContainer = am4core.create(`${this.widgetDetails.widget_id}-legend`, am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);
        this.chart.legend.parent = legendContainer;
      }

      // Make chart not full circle
      this.chart.startAngle = -90;
      this.chart.endAngle = 180;
      this.chart.innerRadius = am4core.percent(20);

      // Set number format
      this.chart.numberFormatter.numberFormat = '#.#\'%\'';

      // Create axes
      const categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'name';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.fontWeight = 500;
      categoryAxis.renderer.labels.template.adapter.add('fill', (fill, target) => {
        return (target.dataItem.index >= 0) ? this.chart.colors.getIndex(target.dataItem.index) : fill;
      });
      categoryAxis.renderer.minGridDistance = 10;

      const valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;

      const title = this.chart.titles.create();
      title.text = this.widgetDetails.title;
      // title.text = `${widget.params.field.alias? widget.params.field.alias.toUpperCase(): widget.params.field.friendly.toUpperCase()}`;
      title.fontSize = 16;
      title.fontWeight = 'bold';

      // Create series
      const series1 = this.chart.series.push(new am4charts.RadarColumnSeries());
      series1.dataFields.valueX = 'percentage';
      series1.dataFields.categoryY = 'name';
      series1.clustered = false;
      series1.columns.template.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');

      series1.columns.template.fillOpacity = 0.08;
      series1.columns.template.cornerRadiusTopLeft = 20;
      series1.columns.template.strokeWidth = 0;
      series1.columns.template.radarColumn.cornerRadius = 20;

      const series2 = this.chart.series.push(new am4charts.RadarColumnSeries());
      series2.dataFields.valueX = 'count';
      series2.dataFields.categoryY = 'name';
      series2.clustered = false;
      series2.columns.template.strokeWidth = 0;
      series2.columns.template.tooltipText = '{name}: [bold]{count}[/]';
      series2.columns.template.radarColumn.cornerRadius = 20;

      series2.columns.template.adapter.add('fill', (fill, target) => {
        return this.chart.colors.getIndex(target.dataItem.index);
      });
      // Add cursor
      this.chart.cursor = new am4charts.RadarCursor();
      this.chart.data = res;
    })
  }

  refresh(): void {
    this.fetchWidgetData();
  }

  info(): void {
    this.dialog.open(WidgetInfoComponent, {
      width: '40vw',
      maxWidth: '95vw',
      data: this.widgetDetails
    });
  }

  ngOnDestroy(): void {
    this.chart?.dispose();
    this.destroyer$.next(true);
    this.destroyer$.complete();

    if (this.apiCall) {
      this.widgetService.cancel(this.apiCall);
      this.apiCall = null;
    }
  }
}
