import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InputVariableService } from '@sweet-shared/services/input-variable.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import _ from 'lodash';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { AppService } from '@app/app.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WidgetInfoComponent } from '@sweet-shared/components/widgets/widget-info/widget-info.component';

@Component({
  selector: 'app-fuel-gauge',
  templateUrl: './fuel-gauge.component.html',
  styleUrls: ['./fuel-gauge.component.scss']
})
export class FuelGaugeComponent implements OnInit, OnDestroy {
  private apiCall = null;
  private destroyer$ = new Subject();
  private _height;
  loading = false;

  @Input() widgetDetails: any = null;
  @Input() set height(h) {
    this._height = h;
  }
  get height() {
    return this._height;
  }

  @Output() widgetAction = new EventEmitter();

  inputVariables: { [key: string]: any } = null;
  shouldFetch = false;
  errorMessage: string = null;

  // chart element
  chart: any = null;

  constructor(
    private inputVariablesService: InputVariableService,
    private widgetService: AppService<any>,
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Set the input variables
    this.setWidgetInputVariables();
    this.setInputVariableSubscription();
  }

  private setInputVariableSubscription(): void {
    this.inputVariablesService.inputVariables.pipe(takeUntil(this.destroyer$)).subscribe(inputVariables => {
      Object.keys(inputVariables).forEach(name => {
        if (this.inputVariables.hasOwnProperty(name)) {
          if (this.inputVariables[name] === null || this.inputVariables[name] !== inputVariables[name]) {
            this.inputVariables[name] = inputVariables[name];
            this.shouldFetch = true;
          }
        }
      });

      if (this.shouldFetch) {
        if (!Object.values(this.inputVariables).includes(null)) {
          this.fetchWidgetData();
        } else {
          this.shouldFetch = false;
        }
      }
    });
  }

  private setWidgetInputVariables(): void {
    const widgetInputVariables = this.widgetDetails?.input_variables ?? [];
    this.inputVariables = widgetInputVariables.reduce((acc, next) => {
      acc[next.input_variable_name] = next.default_value;
      return acc;
    }, { company: null, dateFrom: null, dateTo: null });
  }

  private fetchWidgetData(): void {
    let response = null;
    this.errorMessage = null;
    this.loading = true;
    this.apiCall = this.widgetService.put(`widgets`, `${this.widgetDetails.dashboard_id}/${this.widgetDetails.widget_id}/get-data`, null, this.inputVariables, true);
    this.apiCall.then(res => response = res)
      .catch(err => this.errorMessage = err)
      .finally(() => {
        if (!this.errorMessage) {
          // check the response and see if we get a 200 or 200 and handle he 202 properly
          if (response.status === 200) {
            const dataUrl = response.data.url;
            this.getWidgetDataResult(dataUrl);
          } else if (response.status === 202) {
            // The query is still running in the backend, let reping it
            return this.fetchWidgetData();
          }
        }
        this.shouldFetch = false;
        this.loading = false;
        this.apiCall = null;
      }
      );
  }

  private getWidgetDataResult(url: string) {
    this.httpClient.get(url).pipe(takeUntil(this.destroyer$)).subscribe((res: any[]) => {
      if (!this.chart) {
        this.chart = am4core.create(this.widgetDetails.widget_id, am4charts.GaugeChart);
        this.chart.innerRadius = -15;

        // since it is a brand new chart, let set the legend
        this.chart.legend = new am4charts.Legend();

        // the legend has its own container, let move it there
        const legendContainer = am4core.create(`${this.widgetDetails.widget_id}-legend`, am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);
        this.chart.legend.parent = legendContainer;
      }

      const axis = this.chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
      axis.min = 0;
      axis.max = 100;
      axis.strictMinMax = true;

      const colorSet = new am4core.ColorSet();

      const gradient = new am4core.LinearGradient();
      gradient.stops.push({ color: am4core.color('#0080DC') });
      gradient.stops.push({ color: am4core.color('#009054') });
      gradient.stops.push({ color: am4core.color('#E6A243') });
      gradient.stops.push({ color: am4core.color('#C94218') });
      gradient.stops.push({ color: am4core.color('#B50D12') });

      axis.renderer.line.stroke = gradient;
      axis.renderer.line.strokeWidth = 25;
      axis.renderer.line.strokeOpacity = 1;

      axis.renderer.grid.template.disabled = true;

      const hand = this.chart.hands.push(new am4charts.ClockHand());
      hand.radius = am4core.percent(75);

      setInterval(() => {
        hand.showValue(res, 1000, am4core.ease.cubicOut);
      }, 2000);
    })
  }

  refresh(): void {
    this.fetchWidgetData();
  }

  info(): void {
    this.dialog.open(WidgetInfoComponent, {
      width: '40vw',
      maxWidth: '95vw',
      data: this.widgetDetails
    });
  }

  ngOnDestroy(): void {
    this.chart?.dispose();
    this.destroyer$.next(true);
    this.destroyer$.complete();

    if (this.apiCall) {
      this.widgetService.cancel(this.apiCall);
      this.apiCall = null;
    }
  }
}
