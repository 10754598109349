import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '@app/app.service';
import { Company } from '@modules/companies/interfaces/companies';
import { CompaniesService } from '@modules/companies/services/companies.service';
import { ReportService } from '@modules/report/services/report.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-report-general-form',
  templateUrl: './report-general-form.component.html',
  styleUrls: ['./report-general-form.component.scss']
})
export class ReportGeneralFormComponent implements OnInit {
  private destroyer$ = new Subject();
  private inputWidgetTypes = ['dropdown', 'text_input', 'string_dropdown'];

  // Input for the related dashboard
  @Input() dashboard: any;
  @Input() company: string;
  @Output() actionHandler: EventEmitter<any> = new EventEmitter();
  companies: Company[] = [];
  shouldUpdateCustom = true;

  form: UntypedFormGroup;
  inputWidgets: any[] = [];
  dateOptions: any[] = [];
  customDate: UntypedFormGroup = this.fb.group({
    start: ['', [Validators.required]],
    end: ['', [Validators.required]],
  });

  constructor(
    private companyService: CompaniesService,
    private reportService: ReportService,
    private httpClient: AppService<any>,
    private fb: UntypedFormBuilder
  ) {
    this.companyService.fetchCompanies().pipe(takeUntil(this.destroyer$)).subscribe({
      next: companies => this.companies = companies,
      error: (error) => console.log(error)
    });

    this.reportService.reportParameters.pipe(takeUntil(this.destroyer$)).subscribe(reportParams => this.dateOptions = reportParams?.dashboardDateRanges ?? []);

    this.form = this.fb.group({
      company: [null, [Validators.required]],
      title: [null, [Validators.required]],
      description: [null, [Validators.required]],
      input_variables: this.fb.group({}),
      data_time_frame: [null, [Validators.required]]
    });

    // attach the data range to react to changes
    this.form.controls.data_time_frame.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(dataRange => {
      const [start, end] = [this.customDate.value.start, this.customDate.value.end];
      if (start !== dataRange.from && end !== dataRange.to && this.shouldUpdateCustom) {
        this.customDate.patchValue({ start: dataRange.from, end: dataRange.to });
      }
      this.shouldUpdateCustom = true;
    });

    this.customDate.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(value => {
      const option = this.dateOptions.find(o => value.start === o.value.from && value.end === o.value.to);
      if (option) {
        this.form.patchValue({ data_time_frame: option.value });
      } else {
        // set it to custom if not alredy set
        if (this.form.value.data_time_frame?.type ?? null !== 'relative') {
          this.shouldUpdateCustom = false;
          this.form.patchValue({ data_time_frame: { from: '', to: '', type: 'relative' } });
        }

        // clear the errors
        this.customDate.controls['start'].setErrors(null);
        this.customDate.controls['end'].setErrors(null);

        // validate maya expression since it has been written by the user.
        forkJoin({
          from: this.httpClient.post('validators', '', null, {
            type: 'maya',
            expression: value.start
          }, true).catch(error => { return { error: error.message } }),
          to: this.httpClient.post('validators', '', null, {
            type: 'maya',
            expression: value.end
          }, true).catch(error => { return { error: error.message } })
        }).pipe(takeUntil(this.destroyer$)).subscribe(response => {
          const { from, to } = response;
          if (from.error) {
            // set the error
            this.customDate.controls['start'].setErrors({ invalid: true });
          }
          if (to.error) {
            this.customDate.controls['end'].setErrors({ invalid: true });
          }
          if (this.customDate.valid) {
            // update the main form to trigger the emit
            this.shouldUpdateCustom = false;
            this.form.patchValue({ data_time_frame: { type: 'maya', from: value.start, to: value.end } });
          }
        });

      }
    })
    this.form.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(values => {
      const data = { valid: this.form.valid, data: values };
      this.actionHandler.emit(data);
    });
  }

  ngOnInit(): void {
    if (this.company) {
      this.form.controls.company.setValue(this.company);
    }
    this.processInputVariables();

  }

  compareWith(option, value): boolean {
    return option && value && ((option.from === value.from && option.to === value.to) || (option.type === 'relative'));
  }


  private processInputVariables(): void {
    if (this.dashboard) {
      const inputParamsForm = this.form.controls['input_variables'] as UntypedFormGroup;
      // get all input variables and for each of them create thier entry into the global form
      this.inputWidgets = this.dashboard
        .widgets
        .filter(widget => this.inputWidgetTypes.includes(widget.type));

      this.inputWidgets.forEach(widget => {
        const paramName = widget.params.inputName;
        const isMulti = widget.params.isMultiSelect;
        const defaultValue = widget.params?.default_value ?? isMulti ? '_Any_' : '%';
        inputParamsForm.addControl(paramName, new UntypedFormControl(isMulti ? [defaultValue] : defaultValue, [Validators.required]));
        widget.options = isMulti ? [{ label: 'Select All', value: '%' }, { label: 'Any', value: '_Any_' }, ...this.getInputWidgetOptions(widget)] : this.getInputWidgetOptions(widget);
      });
    }
  }

  getInputWidgetOptions(widget: any): any[] {
    const widgetName = widget.params.inputName;
    const data = JSON.parse(localStorage.getItem(widgetName) ?? '[]');
    return data;
  }

  trackByMethod(index: number, el: any): string {
    return el.widget_id;
  }
}
