import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-portal-loader',
  templateUrl: './portal-loader.component.html',
  styleUrls: ['./portal-loader.component.scss']
})
export class PortalLoaderComponent implements OnInit {
  @Input() loadingStatus = false;
  @Input() progressMode = 'indeterminate';

  constructor() {
  }

  ngOnInit() {
  }

}
