import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sweet-loader',
  templateUrl: './sweet-loader.component.html',
  styleUrls: ['./sweet-loader.component.scss']
})
export class SweetLoaderComponent implements OnInit {
  @Input() type: 'PROGRESS' | 'SPINNER' = 'PROGRESS';
  @Input() message: string = null;
  @Input() fullScreen: false;

  constructor() { }

  ngOnInit() {
  }

}
