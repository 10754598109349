import { EventField, EventParams } from './event-params.model';
import { createAction, props } from '@ngrx/store';

// Load all the event fields
export const loadEventFields = createAction(
  '[EventParams/API] Load EventParams'
);

// Load all the event field success
export const loadEventFieldsSuccess = createAction(
  '[EventParams/API] Load EventParams Success',
  props<{eventParams: EventParams}>()
);

// Load all the event field error
export const loadEventFieldsError = createAction(
  '[EventParams/API] Load EventParams Error',
  props<{error: string}>()
);
