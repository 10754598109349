<div fxLayout="column" fxLayoutAlign="center center">
  <!--  regular screens-->
  <img src="../../../../../assets/logos/lumen_logo.svg" alt="CenturyLink Logo"
       style="height: 105px; margin-bottom: 25px" fxHide.lt-sm>
  <!--  mobile screens-->
  <img src="../../../../../assets/logos/lumen_logo_x1.png" alt="CenturyLink Logo"
       style="max-height: 105px; margin-bottom: 25px" fxHide.gt-xs>
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

