import { Incident } from './../../incidents/store/incident.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { UserPreference } from './user-preference.model';

export const loadUserPreferences = createAction(
  '[UserPreference/API] Load UserPreferences'
);

export const loadUserPreferencesSuccess = createAction(
  '[UserPreference/API] Load UserPreferences Success',
  props<{ userPreferences: UserPreference }>()
);

export const loadUserPreferencesError = createAction(
  '[UserPreference/API] Load UserPreferences Error',
  props<{ error: string }>()
);

// update user preferences action
export const updateUserPreferences = createAction(
  '[UserPreference/API] Update UserPreferences action',
  props<{ userPreferences: UserPreference, notify: boolean, incident?: Incident }>()
);

// dismiss user preferences action
export const dismissUserPreferences = createAction(
  '[UserPreference/API] Dismiss UserPreferences action',
  props<{ userPreferences: UserPreference, notify: boolean, incident?: Incident }>()
);

export const updateUserPreferencesError = createAction(
  '[UserPreference/API] Update UserPreferences Error',
  props<{ error: string }>()
);

// dismiss user preference error
export const dismissUserPreferencesError = createAction(
  '[UserPreference/API] Dismiss UserPreferences Error',
  props<{ error: string }>()
);

export const clearUserPreference = createAction(
  '[Company/API] Clear User Preference'
);
