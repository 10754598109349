import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {SnackbarComponent} from '../sweet-shared/components/snackbar/snackbar.component';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  open(msg: string, action: string = 'OK', config: object = {duration: 3000}) {
    this.snackBar.open(msg, action, config);
  }

  openCustom(config: MatSnackBarConfig = {duration: 300}) {
    this.snackBar.openFromComponent(SnackbarComponent, config);
  }

}
