import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecretService } from '@modules/secret/services/secret.service';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-secret-form',
  templateUrl: './secret-form.component.html',
  styleUrls: ['./secret-form.component.scss']
})
export class SecretFormComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  error = null;
  loading = false;
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private secretService: SecretService,
    private dialogRef: MatDialogRef<SecretFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, payload: any, edit: boolean }
  ) {
    this.form = this.fb.group({
      key_type: ['', [Validators.required]],
      key: ['', [Validators.required]],
      value: ['', [Validators.required]],
      customer_id: [{ value: '', disabled: true }, [Validators.required]]
    });
  }

  ngOnInit(): void {
    if (this.data.payload) {
      this.form.patchValue(this.data.payload);
      this.form.controls.customer_id.disable();
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  submit(): void {
    this.loading = true;
    this.error = null;
    this.form.disable();
    if (this.data.edit) {
      this.secretService.update(this.form.getRawValue())
        .pipe(
          takeUntil(this.destroyer$),
          finalize(() => this.loading = false)
        )
        .subscribe({
          next: (response) => this.dialogRef.close(response),
          error: (error) => {
            this.error = error.message;
            this.form.enable();
            this.form.controls.customer_id.disable();
          }
        })
    } else {
      this.secretService.create(this.form.getRawValue())
        .pipe(
          takeUntil(this.destroyer$),
          finalize(() => this.loading = false)
        )
        .subscribe({
          next: (_) => this.dialogRef.close(true),
          error: (error) => {
            this.error = error.message;
            this.form.enable();
            this.form.controls.customer_id.disable();
          }
        })
    }

  }

  ngOnDestroy(): void {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }

}
