import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '../../guards/auth-gaurd.service';
import {ReportingComponent} from './components/reporting/reporting.component';
import {PermissionsGuardService} from '../../guards/permissions-guard.service';
import {ReportHistoryComponent} from './components/report-history/report-history.component';


const routes: Routes = [
  {
    path: 'reporting',
    canActivate: [AuthGuardService, PermissionsGuardService],
    data: {
      requiredPermission: ['portal-access.enable-reporting-section'],
      any: false
    },
    children: [
      {
        path: '',
        component: ReportingComponent
      },
      {
        path: ':id',
        component: ReportHistoryComponent,
        canActivate: [PermissionsGuardService],
        data: {
          requiredPermission: ['report.view-history']
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportingRoutingModule { }
