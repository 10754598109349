import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-report-email-form',
  templateUrl: './report-email-form.component.html',
  styleUrls: ['./report-email-form.component.scss']
})
export class ReportEmailFormComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();

  @Input() data: any = null;
  @Output() onAction = new EventEmitter();

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  emails: string[] = [];
  email: UntypedFormControl = new UntypedFormControl('');

  // email form
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder
  ) {
    this.form = this.fb.group({
      enabled: [true, []],
      emails: this.fb.array([]),
      on_cancelled: [false, []],
      on_completion: [false, []],
      on_failure: [false, []]
    });
  }

  ngOnInit(): void {
    // add hooks to force 
    this.form.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(v => this.onAction.emit({ notifications: v }));

    if (this.data) {
      this.form.patchValue(this.data);
      this.emails = this.data?.emails ?? [];
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.emails.push(value);
      this.form.patchValue({ emails: this.emails });
    }
    // Clear the input value
    event.input.value = '';
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
      this.form.patchValue({ emails: this.emails });
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
