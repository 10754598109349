<section class='custom-date-picker'>
  <form [formGroup]='form'>
    <mat-form-field appearance="outline">
      <mat-label>Select an option</mat-label>
      <mat-select formControlName='dateRange'>
        <mat-option *ngFor='let option of dateRangeOptions' [value]="option.value">{{option.friendly}}</mat-option>
      </mat-select>
    </mat-form-field>

    <section *ngIf='showMayaDatePicker'>
      <app-maya-date-picker formControlName='date' [stacked]='true'></app-maya-date-picker>
    </section>
  </form>
</section>
