<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="layout-padding dashboard__detail--page" fxLayout="column" fxFlex>
  <!--  Remove After slm3 decomission-->
  <app-temp-banner></app-temp-banner>
  <br>
  <!-- -->
  <ng-container *ngIf="selectedDashboard$ | async as selDashboard">
    <ng-container *ngIf="selDashboard.widgets as widgets">
      <ng-container *ngTemplateOutlet="dashboardHeaderPane; context: { selected: selDashboard }"></ng-container>
      <ng-container *ngTemplateOutlet="dashboardControlInputsPane; context: { selected: selDashboard }"></ng-container>
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        dashboardLockPane;
        context: { widgets: selDashboard.widgets }
      "
    ></ng-container>
    <ng-container *ngIf="!!(inputVariableService.inputVariableReady | async)">
      <ng-container *ngTemplateOutlet="widgetsPane"></ng-container>
    </ng-container>
  </ng-container>
  <app-portal-loader [loadingStatus]="isUpdating"></app-portal-loader>
</div>

<!--
  Dashboard Templates
-->

<ng-template #dashboardLockPane let-widgets="widgets">
  <div class="lock-row" fxLayout="row" fxLayoutAlign="space-between end" *ngIf="widgets?.length">
    <div>
      <button *ngFor="let queryWidget of filterByType(widgets, queryWidgetType)" mat-stroked-button (click)="openQueryWidgetBuilder(queryWidget.params.variable_name)">
        Set {{ queryWidget.title }}
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <button mat-icon-button *ngIf="gridsterLocked | async; else gridsterUnLocked" (click)="unlockGridster()" matTooltip="Unlock to rearrange widgets" [matTooltipPosition]="'left'">
      <mat-icon>lock</mat-icon>
    </button>
    <ng-template #gridsterUnLocked>
      <button mat-icon-button (click)="lockGridster()" matTooltip="Lock to save widget positions" [matTooltipPosition]="'left'" matTooltipShowDelay="1000">
        <mat-icon>lock_open</mat-icon>
      </button>
    </ng-template>
  </div>
</ng-template>

<ng-template #dashboardHeaderPane let-selected="selected">
  <div
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-md="start stretch"
  >
    <button
      mat-stroked-button
      type="button"
      routerLink="/dashboard"
      class="dashboard_list--back"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
      Dashboard List
    </button>
    <h2 class="text-center">
      <strong>Dashboard Details - {{ selected.title }}</strong>
    </h2>
    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="space-between stretch">

      <button [disabled]="!(inputVariableService.inputVariableReady | async)" mat-raised-button color="primary" mat-button [matMenuTriggerFor]="dashboardMenu"><mat-icon>menu</mat-icon>Dashboard Actions</button>
      <mat-menu #dashboardMenu="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="widgetMenu"><mat-icon>widgets</mat-icon>New Widget(s)</button>
        <button
                *ifUserCan="'dashboard.create-custom'"
                mat-menu-item
                matTooltip="Make a New Copy of This Dashboard.  Only for this User."
                type="button"
                (click)="onDuplicate()"
        >
          <mat-icon>content_copy</mat-icon>
          Duplicate Dashboard
        </button>
        <button
                *ifUserCan="
              ['report.create-from-template', 'report.create-custom'];
              any: true
            "
                mat-menu-item
                matTooltip="Create a Report from this Dashboard."
                type="button"
                (click)="reportDialog()"
        >
          <mat-icon>report</mat-icon>
          Create Report
        </button>
        <button
                *ifUserCan="'report.read'"
                mat-menu-item
                matTooltip="Navigate to the Report Section to see Reports from this Dashboard."
                type="button"
                (click)="viewReport()"
        >
          <mat-icon>assignment</mat-icon>
          View Reports
        </button>
      </mat-menu>

      <mat-menu #widgetMenu="matMenu">
        <button
                *ifUserCan="['widget.create-from-template', 'widget.create-custom']; any: true"
                mat-menu-item
                [disabled]="!formReady"
                matTooltip="Create any Widget (Except Incident Table Widget)"
                (click)="addSettingWidget()"
        >
          <mat-icon>settings_suggest</mat-icon>
          All Widget(s) / Filter(s)
        </button>
        <button
                *ifUserCan="
              ['widget.create-from-template', 'widget.create-custom'];
              any: true"
                mat-menu-item
                [disabled]="!formReady"
                matTooltip="Create an Incident Table Widget"
                (click)="addIncidentTableWidget()">
          <mat-icon>backup_table</mat-icon>
          Incident / Alert Table Widget
        </button>
      </mat-menu>
    </div>
  </div>
</ng-template>

<ng-template #dashboardControlInputsPane let-selected="selected">
  <mat-accordion>
    <mat-expansion-panel expanded="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <app-portal-loader [loadingStatus]="!formReady || dashboardFilterLoading"></app-portal-loader>

      <mat-expansion-panel-header class="dashboard-filter-panel">
        <mat-panel-title>
          <div fxLayout="column">
            <p>Dashboard Widget Global Parameters</p>
            <p class="dashboard-filter-panel--description">
              {{ selected.description }}
            </p>
          </div>
        </mat-panel-title>
        <mat-divider></mat-divider>
      </mat-expansion-panel-header>
      <mat-card class="card_padding" *ifUserCan="'widget.execute'">
        <div class="setting-widget-container">
          <div *ngFor="let widget of filterByType(selected.widgets, settingWidgetTypes)">
            <app-setting-widget
              *ngIf="widget.type !== 'text_input'"
              [widget]="widget"
              [inputVariableService]="inputVariableService"
              (widgetAction)="widgetAction($event)"
            ></app-setting-widget>

            <app-text-input
              *ngIf="widget.type === 'text_input'"
              [widget]="widget"
              (onWidgetAction)="widgetAction($event)"
            ></app-text-input>
          </div>
        </div>

        <!-- DateRange and Company -->
        <div class="filter-container">
          <div class="filter">
            <app-datetime-picker
              [appearance]="outline"
              [defaultValue]="defaultSelectedDate"
              [options]="eventDateRange"
              (onDateSelected)="handleDateSelected($event)"
            ></app-datetime-picker>
          </div>
          <mat-form-field>
            <mat-label>Company</mat-label>
            <mat-select [formControl]="companyControl">
              <mat-option>
                <ngx-mat-select-search
                  #sText
                  [formControl]="searchFormControl"
                  [placeholderLabel]="'Search Company'"
                  noEntriesFoundLabel="No results found"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let option of companyOptions | inputSearch: sText.value"
                [value]="option.value"
              >
                {{ option.friendly }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="filter-btn">
          <button
            [disabled]="!(inputVariableService.inputVariableReady | async)"
            mat-raised-button
            color="primary"
            (click)="applyFilter()"
          >
            Apply
          </button>
        </div>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #widgetsPane>
  <div
    *ngIf="!loading || gridsterItems.length > 0"
    [ngStyle]="{ 'height.px': gridsterContainerHeight }"
    class="gridster_container"
  >
    <gridster
      [options]="gridsterOptions"
      fxLayout="row wrap"
      fxLayoutAlign="start start"
      #gridster
    >
      <gridster-item
        [item]="item.position"
        *ngFor="let item of gridsterItems; trackBy: widgetTrackBy"
        [ngSwitch]="item.widget.type"
      >
        <div *ngSwitchCase="'table'" style="overflow: scroll; height: 450px">
          <app-table-widget
            [widgetDetails]="item.widget"
            [inputVariableService]="inputVariableService"
            (widgetAction)="widgetAction($event)"
          ></app-table-widget>
        </div>

        <!-- Multi bar -->
        <div *ngSwitchCase="'multi_bar'">
          <app-widget-wrapper
            [widgetDetails]="item.widget"
            [height]="item.height"
            [widgetType]="'multi_bar'"
            (widgetAction)="widgetAction($event)"
          ></app-widget-wrapper>
        </div>

        <!-- Multi line -->
        <div *ngSwitchCase="'multi_line'">
          <app-widget-wrapper
            [widgetDetails]="item.widget"
            [height]="item.height"
            [widgetType]="'multi_line'"
            (widgetAction)="widgetAction($event)"
          ></app-widget-wrapper>
        </div>

        <!-- Map -->
        <div *ngSwitchCase="'map'">
          <app-widget-wrapper
            [widgetDetails]="item.widget"
            [height]="item.height"
            [widgetType]="'map'"
            (widgetAction)="widgetAction($event)"
          ></app-widget-wrapper>
        </div>

        <!-- Map -->
        <div *ngSwitchCase="'pie'">
          <app-widget-wrapper
            [widgetDetails]="item.widget"
            [height]="item.height"
            [widgetType]="'pie'"
            (widgetAction)="widgetAction($event)"
          ></app-widget-wrapper>
        </div>

        <!-- Single Metric -->
        <div *ngSwitchCase="'single_metric'">
          <app-widget-wrapper
            [widgetDetails]="item.widget"
            [height]="item.height"
            [widgetType]="'single_metric'"
            (widgetAction)="widgetAction($event)"
          ></app-widget-wrapper>
        </div>

        <!-- Solid Gauge -->
        <div *ngSwitchCase="'solid_gauge'">
          <app-widget-wrapper
            [widgetDetails]="item.widget"
            [height]="item.height"
            [widgetType]="'solid_gauge'"
            (widgetAction)="widgetAction($event)"
          ></app-widget-wrapper>
        </div>

        <!-- Fuel Gauge -->
        <div *ngSwitchCase="'fuel_gauge'">
          <app-widget-wrapper
            [widgetDetails]="item.widget"
            [height]="item.height"
            [widgetType]="'fuel_gauge'"
            (widgetAction)="widgetAction($event)"
          ></app-widget-wrapper>
        </div>

        <!-- This will remain for the table component. We will decide as a team how to deal with this. -->
        <div *ngSwitchDefault>
          <app-widget-header
            [widget]="item.widget"
            [height]="item.height"
            [parentDashboard]="selectedDashboard"
            (widgetAction)="widgetAction($event)"
            [filterAction]="filterAction"
            [newWidgetAction]="newWidgetAction"
            [eventDateRange]="eventDateRange"
          ></app-widget-header>
        </div>
      </gridster-item>
    </gridster>
  </div>
</ng-template>

<ng-template #emptyDashboardPane>
  <div
    *ngIf="!loading && gridsterItems.length === 0"
    fxLayout="row"
    class="dashboard_prompt-empty"
    fxLayoutAlign="center center"
  >
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title
          >This dashboard is empty. Please add a widget.</mat-card-title
        >
      </mat-card-header>
      <mat-card-content fxLayoutAlign="center center">
        <mat-card-actions>
          <button
            mat-stroked-button
            color="primary"
            (click)="addSettingWidget()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
