import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cell-actions',
  templateUrl: './cell-actions.component.html',
  styleUrls: ['./cell-actions.component.scss']
})
export class CellActionsComponent implements OnInit {
  @Input() item: any = null;
  @Output() actionHandler: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  createIncident(): void {
    this.actionHandler.emit({action: 'CREATE_INCIDENT', data: this.item});
  }

}
