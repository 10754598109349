<form [formGroup]="form">
  <app-sweet-loader *ngIf="loading" type="SPINNER"></app-sweet-loader>
  <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>
  <mat-form-field appearance="outline">
    <mat-label>List Name</mat-label>
    <mat-select formControlName="list_id">
      <mat-option *ngFor="let option of listOptions" [value]="option.value">{{
        option.label
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- output name -->
  <mat-form-field appearance="outline">
    <mat-label>Output Variable</mat-label>
    <input
      matInput
      placeholder="Output Variable"
      formControlName="output_variable"
    />
  </mat-form-field>
</form>
