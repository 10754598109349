import { UserPreference } from '../../../pages/user-preference/store/user-preference.model';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Incident, IncidentState } from '../../../pages/incidents/store/incident.model';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PermissionService } from '../../services/permission.service';
import { SelectOptionInterface } from '../form-builder/form-builder.component';
import { IncidentsService } from '../../../pages/incidents/services/incidents.service';
import { TableComponent } from '../table/table.component';
import { UrlService } from '../../../shared-services/url.service';
import { WorklogFormComponent } from '@modules/incident/worklog-form/worklog-form.component';
import { MatDialog } from '@angular/material/dialog';

export interface EventType {
  type: string;
  incident: Incident;
  data?: any;
}


@Component({
  selector: 'app-incident-display',
  templateUrl: './incident-display.component.html',
  styleUrls: ['./incident-display.component.scss']
})
export class IncidentDisplayComponent implements OnInit {
  // Because we need this component to respond to changes on the input of the incident
  // it received each time a change is made, we will be using a BehaviorSubject to accomplished it.
  incident: Incident = null;
  form: UntypedFormGroup;
  loading = false;
  @Input() showDetailsButton: boolean;
  @Input() userPreferences: UserPreference = null;
  @Input() incidentData: BehaviorSubject<Incident> = new BehaviorSubject<Incident>(null);
  @Input() loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() classificationOptions: any[] = [];
  @Input() assignmentGroupOptions: any[] = [];
  @Input() tierOptions: any[] = [];
  @Input() stateOptions: any[] = [];
  @Input() closureStateOptions: any[] = [];
  @Input() priorityOptions: any[] = [];
  @Input() companies: any[] = [];
  @Input() users: any[] = [];
  @Input() currentUser: any = null;

  // Updating signal
  @Input() updatingError: string = null;
  @Output() eventHandler: EventEmitter<EventType> = new EventEmitter();
  @Input() computeIncidentGrade: (incident: Incident, key?: number) => string;

  permissionsMap: { [key: string]: boolean };

  constructor(private fb: UntypedFormBuilder,
    private permissionService: PermissionService,
    private incidentsService: IncidentsService,
    private matDialog: MatDialog,
    public urlService: UrlService) {
  }

  ngOnInit() {
    // initialize permissions map for use in enabling/disabling controls
    this.permissionsMap = {
      category: this.permissionService.hasPermission('response.edit-inv-properties'),
      // no one has permission to change company of an incident
      company: false,
      u_unlisted_affected_user: this.permissionService.hasPermission('response.assign-other-inv'),
      u_queue: this.permissionService.hasPermission('response.edit-inv-properties'),
      incident_state: this.permissionService.hasPermission('response.edit-inv-state'),
      priority: this.permissionService.hasPermission('response.edit-inv-properties'),
      assignmentGroup: this.permissionService.hasPermission('response.edit-inv-properties')
    };
    this.incidentData.pipe(filter(inc => !!inc)).subscribe(val => {
      this.incident = val;
      this.form = this.generateForm(val);
      this.tierOptions = this.removeTiers(this.incident.u_queue);
    });
    this.loadingSubject.subscribe(loading => {
      this.loading = loading;

      if (loading) {
        // disable all form fields
        for (const control in this.form.controls) {
          this.form.controls[control].disable();
        }
      } else {
        for (const control in this.form.controls) {
          // enable form fields if incident is not closed and user has permission to edit field
          if (this.permissionsMap[control] && this.incident.state !== IncidentState.CLOSED) {
            setTimeout(() => this.form.controls[control].enable(), 300);
          } else {
            this.form.controls[control].disable();
          }
        }
      }
    });
  }


  doAction(type: string, incident: Incident, data: any) {
    if (!data) {
      data = this.form.value;
    }
    this.eventHandler.emit({ type, incident, data });
  }

  canDismiss(incident: Incident) {
    // userPreferences is not available immediately; will throw error without this check
    if (this.userPreferences) {
      return incident.sys_id === this.userPreferences.activeInvestigation;
    }
    return false;
  }

  handleDisabling(permission?: string | string[]): boolean {
    // If permission is not passed in, this is set to true
    let hasPermission = true;
    // otherwise...
    if (permission && !this.permissionService.hasPermission(permission)) {
      hasPermission = false;
    }
    //  Inputs and buttons should be disabled if page is
    //    still loading / user doesn't have permission / incident is closed
    return this.loading || !hasPermission || this.incident.state === IncidentState.CLOSED;
  }


  private generateForm(incident: Incident) {
    return this.fb.group({
      category: [
        {
          value: incident && incident.category ? incident.category : '',
          disabled: this.handleDisabling('response.edit-inv-properties'),
        }, []
      ],
      company: [
        {
          value: incident && incident.company ? incident.company : '',
          disabled: true,
        }, []
      ],
      u_unlisted_affected_user: [
        {
          value: incident && incident.u_unlisted_affected_user ? incident.u_unlisted_affected_user : '',
          disabled: this.handleDisabling('response.assign-other-inv'),
        }, []
      ],
      u_queue: [
        {
          value: incident && incident.u_queue ? incident.u_queue : '',
          disabled: this.handleDisabling('response.edit-inv-properties'),
        }, []],
      incident_state: [
        {
          value: incident && incident.state ? incident.state : '',
          disabled: this.handleDisabling('response.edit-inv-state'),
        }, []],
      priority: [
        {
          value: incident && incident.priority ? incident.priority : '',
          disabled: this.handleDisabling('response.edit-inv-properties'),
        }, []
      ]
    }
    );
  }

  removeTiers(queue: string): SelectOptionInterface[] {
    let tierOptions;
    if (queue === 'Tier 3') {
      tierOptions = this.tierOptions.filter(option => option.value === 'Tier 3');
    } else if (queue === 'Tier 2') {
      tierOptions = this.tierOptions.filter(option => option.value !== 'Tier 1');
    } else {
      tierOptions = this.tierOptions;
    }
    return tierOptions;
  }

  onAddWorklog() {
    this.matDialog.open(
      WorklogFormComponent, {
      data: { incident: this.incident },
      disableClose: true, minWidth: '70%'
    });
  }
}
