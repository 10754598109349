import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type ActionType = "INFO" | "REFRESH" | "EDIT_WIDGET" | "DELETED_WIDGET" | "FULL_SCREEN"
export enum ActionTypeValue {
  info = "INFO",
  refresh = "REFRESH",
  edit = "EDIT_WIDGET",
  delete = "DELETED_WIDGET",
  fullScreen = "FULL_SCREEN"
}
export interface IMenuOption {
  label: string;
  icon: string;
  action: ActionType;
  visible: boolean;
}

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})

export class MenuBarComponent {

  @Input() title: string = '';
  @Input() options: IMenuOption[] = [];
  @Output() menuEvent = new EventEmitter();

  getActiveMenus(): IMenuOption[] {
    return this.options.filter(menu => menu.visible)
  }
}
