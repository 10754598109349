import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/pages/admin/store/user/user.model';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/pages/auth/services/auth.service';
import { Subject } from 'rxjs';
import { Auth } from 'aws-amplify';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { EmailVerificationCodeDialogComponent } from '../email-verification-code-dialog/email-verification-code-dialog.component';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnDestroy {
  private destroyer$ = new Subject();
  user: User = null;

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private authService: AuthService
  ) {
    this.authService.getAuthenticatedUser().pipe(takeUntil(this.destroyer$)).subscribe(user => this.user = user);
  }

  getVerificationCode() {
    return this.dialog.open(EmailVerificationCodeDialogComponent, {
      minWidth: '300px',
      panelClass: 'no-padding-dialog',
      disableClose: true
    }).afterClosed();
  }

  submitVerificationCode(user) {
    this.getVerificationCode().subscribe({
      next: (res) => {
        Auth.verifyUserAttributeSubmit(user, 'email', res)
          .then(response => {
            // attribute updated
            setTimeout(() =>
              this.snackbar.open('email activation completed', response),
              5000);
            this.authService.logout();
          })
          .catch(error => {
            this.snackbar.open(error);
          });
      },
      error: (error) => {
        this.snackbar.open(error.message);
      }
    });
  }

  verifyEmail(): void {
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.verifyUserAttribute(user, 'email')
          .then(resp => this.submitVerificationCode(user))
          .catch(err => {
            this.snackbar.open(err);
          });
      })
      .catch(error => {
        this.snackbar.open(error);
      });
  }

  isAccountVerified(): boolean {
    if (this.user) {
      return this.user?.email_verified || this.user?.phone_number_verified;
    }
    return false;
  }

  ngOnDestroy() {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }
}
