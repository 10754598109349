<section class="widget-wrapper" [ngStyle]="{ height: height + 'px' }">
  <div class="menu-container">
    <app-menu-bar
      [title]="widgetDetails.title"
      [options]="menuItems"
      (menuEvent)="handleMenuEvent($event)"
    >
      <span dateRange>
        {{ dateRange?.from | date }} to {{ dateRange?.to | date }}
      </span>
    </app-menu-bar>
  </div>

  <section class="widget-container">
    <section #container></section>
  </section>
</section>
