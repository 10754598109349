import {Action, createReducer, on} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {UserPreference} from './user-preference.model';
import * as UserPreferenceActions from './user-preference.actions';

export const userPreferencesFeatureKey = 'userPreferences';

export interface State extends EntityState<UserPreference> {
  // additional entities state properties
  loading: boolean;
  errorLoading: string;
  userPreferences: UserPreference;
  updating: boolean;
  updateError: string;
}

export const adapter: EntityAdapter<UserPreference> = createEntityAdapter<UserPreference>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  errorLoading: null,
  updating: false,
  userPreferences: JSON.parse(localStorage.getItem('userPreferences') || '{}'),
  updateError: null
});

const userPreferenceReducer = createReducer(
  initialState,
  on(UserPreferenceActions.loadUserPreferences,
    (state, action) => {
      return {
        ...state,
        loading: true,
        errorLoading: null
      };
    }
  ),
  on(UserPreferenceActions.loadUserPreferencesSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        updating: false,
        userPreferences: action.userPreferences,
        errorLoading: null
      };
    }
  ),
  on(UserPreferenceActions.loadUserPreferencesError,
    (state, action) => {
      return {
        ...state,
        errorLoading: action.error,
        loading: false,
        updating: false
      };
    }
  ),
  on(UserPreferenceActions.updateUserPreferences,
    (state, action) => {
      return {
        ...state,
        updating: true,
        updateError: null
      };
    }
  ),
  on(UserPreferenceActions.dismissUserPreferences,
    (state, action) => {
      return {
        ...state,
        updating: true,
        updateError: null
      };
    }
  ),
  on(UserPreferenceActions.updateUserPreferencesError,
    (state, action) => {
      return {
        ...state,
        updating: false,
        updateError: action.error
      };
    }
  ),
  on(UserPreferenceActions.dismissUserPreferencesError,
    (state, action) => {
      return {
        ...state,
        updating: false,
        updateError: action.error
      };
    }
  ),

on(UserPreferenceActions.clearUserPreference,
  (state, action) => {
    return {
      ...state,
      userPreferences: null,
      updating: false,
      updateError: null
    };
  }

)
);

export function reducer(state: State | undefined, action: Action) {
  return userPreferenceReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
