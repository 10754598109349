import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-redirect-modal',
  templateUrl: './redirect-modal.component.html',
  styleUrls: ['./redirect-modal.component.scss']
})
export class RedirectModalComponent implements OnInit {

  constructor(private router: Router, public dialogRef: MatDialogRef<RedirectModalComponent>) { }

  @Input() title: string;
  @Input() message: string;
  @Input() path: string | string[];
  @Input() buttonText: string;

  ngOnInit(): void {
  }

  redirect() {
    this.router.navigate([this.path]).then(() => {
      this.dialogRef.close();
    });
  }

}
