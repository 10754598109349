<section class="company-details" *ngIf="!loading && !error; else loadingDiv">
  <!-- Back button and add company -->
  <div class="btn-container">
    <button
            mat-stroked-button
            type="button"
            routerLink="/admin/company"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
      Back to List of Companies
    </button>

    <!-- Add company button -->
    <button mat-stroked-button class="primary" (click)="editCompany()" *ifUserCan="'company.edit'">
      <mat-icon>edit</mat-icon>
      Company
    </button>
  </div>

  <!-- company attributes -->
  <section class="details" *ngIf="!loading && companyDetails">
    <div class="name">
      <p class="company-name">{{ companyDetails.name }}</p>
      <p class="company-id">
        {{ companyDetails.id }}
      </p>
    </div>
    <div class="container justify-content-md-center align-items-center vh-100">
      <div class="row">
        <div class="offset-md-3 col-md-6">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>Products & Attributes</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <!-- Access Attributes -->
              <div class="control-wrapper attribute">
                <h4>Access Attributes</h4>
                <div *ngFor="let companyDetail of companyDetails.attributes.ACCESS_ATTRIBUTES">
                  <p class="attribute-subtitle">{{companyDetail}}</p>
                </div>
              </div>

              <div fxLayout="row" fxLayoutGap="10">
                <div fxFlex="50%">
                  <ul class="ordered-list" *ngFor="let attribute of dataSource1">
                    <li>
                      <tr class="attribute-title">{{attribute.name}}</tr>
                        <tr *ngFor="let each of attribute.value">
                          <p class="attribute-subtitle">{{each.valueName}} : {{each.value}}</p>
                        </tr>
                    </li>
                  </ul>
                </div>
                <div fxFlex="50%">
                      <ul class="ordered-list" *ngFor="let attribute of dataSource2">
                        <li>
                          <tr class="attribute-title">{{attribute.name}}</tr>
                          <tr *ngFor="let each of attribute.value">
                            <p class="attribute-subtitle">{{each.valueName}} : {{each.value}}</p>
                          </tr>
                        </li>
                      </ul>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </section>

  <section class="networks" *ngIf="!loading && companyDetails">
    <div>
      <h3>IPv4 Networks</h3>
      <div
              class="product-list"
              *ngIf="companyDetails.sourceIPV4CIDRs.length; else notAvailable"
      >
        <span *ngFor="let item of companyDetails.sourceIPV4CIDRs">{{
          item
          }}</span>
      </div>
      <ng-template #notAvailable> - </ng-template>
    </div>
    <div>
      <h3>IPv4 Addresses</h3>
      <div
              class="product-list"
              *ngIf="companyDetails.sourceIPs.length > 0; else notAvailable"
      >
        <span *ngFor="let ip of companyDetails.sourceIPs">{{ ip }}</span>
      </div>
      <ng-template #notAvailable> - </ng-template>
    </div>
    <div>
      <h3>Hostnames</h3>
      <div
              class="product-list"
              *ngIf="companyDetails.sourceHosts.length > 0; else notAvailable"
      >
        <span *ngFor="let hostname of companyDetails.sourceHosts">{{
          hostname
          }}</span>
      </div>
      <ng-template #notAvailable> - </ng-template>
    </div>
  </section>
</section>
<ng-template #loadingDiv>
  <div class="error-container">
    <mat-spinner *ngIf="loading; else errorDiv"></mat-spinner>
    <ng-template #errorDiv>
      <mat-card appearance="outlined">
        <mat-card-content>
          <span class="error-icon">
            <mat-icon color="warn">error</mat-icon>
          </span>
          <p>
            Could not find details about company with ID
            <span>{{ companyId }}</span
            >.
          </p>
          <a type="button" mat-stroked-button routerLink="/admin/company">
            <mat-icon>chevron_left</mat-icon>
            Back To Company list Page
          </a>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </div>
</ng-template>
