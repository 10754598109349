<mat-form-field style="width: 100%;">
  <mat-label>{{field.templateOptions.label}}</mat-label>
  <mat-select multiple (selectionChange)="updateModel($event)" [formControl]="formControl" [formlyAttributes]="field">
    <mat-option
      *ngFor="let option of field.templateOptions.options"
      [value]="option.value"
    >{{option.label}}</mat-option>
  </mat-select>
</mat-form-field>

