import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

import sortBy from 'lodash/sortBy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import { AppService } from '@app/app.service';
import { InputVariableService } from '@sweet-shared/services/input-variable.service';
import { WidgetInfoComponent } from '@sweet-shared/components/widgets/widget-info/widget-info.component';
import { WidgetDrilldownService } from '@shared-services/widget-drilldown.service';
import { Router } from '@angular/router';
import { RedirectBoardService } from '@services/redirect-board.service';
import { REDIRECT_MESSAGE } from '@sweet-shared/sweet-widgets/multi-bar/multi-bar.component';
import { EnvironmentService } from '@app/environment/environment.service';


@Component({
  selector: 'app-multi-line',
  templateUrl: './multi-line.component.html',
  styleUrls: ['./multi-line.component.scss']
})
export class MultiLineComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  apiCall = null;

  private _height;
  loading = false;


  @Input() widgetDetails: any = null;
  @Input() set height(h) {
    this._height = h;
  }
  get height() {
    return this._height;
  }

  inputVariables: { [key: string]: any } = null;
  shouldFetch = false;
  errorMessage: string = null;

  // chart element
  chart: any = null;

  constructor(
    private inputVariablesService: InputVariableService,
    private widgetService: AppService<any>,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private widgetDrillDown: WidgetDrilldownService,
    private appService: AppService<any>,
    private router: Router,
    private redirectBoardService: RedirectBoardService,
    private environmentService: EnvironmentService
  ) { }

  ngOnInit(): void {
    // Set the input variables
    this.setWidgetInputVariables();
    this.setInputVariableSubscription();
  }

  private setInputVariableSubscription(): void {
    this.inputVariablesService.inputVariables.pipe(takeUntil(this.destroyer$)).subscribe(inputVariables => {
      Object.keys(inputVariables).forEach(name => {
        if (this.inputVariables.hasOwnProperty(name)) {
          if (this.inputVariables[name] === null || this.inputVariables[name] !== inputVariables[name]) {
            this.inputVariables[name] = inputVariables[name];
            this.shouldFetch = true;
          }
        }
      });

      if (this.shouldFetch) {
        if (!Object.values(this.inputVariables).includes(null)) {
          this.fetchWidgetData();
        } else {
          this.shouldFetch = false;
        }
      }
    });
  }

  private setWidgetInputVariables(): void {
    const widgetInputVariables = this.widgetDetails?.input_variables ?? [];
    this.inputVariables = widgetInputVariables.reduce((acc, next) => {
      acc[next.input_variable_name] = next.default_value;
      return acc;
    }, { company: null, dateFrom: null, dateTo: null });
  }

  private fetchWidgetData(): void {
    let response = null;
    this.errorMessage = null;
    this.loading = true;
    this.apiCall = this.widgetService.put(`widgets`, `${this.widgetDetails.dashboard_id}/${this.widgetDetails.widget_id}/get-data`, null, this.inputVariables, true);
    this.apiCall.then(res => response = res)
      .catch(err => this.errorMessage = err)
      .finally(() => {
        if (!this.errorMessage) {
          // check the response and see if we get a 200 or 200 and handle he 202 properly
          if (response.status === 200) {
            const dataUrl = response.data.url;
            this.getWidgetDataResult(dataUrl);
          } else if (response.status === 202) {
            // The query is still running in the backend, let reping it
            return this.fetchWidgetData();
          }
        }
        this.shouldFetch = false;
        this.loading = false;
        this.apiCall = null;
      }
      );
  }

  private getWidgetDataResult(url: string) {
    this.httpClient.get(url).pipe(takeUntil(this.destroyer$)).subscribe((res: any) => {
      const valueXAxis = this.widgetDetails.source === 'ingestion' ? 'isodate' : this.widgetDetails.params.athenaStatement.standard[0].select_statement[0].alias;
      if (!this.chart) {
        this.chart = am4core.create(this.widgetDetails.widget_id, am4charts.XYChart);

        this.chart.dateFormatter.utc = true;
        // since it is a brand new chart, let set the legend
        this.chart.legend = new am4charts.Legend();

        // the legend has its own container, let move it there
        const legendContainer = am4core.create(`${this.widgetDetails.widget_id}-legend`, am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);
        this.chart.legend.parent = legendContainer;

        // Build the value axis
        this.buildDateAxis();
        this.chart.yAxes.push(new am4charts.ValueAxis());
        this.chart.scrollbarX = new am4core.Scrollbar();
      }

      const snapTo = this.chart?.cursor?.snapToSeries || [];
      if (res.length > 0) {
        const row = res[0];
        Object.keys(row).forEach((key: string) => {
          // If there's already a series for it, skip
          if (this.chart.series.values.find(item => item.dataFields.valueY === key)) {
            return;
          }
          if (key !== valueXAxis) {
            snapTo.push(this.createLineSeries(key, key, valueXAxis, true));
          }
        });

      }
      if (this.chart.cursor) {
        this.chart.cursor.snapToSeries = snapTo;
      }
      this.chart.data = res = sortBy(res, [(o) => o[`${valueXAxis}`]]);
    });
  }

  buildDateAxis(): void {
    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.groupData = true;
    dateAxis.groupCount = 100;
    dateAxis.renderer.minLabelPosition = 0.05;
    dateAxis.renderer.maxLabelPosition = 0.95;
    return dateAxis;
  }

  private createLineSeries(field: string, name: string, xValueField: string, hiddenInLegend: boolean): void {

    const series = this.chart.series.push(new am4charts.LineSeries());
    series.tooltip.label.interactionsEnabled = true;
    series.dataFields.valueY = field;
    series.dataFields.dateX = xValueField;
    series.name = name;
    series.tooltipText = `{name}: [bold]{valueY}[/]\n${xValueField}: [bold]{dateX}`;
    series.strokeWidth = 2;
    series.minBulletDistance = 5;
    series.groupFields.valueY = 'sum';
    if (hiddenInLegend) {
      series.hiddenInLegend = false;
    }

    // Create Bullet for pinpointing data in line chart
    this.buildBullets(series);

    // Set the cursor
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.maxTooltipDistance = -1;
    return series;
  }

  private buildBullets(series: am4charts.Series): am4core.Circle {
    const bullet = series.bullets.push(new am4core.Circle());
    bullet.fill = new am4core.InterfaceColorSet().getFor('background');
    bullet.fillOpacity = 1;
    bullet.strokeWidth = 2;
    bullet.radius = 4;

    // Setup the 'click' function on each data point in the chart
    if (this.environmentService.widgetDrillDown) {
      bullet.events.on('hit', function (ev) {
        const dataPoint = ev.target.dataItem.dataContext;
        this.drillDown(ev, series, dataPoint);
      }, this);
      return bullet;
    }
  }

  refresh(): void {
    this.fetchWidgetData();
  }

  info(): void {
    this.dialog.open(WidgetInfoComponent, {
      width: '40vw',
      maxWidth: '95vw',
      data: this.widgetDetails
    });
  }

  drillDown(event, series, dataIn) {
    this.redirectBoardService.message$.next(REDIRECT_MESSAGE);
    this.redirectBoardService.redirect$.next(true);
    // Set vars for drill down
    const widgetId = this.widgetDetails.widget_id;
    const widgetInputs = this.widgetDetails.input_variables;
    const widgetCompany = this.widgetDrillDown.getCompany();
    const yName = series.dataFields.valueY;
    const xValue = new Date(dataIn[series.dataFields.dateX]).getTime();
    const dateFrom = this.inputVariables.dateFrom;
    const dateTo = this.inputVariables.dateTo;

    // Format input widgets
    const inputBody = {};

    // TODO this loop might need to be updated to correctly capture the inputs on dropdowns that use the % as the value instead of an array of values
    this.inputVariablesService.inputVariables$().subscribe(vars => {
      for (const row of widgetInputs) {
        // For some reason % is not in an array
        if (row.default_value === '%') {
          inputBody[row.input_variable_name] = row.default_value;
        } else {
          inputBody[row.input_variable_name] = vars[row?.input_variable_name];
        }
      }

    });

    // Build values of the bar clicked
    const inputClick = {};
    inputClick['value'] = yName;
    inputClick['timeslice'] = xValue;

    // Build body for API call
    const body = {
      input_variables: inputBody,
      click: inputClick
    };

    // Insert Date and Company Params into body
    body.input_variables['company'] = widgetCompany;
    body.input_variables['dateFrom'] = dateFrom;
    body.input_variables['dateTo'] = dateTo;

    // Call api to get the returned url route
    this.appService.put('widgetDrillDown', `${this.widgetDetails.dashboard_id}/${widgetId}/drilldown`, null, body)
      .then((res) => this.router.navigateByUrl('events/drilldown?flag=fetchData&' + res['drilldown'])).catch();
  }

  ngOnDestroy(): void {
    // destroy the chart
    this.chart?.dispose();
    if (this.apiCall) {
      this.widgetService.cancel(this.apiCall);
    }
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
