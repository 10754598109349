import { environment } from '../../../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormEvent } from '../../../profile/store/profile.model';
import { SsoOption } from './sso-option.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export const DEFAULT_REGION = 'us-east-1';
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  private destroyer$ = new Subject();
  loginForm: UntypedFormGroup;
  regions: any[] = environment.regions;
  ssoOptions: SsoOption[] = environment.ssoOptions;

  @Input() error: string = null;
  @Input() formLoading = false;
  @Output() actionHandler: EventEmitter<FormEvent> = new EventEmitter<FormEvent>();

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
  ) {

  }

  ngOnInit() {

    let region = localStorage.getItem('region') ?? DEFAULT_REGION;
    // check if a region is already set and use it as default value of the form

    this.loginForm = this.fb.group({
      region: [region, [Validators.required]],
      username: ['', [Validators.required, Validators.minLength(1)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.loginForm.controls['region'].valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(val => {
      console.log('value changed to', val);
      this.authService.configureAmplify(val);
    })
  }


  login() {
    this.actionHandler.emit({
      type: 'LOGIN',
      data: this.loginForm.value
    });
  }

  forgotPasswordDialog() {
    this.actionHandler.emit({
      type: 'FORGOT_PASSWORD',
      data: null
    });

  }

  sso(customProvider) {
    this.authService.ssoLogin(customProvider);
  }


}
