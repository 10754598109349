import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '../../guards/auth-gaurd.service';
import {PermissionsGuardService} from '../../guards/permissions-guard.service';
import {LogStreamComponent} from './log-stream/log-stream.component';
import {LogStreamDetailComponent} from './log-stream-detail/log-stream-detail.component';
import {LogStreamCreateComponent} from "./log-stream-create/log-stream-create.component";

const routes: Routes = [
  {
    path: 'log-stream',
    canActivate: [AuthGuardService],
    data: {
      requiredPermission: ['portal-access.enable-admin-logstream-tab'],
      any: false
    },
    children: [
      {
        path: '',
        canActivate: [PermissionsGuardService],
        component: LogStreamComponent,
      },
      {
        path: ':customerId/:id',
        component: LogStreamCreateComponent,
        canActivate: [PermissionsGuardService],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LogStreamRoutingModule { }
