<section class="add-worklog">
  <h2>Add Worklog</h2>
  <form [formGroup]="form" (submit)="addWorklog()">
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input formControlName="title" matInput />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Message</mat-label>
      <textarea matInput formControlName="message"></textarea>
    </mat-form-field>

    <section class="inline-flex space-between align-center">
      <mat-checkbox formControlName="include"
        >Include search results</mat-checkbox
      >
      <mat-form-field appearance="outline">
        <mat-label>Number of Results</mat-label>
        <input
          matInput
          type="number"
          formControlName="results"
          [max]="eventResults.length"
          min="0"
        />
      </mat-form-field>
    </section>

    <section class="inline-flex flex-align-end">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="form.invalid || loading"
      >
        Create
      </button>
      <button mat-stroked-button color="warn" (click)="dismissDialog()">
        Cancel
      </button>
    </section>
  </form>
</section>
