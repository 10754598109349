<div class="layout-padding">
  <app-portal-loader [loadingStatus]="loading || updating"></app-portal-loader>
  <div class="incident-details" *ngIf="!loading">
    <button
      mat-stroked-button
      type="button"
      routerLink="/incidents"
      class="incident_list--back"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
      Back to Incidents
    </button>
    <ng-container *ngIf="incident">
      <app-incident-display
        *ngIf="loaded"
        [incidentData]="incidentSubject"
        [classificationOptions]="classificationOptions$ | async"
        [tierOptions]="tierOptions$ | async"
        [stateOptions]="stateOptions$ | async"
        [priorityOptions]="priorityOptions$ | async"
        [closureStateOptions]="closureStateOptions$ | async"
        [companies]="companyOptions$ | async"
        [userPreferences]="userPreferences"
        [users]="userOptions$ | async"
        [loadingSubject]="loadingSubject"
        [currentUser]="currentUser"
        [updatingError]="updatingError"
        [computeIncidentGrade]="computeIncidentGrade.bind(this)"
        (eventHandler)="incidentActionHandler($event)"
        [showDetailsButton]="showIncidentDisplayDetailsButton"
      ></app-incident-display>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="history-section--row"
      >
        <mat-icon>history</mat-icon>
        <h2 class="history-section--title">History</h2>
      </div>
      <app-portal-loader [loadingStatus]="loadingWorklogs"></app-portal-loader>
      <mat-accordion multi="true">
        <div class="history-section--accordion" #worklogs></div>
        <mat-expansion-panel
          *ngFor="let worklog of incident.worklogs"
          [expanded]="true"
        >
          <mat-expansion-panel-header>
            <mat-panel-title fxFlex="35"
              >{{ worklog.createdAt || worklog.sys_created_on | userDate }}
            </mat-panel-title>
            <mat-panel-description fxFlex="65">{{
              formatWorklogValue(worklog.username || worklog.value)
            }}</mat-panel-description>
          </mat-expansion-panel-header>
          <ng-container [ngSwitch]="worklog.type">
            <div *ngSwitchCase="'threat_profile'" class="layout-padding">
              <app-threat-profile-worklog
                [worklog]="worklog"
              ></app-threat-profile-worklog>
            </div>
            <div *ngSwitchCase="'threat_history'" class="layout-padding">
              <app-threat-history-worklog
                [worklog]="worklog"
              ></app-threat-history-worklog>
            </div>
            <div *ngSwitchCase="'standard'" class="layout-padding">
              <h3 class="mat-headline-6">{{ worklog.title }}</h3>
              <div fxFill markdown [data]="worklog.message"></div>
            </div>
            <div *ngSwitchDefault>
              <div fxFill markdown [data]="worklog.value"></div>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
      <app-bottom-page-border
        *ngIf="incident?.worklogs"
      ></app-bottom-page-border>
    </ng-container>
  </div>
</div>
