import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetsRoutingModule } from './widgets-routing.module';
import { TextInputComponent } from './components/text-input/text-input.component';
import { TextInputResultFormComponent } from './components/text-input/text-input-result-form/text-input-result-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { QueryWidgetComponent } from './components/query-widget/query-widget.component';
import { QueryBuilderModule } from 'angular2-query-builder';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ListWidgetResultFormComponent } from './components/list-widget-result-form/list-widget-result-form.component';
import { MatSelectModule } from '@angular/material/select';
import { IngestionMultiLineComponent } from './components/ingestion-multi-line/ingestion-multi-line.component';
import { IngestionMultiLineFormComponent } from './components/ingestion-multi-line/ingestion-multi-line-form/ingestion-multi-line-form.component';


@NgModule({
  declarations: [
    TextInputComponent,
    QueryWidgetComponent,
    TextInputResultFormComponent,
    ListWidgetResultFormComponent,
    IngestionMultiLineComponent,
    IngestionMultiLineFormComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
    MatFormFieldModule,
    QueryBuilderModule,
    ReactiveFormsModule,
    WidgetsRoutingModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    TextInputResultFormComponent,
    TextInputComponent,
    ListWidgetResultFormComponent,
    IngestionMultiLineFormComponent,
    TextInputComponent
  ]
})
export class WidgetsModule { }
