<div class="card-title">
  <span><h2>Dashboards Overview</h2></span>
</div>
  <div>
    <div
      fxLayout.gt-sm="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="end end"
      fxLayoutAlign.lt-md="space-between stretch"
    >
      <ng-container *ifUserCan="'dashboard.delete'">
        <button
          mat-stroked-button
          (click)="onMultiDeleteDashboard()"
          [disabled]="!checkedDashboards.length"
          class="dashboard-header--button"
        >
          <mat-icon>delete</mat-icon>
          Delete Dashboard(s)
        </button>
      </ng-container>
      <ng-container
        *ifUserCan="
          ['dashboard.create-custom', 'dashboard.create-from-template'];
          any: true
        "
      >
        <button
          color="primary"
          mat-stroked-button
          class="dashboard-header--button"
          (click)="createDashboardDialog()"
        >
          <mat-icon>add_box</mat-icon>
          Create New Dashboard
        </button>
      </ng-container>
    </div>
    <div class="card-style">
    <mat-card appearance="outlined">
      <app-portal-loader [loadingStatus]="loading"></app-portal-loader>
      <div class="layout-padding">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-title>List of Dashboards</mat-card-title>
          <div (click)="onHelpClick()" aria-label="Dashboard Help">
            <button mat-icon-button>
              <mat-icon>help</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <app-table
        [data]="dashboards"
        [headerStream]="dashboardHeader"
        [checkbox]="checkboxOptions"
        (events)="onRowClick($event)"
        [actionsTemplate]="dashboardActionsTemplate"
        [pageSize]="25"
        #dashboardTable
      >
        <ng-template #dashboardActionsTemplate let-element>
          <div>
            <button
              *ifUserCan="dashboardTableActions.editPermission"
              (click)="dashboardTable.emitClickEvent('EDIT', element)"
              mat-menu-item
            >
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button
              *ifUserCan="dashboardTableActions.deletePermission"
              (click)="dashboardTable.emitClickEvent('DELETE', element)"
              mat-menu-item
            >
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <ng-container *appNotProdReady="true">
              <button
                *ifUserCan="dashboardTableActions.createReport; any: true"
                (click)="
                  dashboardTable.emitClickEvent('CREATE_REPORT', element)
                "
                mat-menu-item
              >
                <mat-icon>report</mat-icon>
                <span>Create Report</span>
              </button>
            </ng-container>
            <ng-container *appNotProdReady="true">
              <button
                *ifUserCan="dashboardTableActions.viewReport"
                (click)="dashboardTable.emitClickEvent('VIEW_REPORT', element)"
                mat-menu-item
              >
                <mat-icon>assignment</mat-icon>
                <span>View Reports</span>
              </button>
            </ng-container>
            <ng-container *appNotProdReady="true">
              <button
                *ifUserCan="dashboardTableActions.createCustomPermission"
                (click)="onDuplicate(element)"
                mat-menu-item
              >
                <mat-icon>content_copy</mat-icon>
                <span>Duplicate</span>
              </button>
            </ng-container>
          </div>
        </ng-template>
      </app-table>
    </mat-card>
    </div>
  </div>

<app-ingestion-multi-line-form></app-ingestion-multi-line-form>
