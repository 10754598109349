<section class="account-preferences">
  <form [formGroup]="form" (submit)="updateUserPreference()">
    <!-- Search columns -->
    <mat-form-field>
      <mat-label>Default Search Columns</mat-label>
      <mat-select formControlName="searchFields" multiple>
        <mat-option
          *ngFor="let option of fieldOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- Search columns -->
    <mat-form-field>
      <mat-label>Default Date Format</mat-label>
      <mat-select formControlName="dateFormat">
        <mat-option *ngFor="let option of dateFormatOptions" [value]="option">{{
          newDate | date : option
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Search Refresh interval -->
    <mat-form-field>
      <mat-label>Default Search Refresh Interval</mat-label>
      <mat-select formControlName="searchRefreshInterval">
        <mat-option
          *ngFor="let option of refreshIntervalOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- Search Refresh Range -->
    <mat-form-field>
      <mat-label>Default Search Range</mat-label>
      <mat-select
        formControlName="dateRange"
        [compareWith]="dateRangeComparison"
      >
        <mat-option
          *ngFor="let option of dateRangeOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button
      mat-stroked-button
      color="primary"
      type="submit"
      [disabled]="form.pristine || loading"
    >
      <mat-icon>save</mat-icon>
      Update
    </button>
  </form>
</section>
