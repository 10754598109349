import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';

import {Auth} from 'aws-amplify';
import {forkJoin, from, Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

import {Attribute, CompanyDetails, Product} from '@modules/companies/interfaces/companies';
import {CompaniesService, IndexedNode, ProductNode} from '@modules/companies/services/companies.service';
import {COMPANY_ACCESS_ATTRIBUTES} from '@modules/companies/constants/companies-constants';


@Component({
    selector: 'app-add-company',
    templateUrl: './add-company.component.html',
    styleUrls: ['./add-company.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddCompanyComponent implements OnInit, OnDestroy {
    private destroyer$ = new Subject();
    readonly accessAttributes: string[] = [...COMPANY_ACCESS_ATTRIBUTES];
    numSteps = 3;
    loading = false;
    error: string = null;
    @ViewChild(MatStepper) stepper;
    selectedIndex = 0;

    sourceIPV4CIDRs: string[] = [];
    sourceIPs: string[] = [];
    sourceHosts: string[] = [];
    checkedMap = {};
    products = [];
    productForm: UntypedFormGroup = this.fb.group({});
    configForm: UntypedFormGroup;
    generalForm: UntypedFormGroup;
    attributes: { [key: string]: Attribute } = {};
    dataSource: MatTreeNestedDataSource<IndexedNode> = new MatTreeNestedDataSource();
    productTree: ProductNode[] = this.data.companyDetails?.product_tree?.map(p => ({...p, expanded: true}));

    constructor(
        private companiesService: CompaniesService,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<AddCompanyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any // should be typed.
    ) {

    }

    ngOnInit(): void {
        // IPV4 config form
        this.configForm = this.fb.group({
            sourceIPV4CIDRs: ['', [Validators.pattern('^([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})\/([0-9]|1[0-9]|2[0-9]|3[0-2])$')]],
            sourceIPs: ['', [Validators.pattern('^([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})$')]],
            sourceHosts: ['', [Validators.pattern('^[a-zA-Z0-9\.\-]+$')]]
        });

        // general information form
        this.generalForm = this.fb.group({
            name: ['', [Validators.required]],
            id: ['', [Validators.required]],
            parent: [{value: '', disabled: true}, [Validators.required]],
            accessAttributes: [[], []],
        });

        forkJoin({
            products: this.companiesService.fetchProducts(),
            attributes: this.companiesService.fetchCompanyAttributes(),
            user: from(Auth.currentAuthenticatedUser()),
        })
            .pipe(takeUntil(this.destroyer$))
            .subscribe({
                next: (resp) => {
                    const {products, attributes, user} = resp;
                    this.products = products;
                    this.attributes = attributes;
                    this.patchForms(this.data.companyDetails, user);
                },
                error: (error) => {
                    this.error = error.message;
                }
            });
    }

    patchForms(details: CompanyDetails, user): void {
        if (details) {
            this.sourceHosts = details.sourceHosts;
            this.sourceIPV4CIDRs = details.sourceIPV4CIDRs;
            this.sourceIPs = details.sourceIPs;

            this.generalForm.patchValue({
                name: details.name,
                id: details.id,
                parent: details.parent === 'centurylink' ? 'centurylink' : details.parent, // TODO - Backend should be sending both
                accessAttributes: details.attributes.ACCESS_ATTRIBUTES
            });

            this.generalForm.controls.parent.disable();
            if (this.data.newCompany === false) {
                this.generalForm.controls.id.disable();
            }
        } else {
            // Cannot change the parent company
            // TODO - Make it changeable when user is from Lumen
            this.generalForm.patchValue({parent: user.attributes.profile});
            this.generalForm.controls.parent.disable();
        }

    }

    deselectNodeTree(node: ProductNode): ProductNode {
        const rslt = {...node, selected: false};

        if (!!!node.children) {
            return rslt;
        }

        return {
            ...rslt,
            children: rslt.children.map((c: ProductNode) => this.deselectNodeTree(c))
        };
    }

    onCheck(node: Partial<Product & { children: Product[] }>, checked: boolean): void {
        // If a child product is checked, set to true in map
        if (checked) {
            this.checkedMap[node.name] = true;
            // enable attribute input if checkbox checked
            if (this.productForm.controls[`${node.name}_input`]) {
                this.productForm.controls[`${node.name}_input`].enable();
            }
            // exit recursion
            if (!node.parent || !node.parent.length) {
                return;
            }
            const parentNode = this.products.find(product => product.name === node.parent[0]);
            // repeat for parent
            return this.onCheck(parentNode, true);
        } else {
            // If a parent is unchecked, set to false in map
            this.checkedMap[node.name] = false;
            // disable attribute input if checkbox unchecked
            if (this.productForm.controls[`${node.name}_input`]) {
                this.productForm.controls[`${node.name}_input`].disable();
            }
            // exit recursion
            if (!node.children || !node.children.length) {
                return;
            }
            // repeat for children
            node.children.forEach(child => {
                return this.onCheck(child, false);
            });
        }
    }

    close(): void {
        this.dialogRef.close(null);
    }

    addCidr(): void {
        const control = this.configForm.controls.sourceIPV4CIDRs;
        this.sourceIPV4CIDRs.push(control.value);
        control.setValue('');
    }

    cidrDisabled(): boolean {
        return this.configForm.controls.sourceIPV4CIDRs.invalid || this.configForm.controls.sourceIPV4CIDRs.value.trim().length === 0;
    }

    removeCidr(ip: string): void {
        this.sourceIPV4CIDRs = this.sourceIPV4CIDRs.filter(sourceIP => ip !== sourceIP);
    }

    addIp(): void {
        const control = this.configForm.controls.sourceIPs;
        this.sourceIPs.push(control.value);
        control.setValue('');
    }

    removeIp(sourceIP: string): void {
        this.sourceIPs = this.sourceIPs.filter(ip => ip !== sourceIP);
    }

    disabledIPv4(): boolean {
        return this.configForm.controls.sourceIPs.invalid || this.configForm.controls.sourceIPs.value.trim().length === 0;
    }

    addHostname(): void {
        const control = this.configForm.controls.sourceHosts;
        this.sourceHosts.push(control.value);
        control.setValue('');
    }

    removeHostname(hostname: string): void {
        this.sourceHosts = this.sourceIPs.filter(item => item !== hostname);
    }

    disabledSourceHosts(): boolean {
        return this.configForm.controls.sourceHosts.invalid || this.configForm.controls.sourceHosts.value.trim().length === 0;
    }

    submit(): void {
        this.loading = true;
        const generalFormData = this.generalForm.getRawValue();
        // TODO - Type this payload properly
        let payload: any = {
            sourceIPV4CIDRs: this.sourceIPV4CIDRs,
            sourceIPs: this.sourceIPs,
            sourceHosts: this.sourceHosts,
            product_tree: this.productTree
        };

        if (this.data.newCompany === true) {
            // new company
            payload = {
                ...payload,
                attributes: {ACCESS_ATTRIBUTES: generalFormData.accessAttributes},
                name: generalFormData.name,
                id: generalFormData.id,
                parent: generalFormData.parent
            };

            // perform the creation here.
            this.companiesService.create(payload).pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false)).subscribe({
                next: (data: CompanyDetails) => {
                    this.dialogRef.close(data);
                },
                error: () => {
                    this.error = 'Could not create company';
                }
            });
        } else {
            payload = {
                ...this.data.companyDetails,
                ...payload,
                attributes: {ACCESS_ATTRIBUTES: generalFormData.accessAttributes},
                name: generalFormData.name,
                parent: generalFormData.parent
            };

            // perform the update here.
            this.companiesService.updateCompany(payload.id, payload).pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false)).subscribe({
                next: (data: CompanyDetails) => {
                    this.dialogRef.close(data);
                },
                error: () => {
                    this.error = 'Could not update company';
                }
            });
        }
    }

    next(): void {
        this.stepper.next();
    }

    selectionChange(evt: StepperSelectionEvent): void {
        this.selectedIndex = evt.selectedIndex;
    }

    ngOnDestroy(): void {
        this.destroyer$.next(false);
        this.destroyer$.complete();
    }
}
