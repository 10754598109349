import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import {FieldInterface, FormBuilderComponent} from '../../../form-builder/form-builder.component';

@Component({
  selector: 'app-pie-create-form',
  templateUrl: './pie-create-form.component.html',
  styleUrls: ['./pie-create-form.component.scss']
})
export class PieCreateFormComponent implements OnInit {

  public pieWidgetFields: FieldInterface[] = [
    {
      name: 'source',
      type: 'text',
      component: 'input-select',
      placeholder: 'Widget Source',
      label: 'Widget Source',
      flex: '100%',
      required: true,
      defaultValue: 'log',
      validators: [Validators.required],
      options: [
        {value: 'log', friendly: 'Log'},
      ],
      signalOnChanged: true
    },
    {
      name: 'fieldType',
      type: 'text',
      component: 'input-select',
      placeholder: 'Fields',
      label: 'Fields',
      required: true,
      flex: '100%',
      defaultValue: null,
      showSearch: true,
      validators: [Validators.required],
      options: [],
      signalOnChanged: true,
    },
    {
      name: 'alias',
      type: 'text',
      component: 'input-text',
      placeholder: 'Alias',
      label: 'Alias',
      flex: '150%',
      defaultValue: '',
      validators: [],
      signalOnChanged: true,
    },
    {
      name: 'yAxisAction',  // fieldTypeAction
      type: 'text',
      component: 'input-select',
      placeholder: 'Field Type Action',
      label: 'Field Type Action',
      flex: '100%',
      defaultValue: null,
      required: true,
      validators: [Validators.required],
      options: [],
      signalOnChanged: true,
      disabled: true
    },
    {
      name: 'ordering',
      type: 'text',
      component: 'input-select',
      placeholder: 'Ordering',
      label: 'Ordering',
      flex: '100%',
      defaultValue: 'desc',
      validators: [Validators.required],
      options: [
        {value: 'desc', friendly: 'DESC'},
        {value: 'asc', friendly: 'ASC'}
      ],
      signalOnChanged: true,
    },
    {
      name: 'sliceCount',
      type: 'text',
      component: 'input-select',
      placeholder: 'Slice Count',
      label: 'Slice Count',
      flex: '100%',
      defaultValue: '10',
      validators: [Validators.required],
      options: [
        {value: 'all', friendly: 'All'}
      ],
      signalOnChanged: true,
    },

  ];

  fieldTypeAction: any;
  @Input() categoryField: any;
  @Input() actions: any;
  @Output() pieFormEvents: EventEmitter<any> = new EventEmitter();
  @Output() actionEvents: EventEmitter<any> = new EventEmitter();
  numbers: any[] = [
    {value: 'all', friendly: 'All'}
  ];
  @ViewChild('createPieForm') createPieForm: FormBuilderComponent;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    Array(50).fill(0).map((x, i) => {
      this.numbers.push({value: `${i + 1}`, friendly: i + 1});
    });
    this.pieWidgetFields.map(f => {
      if (f.name === 'sliceCount') {
        f.options = this.numbers;
      }
    });

    this.pieWidgetFields.map(f => {
      if (f.name === 'fieldType') {
        f.options = this.categoryField;
      }
      return f;
    });
  }

  // this function is used to obtain the yaxis action based on a type
  private getyAxisAction(typ, list) {
    return list.reduce((acc, cur) => {
      if (cur.type.indexOf(typ) > -1) {
        acc.push({value: cur.value, friendly: cur.friendly});
      }
      return acc;
    }, []);
  }

  selectAxis(event) {
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'fieldType' && event.data.value) {
      this.fieldTypeAction = this.getyAxisAction(event.data.value.type, this.actions);
      this.pieWidgetFields.map(f => {
        if (f.name === 'yAxisAction') {
          f.options = this.fieldTypeAction;
        }
      });

    }
  }

  enableYaxisField(event) {
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'fieldType') {
      const yAixsField = this.createPieForm.form.get('yAxisAction');
      if (!event.data.value) {
        setTimeout(() => yAixsField.disable(), 200);
      } else {
        setTimeout(() => yAixsField.enable(), 200);
      }
    }
  }

  actionHandler(event) {
    this.selectAxis(event);
    this.enableYaxisField(event);
    this.actionEvents.emit(event);
  }
}
