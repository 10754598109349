import {Injectable} from '@angular/core';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {AppService} from '../../../app.service';
import {Profile} from './profile.model';
import * as profileActions from './profile.actions';
import {switchMap} from 'rxjs/operators';
import {AmplifyService} from 'aws-amplify-angular';
import {query} from '@angular/animations';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: AppService<Profile>,
    private amplifyService: AmplifyService
  ) {
  }

  @Effect({dispatch: true})
  loadProfile$ = this.actions$.pipe(
    ofType(profileActions.loadProfile),
    switchMap((action) => {
      return this.profileService.get('profiles', action.userSub, null)
        .then((profile: Profile) => profileActions.loadProfileSuccess({profile}))
        .catch((error) => profileActions.loadProfileError({error: error.response.data.message}));
    })
  );
  @Effect({dispatch: true})
  loadCurrentUserCompany$ = this.actions$.pipe(
    ofType(profileActions.loadCurrentUserCompany),
    switchMap((action) => {
      return this.profileService.get('company', action.currentUserCompanyId, null)
        .then((currentUserCompany: any) => {
          return profileActions.loadCurrentUserCompanySuccess({currentUserCompany});
        })
        .catch((error) => profileActions.loadCurrentUserCompanyError({error: error.response.data.message}));
    })
  );
}
