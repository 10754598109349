import {Router} from '@angular/router';
import {UntypedFormControl} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

import {Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

import {CompaniesService} from '@modules/companies/services/companies.service';
import {Company, CompanyTableHeader} from '@modules/companies/interfaces/companies';
import {AddCompanyComponent} from '@modules/companies/components/add-company/add-company.component';

import {COMPANY_TABLE_HEADERS} from '@modules/companies/constants/companies-constants';

@Component({
    selector: 'app-company-list',
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
    private destroyer$ = new Subject();

    loading = false;
    errorMessage: string = null;
    filterCtrl: UntypedFormControl = new UntypedFormControl('');
    companiesDataSource: MatTableDataSource<Company> = new MatTableDataSource([]);
    displayedColumns: string[] = [];
    tableColumns: CompanyTableHeader[] = COMPANY_TABLE_HEADERS;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private companiesService: CompaniesService,
        private dialog: MatDialog,
        private router: Router
    ) {
        // set the columns to display
        this.displayedColumns = this.tableColumns.map((column: CompanyTableHeader) => column.key);
    }

    ngOnInit(): void {
        // fetching companies
        this.fetchCompanies();

        // apply the filter
        this.filterCtrl.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(value => {
            this.companiesDataSource.filter = value?.trim().toLowerCase() ?? '';

            if (this.companiesDataSource.paginator) {
                this.companiesDataSource.paginator.firstPage();
            }
        });
    }

    fetchCompanies(): void {
        this.loading = true;
        this.errorMessage = null;
        this.companiesService.fetchCompanies()
            .pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false))
            .subscribe({
                next: (companies: Company[]) => {
                    this.companiesDataSource.data = companies;
                    setTimeout(() => {
                        this.companiesDataSource.paginator = this.paginator;
                        this.companiesDataSource.sort = this.sort;
                    });
                },
                error: (error) => {
                    console.log(error);
                    this.errorMessage = error.message;
                }
            });
    }

    clearFilter(): void {
        this.filterCtrl.reset();
    }

    addCompany(): void {
        // TODO May need to look into getting or finding the empty template from the backend, this will break if the backend does an update.
        const emptyCompany = {
            sourceIPV4CIDRs: [],
            customerWriteList: [],
            customerReadList: [],
            groupWriteList: [],
            employees: '0',
            industry: null,
            createdAt: '',
            attributes: {
                BUSORG: '',
                Edge_Protect_Data_Retention_Override: null,
                AWS_ACCOUNTS: null,
                Number: '',
                MEDR_Carbon_Black_Data_Retention_Override: 10,
                SNOW_ID: null,
                GB_Per_Day: 10,
                MEDR_Carbon_Black_BAN: '',
                ACCESS_ATTRIBUTES: [],
                MEDR_Carbon_Black_ORG_KEY: '',
                Logstream_Limit: 6
            },
            name: '',
            groupReadList: [
                '*'
            ],
            sourceHosts: [],
            products: [],
            updatedAt: '',
            parent: 'centurylink',
            id: '',
            sourceIPs: [],
            product_tree: [
                {
                    attributes: [
                        {
                            description: 'Data retention override value for Adaptive Network Security in days',
                            friendly: 'Adaptive Network Security Data Retention Override',
                            name: 'ANS_Data_Retention_Override',
                            type: 'Int',
                            value: null
                        }
                    ],
                    children: [
                        {
                            attributes: [],
                            children: [
                                {
                                    attributes: [],
                                    children: [],
                                    description: 'The ANS Rapid Threat Defense upgrade',
                                    friendly: 'Rapid Threat Defense',
                                    name: 'TID_Premium',
                                    selected: false
                                }
                            ],
                            description: 'The ANS Threat Visualization upgrade',
                            friendly: 'Threat Visualization',
                            name: 'TID_Standard',
                            selected: false
                        }
                    ],
                    default_data_retention: null,
                    description: 'The Adaptive Network Security product',
                    friendly: 'Adaptive Network Security (ANS)',
                    name: 'ANS',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The list of AWS Account Numbers',
                            friendly: null,
                            name: 'AWS_ACCOUNTS',
                            type: 'String',
                            value: null
                        }
                    ],
                    children: [],
                    description: 'AWS Account Numbers',
                    friendly: 'AWS Account Numbers',
                    name: 'AWS_ACCOUNTS',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The business organization associated with the company',
                            friendly: null,
                            name: 'BUSORG',
                            type: 'String',
                            value: null
                        }
                    ],
                    children: [],
                    description: 'The business organization associated with the company',
                    friendly: 'Business Organization (BUSORG)',
                    name: 'BUSORG',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The CenturyLink Billing Number.',
                            friendly: null,
                            name: 'Number',
                            type: 'String',
                            value: null
                        }
                    ],
                    children: [],
                    description: 'CenturyLink Billing Number',
                    friendly: 'CenturyLink Billing Number',
                    name: 'Billing_Number',
                    selected: false
                },
                {
                    attributes: [],
                    children: [
                        {
                            attributes: [
                                {
                                    description: 'The Managed Object configuration parameter for Arbor DDOS.',
                                    friendly: 'Managed Object',
                                    name: 'MANAGED_OBJECT',
                                    type: 'String',
                                    value: null
                                },
                                {
                                    description: 'Data retention override value for Arbor DDOS in days',
                                    friendly: 'Arbor DDOS Data Retention Override',
                                    name: 'DDOS_Arbor_Data_Retention_Override',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            default_data_retention: 365,
                            description: 'The Arbor DDOS product',
                            friendly: 'Arbor DDOS',
                            name: 'DDOS_Arbor',
                            selected: false
                        }
                    ],
                    description: 'Required for a customer using any DDOS products, upgrades, or services',
                    friendly: 'DDOS',
                    name: 'DDOS',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'Data retention override value for Edge Protect in days',
                            friendly: 'Edge Protect Data Retention Override',
                            name: 'Edge_Protect_Data_Retention_Override',
                            type: 'Int',
                            value: null
                        }
                    ],
                    children: [],
                    default_data_retention: 365,
                    description: 'The Edge Protect Product',
                    friendly: 'Edge Protect',
                    name: 'Edge_Protect',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The ID used for managed firewall.',
                            friendly: null,
                            name: 'EXO_Number',
                            type: 'Int',
                            value: null
                        }
                    ],
                    children: [],
                    description: 'Number used by managed firewall customr systems.(EXO ID/SW Customer ID)',
                    friendly: 'Managed Firewall Customer ID',
                    name: 'EXO_Number',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The ingest limit attribute for a given customer in GB/day',
                            friendly: 'GB/Day',
                            name: 'Ingest_Limit',
                            type: 'Float',
                            value: null
                        }
                    ],
                    children: [],
                    description: 'Enabled/disable data limiter at selected ingest range',
                    friendly: 'Ingest Limiter',
                    name: 'Ingest_Limiter',
                    selected: false
                },
                {
                    attributes: [],
                    children: [],
                    default_data_retention: 90,
                    description: 'The Legacy Managed Firewall product',
                    friendly: 'Legacy Managed Firewall',
                    name: 'Legacy_Managed_Firewall',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The Level3 Billing Number (BAN)',
                            friendly: null,
                            name: 'LEVEL3_BAN',
                            type: 'String',
                            value: null
                        }
                    ],
                    children: [],
                    description: 'Level 3 Billing Number (BAN)',
                    friendly: 'Level 3 Billing Number (BAN)',
                    name: 'LEVEL3_BAN',
                    selected: false
                },
                {
                    attributes: [],
                    children: [
                        {
                            attributes: [
                                {
                                    description: 'The Billing Account Number for the Carbon Black MEDR product',
                                    friendly: 'Billing Account Number',
                                    name: 'MEDR_Carbon_Black_BAN',
                                    type: 'String',
                                    value: null
                                },
                                {
                                    description: 'Data retention override value for Carbon Black MEDR in days',
                                    friendly: 'Carbon Black MEDR Data Retention Override',
                                    name: 'MEDR_Carbon_Black_Data_Retention_Override',
                                    type: 'Int',
                                    value: null
                                },
                                {
                                    description: 'The Organization Key for the Carbon Black MEDR product',
                                    friendly: 'Organization Key',
                                    name: 'MEDR_Carbon_Black_ORG_KEY',
                                    type: 'String',
                                    value: null
                                },
                                {
                                    description: 'The ServiceNow ID for the Carbon Black MEDR product',
                                    friendly: 'ServiceNow ID',
                                    name: 'SNOW_ID',
                                    type: 'String',
                                    value: null
                                }
                            ],
                            children: [],
                            default_data_retention: null,
                            description: 'The Carbon Black MEDR product',
                            friendly: 'Carbon Black MEDR',
                            name: 'MEDR_Carbon_Black',
                            selected: false
                        }
                    ],
                    description: 'Required for a customer using any MEDR products, upgrades, or services',
                    friendly: 'MEDR',
                    name: 'MEDR',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The Customer ID used to match SASE Fortinet events for this customer',
                            friendly: 'SASE Fortinet Customer ID',
                            name: 'SASE_Fortinet_Customer_ID',
                            type: 'String',
                            value: null
                        },
                        {
                            description: 'Data retention override value for SASE Fortinet in days',
                            friendly: 'SASE Fortinet Data Retention Override',
                            name: 'SASE_Fortinet_Data_Retention_Override',
                            type: 'Int',
                            value: null
                        }
                    ],
                    children: [],
                    default_data_retention: 90,
                    description: 'Required for a customer using any SASE Fortinet',
                    friendly: 'SASE Fortinet',
                    name: 'SASE_Fortinet',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The Tenant Id as defined by the SDWAN Meraki product',
                            friendly: 'SDWAN Meraki Tenant Id',
                            name: 'SDWAN_Meraki_Tenant_Id',
                            type: 'String',
                            value: null
                        }
                    ],
                    children: [
                        {
                            attributes: [],
                            children: [],
                            description: 'The SDWAN Meraki Security Monitoring upgrade',
                            friendly: 'Security Monitoring',
                            name: 'SDWAN_Meraki_Security_Monitoring',
                            selected: false
                        }
                    ],
                    description: 'The SDWAN Meraki product',
                    friendly: 'SDWAN Meraki',
                    name: 'SDWAN_Meraki',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The Tenant Name as defined in SDWAN',
                            name: 'SDWAN_Versa_Tenant_Name',
                            friendly: 'SDWAN Versa Tenant Name',
                            type: 'String',
                            value: null
                        }
                    ],
                    children: [
                        {
                            attributes: [],
                            children: [
                                {
                                    attributes: [],
                                    children: [],
                                    description: 'The SDWAN Versa Security Monitoring upgrade',
                                    friendly: 'Security Monitoring',
                                    name: 'SDWAN_Versa_Security_Monitoring',
                                    selected: false
                                }
                            ],
                            description: 'The SDWAN Versa Log Views upgrade',
                            friendly: 'Log Views',
                            name: 'SDWAN_Versa_Log_Views',
                            selected: false
                        }
                    ],
                    description: 'The SDWAN Versa product',
                    friendly: 'SDWAN Versa',
                    name: 'SDWAN_Versa',
                    selected: false
                },
                {
                    attributes: [
                        {
                            description: 'The GB/day attribute for a given customer.',
                            friendly: 'GB/Day',
                            name: 'GB_Per_Day',
                            type: 'Float',
                            value: 10
                        }
                    ],
                    children: [
                        {
                            attributes: [
                                {
                                    description: 'The number of managed devices for the Advanced Malware Detection Management service. The friendly value should be associated with the appropriate service in the portal interface.',
                                    friendly: 'Device Count',
                                    name: 'Adv_Malware_Dev_Count',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            description: 'The SLM Advanced Malware Detection managed service',
                            friendly: 'Advanced Malware Detection Management',
                            name: 'SLM2_Advanced_Malware',
                            selected: false
                        },
                        {
                            attributes: [
                                {
                                    description: 'Data retention override value for Cloud Security Monitoring in days',
                                    friendly: 'Cloud Security Monitoring Data Retention Override',
                                    name: 'SLM2_CSM_Data_Retention_Override',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            default_data_retention: 365,
                            description: 'The SLM Cloud Security Monitoring upgrade',
                            friendly: 'Cloud Security Monitoring',
                            name: 'SLM2_CSM',
                            selected: false
                        },
                        {
                            attributes: [
                                {
                                    description: 'The number of managed devices for the Data Loss Prevention Management service. The friendly value should be associated with the appropriate service in the portal interface.',
                                    friendly: 'Device Count',
                                    name: 'DLP_Dev_Count',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            description: 'The SLM Data Loss Prevention mananged service',
                            friendly: 'Data Loss Prevention Management',
                            name: 'SLM2_DLP',
                            selected: false
                        },
                        {
                            attributes: [],
                            children: [
                                {
                                    attributes: [],
                                    children: [],
                                    description: 'The SLM Security Analytics upgrade',
                                    friendly: 'Security Analytics',
                                    name: 'SLM2_Security_Analytics',
                                    selected: false
                                }
                            ],
                            description: 'The foundational monitoring base service for SLM2',
                            friendly: 'Foundational Monitoring',
                            name: 'SLM2_Foundational',
                            selected: false
                        },
                        {
                            attributes: [
                                {
                                    description: 'The number of managed devices for the IDS/IPS Management service. The friendly value should be associated with the appropriate service in the portal interface.',
                                    friendly: 'Device Count',
                                    name: 'IDS_IPS_Dev_Count',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            description: 'The SLM IDS/IPS Management managed service',
                            friendly: 'IDS/IPS Management',
                            name: 'SLM2_IDS_IPS_Mgmt',
                            selected: false
                        },
                        {
                            attributes: [
                                {
                                    description: 'The number of expected investigations per month associated with the Incident Management and Response service',
                                    friendly: 'Investigations/Month',
                                    name: 'IMR_Inv_Count',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            description: 'The SLM incident management and response service',
                            friendly: 'Incident Management and Response',
                            name: 'SLM2_IMR',
                            selected: false
                        },
                        {
                            attributes: [
                                {
                                    description: 'The number of logstreams a customer is allowed to have.',
                                    friendly: 'Logstream Limit',
                                    name: 'Logstream_Limit',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            description: 'The Logstream service allows customers to send a copy of logs to the destination of their choice.',
                            friendly: 'Logstreaming',
                            name: 'SLM2_Logstream',
                            selected: false
                        },
                        {
                            attributes: [
                                {
                                    description: 'The number of managed devices for the Next Generation Managed Firewall service. The friendly value should be associated with the appropriate service in the portal interface.',
                                    friendly: 'Device Count',
                                    name: 'NG_Firewall_Dev_Count',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            description: 'The SLM Next Generation Managed Firewall service',
                            friendly: 'Next Generation Managed Firewall',
                            name: 'SLM2_NG_Firewall',
                            selected: false
                        },
                        {
                            attributes: [
                                {
                                    description: 'The number of days to store logs in the Raw Log Storage service',
                                    friendly: 'Storage Period',
                                    name: 'Raw_Log_Period',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            description: 'Raw_Log_Period',
                            friendly: 'Raw Log Storage',
                            name: 'SLM2_Raw_Log_Storage',
                            selected: false
                        },
                        {
                            attributes: [],
                            children: [
                                {
                                    attributes: [],
                                    children: [],
                                    description: 'The Emergency Response upgrade to SOC Monitoring',
                                    friendly: 'Emergency Response',
                                    name: 'Emergency_Response',
                                    selected: false
                                }
                            ],
                            description: 'The SLM SOC Monitoring service',
                            friendly: 'SOC Monitoring',
                            name: 'SLM2_SOC_Monitoring',
                            selected: false
                        },
                        {
                            attributes: [
                                {
                                    description: 'The number of days to store parsed logs in the SLM Product',
                                    friendly: 'Storage Period',
                                    name: 'Parsed_Log_Period',
                                    type: 'Int',
                                    value: null
                                }
                            ],
                            children: [],
                            description: 'The SLM Trending and Analytics upgrade',
                            friendly: 'Trending and Analytics',
                            name: 'SLM2_Trending_Analytics',
                            selected: false
                        }
                    ],
                    default_data_retention: 365,
                    description: 'Required for a customer using any SLM2 products, upgrades, or services',
                    friendly: 'SLM2',
                    name: 'SLM2',
                    selected: false
                }
            ]
        };
        this.dialog.open(AddCompanyComponent, {
            disableClose: true,
            width: '70vw',
            autoFocus: false,
            maxHeight: '90vh',
            panelClass: 'ctl-panel-class',
            data: {
                title: 'New Company',
                companyDetails: emptyCompany,
                newCompany: true
            }
        }).afterClosed().pipe(takeUntil(this.destroyer$)).subscribe(data => {
            if (data) {
                // add the data to the list
                this.fetchCompanies();
            }
        });
    }

    goToDetails(company: Company): void {
        this.router.navigate(['/admin/company', company.id]);
    }

}
