import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-group-by-section',
  templateUrl: './group-by-section.component.html',
  styleUrls: ['./group-by-section.component.scss']
})
export class GroupBySectionComponent extends FieldArrayType {
  constructor() {
    super();
  }
}
