import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { IndexField, SortField } from '@modules/events/interfaces/events';

@Component({
  selector: 'app-column-item',
  templateUrl: './column-item.component.html',
  styleUrls: ['./column-item.component.scss']
})
export class ColumnItemComponent implements OnInit {
  @Input() column: IndexField = null;
  @Input() position: number = 0;
  @Input() sorting: boolean = false;
  @Input() sortOrder: 'asc' | 'desc' = null;

  @Output() actionHandler: EventEmitter<{ name: string, type: 'SORTING' | 'COLUMN', data: IndexField | SortField }> = new EventEmitter(null);

  constructor() { }

  ngOnInit(): void {
  }

  onAction(name: string): void {
    this.actionHandler.emit({ name, type: this.sorting ? 'SORTING' : 'COLUMN', data: this.column });
  }

  updateSortDirection(evt: MatRadioChange): void {
    this.sortOrder = evt.value;
    this.actionHandler.emit({ name: 'UPDATE', type: this.sorting ? 'SORTING' : 'COLUMN', data: { field: this.column.value, order: evt.value } });
  }

}
