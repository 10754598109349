import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { WidgetDrilldownService } from '@shared-services/widget-drilldown.service';

import { WidgetModel } from '@sweet-shared/models/widget.model';

export const SELECT_ALL = '_Select_All';
export const SELECT_ANY = '_Any_';

@Injectable({
  providedIn: 'root'
})

/**
 * manage the input variables for widgets
 *
 * it's main objective is to efficiently manage widget input data and prevent unncessary calls to backend
 */
export class InputVariableService {
  private inputVariablesSubject: BehaviorSubject<any> = new BehaviorSubject({
    company: null,
    dateFrom: null,
    dateTo: null
  });
  private internalInputVariables = {};
  private initialFetch = false;
  public shouldFetch$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private inputVariableReadySubject = new BehaviorSubject(false);
  dropDownTypes = ['dropdown', 'string_dropdown', 'text_input'];
  public refetch$ = new Subject();

  get inputVariables(): Observable<any> {
    return this.inputVariablesSubject.asObservable();
  }

  get inputVariableReady(): Observable<boolean> {
    return this.inputVariableReadySubject.asObservable();
  }

  constructor(private widgetDrillDown: WidgetDrilldownService) { }

  unsetInitialFetch(): void {
    this.initialFetch = false;
  }

  initializeInputVariables(widgets: any[], defaults: any = {}): void {
    this.internalInputVariables = widgets.reduce((prev, next: WidgetModel) => {
      if (this.dropDownTypes.includes(next.type)) {
        const defaultValue = next?.default_value ?? next.params.default_value ?? SELECT_ALL;
        if (next.params.inputName) {
          prev[next.params.inputName] = next.params.isMultiSelect ? [defaultValue] : defaultValue;
        } else if (next.params.output_variable) {
          prev[next.params.output_variable] = defaultValue;
        }
      }
      return prev;
    }, {
      company: defaults?.company ?? null,
      dateFrom: defaults?.dateFrom ?? null,
      dateTo: defaults?.dateTo ?? null
    });
    this.inputVariablesSubject.next(this.internalInputVariables);
    this.inputVariableReadySubject.next(true);
  }

  setSingleInputVariables(name: string, value: any): void {
    this.internalInputVariables[name] = value;

    if (!this.initialFetch) {
      this.inputVariablesSubject.next(this.internalInputVariables);
      this.initialFetch = true;
    }
  }

  emitInputVariables(value?): void {
    value = value ? value : this.inputVariablesSubject.value;
    this.internalInputVariables = value;
    this.inputVariablesSubject.next(value);
  }

  public getInitialFetch(): boolean {
    return this.initialFetch;
  }

  public inputVariables$(): Observable<any> {
    return this.inputVariablesSubject;
  }
}
