import { AuthService } from './pages/auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { LayoutConfig } from '@sweet-shared/models/layout-config';
import { Store } from '@ngrx/store';
import { State } from './reducers';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ThemeSwitcherService } from '@sweet-shared/services/theme-switcher.service';
import { Observable } from 'rxjs';
import { User } from './pages/admin/store/user/user.model';
import { Location } from '@angular/common';
import { setCurrentUser } from './pages/admin/store/user/user.actions';
import { MetricsService } from '@sweet-shared/modules/metrics/services/metrics.service';
import { RedirectBoardService } from '@services/redirect-board.service';
import { MatDialog } from '@angular/material/dialog';
import { RedirectDialogComponent } from '@sweet-shared/components/redirect-dialog/redirect-dialog.component';
import { filter, tap } from 'rxjs/operators';

export const ROUTE_PARTIAL_WIDGET_DRILLDOWN = '/events/drilldown';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ng-portal';
  isAuthenticated = false;
  user: User = null;
  isDarkMode: Observable<boolean>;
  redirect$ = this.redirectBoardService.redirect$;
  redirectDialogRef = null;

  // Layout configuration
  layoutConfig: LayoutConfig = {
    showFooter: true,
    customFooter: false,
    customHeader: false,
    showSidenav: false,
    loadingStatus: false,
    sideNavOpened: JSON.parse(localStorage.getItem('sideNavOpened') || 'true')
  };

  constructor(
    // inject the store
    private store: Store<State>,
    // inject router
    private router: Router,
    // service for switching between dark/light mode
    private themeService: ThemeSwitcherService,
    private authService: AuthService,
    private location: Location,
    public metricsService: MetricsService,
    public redirectBoardService: RedirectBoardService,
    public redirectDial: MatDialog
  ) {

  }

  ngOnInit(): void {
    // check if user is logging in via SSO
    this.checkForSSOLogin();
    this.authService.isAuthenticated().subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
      this.layoutConfig.showSidenav = isAuthenticated;
    });


    // timer(5000).subscribe(_ => {
    //   this.redirect$.next(true);
    // })


    this.authService.getAuthenticatedUser().subscribe(user => {
      this.user = user;
      this.store.dispatch(setCurrentUser({ user: this.user }));

    });
    this.isDarkMode = this.themeService.isDarkTheme;

    // listen for redirections and open redirect board dialog
    this.redirect$.pipe(
      tap(st => {
        if (!!!st) {
          this.redirectDialogRef?.close();
        }
      }),
      filter(st => !!st)
    ).subscribe(pkg => {
      this.redirectDialogRef = this.redirectDial.open(RedirectDialogComponent, {
        width: '600px',
        height: '150px',
        hasBackdrop: true,
        disableClose: true
      });
    });


    // handle widget redirects (start phase)
    // this.router.events.pipe(
    //   filter((evt: RouterEvent) => evt instanceof NavigationStart),
    //   filter((evt: RouterEvent) => evt?.url?.includes(ROUTE_PARTIAL_WIDGET_DRILLDOWN))
    // ).subscribe(dat => {
    //   console.log('the router data is: ', dat)
    // })
    // handle widget redirects (end phase)
    this.router.events.pipe(
      filter((evt: RouterEvent) => evt instanceof NavigationEnd),
      filter((evt: RouterEvent) => evt?.url?.includes(ROUTE_PARTIAL_WIDGET_DRILLDOWN))
    ).subscribe(dat => {
      this.redirectBoardService.redirect$.next(false);
      this.redirectBoardService.message$.next('');
    });
  }

  checkForSSOLogin() {
    // If on admin/external accounts route, exit function
    if (this.location.path(true).includes('admin/external-users')) {
      return;
    }
    if (this.location.path(true).includes('mylevel3-env1')) {
      this.authService.ssoLogin('mylevel3-env1');
    } else if (this.location.path(true).includes('mylevel3-env2')) {
      this.authService.ssoLogin('mylevel3-env2');
    } else if (this.location.path(true).includes('mylevel3-env3')) {
      this.authService.ssoLogin('mylevel3-env3');
    } else if (this.location.path(true).includes('mylevel3-env4')) {
      this.authService.ssoLogin('mylevel3-env4');
    } else if (this.location.path(true).includes('mylevel3')) {
      this.authService.ssoLogin('mylevel3');
    }
    // Oopses fix here, control center currently has the wrong link info.  We are fixing it here.
    else if (this.location.path(true).includes('lumen-control-center')) {
      this.authService.ssoLogin('mylevel3');
    }
  }
}
