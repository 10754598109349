import { createAction, props } from '@ngrx/store';
import { Profile } from './profile.model';

export const loadProfile = createAction(
  '[Profile/API] Load Profile',
  props<{ userSub: string }>()
);

export const loadProfileSuccess = createAction(
  '[Profile/API] Load Profile Success',
  props<{profile: Profile}>()
);

export const loadProfileError = createAction(
  '[Profile/API] Load Profile Error',
  props<{error: string}>()
);

export const loadCurrentUserProfile = createAction(
  '[Profile/API] Load Current User Profile'
);

export const loadCurrentUserProfileSuccess = createAction(
  '[Profile/API] Load Current User Profile Success',
  props<{profile: Profile}>()
);

export const loadCurrentUserProfileError = createAction(
  '[Profile/API] Load Current User Profile Error',
  props<{error: string}>()
);

export const loadCurrentUserCompany = createAction(
  '[Profile/API] Load Current User Company',
  props<{currentUserCompanyId: string}>()
);

export const loadCurrentUserCompanySuccess = createAction(
  '[Profile/API] Load Current User Company Success',
  props<{currentUserCompany: any}>()
);

export const loadCurrentUserCompanyError = createAction(
  '[Profile/API] Load Current User Company Error',
  props<{error: string}>()
);
