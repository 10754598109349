<section class="select-component">
  <mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="selectControl" [multiple]="isMultiple">
      <mat-option value="_Any_" *ngIf="isMultiple">Any</mat-option>
      <mat-option value="_Select_All" *ngIf="isMultiple">Select All</mat-option>
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</section>
