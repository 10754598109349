import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FieldInterface, FormBuilderComponent} from '../../../form-builder/form-builder.component';
import {Validators} from '@angular/forms';
import {CastAsEnum} from '../../widgets';

@Component({
  selector: 'app-single-metric-create-form',
  templateUrl: './single-metric-create-form.component.html',
  styleUrls: ['./single-metric-create-form.component.scss']
})
export class SingleMetricCreateFormComponent implements OnInit {
  @Input() query: string;
  widgetFields: FieldInterface[] = [
    {
      name: 'widgetSource',
      type: 'text',
      component: 'input-select',
      placeholder: 'Widget Source',
      label: 'Widget Source',
      flex: '100%',
      defaultValue: 'log',
      validators: [Validators.required],
      options: [
        {value: 'log', friendly: 'log'}
      ],
      signalOnChanged: true,
      hide: false,
    },
    {
      name: 'fieldType',
      type: 'text',
      component: 'input-select',
      placeholder: 'Fields',
      label: 'Fields',
      flex: '150%',
      showSearch: true,
      defaultValue: null,
      validators: [Validators.required],
      options: [],
      signalOnChanged: true,
    },
    {
      name: 'alias',
      type: 'text',
      component: 'input-text',
      placeholder: 'Alias',
      label: 'Alias',
      flex: '150%',
      defaultValue: '',
      validators: [],
      signalOnChanged: true,
    },
    {
      name: 'cast_as',
      type: 'text',
      component: 'input-select',
      placeholder: 'Cast As',
      label: 'Cast As',
      flex: '150%',
      defaultValue: '',
      validators: [],
      options: Object.values(CastAsEnum).map(cast => {
        return {
          value: cast,
          friendly: cast.charAt(0).toUpperCase() + cast.slice(1)
        };
      }),
      signalOnChanged: true
    },
    {
      name: 'MetricAction',
      type: 'text',
      component: 'input-select',
      placeholder: 'Action',
      label: 'Action',
      flex: '150%',
      defaultValue: null,
      validators: [Validators.required],
      options: [],
      signalOnChanged: true,
      disabled: true
    },

    {
      name: 'query',
      type: 'text',
      component: 'input-text',
      placeholder: 'Query',
      label: 'Query',
      flex: '150%',
      defaultValue: '',
      signalOnChanged: true,
      validators: [],
    }
  ];
  metricField: any = '';
  @Input() categoryField: any;
  @Output() actionEvents: EventEmitter<any> = new EventEmitter();
  @Output() singleMetricFormEvents: EventEmitter<any> = new EventEmitter();
  @Input() actions: any;
  @ViewChild('createSingleMetricForm') createSingleMetricForm: FormBuilderComponent;

  constructor() { }

  ngOnInit() {
    // initialise the option of fieldtype with categories
    this.widgetFields.map(f => {
      if (f.name === 'fieldType') {
        f.options = this.categoryField;
      }
      return f;
    });
  }

  // this function is used to obtain the yaxis action based on a type
  private getFieldAction(typ, list) {
    return list.reduce((acc, cur) => {
      if (cur.type.indexOf(typ) > -1) {
        acc.push({value: cur.value, friendly: cur.friendly});
      }
      return acc;
    }, []);
  }
  // function takes in an event from the user input which is fieldType category and return a yAxisField
  selectAxis(event) {
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'fieldType' && event.data.value) {
      this.metricField = this.getFieldAction(event.data.value.type, this.actions);
      this.widgetFields.map(f => {
        if (f.name === 'MetricAction') {
          f.options = this.metricField;
        }
      });
    }
  }

  setDefaultQuery(event) {
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'query' && this.query && event.data.value !== this.query) {
      this.createSingleMetricForm.form.controls.query.setValue(this.query);
    }
  }

  enableMetricActionField(event) {
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'fieldType') {
      const metricAction = this.createSingleMetricForm.form.get('MetricAction');
      if (!event.data.value) {
        setTimeout(() => metricAction.disable(), 200);
      } else {
        setTimeout(() => metricAction.enable(), 200);
      }
    }
  }

  actionHandler(event) {
    this.setDefaultQuery(event);
    this.selectAxis(event);
    this.enableMetricActionField(event);
    this.actionEvents.emit(event);
  }
}
