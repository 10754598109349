<form [formGroup]="form">
  <!-- Default value -->
  <mat-form-field appearance="outline">
    <mat-label>Default Value</mat-label>
    <input matInput formControlName="default_value" />
  </mat-form-field>

  <!-- Output Variable -->
  <mat-form-field appearance="outline">
    <mat-label>Output Variable</mat-label>
    <input matInput formControlName="output_variable" />
  </mat-form-field>

  <!-- Validation Regex -->
  <mat-form-field appearance="outline">
    <mat-label>Validation Regex</mat-label>
    <input matInput formControlName="validation_regex" />
  </mat-form-field>
</form>
