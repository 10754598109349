import { AuthService } from './../../services/auth.service';
import { Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormEvent } from '../../../profile/store/profile.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../../environments/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { User } from '../../../admin/store/user/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  destroyer$: ReplaySubject<boolean> = new ReplaySubject();
  loading = false;
  isAuthenticated = false;
  error: string = null;
  user: User = null;

  // form settings
  forgotPasswordFormDetails: any = {
    title: 'Forgot Password',
    fieldDetails: [
      {
        type: 'text',
        component: 'input-text',
        label: 'Username',
        name: 'username',
        flex: '100%',
        placeholder: 'Username',
        defaultValue: null,
        validators: [
          Validators.required,
          Validators.minLength(1)
        ],
        required: true
      }
    ],
    actionButtons: {
      flex: 'space-between center',
      buttons: [
        {
          label: 'Cancel',
          name: 'CANCEL',
          color: 'warn',
          type: 'stroked'
        },
        {
          label: 'Submit',
          name: 'SUBMIT',
          color: 'primary',
          type: 'basic'
        }
      ]
    }
  };

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.pageSetup();
    this.authService.getRecoveryData().pipe(
      filter(data => !!data),
      takeUntil(this.destroyer$)
    ).subscribe(data => {
      this.router.navigate(['/auth/reset-password'], { queryParams: data });
    });
  }

  pageSetup() {
    this.authService.getAuthenticatedUser().subscribe(user => this.user = user);
    this.authService.isAuthenticating().subscribe(isAuthenticating => this.loading = isAuthenticating);
    this.authService.getAuthError().subscribe(error => this.error = error);
    this.authService.isAuthenticated().subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
      if (!isAuthenticated) {
        if (!this.router.url.includes('/auth/login')) {
          // redirect the user to the login
          this.router.navigate(['/auth/login'], { queryParams: { next: this.router.url } });
        } else {
          this.router.navigate(['/auth/login'], {});
        }
      } else {
        this.router.navigate([environment.landingPage], {});
      }
    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  actionHandler(data: FormEvent) {
    if (data.type === 'LOGIN') {
      this.authService.login(data.data.username, data.data.password);
    } else if (data.type === 'FORGOT_PASSWORD') {
      this.router.navigate(['/auth/forgot-password'], {});
    }
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
