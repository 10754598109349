import { AppService } from 'src/app/app.service';
import { Injectable } from '@angular/core';
import {User} from '../pages/admin/store/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private appService: AppService<any[]>
  ) { }

  sortUsers(arr: User[]) {
    // Will sort alphabetically and disregards case
    arr = arr.slice().sort((a, b) => {
      if (a.Attributes.family_name.toUpperCase() > b.Attributes.family_name.toUpperCase()) {
        return 1;
      } else if (a.Attributes.family_name.toUpperCase() < b.Attributes.family_name.toUpperCase()) {
        return -1;
      }
      if (a.Attributes.given_name.toUpperCase() > b.Attributes.given_name.toUpperCase()) {
        return 1;
      } else if (a.Attributes.given_name.toUpperCase() < b.Attributes.given_name.toUpperCase()) {
        return -1;
      }
      if (a.Username.toUpperCase() > b.Username.toUpperCase()) {
        return 1;
      } else if (a.Username.toUpperCase() < b.Username.toUpperCase()) {
        return -1;
      }
    });
    return arr;
  }

  // to group users by company to show in mat option groups
  groupUsersByCompany(arr: User[]) {
    arr = this.sortUsers(arr);
    // Returns an object, each key of which is a company name and whose values are an array of users for said company
    const groups = arr.reduce((accumulator, currentValue) => {
      accumulator[currentValue.Attributes.profile || 'Unknown Company'] ?
        accumulator[currentValue.Attributes.profile]
          .push({
            value: currentValue.Username,
            friendly: `${currentValue.Attributes.given_name} ${currentValue.Attributes.family_name} (${currentValue.Username})`
          }) :
        accumulator[currentValue.Attributes.profile] =
          [{
            value: currentValue.Username,
            friendly: `${currentValue.Attributes.given_name} ${currentValue.Attributes.family_name} (${currentValue.Username})`
          }];
      return accumulator;
    }, {});
    const results = [];
    // Builds an array of objects with the below keys, since that is what select-group expects
    for (const key in groups) {
      if (groups.hasOwnProperty(key)) {
        results.push({
          groupName: key,
          option: groups[key]
        });
      }
    }
    // Specific case that looks for friendly as a key and sorts alphabetically in incidents/assignee
    if (results[0].option[0] && 'friendly' in results[0].option[0]) {
      results[0].option = results[0].option.sort((a, b) => a.friendly.toLowerCase().localeCompare(b.friendly.toLowerCase()));

    }
    return results;
  }

  fetchUsers(companies: string[]) {
    return this.appService.get('user', '', {companyFilter: companies.join(',')});
  }
}
