import { AfterViewInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-maya-date-picker',
  templateUrl: './maya-date-picker.component.html',
  styleUrls: ['./maya-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MayaDatePickerComponent)
    }
  ]
})
export class MayaDatePickerComponent implements ControlValueAccessor {
  @Input() stacked = false;
  _value = {start: null, end: null};
  startDateTime: string = null;
  endDateTime: string = null;

  disabled = false;
  touched = false;

  onChange = (_) => {};
  onTouched = () => {};

  form: UntypedFormGroup;

  constructor(private apiService: AppService<any>) {
    this.form = new UntypedFormGroup({
      start: new UntypedFormControl('', [Validators.required]),
      end: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value): void {
    this._value = value;
    this.onChange(value);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(name: string): void {
    if (name === 'start') {
      const value = this.form.value.start;
      this.startDateTime = null;
      this.form.controls.start.setErrors(null);
      this.apiService.post('validators', '', null, {type: 'maya', expression: value}, true)
        .then(response => {
          this.startDateTime = response.data.date;
          this.form.patchValue({start: value});
          this.writeValue(this.form.value);
        })
        .catch(error => this.form.controls.start.setErrors({parsing: error.message}))
    } else if (name === 'end') {
      const value = this.form.value.end;
      this.endDateTime = null;
      this.form.controls.end.setErrors(null);
      this.apiService.post('validators', '', null, {type: 'maya', expression: value}, true)
        .then(response => {
          this.endDateTime = response.data.date;
          this.form.patchValue({end: value});
          this.writeValue(this.form.value)
        })
        .catch(error => this.form.controls.end.setErrors({parsing: error.message}))
    } else {
      // Nothing to validate here.
    }
  }
}
