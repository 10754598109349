import {Component, Inject, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {DrawChartService} from '../../../services/draw-chart.service';
import * as am4charts from '@amcharts/amcharts4/charts';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-full-screen-widget',
  templateUrl: './full-screen-widget.component.html',
  styleUrls: ['./full-screen-widget.component.scss']
})
export class FullScreenWidgetComponent implements OnInit, OnDestroy {
  widgetData: any;
  @Input() chartData: any;
  @Input() chartType: string;
  chart: am4charts.Chart;
  chartId: string;

  constructor(
    private drawChartService: DrawChartService,
    private zone: NgZone,
    private matDialogRef: MatDialogRef<FullScreenWidgetComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.widgetData = data;
  }

  ngOnInit(): void {
    // set id of html container to add chart to
    this.chartId = this.widgetData.widget_id + '_dialog';

    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        if (this.chartType === 'line' || this.chartType === 'multi_line') {
          this.chart = this.drawChartService.drawLineChart(this.chartData, this.chart, this.widgetData, this.chartId);
        } else if (this.chartType === 'bar') {
          this.chart = this.drawChartService.drawBarChart(this.chartData, this.chart, this.widgetData, this.chartId);
        } else if (this.chartType === 'pie') {
          this.chart = this.drawChartService.drawPieChart(this.chartData, this.chart, this.widgetData, this.chartId);
        } else if (this.chartType === 'single_metric') {
          this.chart = this.drawChartService.drawSingleMetric(this.chartData, this.chart, this.widgetData, this.chartId);
        } else if (this.chartType === 'solid_gauge') {
          this.chart = this.drawChartService.drawSolidGaugeChart(this.chartData, this.chart, this.widgetData, this.chartId);
        }
      });
    });
  }

  exitFullScreen() {
    this.zone.run(() => {
      this.matDialogRef.close();
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
