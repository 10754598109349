import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '@app/app.service';
import { AuthService } from '@app/pages/auth/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-list-widget-result-form',
  templateUrl: './list-widget-result-form.component.html',
  styleUrls: ['./list-widget-result-form.component.scss']
})
export class ListWidgetResultFormComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  form: UntypedFormGroup;
  listOptions: { label: string; value: string }[] = [];
  showCustomListIDInput = false;
  loading = true;
  errorMessage: string = null;

  @Output() actionHandler: EventEmitter<{ action: 'VALUE_CHANGED', payload: { valid: boolean, data: { list_id: string, output_variable } } }> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder, private apiService: AppService<any>, private authService: AuthService) {
    this.form = this.fb.group({
      list_id: ['', [Validators.required]],
      output_variable: ['', [Validators.required]]
    });

    this.authService.getAuthenticatedUser().pipe(takeUntil(this.destroyer$)).subscribe(authenticatedUser => {
      this.errorMessage = null;
      this.apiService.get('list', authenticatedUser.profile, null)
        .then(response => {
          this.listOptions = response.map(item => {
            return {
              label: item.name,
              value: item.id
            }
          }).sort((a, b) => a.label.localeCompare(b.label));
          this.errorMessage = response.length === 0 ? 'No list found. Widget cannot be created.' : null;
        })
        .catch(_ => {
          this.listOptions = []
          this.errorMessage = 'No list found. Widget cannot be created.';
        })
        .finally(() => {
          this.loading = false;
          this.actionHandler.emit({
            action: 'VALUE_CHANGED',
            payload: {
              valid: this.form.valid,
              data: {
                output_variable: this.form.value.output_variable,
                list_id: this.form.value.list_id
              }
            }
          })
        })
    })
  }

  ngOnInit(): void {
    // subscribe on value change and emit it
    this.form.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(val => {
      this.actionHandler.emit({
        action: 'VALUE_CHANGED',
        payload: {
          valid: this.form.valid,
          data: {
            output_variable: val.output_variable,
            list_id: val.list_id
          }
        }
      })
    })
  }

  ngOnDestroy(): void {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }
}
