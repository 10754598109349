import { Component, Input, OnInit } from '@angular/core';
import { ProductNode } from '@modules/companies/services/companies.service';

@Component({
  selector: 'app-product-node',
  templateUrl: './product-node.component.html',
  styleUrls: ['./product-node.component.scss']
})
export class ProductNodeComponent implements OnInit {

  @Input() product: ProductNode;
  @Input() index: number;
  @Input() selected = false;

  constructor() { }

  ngOnInit(): void {
    this.initExpanded(this.product)
  }

  toggleCheck(payload) {


    if (!!payload.checked) {
      return;
    }

    this.product = this.recToggleCheck(this.product, payload.checked)
    console.log('the product is: ', this.product);
  }

  recToggleCheck(product: ProductNode, checked: boolean): ProductNode {
    if (!!!product) {
      return product;
    }

    if (!!!product.children || product.children.length === 0) {
      return { ...product, selected: checked };
    }

    return { ...product, selected: checked, children: product.children.map(c => this.recToggleCheck(c, checked)) }

  }

  toggleExpand() {
    this.product = { ...this.product, expanded: !this.product.expanded }
  }


  initExpanded(pdt: ProductNode) {
    if (pdt.children.length <= 0) {
      return pdt;
    }

    return {
      ...pdt,
      expanded: !!pdt.expanded ? pdt.expanded : false,
      children: pdt.children.map(c => this.initExpanded(c))
    }
  }
}
