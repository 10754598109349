import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from './../../services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-mfa-verifier',
  templateUrl: './mfa-verifier.component.html',
  styleUrls: ['./mfa-verifier.component.scss']
})
export class MfaVerifierComponent implements OnInit, OnDestroy {
  destroyer$: ReplaySubject<any> = new ReplaySubject<any>();
  loading = false;
  resetPasswordData: any = null;
  username: string = null;
  error: string = null;
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      code: [null, [Validators.required, Validators.minLength(1)]]
    });

    this.authService.isMfaRequired().pipe(takeUntil(this.destroyer$)).subscribe(isMFARequired => {
      if (!isMFARequired) {
        this.router.navigate(['/dashboard'], {});
      }
    });
  }

  onMFACodeSubmit() {
    if (this.form.valid) {
      this.authService.confirmSignIn(this.form.value.code);
    }
  }

  ngOnDestroy() {
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }

}
