import { Injectable } from '@angular/core';
import { Auth, API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})

// Generic service to be used across the entire app. This service will allow us to pass a custom model/interface to be supported
// Each backend service call MUST use it and is forced to provide the format/shape of the data being return by the call. Thus also allows
// us to control the properties of the data coming it by having a strong data type attached to any object coming in from the backend.
export class AppService<T> {
  // Each verb is composed of 2 to 4 parts that should be provided.
  // apiName: String is the name of the API to be called as registered in the environment. This will be able to select the correct endpoint
  // path: String. The registration of an endpoint is only done with the main endpoint of the module/micro-service we need, so in case we
  //               need to hit a particular path of this endpoint we need to provide it via this parameter or let it empty.
  // body: T|Any. This is the body in case of a post call
  private fixedRouteNames = ['widgets'];

  constructor() { }

  get(apiName: string, path: string, queryStringParams: object | null) {
    return Auth.currentSession().then(
      res => {
        // The currentSession will give us information about the user currently loggedIn and we will be able to
        // extract the Authorization token from it as well to build the header needed by the backend.
        const params = {
          headers: { Authorization: res.getIdToken().getJwtToken() },
          queryStringParameters: queryStringParams ? queryStringParams : {}
        };
        return API.get(apiName, path, params);
      }
    );
  }

  post(apiName: string, path: string, queryStringParams: object | null, body: object | null, includeEntireResponse = false, secured = true) {
    if (secured) {
      return Auth.currentSession().then(
        res => {
          // The currentSession will give us information about the user currently loggedIn and we will be able to
          // extract the Authorization token from it as well to build the header needed by the backend.
          const params = {
            headers: { Authorization: res.getIdToken().getJwtToken() },
            queryStringParameters: queryStringParams ? queryStringParams : {},
            body: body ? body : {},
            response: includeEntireResponse
          };
          return API.post(apiName, path, params);
        }
      );
    } else {
      return API.post(apiName, path, { body: body, response: includeEntireResponse });
    }
  }

  put(apiName: string, path: string, queryStringParams: object | null, body: object | null, includeEntireResponse = false) {
    if (this.fixedRouteNames.includes(apiName)) {
      const params = {
        queryStringParameters: queryStringParams ? queryStringParams : {},
        body: body ? body : {},
        response: includeEntireResponse
      };
      return API.put(apiName, path, params);
    }
    return Auth.currentSession().then(
      res => {
        // The currentSession will give us information about the user currently loggedIn and we will be able to
        // extract the Authorization token from it as well to build the header needed by the backend.
        const params = {
          headers: { Authorization: res.getIdToken().getJwtToken() },
          queryStringParameters: queryStringParams ? queryStringParams : {},
          body: body ? body : {},
          response: includeEntireResponse
        };
        return API.put(apiName, path, params);
      }
    );
  }

  delete(apiName: string, path: string, queryStringParams: object | null, body: object | null) {
    return Auth.currentSession().then(
      res => {
        // The currentSession will give us information about the user currently loggedIn and we will be able to
        // extract the Authorization token from it as well to build the header needed by the backend.
        const params = {
          headers: { Authorization: res.getIdToken().getJwtToken() },
          queryStringParameters: queryStringParams ? queryStringParams : {},
          body: body ? body : {}
        };
        return API.del(apiName, path, params);
      }
    );
  }

  cancel(apiPromise): void {
    API.cancel(apiPromise, 'Request Cancelled.');
  }
}
