import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import { AppService } from '@app/app.service';
import { InputVariableService } from '@sweet-shared/services/input-variable.service';
import { WidgetInfoComponent } from '@sweet-shared/components/widgets/widget-info/widget-info.component';
import { WidgetDrilldownService } from '@shared-services/widget-drilldown.service';
import { Router } from '@angular/router';
import { RedirectBoardService } from '@services/redirect-board.service';
import {REDIRECT_MESSAGE} from '@sweet-shared/sweet-widgets/multi-bar/multi-bar.component';
import {EnvironmentService} from '@app/environment/environment.service';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit, OnDestroy {
  private apiCall = null;
  private destroyer$ = new Subject();
  private _height;
  private reroute: any;
  loading = false;

  @Input() widgetDetails: any = null;
  @Input() set height(h) {
    this._height = h;
  }
  get height() {
    return this._height;
  }

  @Output() widgetAction = new EventEmitter();

  inputVariables: { [key: string]: any } = null;
  shouldFetch = false;
  errorMessage: string = null;

  // chart element
  chart: any = null;

  constructor(
    private inputVariablesService: InputVariableService,
    private widgetService: AppService<any>,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private widgetDrillDown: WidgetDrilldownService,
    private appService: AppService<any>,
    private router: Router,
    private redirectBoardService: RedirectBoardService,
    private environmentService: EnvironmentService
  ) { }

  ngOnInit(): void {
    // Set the input variables
    this.setWidgetInputVariables();
    this.setInputVariableSubscription();
  }

  private setInputVariableSubscription(): void {
    this.inputVariablesService.inputVariables.pipe(takeUntil(this.destroyer$)).subscribe(inputVariables => {
      Object.keys(inputVariables).forEach(name => {
        if (this.inputVariables?.hasOwnProperty(name)) {
          if (this.inputVariables[name] === null || this.inputVariables[name] !== inputVariables[name]) {
            this.inputVariables[name] = inputVariables[name];
            this.shouldFetch = true;
          }
        }
      });

      if (this.shouldFetch) {
        if (!Object.values(this.inputVariables).includes(null)) {
          this.fetchWidgetData();
        } else {
          this.shouldFetch = false;
        }
      }
    });
  }

  private setWidgetInputVariables(): void {
    const widgetInputVariables = this.widgetDetails?.input_variables ?? [];
    this.inputVariables = widgetInputVariables.reduce((acc, next) => {
      acc[next.input_variable_name] = next.default_value;
      return acc;
    }, { company: null, dateFrom: null, dateTo: null });
  }

  private fetchWidgetData(): void {
    let response = null;
    this.errorMessage = null;
    this.loading = true;
    this.apiCall = this.widgetService.put(`widgets`, `${this.widgetDetails.dashboard_id}/${this.widgetDetails.widget_id}/get-data`, null, this.inputVariables, true);

    this.apiCall.then(res => response = res)
      .catch(err => this.errorMessage = err)
      .finally(() => {
        if (!this.errorMessage) {
          // check the response and see if we get a 200 or 200 and handle he 202 properly
          if (response.status === 200) {
            const dataUrl = response.data.url;
            this.getWidgetDataResult(dataUrl);
          } else if (response.status === 202) {
            // The query is still running in the backend, let reping it
            return this.fetchWidgetData();
          }
        }
        this.shouldFetch = false;
        this.loading = false;
        this.apiCall = null;
      }
      );
  }

  private getWidgetDataResult(url: string) {
    this.httpClient.get(url).pipe(takeUntil(this.destroyer$)).subscribe((res: any[]) => {
      if (!this.chart) {
        this.chart = am4core.create(this.widgetDetails.widget_id, am4charts.PieChart);

        // generating the pie series
        const pieSeries = this.chart.series.push(new am4charts.PieSeries());
        pieSeries.tooltip.label.interactionsEnabled = true;
        pieSeries.radius = am4core.percent(100);
        pieSeries.dataFields.value = 'count';
        pieSeries.dataFields.category = 'name';

        // Setup the 'click function on the pie chart
        if (this.environmentService.widgetDrillDown) {
          pieSeries.slices.template.events.on('hit', function(ev) {
            const dataPoint = ev.target.dataItem.dataContext;
            this.drillDown(ev, pieSeries, dataPoint);
          }, this);
        }
        // Set the label
        pieSeries.alignLabels = true;
        const title = this.chart.titles.create();
        title.text = this.widgetDetails.title;
        title.fontSize = 16;
        title.fontWeight = 'bold';

        // since it is a brand new chart, let set the legend
        this.chart.legend = new am4charts.Legend();

        // the legend has its own container, let move it there
        const legendContainer = am4core.create(`${this.widgetDetails.widget_id}-legend`, am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);
        this.chart.legend.parent = legendContainer;
      }

      this.chart.data = res;
    });
  }

  refresh(): void {
    this.fetchWidgetData();
  }

  info(): void {
    this.dialog.open(WidgetInfoComponent, {
      width: '40vw',
      maxWidth: '95vw',
      data: this.widgetDetails
    });
  }

  drillDown(event, series, dataIn) {
    this.redirectBoardService.message$.next(REDIRECT_MESSAGE);
    this.redirectBoardService.redirect$.next(true);
    // Set vars for drill down
    const widgetId = this.widgetDetails.widget_id;
    const widgetInputs = this.widgetDetails.input_variables;
    const widgetCompany = this.widgetDrillDown.getCompany();
    const value = dataIn['name'];
    const dateFrom = this.inputVariables.dateFrom;
    const dateTo = this.inputVariables.dateTo;

    // Format input widgets
    const inputBody = {};

    // TODO this loop might need to be updated to correctly capture the inputs on dropdowns that use the % as the value instead of an array of values
    this.inputVariablesService.inputVariables$().subscribe(vars => {
      for (const row of widgetInputs) {
        // For some reason % is not in an array
        if (row.default_value === '%') {
          inputBody[row.input_variable_name] = row.default_value;
        } else {
          inputBody[row.input_variable_name] = vars[row?.input_variable_name];
        }
      }

    });

    // input Click value is empty for single metric
    const inputClick = {};
    inputClick['value'] = value;
    inputClick['timeslice'] = null;

    // Build body for API call
    const body = {
      input_variables: inputBody,
      click: inputClick
    };

    // Insert Date and Company Params into body
    body.input_variables['company'] = widgetCompany;
    body.input_variables['dateFrom'] = dateFrom;
    body.input_variables['dateTo'] = dateTo;

    this.appService.put('widgetDrillDown', `${this.widgetDetails.dashboard_id}/${widgetId}/drilldown`, null, body)
      .then((res) => this.router.navigateByUrl('events/drilldown?flag=fetchData&' + res['drilldown'])).catch();
  }

  ngOnDestroy(): void {
    // dispose the chart if created
    this.chart?.dispose();
    this.destroyer$.next(false);
    this.destroyer$.complete();
    if (this.apiCall) {
      this.widgetService.cancel(this.apiCall);
    }
  }
}
