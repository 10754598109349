<div class="card-title">
  <div class="layout-padding">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span><h2>Security Posture</h2></span>
      <div (click)="onHelpClick()" aria-label="Security Posture Help">
        <button mat-icon-button>
          <mat-icon>help</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<app-portal-loader [loadingStatus]="loadingSecurityPosture$ | async"></app-portal-loader>
<div class="layout-padding" fxLayout="column">
  <mat-card appearance="outlined" fxFlexFill *ifUserCan="'secure-dns.view'" class="card-style">
    <mat-card-title>Filters</mat-card-title>
    <mat-card-content>
      <form [formGroup]="filterForm">
        <formly-form [form]="filterForm" [fields]="filterFields"></formly-form>
      </form>
    </mat-card-content>
  </mat-card>
  <br>
  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <mat-icon color="warn" [className]="'warning-icons'">warning</mat-icon>
    <div class="mat-h4 warning-text">
      <p>Please submit a <a href=" https://www.lumen.com/help/en-us/security-solutions.html#security-support-contacts"> Portal Support Center Ticket</a>
        to add users.
        If you do not have an Admin on this portal, please submit a  <a href=" https://www.lumen.com/help/en-us/security-solutions.html#security-support-contacts">
          Security Trouble Ticket</a> to elevate an existing user privileges on this portal.
      </p>
    </div>
    <mat-icon color="warn" [className]="'warning-icons'">warning</mat-icon>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" *ifUserCan="'secure-dns.view'">

    <mat-card appearance="outlined" fxFlex.gt-md="70" fxFlex.lt-lg="100">

    <mat-card-content>
      <div fxLayout="column">
        <div fxFlex="100">
          <mat-card-title fxFlex="50">Security Posture</mat-card-title>
          <mat-card-title fxFlex="50">Description</mat-card-title>
        </div>
        <div fxFlex="100" *ngFor="let def of securityPostureDefinitions" fxLayout="row wrap" class="security_definition">
          <div fxFlex="50">
            <form [formGroup]="securityPostureForm">
              <mat-radio-group formControlName="securityLevel" (change)="radioChange($event)">
                <mat-radio-button [value]="def.value"></mat-radio-button>
                <span [ngStyle]="{'color': def.style.color}">{{def.label}}</span>
              </mat-radio-group>
            </form>
          </div>
          <div fxFlex="50">{{def.description}}</div>
        </div>
      </div>
    </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayoutAlign="end center" *ifUserCan="'secure-dns.edit'">
        <button
          mat-flat-button
          color="primary"
          (click)="onUpdateClick()"
          [disabled]="!selectedSecurityPosture || selectedSecurityPosture === securityPostureForm.get('securityLevel').value">Update
        </button>
      </mat-card-actions>

    </mat-card>
  </div>
</div>
