import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  AfterContentInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { find } from 'lodash';

import { AppService } from '@app/app.service';
import { DateService } from '@shared-services/date.service';
import { QueryWidgetModel, WidgetModel } from '@sweet-shared/models/widget.model';

import { TextInputResultFormComponent } from '@modules/widgets/components/text-input/text-input-result-form/text-input-result-form.component';
import { TextInputResultData } from '@modules/widgets/components/text-input/interface';
import { ListWidgetResultFormComponent } from '@modules/widgets/components/list-widget-result-form/list-widget-result-form.component';

export interface WidgetSettingFormData {
  title: string;
  isNew: boolean;
  settingWidgets: WidgetModel[];
  queryWidgets: QueryWidgetModel[];
  defaultModels: any;
}

export interface ActionData {
  name: string;
  data: any;
}


@Component({
  selector: 'app-incident-table-widget',
  templateUrl: './incident-table-widget.component.html',
  styleUrls: ['./incident-table-widget.component.scss']
})

export class IncidentTableWidgetComponent implements OnInit , AfterContentInit, OnDestroy {
  private destroyer$ = new Subject();

  // Result container
  @ViewChild('resultContainer', {read: ViewContainerRef}) resultContainer;
  resultContainerRef: ComponentRef<TextInputResultFormComponent | ListWidgetResultFormComponent>;
  textInputResultData = null;
  listInputData = null;

  // emitter
  @Output() crupdate: EventEmitter<ActionData> = new EventEmitter();
  @Input() loading = false;
  generalInfoType = '';
  optionDataForm: any;
  canCreate = true;

  // Index choices getter/loader
  private selectedUserIndex: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private availableFields: string[] = [];
  private availableTypes: any[] = [
    {label: 'Table', value: 'table'}
  ];
  private allAvailableTypes: any[] = [...this.availableTypes];

  athenaStatement: any = {
    standard: [
      {
        select_statement: [],
        where_statement: {
          time: {
            field: 'isodate',
            start: null,
            end: null
          },
          filter: null,
        },
        group_by_statement: [],
      }
    ],
    order_by: []
  };
  allIndexes: any[] = [];
  indexesOptions: any[] = [];
  operators: any = {
    '=': 'string',
    '!=': 'string',
    '<': 'string',
    '<=': 'string',
    '>': 'string',
    '>=': 'string',
    contains: 'string',
    like: 'string',
    in: 'array',
    incidr: 'array',
    'is null': null,
    'is not null': null,
    'not like': 'string',
    between: 'array'
  };

  // Error message controller
  errorMessage: string = null;

  // Stepper form controllers
  // Tab 1 General
  generalInformationModel: any = this.data.isNew ? {} : this.data.defaultModels.generalInformationModel;
  generalInformationForm = new UntypedFormGroup({});
  generalInformationFields: FormlyFieldConfig[] = [
    {
      key: 'title',
      type: 'input',
      defaultValue: null,
      templateOptions: {
        label: 'Title',
        placeholder: 'Widget Title',
        required: true,
      },
    },
    {
      key: 'description',
      type: 'textarea',
      defaultValue: null,
      templateOptions: {
        label: 'Widget Description',
        placeholder: 'Widget Description',
        required: true,
        input: 'textarea'
      },
    },
    {
      key: 'source',
      type: 'select',
      defaultValue: 'incidents',
      templateOptions: {
        label: 'Widget Source',
        placeholder: 'Widget source',
        required: true,
        options: [
          {label: 'Incidents', value: 'incidents'}
        ],
        change: (model, newValue) => {
          this.availableTypes = [...this.allAvailableTypes];
          this.generalInformationFields.map((val) => {
            if (newValue.value === 'incidents') {

              if (val.key === 'type') {
                val.templateOptions.options = [{label: 'Table', value: 'table'}];
              }
            } else {
              if (val.key === 'type') {
                val.templateOptions.options = this.availableTypes;
              }
            }
          });
        },
      }
    },
    {
      key: 'type',
      type: 'select',
      defaultValue: this.data.isNew ? 'dropdown' : this.data.defaultModels?.generalInformationModel?.type,
      templateOptions: {
        label: 'Widget Type',
        placeholder: 'Widget Type',
        required: true,
        options: this.availableTypes,
        change: (_, newValue) => this.setSelectFieldLimit(newValue.value),

      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.setSelectFieldLimit(field.defaultValue);
        }
      }
    }
  ];

  // Tab 2 Field(s)
  // dropdown setting widget option.
  dropdownWidgetQueryModel: any = {fields: []};
  dropdownWidgetQueryForm = new UntypedFormGroup({});
  dropdownWidgetQueryFields: FormlyFieldConfig[] = [
    {
      key: 'fields',
      type: 'groupBy',
      templateOptions: {
        addText: 'Add Field',
        showRemoveBtn: true,
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'column_field',
            defaultValue: null,
            type: 'select',
            templateOptions: {
              label: 'Widget Field',
              placeholder: 'Widget Field',
              required: true,
              options: [
                {label: 'Incident Number', value: 'number'},
                {label: 'Company ID', value: 'company'},
                {label: 'Company', value: 'company_friendly'},
                {label: 'Short Description', value: 'short_description'},
                {label: 'Queue', value: 'u_queue'},
                {label: 'State', value: 'state'},
                {label: 'Assignee', value: 'u_unlisted_affected_user'},
                {label: 'Priority', value: 'priority'},
                {label: 'Category', value: 'category'},
                {label: 'Closure Code', value: 'close_code'},
                {label: 'Created', value: 'sys_created_on'},
                {label: 'Updated', value: 'sys_updated_on'},
                {label: 'Latest Customer Update', value: 'u_contact_time'}
              ]
            },
          }
        ]
      }
    },
  ];

  // Tab 3 Query
  // Stepper form controllers
  resultInformationModel: any = this.data.isNew ? {} : this.data.defaultModels.resultInformationModel;
  queryInputForm = new UntypedFormGroup({});
  queryInputField: FormlyFieldConfig[] = [
    {
      key: 'queryValue',
      type: 'input',
      defaultValue: '*',
      templateOptions: {
        label: 'Query',
        required: true,
        type: 'string',
        change: (_, newValue) => {
          if (this.resultInformationModel.limit) {
            this.athenaStatement.limit = newValue.target.value;
          } else {
            if (this.athenaStatement.hasOwnProperty('limit')) {
              delete this.athenaStatement.limit;
            }
          }
        }
      },
    }
  ];

  showQueryVariables = false;
  queryVariablesModel: any = this.data.isNew ? {} : this.data.defaultModels.queryVariablesModel;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: WidgetSettingFormData,
      private dateService: DateService,
      private apiService: AppService<any>,
      private formBuilder: UntypedFormBuilder,
      private resolver: ComponentFactoryResolver
  ) {

  }

  ngOnInit(): void {

    this.dropdownWidgetQueryForm.valueChanges.subscribe((value) => {
      this.formatSelectedField(value);
    });
  }

  private setSelectFieldLimit(value: any): void {
    this.generalInfoType = '';
    this.generalInfoType = value;

    // clear all the containers
    this.resultContainer?.clear();

    if (value === 'text_input') {
      const factory: ComponentFactory<TextInputResultFormComponent> = this.resolver.resolveComponentFactory(TextInputResultFormComponent);
      this.resultContainerRef = this.resultContainer.createComponent(factory);

      // We can also hook to any of the output
      (this.resultContainerRef as ComponentRef<TextInputResultFormComponent>).instance.onChange.pipe(takeUntil(this.destroyer$)).subscribe(data => this.handlerTextInputResultStepChange(data));
    }

    if (this.data.isNew) {
      switch (value) {
        case 'table':
          try {
            this.dropdownWidgetQueryFields[0].templateOptions.maxItems = 20;
            this.dropdownWidgetQueryFields[1].templateOptions.minItems = 1;
            if (this.dropdownWidgetQueryFields[1] !== undefined) {
              this.dropdownWidgetQueryFields[1].templateOptions.minItems = 1;
            }
            this.dropdownWidgetQueryModel = {...this.dropdownWidgetQueryModel, fields: [{}, {}]};
          } catch (e) {
            console.log();
            break;
          }
          break;
        case 'query_builder':
          break;
        default:
          this.dropdownWidgetQueryFields[0].templateOptions.maxItems = null;
          this.dropdownWidgetQueryFields[0].templateOptions.minItems = 1;
          this.dropdownWidgetQueryFields[0].templateOptions.showRemoveBtn = false;
          this.dropdownWidgetQueryModel = {
            ...this.dropdownWidgetQueryModel,
            fields: [{}]
          };
          break;
      }
    }
  }

  ngAfterContentInit(): void {
    // Fetch all the indexes
    this.fetchIndexes();
  }

  createWidget(): void {
    const typeofWidget = this.generalInformationModel.type;

    const tempFields = [];
    this.dropdownWidgetQueryModel.fields.forEach(value => {
      tempFields.push(value['column_field']);
    });
    let widgetData: any = {};
    const selectStatement = [];
    const tableList = [
      {label: 'Incident Number', value: 'number'},
      {label: 'Company ID', value: 'company'},
      {label: 'Company', value: 'company_friendly'},
      {label: 'Short Description', value: 'short_description'},
      {label: 'Queue', value: 'u_queue'},
      {label: 'State', value: 'state'},
      {label: 'Assignee', value: 'u_unlisted_affected_user'},
      {label: 'Priority', value: 'priority'},
      {label: 'Category', value: 'category'},
      {label: 'Closure Code', value: 'close_code'},
      {label: 'Created', value: 'sys_created_on'},
      {label: 'Updated', value: 'sys_updated_on'},
      {label: 'Latest Customer Update', value: 'u_contact_time'}
    ];

    tempFields.forEach(value => {
      tableList.forEach(item => {
        if (item.value === value) {
          const tempRow = {alias: item.label, select_field: {column_field: item.value}};
          selectStatement.push(tempRow);
        }
      });
    });

    if (typeofWidget === 'table') {
      widgetData = {
        title: this.generalInformationModel.title,
        description: this.generalInformationModel.description,
        source: this.generalInformationModel.source,
        type: this.generalInformationModel.type,
        params: {
          ...this.optionDataForm || [],
          dateFrom: '',
          dateTo: '',
          query: this.resultInformationModel.queryValue,
          table_fields: tempFields,
          athenaStatement: {
            standard: [
              {
                group_by_statement: [],
                select_statement: selectStatement,
                where_statement: []
              }]
          },
        }
      };
    }

    this.crupdate.emit({
      name: this.data.isNew ? 'CREATE' : 'UPDATE',
      data: this.generalInfoType === 'text_input' ? this.textInputResultData : this.generalInformationForm.value.source === 'list' ? this.listInputData : widgetData
    });
  }

  private formatSelectedField(selectedFields: any): void {
    const fields = selectedFields.fields;

    this.athenaStatement.standard[0].select_statement = fields.map((field: any) => {
      let athenaFormattedField: any = {};
      if (field.function && field.function !== '-') {
        athenaFormattedField.alias = field.alias;
        const selectedField: { [key: string]: any } = {};
        selectedField[field.function] = {
          column_field: field.column_field,
        };
        if (['avg', 'count', 'sum'].includes(field.function)) {
          selectedField[field.function].quantifier = field.quantifier && field.quantifier !== '-' ? field.quantifier : '';
          selectedField[field.function].data_type = field.data_type;
        }
        // sometimes the user might pick date trunc then in that case we need to add the unit
        if (field.function === 'date_trunc') {
          selectedField[field.function] = {
            ...selectedField[field.function],
            unit: field.unit
          };
        }
        athenaFormattedField.select_field = selectedField;
      } else {
        athenaFormattedField = {
          alias: field.alias,
          select_field: {
            column_field: field.column_field
          }
        };
      }
      return athenaFormattedField;
    });
  }

  private fetchIndexes(): void {
    this.apiService.get('index', '', null)
        .then((indexes: any[]) => {
          this.allIndexes = indexes;
          this.indexesOptions = indexes.map(index => {
            return {label: index.friendly, value: index.id};
          });

          // update the index choices
          this.setIndexOptions();
          this.setFieldOptions(this.generalInformationFields[4].defaultValue);
        })
        .catch((error: any) => {
              console.log(this.errorMessage);
              // this.errorMessage = error;
            }
        );
  }

  private setIndexOptions(): void {
    this.generalInformationFields = this.generalInformationFields.map(field => {
      if (field.key === 'index') {
        field.templateOptions.options = this.indexesOptions;
      }
      return field;
    });
  }

  private updateSelectedFields(indexFields: string[]): void {
    if (this.dropdownWidgetQueryModel.fields) {
      this.dropdownWidgetQueryModel.fields = this.dropdownWidgetQueryModel.fields.filter(field => {
        if (Object.keys(field).length === 0) {
          return field;
        }
        return indexFields.includes(field.column_field) && field.column_field;
      });
    }



  }

  private setFieldOptions(selectedIndexId: string): void {
    const selectedIndex = find(this.allIndexes, (index: any) => index.id === selectedIndexId);
    if (selectedIndex) {
      this.selectedUserIndex.next(selectedIndex);
      // update the selected field by removing all the fields that are not part of the index the user selected.
      this.updateSelectedFields(selectedIndex.fields.map(f => f.value));
    }

  }

  handlerTextInputResultStepChange = (evt: TextInputResultData): void => {
    this.textInputResultData = {
      description: this.generalInformationModel.description,
      dashboard_id: '',
      type: this.generalInformationModel.type,
      params: {
        ...evt.data
      },
      title: this.generalInformationModel.title,
      source: this.generalInformationModel.source,
    };
  }

  ngOnDestroy(): void {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }
}
