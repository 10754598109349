import {Injectable} from '@angular/core';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {AppService} from '../../../../app.service';
import {User} from './user.model';
import * as userActions from './user.actions';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {SnackbarService} from '../../../../shared-services/snackbar.service';
import {Store} from '@ngrx/store';
import {State} from '../../../../reducers';
import {Router} from '@angular/router';

@Injectable()
export class UserEffects {


  constructor(
    private actions$: Actions,
    private userService: AppService<User[]>,
    private snackbarService: SnackbarService,
    private router: Router,
    private store: Store<State>) {
  }

  @Effect({dispatch: true})
  loadUsers$ = this.actions$.pipe(
    ofType(userActions.loadUsers),
    distinctUntilChanged((prev, curr) => {
      if (prev.companyFilter.join(',') === curr.companyFilter.join(',')) {
        this.store.dispatch(userActions.forceLoadingOff());
        return true;
      } else {
        return false;
      }
    }),
    switchMap((action: {companyFilter: string[]}) => {
      return this.userService.get('user', '', {companyFilter: action.companyFilter.join(',')})
        .then((res: User[]) =>
          userActions.loadUsersSuccess({users: _.sortBy(res, [(o) => o.Attributes.family_name])})
        )
        .catch((error: any) => userActions.loadUsersError({error: error.message}));
    })
  );

  @Effect({dispatch: true})
  loadCurrentUser$  = this.actions$.pipe(
    ofType(userActions.loadUser),
    switchMap((action: {username: string}) => {
      return this.userService.get('user', `${action.username}`, null)
        .then((user: User) => userActions.loadUserSuccess({user}))
        .catch((error) => userActions.loadUserError({error: error.message}));
    })
  );

  @Effect({dispatch: true})
  editUser$ = this.actions$.pipe(
    ofType(userActions.editUser),
    switchMap((action: {user?: User, path?: string}) => {
      return this.userService.put('user', `${action.user.Username}/${action.path}`, null, {id: action.user.Username})
        .then((user: User) => {
          this.snackbarService.open(`Account for ${action.user.Username} has been updated`);
          return userActions.editUserSuccess({user});
        })
        .catch((error) => {
          this.snackbarService.open(`There's been an error.  Account was not updated`);
          return userActions.editUserError({error: error.message});
        });
    })
  );

  @Effect({dispatch: true})
  deleteUser$  = this.actions$.pipe(
    ofType(userActions.deleteUser),
    switchMap((action: {user: User, routePath: string}) => {
      return this.userService.delete('user', `${action.user.Username}`, null, null)
        .then((deleteData) => {
          this.snackbarService.open(`Account for ${action.user.Username} has been deleted`);
          if (action.routePath) {
            this.router.navigate([action.routePath]);
          }
          return userActions.deleteUserSuccess({user: action.user});
        })
        .catch((error) => {
          this.snackbarService.open(`There's been an error.  Account was not deleted`);
          return userActions.deleteUserError({error: error.message});
        });
    })
  );

}
