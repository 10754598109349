import { BehaviorSubject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { MatOption } from '@angular/material/core';



export interface SelectOptionInterface {
  value: any;
  friendly: string;
}

export interface SelectOptionGroupInterface {
  name: string;
  options: SelectOptionInterface[];
}

export interface KeyValueInterface {
  [key: string]: string | number | boolean;
}

export interface ActionButton {
  label: string;
  iconName?: string;
  name: string;
  color: string;
  type: 'basic' | 'stroked';
  iconOnly?: boolean;
  pristineDisabled?: boolean;
  class?: string;
}

export interface ActionButtonInterface {
  flex: string;
  buttons: ActionButton[];
  menuButtons?: ActionButton[];
}

export interface FieldInterface {
  type?: 'text' | 'password' | 'email' | 'url' | 'tel' | 'datepicker';
  component: 'input-text' | 'input-datepicker' | 'input-textarea' | 'input-select' | 'input-checkbox' | 'input-select-group' | 'input-date-time' | 'input-radio-group';
  label: string;
  name: string;
  autocomplete?: string;
  flex: string;
  placeholder: string;
  defaultValue: string | string[] | boolean | Date[];
  groupOption?: any[];
  validators?: ValidatorFn[];
  required?: boolean;
  selectMultiple?: boolean;
  signalOnChanged?: boolean;
  options?: SelectOptionInterface[] | SelectOptionGroupInterface[];
  disabled?: boolean;
  hide?: boolean;
  showSearch?: boolean; // used for the search input to show
  showSelectAll?: boolean;
  showSearchFormControl?: UntypedFormControl;
  showClearButton?: boolean;
  rows?: number; // used for the input text height
  appearance?: string;
}

export const MY_NATIVE_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};



@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
  ]
})

export class FormBuilderComponent implements OnInit {

  @ViewChild('allSelected') private allSelected: MatOption;

  // title of the form
  @Input() title: string = null;

  // Input for the field configuration
  @Input() fieldDetails: FieldInterface[] = [];

  // Action emitter for all action in the form
  @Input() actionButtons: ActionButtonInterface = null;

  // Loader controller
  @Input() loading = false;

  // Error to display
  @Input() error: string = null;

  // subject for the loader since the form need to be disabled while loading.
  @Input() loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Output for actions
  @Output() actionEvents: EventEmitter<any> = new EventEmitter();
  @Input() forgotPasswordMessage: string = null;
  @Input() updateField: EventEmitter<any> = new EventEmitter<any>();
  @Input() pathValues: EventEmitter<any> = new EventEmitter();
  @Output() dateSelection = new EventEmitter();



  // Max moment: April 25 2018, 20:30
  max = new Date();
  datePickerVal = null;

  // Holder for the formGroup
  form: UntypedFormGroup;
  searchText = '';
  private searchTexts: any = {};

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.generateFormGroup();
    // subcribe to the updater
    this.updateField.subscribe(field => this.updatedFieldHelper(field));
    this.pathValues.subscribe(data => this.patchFormValue(data));
  }

  // receiving input from auto-complet components
  onTextInput(value, fieldName) {
    this.searchTexts[fieldName] = value;
  }


  private generateFormGroup() {
    this.form = this.fb.group({});
    for (let index = 0, len = this.fieldDetails.length; index < len; index++) {
      this.form.addControl(
        this.fieldDetails[index].name,
        new UntypedFormControl(
          { disabled: this.fieldDetails[index].disabled, value: this.fieldDetails[index].defaultValue },
          this.fieldDetails[index].validators
        )
      );
      // set the signal if needed.
      if (this.fieldDetails[index].signalOnChanged) {
        this.form.get(this.fieldDetails[index].name).valueChanges.subscribe(
          val => {
            // if (val.)
            this.actionEvents.emit({
              name: 'VALUE_CHANGED',
              data: {
                field: this.fieldDetails[index].name,
                value: val
              }
            });
          }
        );
      }

      if (this.fieldDetails[index].showSearchFormControl) {
        this.fieldDetails[index].showSearchFormControl.valueChanges
          .subscribe(val => {
            this.onTextInput(val, this.fieldDetails[index].name);
          });
      }
    }
    // Subscribe to the changes of the loader.
    this.loadingSubject.subscribe(loading => {
      // this.loading = loading;
      if (loading) {
        this.form.disable();
      } else {
        setTimeout(() => {
          const controls = this.form.controls;
          for (const control in controls) {
            if (!this.fieldDetails.find(item => item.name === control).disabled) {
              this.form.controls[control].enable();
            }
          }
        });
      }
    });

    // emit the status of the form
    this.form.valueChanges.subscribe((val) => this.actionEvents.emit({ name: 'FORM_STATUS', value: this.form.valid }));
  }

  private patchFormValue(dataValue): void {
    this.form.patchValue(dataValue);
  }

  private updatedFieldHelper(field) {
    // find the index of the fields
    const fieldIndex = this.fieldDetails.findIndex(f => f.name === field.name);
    if (fieldIndex >= 0) {
      this.fieldDetails[fieldIndex] = field;

      // check if the field option are present and enable and disabled it as needed.
      if (field.groupOption && field.groupOption.length === 0) {
        this.form.get(field.name).disable();
      } else {
        this.form.get(field.name).enable();
      }
    }
  }

  onActionClick(name) {
    if (name === 'SUBMIT') {
      if (this.form.valid) {
        this.actionEvents.emit({ name, data: this.form.value });
      }
    } else {
      this.actionEvents.emit({ name, data: this.form.value });
    }
  }

  handleDisabling(button: ActionButton): boolean {
    return button.name !== 'CANCEL' && (this.form.invalid || this.loading) || (this.form.pristine && button.pristineDisabled);
  }

  // function used to filter values from the input select
  filterField(items: any[], fieldName: string): any[] {
    // check to see if the field name is part of the searchable text
    if (this.searchTexts.hasOwnProperty(fieldName) && this.searchTexts[fieldName].trim() !== '') {
      return _.filter(items, (item: any) => {
        return JSON.stringify(item)?.toLowerCase().includes(this.searchTexts[fieldName]?.toLowerCase());
      });
    }
    return items;
  }

  toggleAllOptions(controlName: string, fieldOptions: any[], event): void {
    event.preventDefault();
    const control = this.form.controls[controlName];
    const allOptions = fieldOptions.map(option => option.value);
    if (control.value.length === allOptions.length) {
      this.form.controls[controlName].patchValue([]);
    } else {
      this.form.controls[controlName].patchValue(allOptions);
    }
  }

  handleDateSelection(selection: any) {

  }

  handleChecked(controlName, fieldOptons) {
    const value = this.form.controls[controlName].value;
    return value.length === fieldOptons.length;
  }

  clear(value) {
    if (value.defaultValue) {
      this.form.controls[value.name].setValue([]);
    } else {
      this.form.controls[value.name].reset();
    }
  }


  //toggle all the option
  toggleAllSelection(controlName: string, fieldOptions: any[]) {
    // const allOptions = fieldOptions.map(option => option.value); = fieldOptions.map(option => option.value);
    const allOptions = fieldOptions;
    if (this.allSelected.selected) {
      this.form.controls[controlName].patchValue([...allOptions.map(item => item.value), 0]);
    } else {
      this.form.controls[controlName].patchValue([]);
    }
  }

  // when select all is select and user select one untoggle all
  toggleOne(controlName, all, fieldOptions) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.form.controls[controlName].value.length === fieldOptions.length)
      this.allSelected.select();

  }

  updateDatePicker(payload: any) {
    const dPicker = this.fieldDetails.find(fd => fd.component === 'input-datepicker');
    const dPickerFC = this.form.get(dPicker?.name);
    const val = { from: payload.from, to: payload.to }
    dPickerFC.setValue(val);
    this.dateSelection.emit(payload)
  }

}
