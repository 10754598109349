import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OWL_DATE_TIME_FORMATS} from 'ng-pick-datetime';
import {MY_NATIVE_FORMATS, SelectOptionInterface} from '../../../form-builder/form-builder.component';
import {DateService} from '../../../../../shared-services/date.service';
import {Store} from '@ngrx/store';
import {State} from '../../../../../reducers';
import * as dashboardActions from '../../../../../pages/dashboards/store/dashboard.actions';
import {filter, take} from 'rxjs/operators';

@Component({
  selector: 'app-widget-form',
  templateUrl: './widget-form.component.html',
  styleUrls: ['./widget-form.component.scss'],
  providers: [
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS},
  ]
})
export class WidgetFormComponent implements OnInit {
  widgetForm: UntypedFormGroup;
  widgetFormStatus = false;
  lineWidgetForm: UntypedFormGroup;
  widgetType: string = null;
  checkBoxValue: boolean;
  dashboardOptions: SelectOptionInterface[];
  @Input() widgetDateRange: string;
  @Input() eventDateRange: any;
  @Input() loading: boolean;
  @Input() categoryField: any[] = [];
  @Input() actions: any;
  @Input() includeDashboardOptions: boolean;
  @Input() query: string;
  @Output() createWidgetEvent: EventEmitter<any> = new EventEmitter();

  // Max moment: April 25 2018, 20:30
  max = new Date();
  widgetTypes: any[] = [
    {value: 'line', friendly: 'Line'},
    {value: 'pie', friendly: 'Pie'},
    {value: 'bar', friendly: 'Bar'},
    {value: 'single_metric', friendly: 'Single Metric', notProdReady: true},
    {value: 'solid_gauge', friendly: 'Solid Gauge', notProdReady: true}
  ];
  private widgetVal: any = {};

  constructor(private fb: UntypedFormBuilder, private dateRangeService: DateService, private store: Store<State>) { }

  ngOnInit() {
    this.widgetForm = this.fb.group({
      dashboard: ['', this.requiredIfDashboardIncluded.bind(this)],
      title: ['', Validators.required],
      widgetType: ['', Validators.required],
      description: [''],
      dateFrom: [''],
      dateTo: ['']
    });
    this.checkBoxValue = true;

    if (this.includeDashboardOptions) {
      this.store.dispatch(dashboardActions.loadDashboard());
      this.store.select(state => state.dashboard.dashboards)
      .pipe(filter(val => !!val), take(1))
      .subscribe(dashboards => {
        this.dashboardOptions = dashboards.map((dashboard) => {
          return {
            value: dashboard.dashboard_id,
            friendly: dashboard.title
          };
        });
      });
    }

    this.widgetForm.valueChanges.subscribe(val => {
      if (val.hasOwnProperty('widgetType')) {
        this.widgetType = val.widgetType;
      }
    });
    const dateRange = this.dateRangeService.dataRange(this.widgetDateRange, this.eventDateRange);
    this.widgetForm.patchValue({
        dateFrom:  dateRange.dateFrom,
        dateTo: dateRange.dateTo
      });
  }

  requiredIfDashboardIncluded(formControl: UntypedFormControl) {
    if (!formControl.parent) {
      return null;
    }
    if (this.includeDashboardOptions) {
      return Validators.required(formControl);
    }
  }

  formHandler(event) {
    this.lineWidgetForm = event;
  }

  onSubmit(value: string) {
    this.createWidgetEvent.emit({name: value, data: Object.assign(this.widgetForm.value, this.widgetVal)});
  }

  actionHandler(event) {
    if (event.name === 'VALUE_CHANGED' && event.data.value) {
      this.widgetVal[event.data.field] = event.data.value;
   } else if (event.name === 'FORM_STATUS') {
      this.widgetFormStatus = event.value;
    } else if (event.name === 'singleGaugeFields') {
      this.widgetFormStatus = event.formStatus;
    }
  }
}
