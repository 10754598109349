<form [formGroup]='form' class='maya-container' [ngClass]="{'stacked': stacked}">
  <div class='input-text'>
    <mat-form-field appearance="outline">
      <mat-label>Start</mat-label>
      <input matInput placeholder="Start" formControlName='start'>
    </mat-form-field>
    <button mat-raised-button [disabled]='form.get("start").invalid' (click)='validate("start")'>Validate</button>
    <div *ngIf='startDateTime' class='sample-date'>
      <strong>{{startDateTime | date : 'EE d MMM y h:mm:ss a z'}}</strong>
    </div>
  </div>

  <div class='input-text'>
    <mat-form-field appearance="outline">
      <mat-label>End</mat-label>
      <input matInput placeholder="End" formControlName='end'>
    </mat-form-field>
    <button mat-raised-button [disabled]='form.get("end").invalid' (click)='validate("end")'>Validate</button>
    <div *ngIf='endDateTime' class='sample-date'>
      <strong>{{endDateTime | date : 'EE d MMM y h:mm:ss a z'}}</strong>
    </div>
  </div>
</form>
