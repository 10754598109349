import { createAction, props } from '@ngrx/store';
import { StoreEntryFunction } from '../models';

export const execAction = createAction(
  '[Store] Execute Action',
  props<{kind: string, payload: any}>()
);

export const fetchData = createAction(
  '[Store] Fetch Data',
  props<{key: string, payload: StoreEntryFunction}>()
);

export const putData = createAction(
  '[Store] Put Data',
  props<{key: string, data: StoreEntryFunction | any}>()
);

export const removeData = createAction(
  '[Store] Delete Data',
  props<{ key: string }>()
)

export const putError = createAction(
  '[Store] Put Error',
  props<{key: string, error: Error}>()
);

export const removeError = createAction(
  '[Store] Delete Error',
  props<{ key: string }>()
);


export const putStatus = createAction(
  '[Store] Put Status',
  props<{key: string, status: any}>()
);

export const removeStatus = createAction(
  '[Store] Delete Data',
  props<{ key: string }>()
);