import { createAction, props } from '@ngrx/store';
import { Company } from './company.model';

export const loadCompanies = createAction(
  '[Company/API] Load Companies'
);

export const loadCompaniesSuccess = createAction(
  '[Company/API] Load Companies Success',
  props<{companies: Company[]}>()
);

export const loadCompaniesError = createAction(
  '[Company/API] Load Companies Error',
  props<{error: string}>()
);

export const editCompany = createAction(
  '[Company/API] Edit Company',
  props<{company: Company}>()
);

export const editCompanySuccess = createAction(
  '[Company/API] Edit Company',
  props<{company: Company}>()
);

export const editCompanyError = createAction(
  '[Company/API] Load Companies Error',
  props<{error: string}>()
);

export const createCompanySuccess = createAction(
  '[Company/API] Create Company Success',
  props<{company: Company}>()
);

export const createCompanyError = createAction(
  '[Company/API] Create Company Error',
  props<{error: string}>()
);

export const clearCompanyStore = createAction(
  '[Company/API] Clear Companies Store'
);
