<app-portal-loader
        [loadingStatus]="indexesOptions.length === 0 || loading"
></app-portal-loader>
<div class="dialog-wrapper">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <h2 mat-dialog-title>{{ data.title }}</h2>
        </div>
    </div>

    <mat-dialog-content>
        <p class="error" *ngIf="errorMessage">
            {{ errorMessage }}. Please try again later
        </p>
        <mat-horizontal-stepper
                linear="true"
                #stepper
                *ngIf="indexesOptions.length > 0"
        >
            <!-- Step 1 - Widget Title, Description, Type, Source, Index -->
            <mat-step
                    [stepControl]="generalInformationForm"
                    [editable]="!loading"
                    [completed]="
          !data.isNew ||
          (generalInformationForm.valid && generalStep.interacted)
        "
                    #generalStep
            >
                <ng-template matStepLabel>General</ng-template>
                <form [formGroup]="generalInformationForm" class="layout-padding">
                    <formly-form
                            [model]="generalInformationModel"
                            [fields]="generalInformationFields"
                            [form]="generalInformationForm"
                    ></formly-form>
                </form>
                <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
                    <button mat-button color="warn" matDialogClose>Cancel</button>
                    <button
                            mat-stroked-button
                            matStepperNext
                            [disabled]="!generalInformationForm.valid">
                        Next
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
                </mat-dialog-actions>
            </mat-step>

            <!--      Step 2 - Field selection. From field type, name, quantifiers, etc.-->
            <mat-step
                    [stepControl]="dropdownWidgetQueryForm"
                    [editable]="!loading"
                    [completed]="!data.isNew || (dropdownWidgetQueryForm.valid && fieldsStep.interacted)" #fieldsStep>
                <ng-template matStepLabel>Field(s)</ng-template>
                <form [formGroup]="dropdownWidgetQueryForm" class="layout-padding">
                    <p>Select the field(s) for your widget.</p>
                    <p>The first field selected will be the first column shown.  The second field selected will be the second column shown...etc (left to right).  Order to your preference.</p>
                    <formly-form
                            [model]="dropdownWidgetQueryModel"
                            [fields]="dropdownWidgetQueryFields"
                            [form]="dropdownWidgetQueryForm"
                    ></formly-form>
                </form>
                <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
                    <button mat-button color="warn" matDialogClose>Cancel</button>
                    <button mat-stroked-button matStepperPrevious>Prev</button>
                    <button mat-stroked-button matStepperNext>Next</button>
                </mat-dialog-actions>
            </mat-step>

            <!--      Step 3 - Final step, Custom Query-->
            <mat-step [stepControl]="queryInputForm" [editable]="!loading"
                      [completed]="!data.isNew || (queryInputForm.valid && resultsStep.interacted)" #resultsStep>
                <ng-template matStepLabel>Query</ng-template>
                <form [formGroup]="queryInputForm" class="layout-padding"
                      *ngIf="!['text_input'].includes(generalInfoType) && generalInformationForm.value.source !== 'list'">
                    <formly-form
                            [model]="resultInformationModel"
                            [fields]="queryInputField"
                            [form]="queryInputForm"
                    ></formly-form>
                </form>

                <!-- Template for the result step -->
                <template #resultContainer></template>

                <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
                    <button mat-button color="warn" matDialogClose>Cancel</button>
                    <button mat-stroked-button matStepperPrevious>Prev</button>
                    <button
                            mat-raised-button
                            color="primary"
                            (click)="createWidget()"
                            [disabled]="loading || !canCreate"
                    >
                        Submit
                    </button>
                </mat-dialog-actions>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-dialog-content>
</div>
