import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {WidgetModel} from "../../../models/widget.model";

@Component({
  selector: 'app-widget-info',
  templateUrl: './widget-info.component.html',
  styleUrls: ['./widget-info.component.scss']
})
export class WidgetInfoComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public widget: WidgetModel) {
  }

  ngOnInit(): void {
  }

}
