import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PermissionService} from '../sweet-shared/services/permission.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuardService implements CanActivate {

  constructor(private router: Router, private permissionService: PermissionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const requiredPermission = route.data.requiredPermission;
    const anyPermission = route.data.any;
    if (this.permissionService.hasPermission(requiredPermission, anyPermission) && !environment.hiddenFromProduction.includes(route.routeConfig.path)) {
      return true;
    }
    return false;
  }
}
