import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { UserService } from '@modules/user/services/user.service';
import { SnackbarService } from '@shared-services/snackbar.service';
import { ExternalUser } from '@modules/user/interfaces/external-user';
import { AddExternalUserComponent } from '@app/pages/admin/components/add-external-user/add-external-user.component';
import { ConfirmationDialogComponent } from '@sweet-shared/components/confirmation-dialog/confirmation-dialog.component';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-external-accounts-detail',
  templateUrl: './external-accounts-detail.component.html',
  styleUrls: ['./external-accounts-detail.component.scss']
})
export class ExternalAccountsDetailComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<boolean> = new Subject();
  private externalAccID: null;
  private domain: null;
  private realm: null;

  // user details
  loading = true;
  user: ExternalUser = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private snackbarService: SnackbarService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (!params.id && !params.domain && !params.realm) {
        this.router.navigate(['/admin/external-users']);
      } else {
        this.externalAccID = params.id;
        this.domain = params.domain;
        this.realm = params.realm;
        this.setupPage();
      }
    });
  }

  private setupPage(): void {
    this.loading = true;
    this.userService.fetchExternalUserDetails(this.domain, this.realm, this.externalAccID)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe({
        next: (user: ExternalUser) => {
          this.user = user;
        },
        error: (error) => {
          console.log('error is', error);
        }
      });
  }

  onEditUser() {
    this.dialog.open(AddExternalUserComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'Edit User',
        loggedUser: this.user
      }
    });
  }

  onDeleteUser() {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'ctl-panel-class',
    });
    confirmationDialog.componentInstance.title = 'Confirmation';
    confirmationDialog.componentInstance.message = 'Are you sure you would like to delete this user?';
    confirmationDialog.afterClosed().subscribe((val: boolean) => {
      if (val) {
        this.userService.deleteExternalUser(this.user.domain, this.user.realm, this.user.username)
          .pipe(
            untilDestroyed(this)
            )
          .subscribe({
            next: () => this.router.navigate(['/admin', 'external-users']),
            error: (error) => this.snackbarService.open(`Could not delete user: ${error.message}`)
          });
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
