import {Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../store/profile.model';

@Component({
  selector: 'app-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss']
})
export class UserDisplayComponent implements OnInit {
  @Input() profile: Profile = null;
  constructor() { }

  ngOnInit() {
  }

}
