import { Injectable } from '@angular/core';
import {AppService} from '../../../app.service';
import {BehaviorSubject, Observable} from 'rxjs';
import { Router } from '@angular/router';

export interface ExternalAccount {
  user_id: string;
  family_name: string;
  given_name: string;
  gender: string[];
  profile: string;
  realm: string;
  domain: string;
  username: string;
  access_attributes: string[];
  allowed_permissions: string[];
  blocked_permissions: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ExternalAccountService {
  private userExternalAccountData: BehaviorSubject<ExternalAccount[]> = new BehaviorSubject<ExternalAccount[]>([]);
  private loadingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private error: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private putResponse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingStatusPut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private systemPermissionSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private systemPermissionErrorSubject: BehaviorSubject<string> = new BehaviorSubject(null);

  private loadingUserDetailsSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loadingUserDetailsErrorSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  private userDetailsSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  private updatingUserDetailsSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private updatingUserDetailsErrorSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  private doneUpdatingUserSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private userPermissionsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private userPermissionsErrorSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  private userPermissionsLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);


  constructor(private appService: AppService<any>) { }

  get userPermissions(): Observable<any[]> {
    return this.userPermissionsSubject.asObservable();
  }

  get UserPermissionsLoading(): Observable<boolean> {
    return this.userPermissionsLoadingSubject.asObservable();
  }

  get userPermissionsError(): Observable<string> {
    return this.userPermissionsErrorSubject.asObservable();
  }

  get loadingUserDetails(): Observable<boolean> {
    return this.loadingUserDetailsSubject.asObservable();
  }

  get loadingUserDetailsError(): Observable<string> {
    return this.loadingUserDetailsErrorSubject.asObservable();
  }

  get userDetails(): Observable<any> {
    return this.userDetailsSubject.asObservable();
  }

  get loadingExternalUser(): Observable<boolean> {
    return this.loadingStatus.asObservable();
  }

  get loadingExternalUserError(): Observable<string> {
    return this.error.asObservable();
  }

  get externalUsers(): Observable<ExternalAccount[]> {
    return this.userExternalAccountData.asObservable();
  }

  get systemPermissions(): Observable<any[]> {
    return this.systemPermissionSubject.asObservable();
  }

  get systemPermissionError(): Observable<string> {
    return this.systemPermissionErrorSubject.asObservable();
  }

  get updatingUser(): Observable<boolean> {
    return this.updatingUserDetailsSubject.asObservable();
  }

  get updatingUserError(): Observable<string> {
    return this.updatingUserDetailsErrorSubject.asObservable();
  }

  get doneUpdatingUser(): Observable<boolean> {
    return this.doneUpdatingUserSubject.asObservable();
  }


  fetchUserPermissions() {
    this.userPermissionsLoadingSubject.next(true);
    this.userPermissionsErrorSubject.next(null);
    this.appService.get('permissionRoles', '', null)
      .then((response: any[]) => {
        const roleBasePermissions = response.reduce((prev: any, next: any) => {
          prev[next.name] = next.permissions;
          return prev;
        }, {})
        this.userPermissionsSubject.next(roleBasePermissions);
      })
      .catch(error => this.userPermissionsErrorSubject.next(error.message))
      .finally(() => this.userPermissionsLoadingSubject.next(false));
  }

  fetchSystemPermissions(): void {
    this.appService.get('granularPermissions', '', null)
      .then((res) => {
        const systemPermission = Object.keys(res).reduce((prev, next) => {
          prev.push({
            friendly: res[next].friendly,
            description: res[next].description,
            actions: res[next].actions,
            key: next
          });
          return prev;
        }, []);
        this.systemPermissionSubject.next(systemPermission);
      })
      .catch((error) => this.systemPermissionErrorSubject.next(error.message));
  }

  fetchUserDetails(domain: string, realm: string, username: string): void {
    this.doneUpdatingUserSubject.next(false);
    this.loadingUserDetailsSubject.next(true);
    this.appService.get('external-account', `/${domain}/${realm}/${username}`, null)
      .then((userDetails: any) => {
        userDetails.gender = userDetails.gender.split(',').map((role: string) => role.charAt(0).toUpperCase() + role.slice(1))
        this.userDetailsSubject.next(userDetails);
        this.loadingUserDetailsSubject.next(false);
      })
      .catch((error: any) => {
        this.loadingUserDetailsErrorSubject.next(error.message);
        this.loadingUserDetailsSubject.next(false);
      }
    )
  }

  updateUser(user: any): void {
    this.updatingUserDetailsSubject.next(true);
    this.updatingUserDetailsErrorSubject.next(null);
    user.gender = user.gender.join(',')
    this.appService.put('external-account', `/${user.domain}/${user.realm}/${user.username}`, null, user)
      .then(user => {
        this.userDetailsSubject.next(user);
        this.updatingUserDetailsSubject.next(false);
        this.doneUpdatingUserSubject.next(true);
      })
      .catch((error) => {
        this.updatingUserDetailsErrorSubject.next(error.message);
        this.updatingUserDetailsSubject.next(false);
      })
  }

  getExternalUserAccount() {
    return this.userExternalAccountData;
  }

  getExternalAccountError() {
    return this.error;
  }

  getExternalUserAccountLoader(): Observable<boolean> {
    return this.loadingStatus.asObservable();
  }

  externalUserAccount(params: any) {
    this.loadingStatus.next(true);
    this.appService.get('external-account', ``, params)
      .then((value => {
        this.loadingStatus.next(false);
        this.userExternalAccountData.next(value);
      }))
      .catch(error => {
        this.error.next(error.message)
        this.loadingStatus.next(false);
      });
  }

  createExternalUserAccount(body: ExternalAccount) {
    this.loadingStatus.next(true);
    return this.appService.post('external-account', ``, null, body);
  }

  updateExternalUserAccount(body: ExternalAccount, putUrl: string) {
    this.loadingStatusPut.next(true);
    this.appService.put('external-account', putUrl, null, body)
      .then((value => {
        this.putResponse.next(true)
        this.loadingStatusPut.next(false);
      }))
      .catch(error => {
        this.error.next(error.message)
        this.loadingStatusPut.next(false);
      });
  }

  deleteUser(domain: string, realm: string, username: string): Promise<any> {
    return this.appService.delete('external-account', `/${domain}/${realm}/${username}`, null, null)
  }

}
