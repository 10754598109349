<mat-form-field>
  <mat-select [formControl]="formControl">
    <mat-option>
      <ngx-mat-select-search *ngIf="!!to?.search" noEntriesFoundLabel="{{to?.noResult || 'No results found'}}" [formControl]="searchCtrl" placeholderLabel="{{to?.searchLabel}}"  [preventHomeEndKeyPropagation]="true">
        <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let option of to?.options | async | inputSearch:searchCtrl?.value" [value]="option?.value">
      {{option.label}}
    </mat-option>
  </mat-select>
</mat-form-field>