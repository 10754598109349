import { Validators } from '@angular/forms';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { State } from '@app/reducers';
import { AppService } from '@app/app.service';
import { DateService } from '@shared-services/date.service';
import { WidgetModel } from '@sweet-shared/models/widget.model';
import { SnackbarService } from '@shared-services/snackbar.service';
import { AuthService } from '@app/pages/auth/services/auth.service';
import { Dashboard } from '@app/pages/dashboards/store/dashboard.model';
import * as dashboardActions from '@app/pages/dashboards/store/dashboard.actions';
import { CreateReportFormValueModel, ReportModel, ReportRateEnum } from '@app/pages/reporting/report.model';
import { CreateReportComponent } from '@app/pages/reporting/components/create-report/create-report.component';
import { FieldInterface, FormBuilderComponent } from '@sweet-shared/components/form-builder/form-builder.component';
import { SettingWidgetFormComponent } from '@app/pages/dashboards/dashboard-detail-page/components/setting-widget-form/setting-widget-form.component';
import { Subject } from 'rxjs';
import { WidgetService } from '@sweet-shared/services/widget-service.service';
import {IncidentTableWidgetComponent} from '@app/pages/dashboards/dashboard-detail-page/components/incident-table-widget/incident-table-widget.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  toRefresh$ = new Subject();

  constructor(
    private dialog: MatDialog,
    private store: Store<State>,
    private dashboardAppService: AppService<Dashboard>,
    private reportAppService: AppService<ReportModel>,
    private widgetsAppService: AppService<WidgetModel>,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private dateService: DateService,
    public widgetService: WidgetService
  ) { }

  deleteWidget(widget: WidgetModel, emitter: EventEmitter<any>) {
    // ask the user to confirm the deletion of the widget before poceeding
    const dialogRef = this.dialog.open(FormBuilderComponent, {
      minWidth: '300px',
      minHeight: '100px',
      disableClose: true,
    });

    dialogRef.componentInstance.actionButtons = {
      flex: 'end center',
      buttons: [
        {
          label: 'No',
          iconName: null,
          name: 'No',
          color: 'warn',
          type: 'basic',
        },
        {
          label: 'Yes',
          iconName: null,
          name: 'Yes',
          color: 'primary',
          type: 'stroked',
        },
      ],
    };
    dialogRef.componentInstance.title = `Are you sure you would like to delete this ${widget.type === 'dropdown' ? 'filter' : 'widget'}?`;
    dialogRef.componentInstance.loading = false;

    dialogRef.componentInstance.actionEvents.subscribe((evt) => {
      switch (evt.name) {
        case 'Yes':
          dialogRef.componentInstance.loading = true;
          this.widgetsAppService
            .delete('widgets', `${widget.dashboard_id}/${widget.widget_id}`, null, null)
            .then((res) => {
              this.snackbarService.open('Widget deleted from Dashboard');
              dialogRef.close();
              emitter.emit({
                name: 'DELETED_WIDGET',
                widgetId: widget.widget_id,
                widgetType: widget.type
              });
            })
            .catch((error) => {
              this.snackbarService.open('Unable to delete widget at this time.');
            });
          break;
        case 'No':
          dialogRef.close();
          break;
      }
    });
  }


  editWidget(widget: WidgetModel, parentDashboard: Dashboard, emitter: EventEmitter<any>) {
    let inputVariables;
    let queryVariable;
    let filterInformationModel;

    if (widget.input_variables?.length) {
      inputVariables = widget.input_variables.filter(input => {
        return parentDashboard.widgets.find(w => w.params.inputName === input.input_variable_name && (w.type === 'dropdown' || w.type === 'string_dropdown')
        );
      });
      queryVariable = widget.input_variables.find(input => {
        return parentDashboard.widgets.find(w => input.input_variable_name === w.params.variable_name && w.type === 'query_builder');
      });
    }

    if (typeof widget?.params?.athenaStatement?.standard[0]?.where_statement.filter === 'string') {
      filterInformationModel = queryVariable.default_value;
    } else {
      filterInformationModel = widget?.params?.athenaStatement?.standard[0]?.where_statement.filter;
    }

    const data = {
      title: 'Edit Widget / Filter',
      isNew: false,
      settingWidgets: parentDashboard.widgets.filter(w => w.type === 'dropdown' || w.type === 'string_dropdown'),
      queryWidgets: parentDashboard.widgets.filter(w => w.type === 'query_builder'),
      defaultModels: {
        generalInformationModel: {
          title: widget.title,
          description: widget.description,
          source: widget.source,
          type: widget.type,
          index: widget.index_name
        },
        dateRanges: {
          from: new Date(parseInt(widget.params.athenaStatement?.standard[0].where_statement.time.start, 10) * 1000),
          to: new Date(parseInt(widget.params.athenaStatement?.standard[0].where_statement.time.end, 10) * 1000),
        },
        selectFormModel: widget?.params?.athenaStatement?.standard[0]?.select_statement,
        showInputVariables: !!inputVariables?.length,
        inputVariablesModel: inputVariables,
        showQueryVariables: !!queryVariable,
        queryVariablesModel: { queryVariables: queryVariable?.input_variable_name || null },
        filterInformationModel,
        groupingInformationModel: widget?.params?.athenaStatement?.standard[0]?.group_by_statement || [],
        orderByInformationModel: widget?.params?.athenaStatement?.order_by || [],
        resultInformationModel: {
          variableName: widget.params?.inputName,
          limit: !!widget.params.athenaStatement?.limit,
          limitValue: widget.params.athenaStatement?.limit || []
        }
      },
    };
    if (['bar', 'multi_bar', 'line', 'multi_line'].includes(data.defaultModels.generalInformationModel.type)) {
      (data.defaultModels.generalInformationModel as any).treat_empty_values_as = widget.treat_empty_values_as;
    }
    const dialogRef = this.dialog.open(SettingWidgetFormComponent, {
      width: '90%',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data
    });

    dialogRef.componentInstance.crupdate.pipe(take(1)).subscribe(editWidgetData => {
      dialogRef.componentInstance.loading = true;
      const widgetData = {
        ...widget,
        ...editWidgetData.data
      };
      this.widgetsAppService.put('widgets', `${widget.dashboard_id}/${widget.widget_id}`, null, widgetData)
        .then((response: any) => {
          // Let listener know to update widget and refresh
          emitter.emit({
            name: 'EDITED_WIDGET',
            widgetId: widget.widget_id,
            data: response
          });
          this.snackbarService.open('Widget successfully updated');
          this.toRefresh$.next(widget)
          dialogRef.close();
        })
        .catch((error: any) => {
          dialogRef.componentInstance.loading = false;
          this.snackbarService.open('Unable to save changes to widget');
        });
    });
  }

  editWidgetIncidentTable(widget: WidgetModel, parentDashboard: Dashboard, emitter: EventEmitter<any>) {
    let inputVariables;
    let queryVariable;
    let filterInformationModel;

    if (widget.input_variables?.length) {
      inputVariables = widget.input_variables.filter(input => {
        return parentDashboard.widgets.find(w => w.params.inputName === input.input_variable_name && (w.type === 'dropdown' || w.type === 'string_dropdown')
        );
      });
      queryVariable = widget.input_variables.find(input => {
        return parentDashboard.widgets.find(w => input.input_variable_name === w.params.variable_name && w.type === 'query_builder');
      });
    }

    if (typeof widget?.params?.athenaStatement?.standard[0]?.where_statement.filter === 'string') {
      filterInformationModel = queryVariable.default_value;
    } else {
      filterInformationModel = widget?.params?.athenaStatement?.standard[0]?.where_statement.filter;
    }

    const data = {
      title: 'Edit Widget / Filter',
      isNew: false,
      settingWidgets: parentDashboard.widgets.filter(w => w.type === 'dropdown' || w.type === 'string_dropdown'),
      queryWidgets: parentDashboard.widgets.filter(w => w.type === 'query_builder'),
      defaultModels: {
        generalInformationModel: {
          title: widget.title,
          description: widget.description,
          source: widget.source,
          type: widget.type,
          index: widget.index_name
        },
        dateRanges: {
          from: new Date(parseInt(widget.params.athenaStatement?.standard[0].where_statement.time.start, 10) * 1000),
          to: new Date(parseInt(widget.params.athenaStatement?.standard[0].where_statement.time.end, 10) * 1000),
        },
        selectFormModel: widget?.params?.athenaStatement?.standard[0]?.select_statement,
        showInputVariables: !!inputVariables?.length,
        inputVariablesModel: inputVariables,
        showQueryVariables: !!queryVariable,
        queryVariablesModel: { queryVariables: queryVariable?.input_variable_name || null },
        filterInformationModel,
        groupingInformationModel: widget?.params?.athenaStatement?.standard[0]?.group_by_statement || [],
        orderByInformationModel: widget?.params?.athenaStatement?.order_by || [],
        resultInformationModel: {
          variableName: widget.params?.inputName,
          limit: !!widget.params.athenaStatement?.limit,
          limitValue: widget.params.athenaStatement?.limit || []
        }
      },
    };

    const dialogRef = this.dialog.open(IncidentTableWidgetComponent, {
      width: '90%',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data
    });

    dialogRef.componentInstance.crupdate.pipe(take(1)).subscribe(editWidgetData => {
      dialogRef.componentInstance.loading = true;
      const widgetData = {
        ...widget,
        ...editWidgetData.data
      };
      this.widgetsAppService.put('widgets', `${widget.dashboard_id}/${widget.widget_id}`, null, widgetData)
          .then((response: any) => {
            // Let listener know to update widget and refresh
            emitter.emit({
              name: 'EDITED_WIDGET',
              widgetId: widget.widget_id,
              data: response
            });
            this.snackbarService.open('Widget successfully updated');
            this.toRefresh$.next(widget);
            dialogRef.close();
          })
          .catch((error: any) => {
            dialogRef.componentInstance.loading = false;
            this.snackbarService.open('Unable to save changes to widget');
          });
    });
  }



  // used to create a report
  createReportDialog(dashboardData, selectedCompany?: string) {
  }

  reportPayloadFactory(reportData: CreateReportFormValueModel): ReportModel | any {
    let dateFrom;
    let dateTo;
    if (reportData.rate === ReportRateEnum.ONCE) {
      // If 'once' dateFrom and dateTo must be the same
      dateFrom = dateTo = moment(reportData.onceDateTime).format();
    } else if (reportData.rate === ReportRateEnum.RANGE) {
      dateFrom = moment(reportData.rangeStartTime).format();
      dateTo = moment(reportData.rangeStartEndTime).format();
    }
    const customRange = { unit: reportData.customRangeUnit, value: reportData.customRangeValue };
    const dataRange = this.dateService.unitValueFormatter(reportData.data_range, customRange);
    const postData = {
      related_dashboard_id: reportData.related_dashboard_id,
      name: reportData.name,
      description: reportData.description,
      is_active: true,
      params: {
        company: reportData.company,
        schedule_rate: {
          dateFrom,
          dateTo
        },
        data_range: dataRange,
        frequency: reportData.cronValue || 'NO SCHEDULE',
      }
    };
    return postData;
  }

  // Create a report
  createReport(reportData: CreateReportFormValueModel, dialogRef: MatDialogRef<CreateReportComponent>, dateRanges, cron: string): void {

  }

  onDuplicate(dashboard: Dashboard): void {
    this.store.select(state => state.dashboard.dashboards)
      .pipe(filter(val => !!val), take(1))
      .subscribe(allDashboards => {
        const dialogRef = this.dialog.open(FormBuilderComponent, {
          width: '800px',
          disableClose: true,
          autoFocus: false,
          maxHeight: '90vh',
          panelClass: 'ctl-panel-class'
        });
        dialogRef.componentInstance.title = `Duplicate Dashboard`;
        dialogRef.componentInstance.actionButtons = {
          flex: 'end center',
          buttons: [
            {
              label: 'Cancel',
              iconName: null,
              name: 'CANCEL',
              color: 'warn',
              type: 'basic',
            },
            {
              label: 'Submit',
              iconName: null,
              name: 'SUBMIT',
              color: 'primary',
              type: 'stroked',
            },
          ],
        };
        const duplicatDashboardFields: FieldInterface[] = [
          {
            component: 'input-select',
            label: 'Dashboard Name',
            name: 'dashboard_id',
            placeholder: 'Dashboard Name',
            flex: '100',
            options: allDashboards.map(item => {
              return { value: item.dashboard_id, friendly: item.title };
            }),
            defaultValue: dashboard.dashboard_id,
            validators: [Validators.required]
          },
          {
            component: 'input-text',
            label: 'New Dashboard Title',
            name: 'title',
            placeholder: 'Enter Dashboard Title',
            flex: '100',
            defaultValue: '',
            validators: [Validators.required]
          },
          {
            component: 'input-textarea',
            label: 'New Dashboard Description',
            name: 'description',
            placeholder: 'Enter Dashboard Description',
            flex: '100',
            defaultValue: dashboard.description,
            validators: [Validators.required]
          }
        ];
        dialogRef.componentInstance.fieldDetails = duplicatDashboardFields;
        dialogRef.componentInstance.actionEvents.subscribe((evt) => {
          switch (evt.name) {
            case 'SUBMIT':
              dialogRef.componentInstance.loading = true;
              this.dashboardAppService.post('dashboard-duplicate', '', null, {
                dashboard_id: evt.data.dashboard_id,
                title: evt.data.title,
                description: evt.data.description
              }).then(res => {
                this.store.dispatch(dashboardActions.loadDashboardSuccess({ dashboards: [...allDashboards, res] }));
                this.snackbarService.open('Dashboard has been successfully duplicated');
                dialogRef.close();
              }).catch(err => {
                dialogRef.componentInstance.loading = false;
                this.snackbarService.open('Unable to duplicate dashboard. Please try again later.');
              });
              break;
            case 'CANCEL':
              dialogRef.close();
              break;
          }
        });
      });
  }

  commonWidgetPayload(data, dashboardId, user, userPrefDateRange, dateRanges) {
    return {
      dashboard_id: dashboardId,
      source: data.widgetSource,
      type: data.widgetType,
      description: data.description,
      title: data.title,
      params: {
        company: user.profile,
        interval: this.getCreateInterval(userPrefDateRange, dateRanges),
        query: data.query,
        field: data.fieldType,
        yAxisAction: data.yAxisAction,
        yAxisField: data.fieldType,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
      }
    };
  }

  piePayload(data, dashboardId, user, userPrefDateRange, dateRanges) {
    return {
      dashboard_id: dashboardId,
      source: data.source,
      type: data.widgetType,
      description: data.description,
      title: data.title,
      params: {
        ordering: data.ordering,
        sliceCount: data.sliceCount,
        alias: data.alias,
        company: user.profile,
        interval: this.getCreateInterval(userPrefDateRange, dateRanges),
        field: data.fieldType,
        fieldTypeAction: data.yAxisAction,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
      },
    };
  }

  singleMetricPayload(data, dashboardId, user) {
    return {
      title: data.title,
      description: data.description,
      dashboard_id: dashboardId,
      source: data.widgetSource,
      type: data.widgetType,
      params: {
        company: user.profile,
        query: data.query || '*',
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        field: {
          name: data.fieldType.name,
          friendly: data.fieldType.friendly,
          type: data.fieldType.type,
          fx: data.MetricAction,
          fx_param: data.fx_param,
          cast_as: data.cast_as,
          alias: data.alias
        }
      }
    };
  }

  private getCreateInterval(userPreferenceDateRange, dateRanges) {
    if (userPreferenceDateRange.endsWith('year')) {
      return 'month';
    }
    if (userPreferenceDateRange.endsWith('week')) {
      return 'day';
    }
    if (userPreferenceDateRange.endsWith('month')) {
      return 'week';
    }
    if (userPreferenceDateRange.endsWith('day')) {
      return 'hour';
    }
    if (userPreferenceDateRange.endsWith('hour')) {
      return 'minute';
    } else {
      return dateRanges.find(v => v.name === userPreferenceDateRange).units;
    }
  }
}
