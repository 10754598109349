<mat-card appearance="outlined" class="layout-padding">
  <mat-card-title *ngIf="title">{{ title }}</mat-card-title>
  <mat-card-content>
    <div>{{ message }}</div>
  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button color="primary" (click)="redirect()">{{buttonText}}</button>
    <button mat-button [mat-dialog-close]='true'>No thanks</button>
  </mat-card-actions>
</mat-card>
