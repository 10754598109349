<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="dialog-wrapper">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <h2 mat-dialog-title>Create Widget</h2>

    </div>
    <div class="widget_image" *ngIf="widgetType === 'line'">
      <img src="../../../../../../assets/widget_picture/line_chart.png"/>
    </div>
    <div class="widget_image" *ngIf="widgetType === 'pie'">
      <img src="../../../../../../assets/widget_picture/pie_chart.png"/>
    </div>
    <div class="widget_image" *ngIf="widgetType === 'bar'">
      <img src="../../../../../../assets/widget_picture/bar_chart.png"/>
    </div>
  </div>

  <mat-dialog-content style="overflow: unset !important;">
    <form [formGroup]="widgetForm">
      <mat-form-field *ngIf="includeDashboardOptions" fxFlexFill>
        <mat-label>Dashboard</mat-label>
        <mat-select formControlName="dashboard" [required]="includeDashboardOptions">
          <mat-option *ngFor="let dashboard of dashboardOptions" [value]="dashboard.value">{{dashboard.friendly}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlexFill>
        <mat-label>Widget Title</mat-label>
        <input formControlName="title" matInput type="text" placeholder="Title" required>
      </mat-form-field>

      <mat-form-field fxFlexFill>
        <mat-label>Widget Type</mat-label>
        <mat-select formControlName='widgetType' required>
          <ng-container *ngFor="let field of widgetTypes">
            <ng-container *appNotProdReady="field.notProdReady">
              <mat-option [value]="field.value">{{field.friendly}}</mat-option>
            </ng-container>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlexFill>
        <mat-label>Description</mat-label>
        <input formControlName="description" matInput type="text" placeholder="Description">
      </mat-form-field>

      <app-line-create-form *ngIf="widgetType === 'line'" (actionEvents)="actionHandler($event)"
                            (lineFormEvents)="formHandler($event)"
                            [actions]="actions"
                            [categoryField]="categoryField"
                            [query]="query"
      ></app-line-create-form>
      <app-pie-create-form *ngIf="widgetType === 'pie'" [actions]="actions"
                           (actionEvents)="actionHandler($event)"
                           [categoryField]="categoryField"
                           (pieFormEvents)="formHandler($event)"
      ></app-pie-create-form>
      <app-bar-create-form *ngIf="widgetType === 'bar'" (actionEvents)="actionHandler($event)"
                           (barFormEvents)="formHandler($event)"
                           [actions]="actions"
                           [categoryField]="categoryField"
                           [query]="query"
      ></app-bar-create-form>
      <app-single-metric-create-form
        *ngIf="widgetType === 'single_metric'"
        (actionEvents)="actionHandler($event)"
        (singleMetricFormEvents)="formHandler($event)"
        [actions]="actions"
        [categoryField]="categoryField"
        [query]="query"
      >
      </app-single-metric-create-form>
      <app-single-gauge-form
        *ngIf="widgetType === 'solid_gauge'"
        [actions]="actions"
        [categoryField]="categoryField"
        (singleGaugeFormEvents)="actionHandler($event)"
      ></app-single-gauge-form>
      <mat-checkbox [checked]="checkBoxValue">
        Limit results for speed optimization.
      </mat-checkbox>

    </form>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
      <button mat-button color="warn" (click)="onSubmit('cancel')">Cancel</button>
      <button mat-raised-button color="primary" (click)="onSubmit('submit')"
              [disabled]="!widgetFormStatus || widgetForm.invalid || loading">Submit
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
