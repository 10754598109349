import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentFormComponent } from './incident-form/incident-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { WorklogFormComponent } from './worklog-form/worklog-form.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {InputSearchPipe} from '@sweet-shared/pipes/input-search.pipe';
import {SweetSharedModule} from '@sweet-shared/sweet-shared.module';



@NgModule({
  declarations: [
    IncidentFormComponent,
    WorklogFormComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        SweetSharedModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatButtonModule,
        NgxMatSelectSearchModule
    ],
  exports: [
    IncidentFormComponent
  ]
})
export class IncidentModule { }
