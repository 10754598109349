import {AfterViewInit, Component, OnDestroy, EventEmitter, Output} from '@angular/core';
import {AppService} from '@app/app.service';
import {KbService} from '../../kb.service';
import {catchError} from 'rxjs/operators';

@Component({
    selector: 'app-knowledge-base-page',
    templateUrl: './knowledge-base-page.component.html',
    styleUrls: ['./knowledge-base-page.component.scss'],
})
export class KnowledgeBasePageComponent implements AfterViewInit, OnDestroy {

    @Output() actionEvents: EventEmitter<any> = new EventEmitter();
    searchQuery = '';
    hitList = null;
    hitListLen = 0;
    hitListKeywords = [];
    templatesLoaded = true;
    mainArticle = null;

    constructor(private knowledgeBaseService: AppService<any>) {

    }

    ngAfterViewInit(): void {
    }

    onSendInitQuery(keyWordSearch?) {
        // Clear main article
        this.destroyMainArticle();

        // Check to see if a query has been submitted, if not, kick back and do nothing
        try {
            if (keyWordSearch === undefined && this.searchQuery === '') {
                // Clear results and reset page
                this.hitListKeywords = null;
                this.hitList = null;
                return;
            } else if (keyWordSearch !== undefined) {
                // Clear array
                this.hitListKeywords = [];
                // Search using predefined keywords
                // this.hitList = this.kbService.generateListOfArticles(keyWordSearch);
                this.knowledgeBaseService.get('kb', '', {query: keyWordSearch}).then((res) => {
                    this.hitList = res;
                    this.hitListLen = this.hitList.length;
                } );
                // Modified list of keywords to show user
                this.hitListKeywords.push(keyWordSearch);
            } else if (this.searchQuery !== '') {
                // Search using custom keywords
                this.knowledgeBaseService.get('kb', '', {query: keyWordSearch}).then((res) => {
                    this.hitList = res; console.log(res);
                    this.hitListLen = this.hitList.length;
                } );
            } else {
                return;
            }
        } catch (e) {
            console.log(e);
        }
    }

    loadMainArticle(articleTitle) {
        this.knowledgeBaseService.get('kb', articleTitle, null ).then((res) => {
           this.mainArticle = res;
        });
    }

    destroyMainArticle(): void {
        this.hitListLen = 0;
        this.mainArticle = null;
    }

    onClose(val) {
        this.actionEvents.emit({name: val});
    }

    ngOnDestroy() {
    }
}
