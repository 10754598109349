<section>
  <app-portal-loader [loadingStatus]="!filterReady"></app-portal-loader>
  <div class="layout-padding">
    <button
      mat-stroked-button
      type="button"
      routerLink="/admin"
      class="admin_sections--back"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
      Back to Admin Page
    </button>
    <mat-card appearance="outlined" fxFill style="padding: inherit">
      <mat-card-title>Filters</mat-card-title>
      <mat-card-content>
        <app-form-builder
          *ngIf="userFilterDetails.length"
          [fieldDetails]="userFilterDetails"
          (actionEvents)="onCompanyChanged($event)"
          #usersForm
        >
        </app-form-builder>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" style="padding: inherit">
      <mat-card-title>User Accounts</mat-card-title>
      <mat-card-actions>
        <button
          *ifUserCan="'account.create'"
          mat-stroked-button
          color="primary"
          (click)="onAddUser()"
        >
          <mat-icon>add</mat-icon>
          <span>Create User</span>
        </button>
      </mat-card-actions>
    </mat-card>
    <br />
    <mat-card appearance="outlined">
      <app-table
        [data]="usersDataStream"
        [headerStream]="usersHeaders"
        [pageSize]="25"
        [showFilter]="true"
        [loading]="loading"
        #usersTable
        (events)="onClick($event)"
      >
      </app-table>
    </mat-card>
  </div>
</section>
