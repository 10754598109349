
<div class="app-layout">
  <div class="app-layout-ib">
    <div>
      <app-header [isAuthenticated]="isAuthenticated" [user]='user'></app-header>
    </div>
    <div class="body-content" [ngClass]="{'logged-out': !isAuthenticated}">
      <app-email-verification *ngIf="isAuthenticated"></app-email-verification>
      <app-portal-loader [loadingStatus]="config.loadingStatus"></app-portal-loader>
      <app-sidenav [isAuthenticated]="config.showSidenav" ></app-sidenav>
    </div>
  </div>
</div>

