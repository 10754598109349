<section class='table-widget'>
  <ng-container *ngIf="widgetDetail$ | async as widgetDetails">
    <app-widget-bar [disabled]='dataSource.data.length === 0' [title]="widgetDetails.title"
      (eventBus)="handleEvents($event)" enableDownload='true' [dataHeadings]='displayedColumns' [widgetType]='widgetDetails.type' [widgetSource]='widgetDetails.source'>
      <div dateRange>
        {{ dateRange?.from | date }} to {{ dateRange?.to | date }}
      </div>
    </app-widget-bar>
  </ng-container>

  <!-- table section -->
  <section class='table-content'>
    <table mat-table [dataSource]="dataSource" [fixedLayout]='false'>
      <ng-container [matColumnDef]="col" *ngFor='let col of displayedColumns'>
        <th mat-header-cell *matHeaderCellDef> {{ col }} </th>
        <td mat-cell *matCellDef="let element"> {{element[col]}} </td>
      </ng-container>

      <tr mat-header-row  *matHeaderRowDef="displayedColumns; sticky: true"
         [sticky]='true' ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- <ngx-loading [show]="true"></ngx-loading> -->
    <ng-container  *ngIf="loading$ | async; else errorTemplate">
      <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
        <div class="indicator-container">
          <ngx-loading [show]="true"></ngx-loading>
        </div>
      </div>
    </ng-container>
    <ng-template #errorTemplate>
      <p [ngStyle]="{'height.px': height * 0.9 }" class='error' *ngIf='error$ | async'>There has been an error. Please
        click <a class="refresh" (click)="fetchWidgetData()">here</a> to refresh, or try again later</p>
    </ng-template>

    <!-- pagination -->
    <mat-paginator [pageSizeOptions]="[25, 50, 100, 250, 500]" showFirstLastButtons></mat-paginator>
  </section>
</section>
