import { createAction, props } from '@ngrx/store';

export const loadReports = createAction(
  '[Report] Load Reports'
);

export const loadReportsSuccess = createAction(
  '[Report] Load Reports Success',
  props<{ reports: any[] }>()
);

export const loadReportsFailure = createAction(
  '[Report] Load Reports Failure',
  props<{ error: any }>()
);


export const createReport = createAction(
  '[Report] Create Report',
   props<{ report: any }>()

);

export const createReportSuccess = createAction(
  '[Report] Create Report Success',
  props<{ report: any }>()
);

export const createReportFailure = createAction(
  '[Report] Create Report Failure',
  props<{ error: string }>()
);

export const deleteReport = createAction(
  '[Report] Delete Report',
  props<{ reportId: string }>()
);

export const deleteReportSuccess = createAction(
  '[Report] Delete Report Success',
  props<{ reportId: string }>()
);

export const deleteReportFailure = createAction(
  '[Report] Delete Report Failure',
  props<{ error: string }>()
);

export const updateReportSuccess = createAction(
  '[Report] Update Report Success',
  props<{ report: any }>()
);
