import { Component, Inject, Input, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {finalize} from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import { AccountsService } from '../../services/accounts.service';
import { PermissionService } from '@modules/permission/services/permission.service';
import { RolePermission } from '@modules/permission/interfaces/permission';
import { UserService } from '@modules/user/services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  permissions: any[] = [];
  roles$: Observable<RolePermission[]>;
  companies$: Observable<any[]>;
  attributes: string[] = ['US Resident'];
  userRoles$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  addUserForm: UntypedFormGroup;
  error: string = null;
  searchCompany = new UntypedFormControl('');
  companieList: [];


  @Input() loading: boolean;
  @Input() edit = false;
  @Input() loggedUser: any = null;

  // controller to show and hide permissions selector
  showPermissions = true;


  permissionChecked: any = null;

  color: ThemePalette = 'primary';
  checked = false;
  disabled = false;

  constructor(
    private fb: UntypedFormBuilder,
    private permissionService: PermissionService,
    private accountsService: AccountsService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddUserComponent>,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.roles$ = this.permissionService.roleBasePermissions$;

    this.data.companies$.subscribe(company => {
      this.companieList = company;
    });
    this.companies$ = this.data.companies$;

    this.addUserForm = this.fb.group({
      profile: [this.data.profile, [Validators.required]],
      username: ['', [Validators.required]],
      given_name: ['', [Validators.required]],
      family_name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      gender: [[], [Validators.required]],
      access_attributes: [[], []],
      allowed_permissions: [[], []], // give permission he was not supposed to have
      blocked_permissions: [[], []], // blocked remove what he is supposed to have
    });


    if (this.data.loggedUser) {
      // patch the form
      this.addUserForm.patchValue({
        profile: this.data.loggedUser.Attributes.profile,
        username: this.data.loggedUser.Username,
        given_name: this.data.loggedUser.Attributes.given_name,
        family_name: this.data.loggedUser.Attributes.family_name,
        email: this.data.loggedUser.Attributes.email,
        gender: this.data.loggedUser.Attributes.gender.split(','),
        access_attributes: this.data.loggedUser.Attributes.access_attributes,
        allowed_permissions: this.data.loggedUser.Attributes.allowed_permissions,
        blocked_permissions: this.data.loggedUser.Attributes.blocked_permissions
      });

      // disabled componie and username as those are not changeable
      this.addUserForm.controls['profile'].disable();
      this.addUserForm.controls['username'].disable();
    }


    // Since we need to know when to display the permission choices so that the user
    // can allowed extra permission/block permissions
    this.addUserForm.get('gender').valueChanges.subscribe(selectedRole => this.userRoles$.next(selectedRole));
    this.accountsService.accountsError().pipe(
      untilDestroyed(this)).subscribe(error => this.error = error);
  }

  updatePermissions(evt: { allowed_permissions: string[], blocked_permissions: string[] }): void {
    this.addUserForm.patchValue(evt);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  onSubmit() {
    this.loading = true;
    if (this.addUserForm.valid) {
      if (this.data.loggedUser) {
        // updated
        let tempValues = this.addUserForm.value;
        tempValues['profile'] = this.data.loggedUser.Attributes.profile;
        tempValues['sub'] = this.data.loggedUser.Attributes.sub;
        tempValues['username'] = this.data.loggedUser.Username;

        // updated
        this.userService.updateUser(tempValues, this.data.loggedUser.Username)
            .pipe(
                untilDestroyed(this),
                finalize(() => this.loading = false)
            )
            .subscribe({
              next: (user) => this.dialogRef.close(user),
              error: (error) => this.error = error.message
            });
      } else {
        // create
        this.userService.createUser(this.addUserForm.value)
          .pipe(
            untilDestroyed(this),
            finalize(() => this.loading = false)
          )
          .subscribe({
            next: (user) => this.dialogRef.close(user),
            error: (error) => this.error = error.message
          });
      }
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }


}



