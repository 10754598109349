import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/material';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-multi-select-type',
  templateUrl: './multi-select-type.component.html',
  styleUrls: ['./multi-select-type.component.scss']
})
export class MultiSelectTypeComponent extends FieldType {
  formControl: UntypedFormControl;

  constructor() {
    super();
  }

  updateModel(event): void {
    this.field.templateOptions.change(this.field, event.value);
  }
}
