import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InputVariableService } from '@sweet-shared/services/input-variable.service';
import { ActionTypeValue } from '@sweet-shared/sweet-widgets/menu-bar/menu-bar.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextInputResult, TextInputWidget } from './interface';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();

  @Input() widget: TextInputWidget = null;
  @Output() onWidgetAction: EventEmitter<{ name: ActionTypeValue, data: TextInputWidget }> = new EventEmitter();

  inputCtrl: UntypedFormControl = new UntypedFormControl(null);
  inputCtrlSettings: TextInputResult = null;

  constructor(
    private inputVariableService: InputVariableService
  ) { }

  ngOnInit(): void {
    if (this.widget) {
      // set the input control setting
      this.inputCtrlSettings = this.widget.params;

      // apply the settings to the control and update the control
      this.inputCtrl.setValue(this.inputCtrlSettings.default_value);
      this.inputCtrl.setValidators([Validators.pattern(this.inputCtrlSettings.validation_regex)]);
      this.inputCtrl.updateValueAndValidity();
    }

    // set the trigger to send the changes to inputVariableService each time that there is a change
    this.inputCtrl.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(value => {
      this.inputVariableService.setSingleInputVariables(this.inputCtrlSettings.output_variable, value);
    });
  }

  delete(): void {
    this.onWidgetAction.emit({ name: ActionTypeValue.delete, data: this.widget });
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
