import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KnowledgeBaseRoutingModule} from './knowledge-base-routing.module';
import {KnowledgeBasePageComponent} from './components/knowledge-base-page/knowledge-base-page.component';
import {MatDividerModule} from '@angular/material/divider';
import {SweetSharedModule} from '../../sweet-shared/sweet-shared.module';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MarkdownModule, MarkedOptions} from 'ngx-markdown';
import {MatInputModule} from '@angular/material/input';


@NgModule({
    declarations: [KnowledgeBasePageComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    KnowledgeBaseRoutingModule,
    MatDividerModule,
    SweetSharedModule,
    FlexModule,
    ExtendedModule,
    MatCardModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MarkdownModule.forRoot({
        markedOptions: {
          provide: MarkedOptions,
          useValue: {
            gfm: true,
            tables: true,
            breaks: true,
            sanitize: true,
          },
        }
      }
    ),
  ]
})
export class KnowledgeBaseModule {
}
