import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { FormBuilderComponent } from '../components/form-builder/form-builder.component';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  constructor(
    /* Http service for network calls */
    private httpService: AppService<any>,

    /* Dialog service */
    private dialog: MatDialog
  ) { }

  /*
   * Delete widget
   *
   * @param dashboardId - The id of the dashboard
   * @param widgetId - The id of the widget to be deleted
   * @param widgetType - The type of widget to be deleted.
   *
   * @return - Observable confirming whether or not the deletion was successfull.
   * */
  deleteWidget(dashboardId: string, widgetId: string, widgetType: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const urlPath = `${dashboardId}/${widgetId}`;

      const dialogRef = this.dialog.open(FormBuilderComponent, {
        minWidth: '300px',
        minHeight: '100px',
        disableClose: true,
      });

      dialogRef.componentInstance.actionButtons = {
        flex: 'end center',
        buttons: [
          {
            label: 'No',
            iconName: null,
            name: 'No',
            color: 'warn',
            type: 'basic',
          },
          {
            label: 'Yes',
            iconName: null,
            name: 'Yes',
            color: 'primary',
            type: 'stroked',
          },
        ]
      };
      dialogRef.componentInstance.title = `Are you sure you would like to delete this ${widgetType === 'dropdown' ? 'filter' : 'widget'}?`;
      dialogRef.componentInstance.loading = false;

      dialogRef.componentInstance.actionEvents.subscribe(event => {
        if (event.name === 'Yes') {
          dialogRef.componentInstance.loading = true;
          return this.httpService.delete('widgets', urlPath, null, null)
            .then(_ => {
              return resolve('Widget deleted');
            })
            .catch(error => {
              return reject('Failed to delete widget.')
            })
            .finally(() => {
              dialogRef.close();
            })
        } else {
          dialogRef.close();
          return reject('Request cancelled')
        }
      })
    });
  }

  createWidget(widgetDetails: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post('widgets', '', null, widgetDetails)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
}
