import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {State} from '../../../../../reducers';

@Component({
  selector: 'app-single-gauge-form',
  templateUrl: './single-gauge-form.component.html',
  styleUrls: ['./single-gauge-form.component.scss']
})
export class SingleGaugeFormComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  @Input() categoryField: any;
  @Input() actions: any;
  @Output() actionEvents: EventEmitter<any> = new EventEmitter();
  @Output() singleGaugeFormEvents: EventEmitter<any> = new EventEmitter();
  singleGaugeForms: UntypedFormGroup;
  fields: UntypedFormArray;
  fxOptionsMap = {};

  sources: any[] = [
    {value: 'log', friendly: 'Log'}
  ];

  constructor(
    private store: Store<State>,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.singleGaugeForms = this.formBuilder.group({
      fields: this.formBuilder.array([this.createField(0)]),
      source: ['', [Validators.required]]
    });
    this.onChanges();
  }

  // this function is used to obtain the yaxis action based on a type
  private getyAxisAction(typ, list) {
    return list.reduce((acc, cur) => {
      if (cur.type.indexOf(typ) > -1) {
        acc.push({value: cur.value, friendly: cur.friendly});
      }
      return acc;
    }, []);
  }

  fieldFunctionHandler(event, index: number) {
    // to track the different fx param options available for different 'field' selections
    this.fxOptionsMap[index] = this.getyAxisAction(event.value.type, this.actions);
    const control = (this.singleGaugeForms.get('fields') as UntypedFormArray).controls[index].get(`fx${index}`);
    setTimeout(() => control.enable(), 200);
  }

  addField(): void {
    this.fields = this.singleGaugeForms.get('fields') as UntypedFormArray;
    this.fields.push(this.createField(this.fields.length));
  }

  getControls() {
    return (this.singleGaugeForms.get('fields') as UntypedFormArray).controls;
  }

  // create fields with unique names by using index
  createField(index: number) {
    return this.formBuilder.group({
      [`function${index}`]: ['', [Validators.required]],
      [`fx${index}`]: new UntypedFormControl({value: '', disabled: true}),
    });
  }

  // used to delete a dashboard form
  delete(value: any) {
    this.fields.removeAt(value);
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  actionHandler(event) {
    this.actionEvents.emit(event);
  }

  onChanges(): void {
    this.singleGaugeForms.valueChanges.subscribe(val => {
      if (this.singleGaugeForms.valid) {
        this.singleGaugeFormEvents.emit({formStatus: true, name: 'singleGaugeFields', data: this.singleGaugeForms.value});
      }
    });
  }


}
