import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogStreamRoutingModule } from './log-stream-routing.module';
import { LogStreamComponent } from './log-stream/log-stream.component';
import {SweetSharedModule} from '../../sweet-shared/sweet-shared.module';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatInputModule} from '@angular/material/input';
import { LogStreamCreateComponent } from './log-stream-create/log-stream-create.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import { LogStreamDetailComponent } from './log-stream-detail/log-stream-detail.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatListModule} from '@angular/material/list';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";


@NgModule({
  declarations: [LogStreamComponent, LogStreamCreateComponent, LogStreamDetailComponent],
  exports: [LogStreamCreateComponent],
    imports: [
        CommonModule,
        FormsModule,
        SweetSharedModule,
        LogStreamRoutingModule,
        MatCardModule,
        ReactiveFormsModule,
        FormlyModule,
        MatIconModule,
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        FlexLayoutModule,
        FormlyModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatTooltipModule,
        ClipboardModule,
        MatListModule,
        NgxMatSelectSearchModule
    ]
})
export class LogStreamModule { }
