import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ChangeLogInfo {
  email: string;
}

@Component({
  selector: 'app-whats-new-dialog',
  templateUrl: './whats-new-dialog.component.html',
  styleUrls: ['./whats-new-dialog.component.scss']
})
export class WhatsNewDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<WhatsNewDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ChangeLogInfo
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
