import { filter } from 'rxjs/operators';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Dashboard } from './dashboard.model';
import * as dashboardActions from './dashboard.actions';
import { Action, createReducer, on } from '@ngrx/store';


export const dashboardsFeatureKey = 'dashboard';

export interface State extends EntityState<Dashboard> {
  loading: boolean;
  updating: boolean;
  error: string;
  templateTags: any[];
  dashboards: Dashboard[];
  dashboardId: string;
  dialogClose: boolean;
  selectedDashboard: any;
  dateRange?: any;
}

export const adapter: EntityAdapter<Dashboard> = createEntityAdapter<Dashboard>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  updating: false,
  error: null,
  templateTags: [],
  dashboards: null,
  dialogClose: false,
  dashboardId: null,
  selectedDashboard: null,
  dateRange: null
});

const dashboardsReducer = createReducer(
  initialState,
  on(dashboardActions.getAllDashboardTemplate,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: null,
        selectedDashboard: null
      };
    }
  ),
  on(dashboardActions.getAllDashboardTemplateSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        templateTags: action.templateTags
      };
    }
  ),
  on(dashboardActions.getAllDashboardTemplateError,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
  ),
  on(dashboardActions.loadDashboard,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),
  on(dashboardActions.loadDashboardSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        dashboards: action.dashboards
      };
    }
  ),
  on(dashboardActions.loadDashboardFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
  ),
  on(dashboardActions.dashboardCreate,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
  ),
  on(dashboardActions.dashboardCreateSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        dialogClose: true,
        error: null,
        dashboards: [
          ...state.dashboards,
          ...action.dashboards
        ]
      };
    }
  ),
  on(dashboardActions.dashboardCreateFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),
  on(dashboardActions.getDashboard,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),
  on(dashboardActions.getDashboardSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        selectedDashboard: action.selectedDashboard
      };
    }
  ),
  on(dashboardActions.getDashboardFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),
  on(dashboardActions.deleteDashboardSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        dashboards: [...state.dashboards.filter(dashboard => dashboard.dashboard_id !== action.dashboardId)]
      };
    }
  ),
  on(dashboardActions.editDashboard,
    (state, action) => {
      return {
        ...state,
        updating: true
      };
    }),
  on(dashboardActions.editDashboardSuccess,
    (state, action) => {
      // For updating all dashboards for dashboard page
      const dashboardsCopy = [...state.dashboards];
      const editedIndex = dashboardsCopy.findIndex(dashboard => dashboard.dashboard_id === action.dashboard.dashboard_id);
      dashboardsCopy.splice(editedIndex, 1, action.dashboard);
      // For updating selected dashboard for dashboard-detail page
      const newDashboard = {
        ...state.selectedDashboard,
        ...action.dashboard
      };
      return {
        ...state,
        updating: false,
        dashboards: dashboardsCopy,
        selectedDashboard: newDashboard
      };
    }),
  on(dashboardActions.editDashboardFailure,
    (state, action) => {
      return {
        ...state,
        updating: false,
        error: action.error
      };
    }),
  on(dashboardActions.updateDateRange,
    (state, action) => {
      return {
        ...state,
        dateRange: action.range
      };
    }
  ),
  on(dashboardActions.addWidget, (state, action) => {
    return {
      ...state,
      selectedDashboard: {
        ...state.selectedDashboard,
        widgets: [...state.selectedDashboard.widgets, action.widget]
      }
    }
  }),
  on(dashboardActions.removeWidget, (state, action) => {
    return {
      ...state,
      selectedDashboard: {
        ...state.selectedDashboard,
        widgets: state.selectedDashboard.widgets.filter(widget => widget.widget_id !== action.widgetId)
      }
    }
  })
);

export function reducer(state: State | undefined, action: Action) {
  return dashboardsReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
