import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { State } from 'src/app/pages/user-preference/store/user-preference.reducer';
import { DateService } from 'src/app/shared-services/date.service';

export interface ITimeInfo {
  value: number;
  unit: string;
}

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  refreshInterval$: Observable<any>;
  refreshRate$ = new BehaviorSubject(null)

  constructor(private store: Store<State>, public dateSrv: DateService) {
    this.refreshInterval$ = this.store.pipe(
      select((st: State) => st.userPreferences),
      map((pkg: any) => pkg?.userPreferences?.searchRefreshInterval),
      take(1),
      map((dat: any) => this.makeTimeInfo(dat)),
      filter((dat: any) => !!dat),
      map((info: ITimeInfo) => {
        const secs = this.makeTimeInSeconds(info);
        this.refreshRate$.next(secs);
        return secs
      })
    )
  }

  makeTimeInfo(info: string): ITimeInfo {
    if (!!!info) {
      return { value: -1, unit: "" }
    }

    const p = info.split(" ")

    return {
      value: parseInt(p[0] ?? "") ?? -1,
      unit: p[1] ?? ""
    }
  }

  minutesToSeconds(unit: number): number {
    if (!!!unit) {
      return -1;
    }

    return unit * 60
  }

  hoursToSeconds(unit: number): number {
    if (!!!unit) {
      return -1;
    }

    return unit * 120;
  }

  makeTimeInSeconds(ti: ITimeInfo): number {
    if (!!!ti) {
      return -1;
    }

    const unit = ti?.unit;
    const value = ti?.value;

    if (!!!(unit && value)) {
      return -1;
    }

    switch (unit) {
      case "minute":
      case "minutes": {
        return this.minutesToSeconds(value)
      }
      case "hour":
      case "hours": {
        return this.hoursToSeconds(value)
      }
      default: {
        return -1;
      }
    }
  }
}
