<app-portal-loader [loadingStatus]="loading"></app-portal-loader>

<div class="btn-container">
  <button
          mat-stroked-button
          type="button"
          routerLink="/admin/users"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
    Back to Accounts Page
  </button>

  <button  mat-raised-button color="primary" [matMenuTriggerFor]="menu">Account Actions</button>
  <mat-menu #menu="matMenu">
    <ng-container *ifUserCan="'account.delete'">
      <button mat-menu-item class="warn" (click)="onDeleteUser()">Delete</button>
    </ng-container>

    <ng-container *ifUserCan="'account.edit'">
      <button mat-menu-item class="primary" *ngIf="userInfo.Enabled" (click)="accountActions('disable', userInfo)"> Disable </button>
      <button mat-menu-item *ngIf="!userInfo.Enabled"  (click)="accountActions('enable', userInfo)">Enable</button>
    </ng-container>

    <ng-container *ifUserCan="'account.edit'">
      <button mat-menu-item (click)="onEditUser(userInfo)">Edit</button>
      <button mat-menu-item *ngIf="false">Global Logout</button>
      <div [matTooltip]=" !canResetUserPassword()? 'Cannot Reset Password. Email and Phone Number not verified': null">
        <button mat-menu-item [disabled]="!canResetUserPassword()"  (click)="onResetPassword(userInfo)">Reset Password</button>
      </div>
      <button mat-menu-item *ngIf="userInfo.UserStatus === userStatusEnum.ForceChangePassword"  (click)="resendTempCode(userInfo)">Re-send Temp Password</button>
    </ng-container>
  </mat-menu>
</div>

<div fxLayout="row" fxLayout.lt-md="column" class="layout-padding" fxLayoutAlign="space-between start"  fxLayoutAlign.lt-md="start stretch">

  <mat-card appearance="outlined" fxFlex.gt-sm="70" fxFlex.lt-md="100" *ngIf="userInfo && !loading">
    <mat-card-header fxLayout="row">
      <div mat-card-avatar>
        <app-user-display [profile]="userInfo.Attributes"></app-user-display>
      </div>
      <div>
        <mat-card-title class="profile_user-name"
          >{{ userInfo.Attributes.given_name }}
          {{ userInfo.Attributes.family_name }}</mat-card-title
        >
        <mat-card-subtitle>{{ userInfo.Attributes.email }}</mat-card-subtitle>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-list>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Company</h3>
        <mat-list-item>
          <p>{{ userInfo.Attributes.profile | titlecase }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Status</h3>
        <mat-list-item>
          <p>{{ userInfo.UserStatus }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Role</h3>
        <mat-list-item>
          <mat-chip-listbox disabled>
            <mat-chip-option *ngFor="let role of userRoles">{{ role }}</mat-chip-option>
          </mat-chip-listbox>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Login ID</h3>
        <mat-list-item>
          <p>{{ userInfo.Username }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>MFA Enabled</h3>
        <mat-list-item>
          <p>{{ userInfo.Enabled }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Created</h3>
        <mat-list-item>
          <p>{{ userInfo.UserCreateDate | userDate }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Last Modified</h3>
        <mat-list-item>
          <p>{{ userInfo.UserLastModifiedDate | userDate }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <section
        class="permission-container"
        *ifUserCan="'account.edit-permissions'"
      >
        <h2>Granular Permissions</h2>
        <mat-card-subtitle
          >Below you'll be able to modify user permissions on a granular
          level</mat-card-subtitle
        >
        <app-permission-list
          [user]="user"
          [editable]="false"
        ></app-permission-list>
      </section>
    </mat-card-content>

  </mat-card>
  <div></div>
</div>
