export interface PieWidgetInterface {
  'owner_id': string;
  'type': 'pie',
  'title': string;
  'max_slices': number;
  'createdAt': number;
  'createdBy': string;
  'updatedAt': number;
  'updatedBy': string;
  'dashboard_id': string;
  'widget_id': string;
  'position': {
    'x': number;
    'y': number;
  };
  'size': {
    'height': number;
    'width': number;
  };
  'params': {
    'dateRange': string;
    'field': {
      'decimal_places': number;
      'friendly': string;
      'name': string;
      'fx': 'count' | 'max' | 'min';
    };
  };
}

export interface WidgetChart {
  drawChart(data: any, chart, widget, isInit, loading): void;
}

// TODO - Add line widget interface.

export enum CastAsEnum {
  TIMESTAMPE = 'timestamp',
  BOOLEAN = 'boolean',
  TINYINT = 'tinyint',
  SMALLINT = 'smallint',
  INTEGER = 'integer',
  REAL = 'real',
  DOUBLE = 'double',
  DECIMAL = 'decimal',
  VARCHAR = 'varchar',
  CHAR = 'char'
}
