import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '@app/guards/auth-gaurd.service';
import { CompanyListComponent } from './components/company-list/company-list.component';

const routes: Routes = [
  {
    path: 'company',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: CompanyListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule { }
