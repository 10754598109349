import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class DatetimeService {

  constructor(
    private apiService: AppService<any>
  ) { }


  dateRangeComparison(option, value): boolean {
    if (!option || !value) return true;
    return option.from === value.from && option.to === value.to && option.type === value.type;
  }

  validateMayTime(mayExpression: string) {
    return this.apiService.post('validators', '', null, { expression: mayExpression, type: 'maya' });
  }
}
