import { selectEventFields } from './event-params.selector';
import { Store, select } from '@ngrx/store';
import { AppService } from './../../app.service';
import { EventField, EventParams } from './event-params.model';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import * as EventParamActions from './event-params.actions';
import { State } from '../../reducers';
import { of } from 'rxjs';



@Injectable()
export class EventParamsEffects {

  constructor(
    private actions$: Actions,
    private eventParamsService: AppService<EventParams>,
    private store: Store<State>
  ) {}

  @Effect({dispatch: true})
  loadEventParams$ = this.actions$.pipe(
    ofType(EventParamActions.loadEventFields),
    withLatestFrom(this.store.pipe(select(selectEventFields))),
    switchMap(([action, eventParams]) => {
      if (eventParams) {
        return of(EventParamActions.loadEventFieldsSuccess({eventParams}));
      } else {
        return this.eventParamsService.get('eventParams', '', null)
          .then((evtParams: EventParams) => EventParamActions.loadEventFieldsSuccess({eventParams: evtParams}))
          .catch((error: any) => EventParamActions.loadEventFieldsError({error: error.response.message}));
      }
    })
  );
}
