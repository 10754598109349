import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SecretService {
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private errorSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private secretSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();
  error$: Observable<string | null> = this.errorSubject.asObservable();
  secrets$: Observable<any[]> = this.secretSubject.asObservable();


  constructor(private httpClient: AppService<any>) { }

  fetchSecrets(customerId: string): void {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.httpClient.get('company-secret', customerId, null)
      .then(response => this.secretSubject.next(response))
      .catch(error => this.errorSubject.next(error.message))
      .finally(() => this.loadingSubject.next(false));
  }

  create(payload): Observable<any> {
    return from(this.httpClient.post('company-secret', '', null, payload)).pipe(
      tap(() => this.fetchSecrets(payload.customer_id))
    );
  }

  update(payload): Observable<any> {
    return from(this.httpClient.put('company-secret', '', null, payload)).pipe(
      tap(() => this.fetchSecrets(payload.customer_id))
    )
  }

  delete(customerId: string, secretId: string): void {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.httpClient.delete('company-secret', `${customerId}/${secretId}`, null, null)
      .then(_ => this.secretSubject.next(this.secretSubject.value.filter(item => item.key_type !== secretId)))
      .catch(error => this.errorSubject.next(error.message))
      .finally(() => this.loadingSubject.next(false))
  }
}
