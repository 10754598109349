import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ingestion-multi-line-form',
  templateUrl: './ingestion-multi-line-form.component.html',
  styleUrls: ['./ingestion-multi-line-form.component.scss']
})
export class IngestionMultiLineFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
