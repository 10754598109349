import { Injectable } from '@angular/core';
import { kbArticles} from './components/knowledge-base-page/kb-articles';

@Injectable({
  providedIn: 'root'
})
export class KbService {

  articles = kbArticles;

  normalizeSearchQuery(query: string) {
    const lowerCaseQuery = query.toLowerCase();
    const listOfKeywords = lowerCaseQuery.split(' ');
    return this.removeCommonWords(listOfKeywords);
  }

  generateListOfArticles(searchParams: string) {
    const searchKeywords = this.normalizeSearchQuery(searchParams);
    // setup needed vars and placeholders
    const matchingArticles = [];
    const temporaryArticlesKeys = [];
    let temporarySummary: any;
    let uniqueKeys: Array<string>;
    // Looping through user provided list of search terms
    searchKeywords.forEach(keyWord =>
      // Looping through each of the stored articles
      this.articles.forEach(eachArticle =>
        // Looping through each tag in a single articles
        eachArticle.tags.forEach(eachTag => {
            // Matches user keyword against tag in each article
            if (keyWord === eachTag) {
              // Build the summary that MAY be returned to user
              temporarySummary = {
                title: eachArticle['title'],
                summary: eachArticle['summary'],
                createdOn: eachArticle['createdOn'],
                section: eachArticle['section']
              };
              // Check to see if list of matching articles (via tag hit) is empty (initial state) or not
              if (matchingArticles.length >= 1) {
                // Cycle through each matching article and pull out the article title into a list
                matchingArticles.forEach(eachMatchingArticle => {
                  temporaryArticlesKeys.push(eachMatchingArticle['title']);
                });
                // Filter out the list of matching articles so only one copy of each exist.
                // This is necessary because the same article could have multiple matching tags:keywords which
                // would cause the article to be duplicated in the matching list.
                // tslint:disable-next-line:only-arrow-functions
                uniqueKeys = temporaryArticlesKeys.filter(function(element, index, self){
                  return index === self.indexOf(element);
                });
                // If the temporary summary title isn't already in the unique keys list, add it.
                if (!uniqueKeys.includes(temporarySummary['title'])) {
                  matchingArticles.push(temporarySummary);
                }
              } else {
                // Initial push when a hit is found and the matching articles list is empty
                matchingArticles.push(temporarySummary);
              }
            }
          }
        )
      )
    );
    return matchingArticles;
  }

  findSingleArticle(articleName) {
    return this.articles.find(eachArticle => eachArticle.title === articleName);
  }

  removeCommonWords(keywords) {
    const stopword = require('stopword');
    const modifiedKeywords = stopword.removeStopwords(keywords);
    return modifiedKeywords;
  }

  constructor() { }
}
