import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { from, Observable, of } from 'rxjs';
import { Attribute, Company, CompanyDetails, Product } from '@modules/companies/interfaces/companies';
import { map, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export const ProductAttributeType = {
  "Int": 'number',
  "String": 'text',
  "Float": 'number'
}

export interface ProductAttribute {
  description: string;
  friendly: string;
  name: string;
  type: string;
  value: any;
}
export interface ProductNode {
  attributes: ProductAttribute[];
  children: ProductNode[];
  default_data_retention: number;
  description: string;
  friendly: string;
  name: string;
  selected: boolean;
  expanded: boolean;
}

export interface IndexedNode {
  index: number;
  node: UntypedFormGroup
}



@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(
    private httpClient: AppService<any>,
    private fb: UntypedFormBuilder
  ) { }

  fetchCompanies(): Observable<Company[]> {
    return from(this.httpClient.get('company', '', null));
  }

  fetchProducts(): Observable<Product[]> {
    return from(this.httpClient.get('products', '', null)).pipe(
      map(products => {
        return products.map(product => {
          return {
            ...product,
            parent: product?.parent?.map(parent => parent) ?? [],
            attributes: product.attributes.map(attr => attr)
          };
        });
      })
    );
  }

  fetchCompanyAttributes(): Observable<{ [key: string]: Attribute }> {
    return from(this.httpClient.get('company', 'attributes', null)).pipe(
      map(response => {
        return response.reduce((acc: { [key: string]: Attribute }, next) => {
          acc[next.name] = next;
          return acc;
        }, {});
      })
    )
  }

  fetchCompanyDetails(companyId: string): Observable<CompanyDetails> {
    return from(this.httpClient.get('company', companyId, null));
  }

  getCompanyDetails(): Observable<CompanyDetails> {
    return from(this.httpClient.get('product-details', '', null));
  }

  updateCompany(companyId: string, payload): Observable<CompanyDetails> {
    return from(this.httpClient.put('company', `${companyId}`, null, payload));
  }

  create(payload): Observable<CompanyDetails> {
    return from(this.httpClient.post('company', '', null, payload));
  }


  makeProductNodeForm(node: ProductNode): UntypedFormGroup {
    if (!!!node) {
      return null;
    }

    const fg = this.fb.group({
      description: [node.description],
      friendly: [node.friendly],
      name: [node.name],
      selected: [node.selected],
      attributes: this.fb.array(
        node.attributes.map((attr: ProductAttribute) => this.makeProductAttributeForm(attr))
      ),
      children: this.fb.array(
        node.children.map((node: ProductNode, index: number) => ({ index, node: this.makeProductNodeForm(node) }))
      )
    })

    return fg;
  }

  makeProductAttributeForm(attr: ProductAttribute): UntypedFormGroup {
    if (!!!attr) {
      return null;
    }

    return this.fb.group({
      description: [attr.description],
      friendly: [attr.friendly],
      name: [attr.name],
      type: [attr.type],
      value: [attr.value]
    })
  }
}
