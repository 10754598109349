import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '../../guards/auth-gaurd.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashboardDetailPageComponent} from './dashboard-detail-page/dashboard-detail-page.component';
import {PermissionsGuardService} from '../../guards/permissions-guard.service';
import {ReportsComponent} from './reports/reports.component';

const routes: Routes = [
  // TODO - CHECK FOR THIS PERMISSION & FOR VIEW ID
  {
    path: 'dashboard',
    canActivate: [AuthGuardService, PermissionsGuardService],
    data: {
      requiredPermission: ['portal-access.enable-dashboard-section'],
      any: false
    },
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: ':id',
        component: DashboardDetailPageComponent,
        canActivate: [PermissionsGuardService],
        data: {
          requiredPermission: ['dashboard.read'],
          any: false
        }
      },
      {
        path: ':id/reports',
        component: ReportsComponent,
        canActivate: [PermissionsGuardService],
        data: {
          requiredPermission: ['report.read'],
          any: false
        }
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardsRoutingModule { }
