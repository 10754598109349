<section class="datetime-picker">
    <mat-form-field appearance="{{ appearance }}">
        <mat-label>
            {{ label }}
        </mat-label>
        <mat-select [formControl]="dateTimeSelectedCtrl" [compareWith]="datetimeService.dateRangeComparison">
            <mat-option *ngFor="let option of options" [value]="option.value">{{ option.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="dateTimeSelectedCtrl?.value?.type as dateType">
        <ng-container *ngIf="specialDateValues.includes(dateType)">
            <ng-container *ngIf="dateType === customDate">
                <form [formGroup]="customDateRangeForm" class="custom-relative">
                    <div class="start-date">
                        <mat-form-field>
                            <mat-label>Start</mat-label>
                            <input matInput type="datetime-local" placeholder="Start" formControlName="start">
                        </mat-form-field>

                    </div>

                    <div class="end-time">
                        <mat-form-field>
                            <mat-label>End</mat-label>
                            <input matInput type="datetime-local" placeholder="End" formControlName="end">
                        </mat-form-field>
                    </div>

                    <!-- Invalid Date Messages -->
                    <mat-error *ngIf="customDateRangeForm.controls.start.hasError('matStartDateInvalid')">Invalid
                        start
                        date</mat-error>
                    <mat-error *ngIf="customDateRangeForm.controls.end.hasError('matEndDateInvalid')">Invalid end
                        date
                    </mat-error>
                </form>
            </ng-container>
            <ng-container *ngIf="dateType === relativeDate">
                <form [formGroup]="relativeDateRangeForm" class="custom-relative">
                    <div class="start-date">
                        <mat-form-field>
                            <mat-label>Start</mat-label>
                            <input matInput placeholder="Start" formControlName="start">
                        </mat-form-field>
                        <p *ngIf="!!relativeDateRangeForm.valid && mayaStart; else invalidStart">{{ mayaStart | date :
                            'medium' }}</p>
                    </div>

                    <div class="end-time">
                        <mat-form-field>
                            <mat-label>End</mat-label>
                            <input matInput placeholder="Start" formControlName="end">
                        </mat-form-field>
                        <p *ngIf="!!relativeDateRangeForm.valid && mayaEnd; else invalidEnd">{{ mayaEnd | date :
                            'medium'}}</p>
                    </div>
                </form>
            </ng-container>
        </ng-container>
    </ng-container>


</section>

<ng-template #dateTimePicker>
    <input matStartDate type="datetime-local" placeholder="Start Date" formControlName="start">
    <input matEndDate type="datetime-local" placeholder="End Date" formControlName="end">
</ng-template>