<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)='onActionClick("REFRESH")' [disabled]='!refreshEnabled'>
    <mat-icon>refresh</mat-icon>
    <span>Refresh</span>
  </button>
  <button mat-menu-item (click)='onActionClick("EDIT")' [disabled]='!editEnabled'>
    <mat-icon>edit</mat-icon>
    <span>Edit</span>
  </button>
  <button mat-menu-item (click)='onActionClick("DOWNLOAD")' [disabled]='!downloadEnabled'>
    <mat-icon>download</mat-icon>
    <span>Download</span>
  </button>
  <button mat-menu-item (click)='onActionClick("DELETE")' [disabled]='!deleteEnabled'>
    <mat-icon>delete_forever</mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
