import { Component } from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-email-verification-code-dialog',
  templateUrl: './email-verification-code-dialog.component.html',
  styleUrls: ['./email-verification-code-dialog.component.scss']
})
export class EmailVerificationCodeDialogComponent {
  code: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(3)]);
  constructor(
    private dialogRef: MatDialogRef<EmailVerificationCodeDialogComponent>
  ) { }

  cancel(): void {
    this.dialogRef.close(null);
  }

  submit(): void {
    this.dialogRef.close(this.code.value);
  }
}
