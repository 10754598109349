<mat-card appearance="outlined" class="main-mat-card">
  <mat-card-title>What's New?</mat-card-title>
  <!-- Update content below including the 3 most recent release notes-->
  <!-- Copy the release notes in changelog.md into a markdown to html converter to make your life easy :)  Update text as needed-->
  <mat-card-content>
    <h2>Release: 4.56.1 (2024-02-14)</h2>
    <h3>Fix</h3>
    <ul>
      <li><strong> Updated Action On API Key Delete Button In Personal Information</strong></li>
    </ul>

    <h2>Release: 4.55.0 (2024-01-09)</h2>
    <h3>Feature</h3>
    <ul>
      <li><strong> Updated Device Section Help Button</strong></li>
    </ul>

    <h2>Release: 4.54.0 (2023-12-13)</h2>
    <h3>Feature</h3>
    <ul>
      <li><strong> Updated Reporting Section Help Button</strong></li>
      <li><strong> Updated Dashboard Section Help Button</strong></li>
      <li><strong> Updated Help Modal To Show Loading bar</strong></li>
    </ul>

    <h2>Release: 4.53.2 (2023-12-03)</h2>
    <h3>Fix</h3>
    <ul>
      <li><strong> Partial UI Style Update</strong></li>
    </ul>

    <h2>Release: 4.53.1 (2023-11-28)</h2>
    <h3>Fix</h3>
    <ul>
      <li><strong> Updated Help Links in the Incident and Events Section</strong></li>
    </ul>

    <h2>Release: 4.53.0 (2023-11-27)</h2>
    <h3>Feature</h3>
    <ul>
      <li><strong> Updated Web Framework (15.0)</strong></li>
      <li><strong> Partial UI Style Update</strong></li>
    </ul>

  </mat-card-content>
  <!-- End of content to update-->
  <mat-card-actions>
    <button mat-stroked-button color="primary" (click)="onNoClick()">Got It!</button>
  </mat-card-actions>
</mat-card>
