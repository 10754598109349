import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { DropdownOption, IncidentsService } from '@app/pages/incidents/services/incidents.service';
import { Incident } from '@app/pages/incidents/store/incident.model';
import { Observable } from 'rxjs';
import {Company} from '@modules/companies/interfaces/companies';

@Component({
  selector: 'app-incident-form',
  templateUrl: './incident-form.component.html',
  styleUrls: ['./incident-form.component.scss']
})
export class IncidentFormComponent implements OnInit {
  companyOptions$: Observable<DropdownOption[]>;
  priorityOptions$: Observable<DropdownOption[]>;
  loading = false;
  error: string = null;
  companiesOptions: DropdownOption[] = [];
  filteredCompaniesOptions: DropdownOption[] = [];
  searchFormControl = new UntypedFormControl('');


  form: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl('', [Validators.required]),
    customerId: new UntypedFormControl('', [Validators.required]),
    priority: new UntypedFormControl('', [Validators.required]),
    status: new UntypedFormControl(false, []),

  });

  constructor(
    private incidentService: IncidentsService,
    public dialogRef: MatDialogRef<IncidentFormComponent>,
    private appService: AppService<any>,
  ) {
    this.companyOptions$ = this.incidentService.incidentCompanies;
    this.incidentService.incidentCompanies.subscribe(val => {
      this.filteredCompaniesOptions = val;
      this.companiesOptions = val;
    })
    this.priorityOptions$ = this.incidentService.incidentPriorities;
  }

  ngOnInit(): void {
  }

  dismissDialog(): void {
    this.dialogRef.close(null);
  }

  createIncident(): void {
    this.error = null;
    let response = null;
    const payload = this.form.value;

    this.form.disable();
    this.loading = true;
    this.appService.post('investigation', '', { action: 'create-incident' }, payload)
      .then((res: Incident) => {
        response = res;
      })
      .catch(err => {
        this.error = err.message;
      })
      .finally(() => {
        if (!response) {
          this.loading = false;
          this.form.enable();
        } else {
          this.dialogRef.close(response);
        }
      })
  }

  filterCompany(searchTerm: string): void {
    this.filteredCompaniesOptions = this.companiesOptions.filter(c => c.value.toLowerCase().includes(searchTerm.toLowerCase()));
  }

}
