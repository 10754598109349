<section class="schedule-form">
  <form [formGroup]="form">
    <!-- Activate scheduler -->
    <mat-slide-toggle formControlName="enabled">Is Active</mat-slide-toggle>

    <section formGroupName="schedule_rate">
      <h3>Schedule Time Frame</h3>

      <!-- run forever -->
      <mat-checkbox formControlName="run_forever">Run forever</mat-checkbox>

      <!-- Date range picker when report is not to be ran forever -->
      <mat-form-field
        appearance="outline"
        *ngIf="!form.value.schedule_rate.run_forever"
      >
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            placeholder="Start date"
            formControlName="date_from"
          />
          <input matEndDate placeholder="End date" formControlName="date_to" />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <h3>Frequency</h3>
      <mat-form-field appearance="outline">
        <mat-label>Options</mat-label>
        <mat-select formControlName="frequency">
          <mat-option
            *ngFor="let option of frequencyOptions"
            [value]="option.cron"
            >{{ option.friendly }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </section>
  </form>
  <mat-form-field appearance="outline">
    <mat-label>Cron Expression</mat-label>
    <input matInput [formControl]="cron" />
  </mat-form-field>

  <section class="next-runs">
    <p>
      <strong> Next {{ maxCronExpressionRuns }} runs: </strong>
    </p>
    <p *ngFor="let nextRun of nextRunSchedules">
      {{ nextRun | date: "MMMM dd, yyyy @ HH:mm:ss a" }}
    </p>
  </section>
</section>
