<div class="dialog-padding">

  <h2 mat-dialog-title>{{this.widget.title}}</h2>

  <mat-dialog-content>
    <h3>Input Variables Used ({{this.widget.input_variables.length}}):</h3>
    <div *ngFor="let iv of this.widget.input_variables" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="variable-icon">extension</mat-icon>
      <p>{{iv.input_variable_name}}</p>
    </div>
  </mat-dialog-content>

  <div fxLayout="row" fxLayoutAlign="end end">
    <button mat-button mat-dialog-close>Close</button>
  </div>
</div>
