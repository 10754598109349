import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-page-border',
  templateUrl: './bottom-page-border.component.html',
  styleUrls: ['./bottom-page-border.component.scss']
})
export class BottomPageBorderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
