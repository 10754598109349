import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import * as am4core from '@amcharts/amcharts4/core';
import dark from '@amcharts/amcharts4/themes/dark';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.options.autoSetClassName = true;
am4core.useTheme(am4themes_animated);

@Injectable({
  providedIn: 'root'
})
export class ThemeSwitcherService {
  private _darkTheme = new Subject<boolean>();
  isDarkTheme = this._darkTheme.asObservable();

  setDarkTheme(isDarkTheme: boolean): void {
    this._darkTheme.next(isDarkTheme);
    /*
      If we want to toggle the theme that amCharts uses, we can do it here.
      call am4core.unuseTheme(<old-theme-object>) and am4core.useTheme(<new-theme-object>)
     */
  }

  constructor() {
  }
}
