<section class="filters">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loading || searching"
  ></mat-progress-bar>
  <section class="title">
    <h2>Event Filters</h2>
    <button mat-icon-button (click)="openHelpDialog()">
      <mat-icon>help</mat-icon>
    </button>
  </section>
  <!-- {{ filteredIndexOptions | json }} -->
  <form [formGroup]="form">
    <!-- Date Range -->
    <app-datetime-picker
      [appearance]="''"
      [customDateValue]="customDate$ | async"
      [defaultValue]="defaultSelectedDate"
      [options]="dateRangeOptions"
      (onDateSelected)="handleDateSelected($event)"
    ></app-datetime-picker>

    <!-- Company Filter -->
    <mat-form-field>
      <mat-label>Company Filter</mat-label>
      <mat-select formControlName="company">
        <mat-form-field class="select-with-search">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="filterCompany($event.target.value)" />
        </mat-form-field>
        <mat-option
          *ngFor="let option of filteredCompaniesOptions"
          [value]="option.id"
          >{{ option.name }} ( {{option.id}} )</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- Company Index -->
    <mat-form-field>
      <mat-label>Company Index</mat-label>
      <mat-select formControlName="index">
        <mat-form-field class="select-with-search">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="filterIndex($event.target.value)" />
        </mat-form-field>
        <mat-option
          *ngFor="let option of filteredIndexOptions"
          [value]="option.id"
          >{{ option.friendly }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>

  <section class="query-filter-display">
    <mat-form-field>
      <mat-label>Query</mat-label>
      <textarea matInput [formControl]="queryFilterValue"></textarea>
    </mat-form-field>
  </section>

  <section class="action-container">
    <div>
      <button
        mat-stroked-button
        color="primary"
        (click)="performSearch()"
        [disabled]="loading || searching || form.invalid"
      >
        <mat-icon>search</mat-icon>
        Search
      </button>
      <button
        mat-stroked-button
        color="primary"
        (click)="openQueryBuilder()"
        [disabled]="loading || searching || form.invalid"
      >
        <mat-icon>build</mat-icon>
        Query Builder
      </button>
    </div>
    <!-- <p>Time to refresh: {{ refreshInterval }}</p> -->
  </section>
</section>
