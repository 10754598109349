import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';

export interface HelpInfoModal {
  email: string;
}

@Component({
  selector: 'app-incident-help-dialog',
  templateUrl: './help-dialog-modal.component.html',
  styleUrls: ['./help-dialog-modal.component.scss']
})

export class HelpDialogModalComponent implements OnInit {
  @Input() articleTitle: string;
  public helpArticle: string;
  public emptyArticle = true;
  public loading = true;

  constructor(
      public dialogRef: MatDialogRef<HelpDialogModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
      private knowledgeBaseService: AppService<any>
  ) {
    try {
      let data1 = this.getData();
    }
    catch (e) {
      console.log('Constructor error ', e);
    }
  }

  ngOnInit(): void {
    }

  async getData(): Promise<void> {
    var pathWay = '';
    if (document.URL.includes('/events')) {
      pathWay = 'event_overview';
    } else if (document.URL.includes('/incidents')) {
      pathWay = 'incident_search';
    } else if (document.URL.includes('/dashboard')) {
      pathWay = 'dashboard_overview';
    } else if (document.URL.includes("/reporting")) {
      pathWay = 'report_overview';
    } else if (document.URL.includes("/devices")) {
      pathWay = 'devices_overview';
    } else if (document.URL.includes("/log-stream")) {
      pathWay = 'log-stream_overview';
    } else if (document.URL.includes("/secure-dns")) {
      pathWay = 'secure-dns_overview';
    } else if (document.URL.includes("/list")) {
      pathWay = 'list_overview';
    }

    try {
      await this.knowledgeBaseService.get('kb', pathWay, null).then((res) => {
        console.log(res);
        if ((res.content === null) || (res.content == "")){
          this.createDefaultArticle();
        }
        else {
          this.helpArticle = res.content;
        }
        this.emptyArticle = false;
        this.loading = false;
      } );
    } catch (e) {
      console.log('Catch Error ', e);
      this.helpArticle = 'Error accessing database';
    }
  }

  createDefaultArticle(): void {
    const articleTemplate = {
      state: "present",
      title: "No Articles",
      id: "no_data",
      content: "## No data found at this time.\n\n### ",
      images: [],
      permissions: {
        customerExecuteList: ["*"],
        customerReadList: ["*"],
        customerWriteList: [],
        productExecuteList: ["*"],
        productReadList: ["*"],
        productWriteList: [],
        roleReadList: ["*"],
        roleWriteList: []
      }
    };
    this.helpArticle = articleTemplate['content'];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
