import { Injectable } from '@angular/core';
import {AppService} from '../../../app.service';
import {BehaviorSubject, from, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

export interface SecurityPostureInterace {
  company_id: string;
  createdAt?: string;
  preference_name: string;
  type?: string;
  updatedAt?: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class SecurityPostureService {
  private securityPosture: BehaviorSubject<SecurityPostureInterace> = new BehaviorSubject<any>(null);
  private securityPostureLoading: BehaviorSubject<boolean> = new BehaviorSubject<any>(null);
  private securityPostureError: BehaviorSubject<string> = new BehaviorSubject<any>(null);
  private securityPostureSuccess: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private appService: AppService<any>) {

  }

  fetchSecurityPosture(companyName: string): void {
    this.securityPostureLoading.next(true);
    this.appService.get('security-posture', `${companyName}/preferences/security_posture_text`, null)
      .then(data => {
        this.securityPosture.next(data);
      })
      .catch(err => {
        this.securityPostureError.next('Was unable to get Security Posture data');
      })
      .finally(() => {
        this.securityPostureLoading.next(false);
      });
  }

  updateSecurityPosture(payload: SecurityPostureInterace): void {
    this.securityPostureLoading.next(true);
    this.appService.put('security-posture', `${payload.company_id}/preferences/security_posture_text`, null, payload)
      .then(res => {
        this.securityPosture.next(res);
        this.securityPostureSuccess.next('Security Posture successfully updated');
      })
      .catch(err => {
        this.securityPostureError.next('Was unable to update Security Posture data');
      })
      .finally(() => {
        this.securityPostureLoading.next(false);
      });
  }

  getSecurityPosture(): BehaviorSubject<SecurityPostureInterace> {
    return this.securityPosture;
  }

  getSecurityPostureError(): BehaviorSubject<string> {
    return this.securityPostureError;
  }

  getSecurityPostureLoading(): BehaviorSubject<boolean> {
    return this.securityPostureLoading;
  }

  getSecurityPostureSuccess(): BehaviorSubject<string> {
    return this.securityPostureSuccess;
  }
}
