import { FieldArrayType } from '@ngx-formly/core';
import {Component} from '@angular/core';

@Component({
  selector: 'app-formly-repeat-section',
  template: `<div   *ngFor="let field of field.fieldGroup; let i = index;" class="row">
      <formly-field class="col"  [field]="field"></formly-field>
      <div class="col-sm-2 d-flex align-items-center" >
        <button *ngIf="i > 0" mat-stroked-button type="button" (click)="remove(i)">Remove</button>
      </div>
    </div>
    <div style="margin:30px 0;">
      <button mat-stroked-button type="button" (click)="add()">{{ to.addText }}</button>
    </div>`
})
export class RepeatTypeComponent extends FieldArrayType {
  constructor() {
    super();
  }
}
