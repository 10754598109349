import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit {
  @Input() option: any[] = [];
  @Output() searchText: EventEmitter<any> = new EventEmitter();
  text: any;
  form: UntypedFormGroup;
  searchInputText: UntypedFormControl;

  constructor() { }

  ngOnInit() {
    this.searchInputText = new UntypedFormControl();
    this.searchInputText.valueChanges.subscribe(
      value =>
        this.searchText.emit(value));
  }

}
