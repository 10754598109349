import {Injectable} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import {DateRange} from '../shared-stores/event-params/event-params.model';
import {RelativeDate} from "../sweet-shared/models/relative-date-range.model";
import { string } from '@amcharts/amcharts4/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  incidentsCreatedDate$ = new BehaviorSubject(null);

  dateRangeData = null;
  constructor() {
  }

  relativeDates: RelativeDate[] = [
    {label: 'Now', value: 'now'},
    {label: 'last 5 minutes', value: 'last 5 minutes'},
    {label: 'last 15 minutes', value: 'last 15 minutes'},
    {label: 'last 60 minutes', value: 'last 60 minutes'},
    {label: '4 hours ago', value: '4 hours ago'},
    {label: '24 hours ago', value: '24 hours ago'},
    {label: '7 days ago', value: '7 days ago'},
    {label: '30 days ago', value: '30 days ago'},
    {label: 'last 1 year', value: 'last 1 year'},
  ];


  formatDate(date: string): {gte: string, lte: string} {
    const now = new Date();
    now.setSeconds(0, 0);
    switch (date) {
      case 'last 5 minutes':
        return {gte: moment(now).subtract(5, 'minutes').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'last 15 minutes':
        return {gte: moment(now).subtract(15, 'minutes').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'last 60 minutes':
        return {gte: moment(now).subtract(60, 'minutes').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'last 4 hours':
        return {gte: moment(now).subtract(4, 'hours').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'last 24 hours':
        return {gte: moment(now).subtract(24, 'hours').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'last 7 days':
        return {gte: moment(now).subtract(7, 'days').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'last 30 days':
        return {gte: moment(now).subtract(30, 'days').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'last 1 year':
        return {gte: moment(now).subtract(1, 'years').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'current month':
        return {gte: moment(now).startOf('months').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'previous month':
        return {
          gte: moment(now).subtract(1, 'month').startOf('months').toDate().toISOString(),
          lte: moment(now).startOf('months').toDate().toISOString()
        };
      case 'current year':
        return {gte: moment(now).startOf('years').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'current hour':
        return {gte: moment(now).startOf('hours').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'current day':
        return {gte: moment(now).startOf('days').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      case 'current week':
        return {gte: moment(now).startOf('weeks').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
      default:
        // default is last 30 days
        return {gte: moment(now).subtract(30, 'days').toDate().toISOString(), lte: moment(now).toDate().toISOString()};
    }
  }

  // takes in user preference and eventDate and return a date form
  dataRange(userPreferenceDateRange: string, eventDateRange: DateRange[]) {
    let date: any = {dataeFrom: '', dateTo: ''};
    eventDateRange.map(n => {
      if (_.endsWith(userPreferenceDateRange, 'minutes') && userPreferenceDateRange === n.name) {
        date = {
          dateFrom: moment().subtract(n.val, 'minutes').toDate().toISOString(),
          dateTo: moment().toDate().toISOString()
        };
      }
      // if preference is hours then value is applied
      else if (_.endsWith(userPreferenceDateRange, 'hours') && userPreferenceDateRange === n.name) {
        date = {
          dateFrom: moment().subtract(n.val, 'hours').toDate().toISOString(),
          dateTo: moment().toDate().toISOString()
        };
      } else if (_.endsWith(userPreferenceDateRange, 'days') && userPreferenceDateRange === n.name) {
        const now = new Date();
        now.setSeconds(0, 0);
        date = {
          dateFrom: moment(now).subtract(n.val, 'days').toDate().toISOString(),
          dateTo: moment(now).toDate().toISOString()
        };
      } else if (_.startsWith(userPreferenceDateRange, 'last 1') && userPreferenceDateRange === n.name) {
        date = {
          dateFrom: moment().subtract(n.val, 'year').toDate().toISOString(),
          dateTo: moment().toDate().toISOString()
        };
      } else if (_.startsWith(userPreferenceDateRange, 'current month') && userPreferenceDateRange === n.name) {
        date = {
          dateFrom: moment().startOf('month').toDate().toISOString(),
          dateTo: moment().toDate().toISOString()
        };
      } else if (_.startsWith(userPreferenceDateRange, 'previous month') && userPreferenceDateRange === n.name) {
        date = {
          dateFrom: moment().subtract(n.val, 'month').toDate().toISOString(),
          dateTo: moment().toDate().toISOString()
        };
      } else if (_.startsWith(userPreferenceDateRange, 'current year') && userPreferenceDateRange === n.name) {
        date = {
          dateFrom: moment().startOf('year').toDate().toISOString(),
          dateTo: moment().toDate().toISOString()
        };
      } else if (_.startsWith(userPreferenceDateRange, 'current hour') && userPreferenceDateRange === n.name) {
        date = {
          dateFrom: moment().startOf('hour').toDate().toISOString(),
          dateTo: moment().toDate().toISOString()
        };
      } else if (_.startsWith(userPreferenceDateRange, 'current day') && userPreferenceDateRange === n.name) {
        date = {
          dateFrom: moment().startOf('day').toDate().toISOString(),
          dateTo: moment().toDate().toISOString()
        };
      } else if (_.startsWith(userPreferenceDateRange, 'current week') && userPreferenceDateRange === n.name) {
        date = {
          dateFrom: moment().startOf('week').toDate().toISOString(),
          dateTo: moment().toDate().toISOString()
        };
      }
    });
    return date;
  }

  unitValueFormatter(dateRange: string, customRange?): { unit: string, value: number } {
    switch (dateRange) {
      case 'last 5 minutes':
        return {unit: 'minute', value: 5};
      case 'last 15 minutes':
        return {unit: 'minute', value: 15};
      case 'last 60 minutes':
        return {unit: 'minute', value: 60};
      case 'last 4 hours':
        return {unit: 'hour', value: 4};
      case 'last 24 hours':
        return {unit: 'hour', value: 24};
      case 'last 7 days':
        return {unit: 'day', value: 7};
      case 'last 30 days':
        return {unit: 'day', value: 30};
      case 'last 1 year':
        return {unit: 'year', value: 1};
      case 'current month':
        return {unit: 'month', value: null};
      case 'previous month':
        return {unit: 'month', value: 1};
      case 'current year':
        return {unit: 'year', value: null};
      case 'custom':
        return customRange;
      case 'current hour':
        return {unit: 'hour', value: null};
      case 'current day':
        return {unit: 'day', value: null};
      case 'current week':
        return {unit: 'week', value: null};
      default:
        // default is last 30 days
        return {unit: 'day', value: 30};
    }
  }

}
