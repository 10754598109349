import {Injectable} from '@angular/core';
import {cloneDeep} from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class HeaderSortingService {
    // Determines the arrangement of the headers depending on page as default
    private defaultArrangementList = [
        'number',
        'actions',
        'sys_created_on',
        'sys_updated_on',
        'JCEF_srcIP',
        'JCEF_srcPort',
        'JCEF_dstIP',
        'JCEF_dstPort'
    ];
    private eventsArrangementList = [
        'view',
        'ISODATE',
        'sys_created_on',
        'sys_updated_on',
        'JCEF_srcIP',
        'JCEF_srcPort',
        'JCEF_dstIP',
        'JCEF_dstPort'
    ];
    private incidentsArrangementList = [
        'number',
        'u_contact_time',
        'sys_updated_on',
        'category'
    ];
    // Can add more ArrangementList for different tables as needed

    sortList(displayedColumns, page?: string) {
        // Final var to be returned
        let sortedData = [];
        // Deep copy of data coming in to be manipulated
        let columnNames = cloneDeep(displayedColumns);
        // Map of what headers should come first
        let headerArrangementList: string[];

        // Below list and order determines what user will see in tables
        if (page === 'events') {
            headerArrangementList = this.eventsArrangementList;
            // View needs to be pushed into the list for events section
            columnNames.push('view');
        } else if (page === 'incident') {
            headerArrangementList = this.incidentsArrangementList;
        } else if (page === 'default') {
            headerArrangementList = this.defaultArrangementList;
        } else {
            headerArrangementList = this.defaultArrangementList;
        }

        // This next group of logic can be simplified in the future to handle either a pure list of strings or a list of TableHeader items
        if (columnNames[0].name) {
            headerArrangementList.forEach((headerItem) => {
                // Find the column headers that need to go first Actions / Updated / Created & Incident Number if present
                if (columnNames.find(element => element.name === headerItem)) {
                    // Append Actions to front of headers array
                    sortedData.push(columnNames.find(element => element.name === headerItem));
                    columnNames.splice(columnNames.findIndex(item => item.name === headerItem), 1);
                }
            });
            // Append rest of column headers to array
            columnNames.forEach((element) => {
                sortedData.push(element);
            });
        } else {
            headerArrangementList.forEach((headerItem) => {
                // Find the column headers that need to go first Actions / Updated / Created & Incident Number if present
                if (columnNames.find(element => element === headerItem)) {
                    // Append Actions to front of headers array
                    sortedData.push(columnNames.find(element => element === headerItem));
                    columnNames.splice(columnNames.findIndex(item => item === headerItem), 1);
                }
            });
            // Append rest of column headers to array
            columnNames.forEach((element) => {
                sortedData.push(element);
            });
        }

        return sortedData;
    }
}
