<div class="dialog-wrapper">
  <h2 mat-dialog-title>{{ data.title }}</h2>

  <mat-dialog-content>
    <p class="error" *ngIf="error">
      AN error occurred while creating secret key
    </p>

    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Customer ID</mat-label>
        <input
          matInput
          placeholder="Customer ID"
          formControlName="customer_id"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Key Type</mat-label>
        <input matInput placeholder="Key Type" formControlName="key_type" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Key</mat-label>
        <input matInput placeholder="Key" formControlName="key" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Value</mat-label>
        <textarea
          matInput
          placeholder="Key Value"
          formControlName="value"
        ></textarea>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button color="warn" (click)="cancel()">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="form.invalid || loading"
      (click)="submit()"
    >
      Submit
    </button>
  </mat-dialog-actions>
</div>
