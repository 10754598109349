<div class="product">
    <button *ngIf="product?.children?.length > 0" mat-icon-button [attr.aria-label]="'Toggle ' + product.name"
        (click)="toggleExpand()">
        <mat-icon class="mat-icon-rtl-mirror">
            {{ product?.expanded ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
    </button>
    <div [ngClass]="{'indent-product': product?.children?.length <= 0}" class="leaf-product">
        <mat-checkbox [(ngModel)]="product.selected" (change)="toggleCheck($event)" class="checklist-leaf-node">{{product.friendly}}
        </mat-checkbox>

        <ng-container *ngFor="let attr of product.attributes; let index=index">
            <div class="product-attributes">
                <mat-form-field *ngIf="attr?.type === 'Int'" class="tree-form-field">
                    <input matInput [(ngModel)]="attr.value" type="number" min="0" step="1"
                        [placeholder]="attr?.friendly" [disabled]="!!!product.selected">
                </mat-form-field>

                <mat-form-field *ngIf="attr?.type  === 'String'" class="tree-form-field">
                    <input matInput [(ngModel)]="attr.value" type="text" [placeholder]="attr?.friendly" [disabled]="!!!product.selected">
                </mat-form-field>

                <mat-form-field *ngIf="attr?.value?.type  === 'Float'" class="tree-form-field">
                    <input matInput [(ngModel)]="attr.value" type="number" min="0" [placeholder]="attr?.friendly" [disabled]="!!!product.selected">
                </mat-form-field>
            </div>
        </ng-container>
    </div>
</div>

<div class="child-block" *ngIf="product?.children?.length > 0 && !!product.expanded">
    <app-product-node *ngFor="let child of product.children; let index=index" [product]="child" [index]="index" [expanded]="product.expanded"></app-product-node>
</div>