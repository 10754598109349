import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ReportingRoutingModule } from './reporting-routing.module';
import { ReportingComponent } from './components/reporting/reporting.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRadioModule } from '@angular/material/radio';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { MatNativeDateModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { SweetSharedModule } from '../../sweet-shared/sweet-shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { CreateReportComponent } from './components/create-report/create-report.component';
import { ReportHistoryComponent } from './components/report-history/report-history.component';
import { CronEditorModule } from 'cron-editor';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormlyModule } from '@ngx-formly/core';
import { ReportGeneralInformationComponent } from './components/report-general-information/report-general-information.component';
import { StoreModule } from '@ngrx/store';
import * as fromReport from './store/report.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ReportEffects } from './store/report.effects';
import { MatChipsModule } from '@angular/material/chips';
import { ReportSchedulerInformationComponent } from './components/report-scheduler-information/report-scheduler-information.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReportNotificationInformationComponent } from './components/report-notification-information/report-notification-information.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { ReportingService } from './services/forms/reporting.service';
import { MayaDatePipe } from './pipes/maya-date.pipe';
import { InputVariableDialogFormComponent } from './forms/input-variable-dialog-form/input-variable-dialog-form.component';
import { DatetimePickerModule } from 'src/app/sweet-shared/datetime-picker/datetime-picker.module';
import { ReportModule } from '@modules/report/report.module';


@NgModule({
  declarations: [ReportingComponent, CreateReportComponent, ReportHistoryComponent, ReportGeneralInformationComponent, ReportSchedulerInformationComponent, ReportNotificationInformationComponent, MayaDatePipe, InputVariableDialogFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    MatNativeDateModule,
    ReportingRoutingModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatIconModule,
    FlexLayoutModule,
    MatRadioModule,
    MatChipsModule,
    OwlDateTimeModule,
    MatOptionModule,
    MatSelectModule,
    SweetSharedModule,
    SweetSharedModule,
    MatCardModule,
    MatMenuModule,
    MatDatepickerModule,
    FlexLayoutModule,
    CronEditorModule,
    MatCheckboxModule,
    MatDatepickerModule,
    FormlyModule,
    StoreModule.forFeature(fromReport.reportFeatureKey, fromReport.reducer),
    EffectsModule.forFeature([ReportEffects]),
    DatetimePickerModule,
    ReportModule
  ],
  exports: [
    CreateReportComponent
  ],
  providers: [TitleCasePipe, ReportingService]
})
export class ReportingModule { }
