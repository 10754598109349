import {Action, createReducer, on} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {User} from './user.model';
import * as UserActions from './user.actions';

export const usersFeatureKey = 'users';

export interface State extends EntityState<User> {
  loadingUsers: boolean;
  error: string;
  users: User[];
  userCreated: any;
  currentUser: User;
  currentUserError: string;
  currentUserLoading: boolean;
  dialogOpen: boolean;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: State = adapter.getInitialState({
  loadingUsers: false,
  error: null,
  users: null,
  userCreated: '',
  currentUser: null,
  currentUserLoading: false,
  currentUserError: null,
  dialogOpen: null
});


const userReducer = createReducer(
  initialState,
  on(UserActions.loadUsers, (state) => {
      return {
        ...state,
        loadingUsers: true,
        error: null
      };
    }
  ),
  on(UserActions.loadUsersSuccess, (state, action) => {
      return {
        ...state,
        loadingUsers: false,
        error: null,
        users: action.users
      };
    }
  ),
  on(UserActions.loadUsersError, (state, action) => {
      return {
        ...state,
        loadingUsers: false,
        error: action.error
      };
    }
  ),
  on(UserActions.loadUser, (state) => {
      return {
        ...state,
        currentUserLoading: true,
        currentUserError: null
      };
    }
  ),
  on(UserActions.loadUserError, (state, action) => {
      return {
        ...state,
        currentUserLoading: false,
        currentUserError: action.error
      };
    }
  ),
  on(UserActions.loadUserSuccess, (state, action) => {
      return {
        ...state,
        currentUserLoading: false,
        currentUser: action.user
      };
    }
  ),
  on(UserActions.editUser, (state) => {
    return {
      ...state,
      loadingUsers: true,
      currentUserLoading: true
    };
  }),
  on(UserActions.editUserSuccess, (state, action) => {
      const usersCopy = [...state.users];
      const oldUserIndex = usersCopy.findIndex(user => user.sub === action.user.sub);
      usersCopy.splice(oldUserIndex, 1, action.user);
      return {
        ...state,
        loadingUsers: false,
        currentUserLoading: false,
        users: usersCopy,
        currentUser: action.user
      };
    }
  ),
  on(UserActions.editUserError, (state, action) => {
      return {
        ...state,
        loadingUsers: false,
        currentUserLoading: false,
        error: action.error
      };
    }
  ),
  on(UserActions.deleteUser, (state) => {
      return {
        ...state,
        loadingUsers: true,
        currentUserLoading: true,
        dialogOpen: true
      };
    }
  ),
  on(UserActions.deleteUserSuccess, (state, action) => {
      const newUsers = [...state.users].filter(user => user.sub !== action.user.sub);
      return {
        ...state,
        loadingUsers: false,
        currentUserLoading: false,
        users: newUsers,
        dialogOpen: false
      };
    }
  ),
  on(UserActions.deleteUserError, (state, action) => {
      return {
        ...state,
        loadingUsers: false,
        currentUserLoading: false,
        error: action.error,
        dialogOpen: false
      };
    }
  ),
  on(UserActions.openDialog, (state) => {
      return {
        ...state,
        dialogOpen: true
      };
    }
  ),
  on(UserActions.forceLoadingOff, (state) => {
      return {
        ...state,
        loadingUsers: false,
        currentUserLoading: false
      };
    }),
  on(UserActions.addUser,
    (state, action) => {
      return {
        ...state,
        users: [action.user, ...state.users]
      };
    }),
  on(UserActions.setCurrentUser,
    (state, action) => {
      return {
        ...state,
        currentUser: action.user
      };
    }),
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

