import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TableComponent } from '../../table/table.component';
import { DrawChartService } from '../../../services/draw-chart.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from '../../../../pages/dashboards/services/dashboard.service';
import { AppService } from '../../../../app.service';
import { DateService } from '../../../../shared-services/date.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ReportsWarningComponent } from '../../../../pages/dashboards/reports-warning/reports-warning.component';
import { isPlatformBrowser } from '@angular/common';
import { AthenaFilterStatement } from '../../../models/widget.model';

@Component({
  selector: 'app-table-widget-header',
  templateUrl: './table-widget-header.component.html',
  styleUrls: ['./table-widget-header.component.scss']
})
export class TableWidgetHeaderComponent implements OnInit, OnDestroy {

  @Input() widget: any = null;
  @Input() height: number;
  @Input() parentDashboard: any;
  @Input() eventDateRange: any;
  @Input() filterAction: EventEmitter<any> = new EventEmitter();
  @Input() newWidgetAction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('tableComponent') tableComponent: TableComponent;
  @Output() widgetAction: EventEmitter<any> = new EventEmitter();
  tableHeader: BehaviorSubject<any[]> = new BehaviorSubject([]);
  tableData: BehaviorSubject<any[]> = new BehaviorSubject([]);


  private destroyer$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  loading = true;
  error: string = null;
  _filters = null;

  constructor(private drawChartService: DrawChartService,
    private dialog: MatDialog,
    private http: HttpClient,
    private zone: NgZone,
    private dateService: DateService,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef,
    private widgetService: AppService<any[]>,
    @Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit(): void {
    // subscribe to the filter actions
    this.filterAction.pipe(takeUntil(this.destroyer$)).subscribe(filtersEvent => {
      this._filters = filtersEvent;
      this.setInputVariablesAndGetData(filtersEvent);
    });

    // when new widget is created
    this.newWidgetAction.pipe(
      filter(event => event.widgetId === this.widget.widget_id),
      takeUntil(this.destroyer$)
    ).subscribe(newWidgetAction => {
      if (newWidgetAction.name === 'NEW_WIDGET') {
        const company = newWidgetAction.data.company;
        const data = this.dateService.dataRange(newWidgetAction.data.dateRange, this.eventDateRange);
        const dateFrom = data.dateFrom;
        const dateTo = data.dateTo;
        const newWidgetParams = { company, dateFrom, dateTo };
        this._filters = { company, dateFrom, dateTo };
        this.setInputVariablesAndGetData(newWidgetParams);
      } else if (newWidgetAction.name === 'EDITED_WIDGET') {
        this.widget = {
          ...this.widget,
          ...newWidgetAction.data
        };
        this.setInputVariablesAndGetData(this._filters);
      } else if (newWidgetAction.name === 'DELETED_WIDGET') {
        this.widgetAction.emit(newWidgetAction);
      }
    });
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  // Method to check if an input variable is being used in conjunction with an operator
  // that expects an array of 'values', i.e. 'in', 'between', 'incidr'
  isArrayOperator(variableName: string, filterStatement: AthenaFilterStatement): boolean {
    // Iterate through filter-statement properties
    for (const key in filterStatement) {
      if (filterStatement.hasOwnProperty(key)) {
        // Each property should be an array of rules
        for (const rule of filterStatement[key]) {
          // If the rule has a 'values'(plural) property, then it is being used with array-friendly operator, e.g. 'in'
          if (rule.hasOwnProperty('comparison') && rule.hasOwnProperty('values')) {
            // Check if the 'values' property is set to the input variable passed in as 'variablename'
            const escapedValue = variableName.replace('$', '\\$');
            const regex = new RegExp(`{{\\s*${escapedValue}\\s*}}`, 'g');
            // If it's a match, return true and exit
            if (regex.test(rule.values)) {
              return true;
            }
          }
        }
        // Athena statements can be nested however deep with 'and's and 'or's, so check recursively
      } else if (filterStatement[key].hasOwnProperty('and') || filterStatement[key].hasOwnProperty('or')) {
        return this.isArrayOperator(variableName, filterStatement[key].and || filterStatement[key].or);
      }
    }
    return false;
  }

  setInputVariablesAndGetData(filters): void {
    // Overwrite input variable values, if widget has any
    if (this.widget.input_variables?.length) {
      // Get all the names of the setting widgets that have values, i.e. that are are emitted
      const settingWidgets = filters.settingWidgetData || [];

      const selectedSetttings = settingWidgets.map(settingWidgetVal => {
        return Object.keys(settingWidgetVal)[0];
      });
      // Determine which of those emitting setting widgets are being listened to by this widget
      const emittingWidgets = this.widget.input_variables.filter(variable => {
        return selectedSetttings.includes(variable.input_variable_name);
      });
      // and which have not emitted
      const nonEmittingWidgets = this.widget.input_variables.filter(variable => {
        return !selectedSetttings.includes(variable.input_variable_name);
      });
      // add the non emitted variables into the filters
      filters = nonEmittingWidgets.reduce((prev, next) => {
        prev[next.input_variable_name] = next.default_value;
        return prev;
      }, filters);
      // overwriting all input variables that have emitted since they take precedence
      filters = emittingWidgets.reduce((prev, next) => {
        prev[next.input_variable_name] = settingWidgets.find(widget => Object.keys(widget)[0] === next.input_variable_name)[next.input_variable_name] || next.default_value;
        return prev;
      }, filters);

    }
    // copying so original not altered
    const updatedFilters = { ...filters };
    // Remove input variables n/a to this widget
    for (const key in updatedFilters) {
      if (updatedFilters.hasOwnProperty(key)) {
        // But not these query-params
        if (['company', 'dateFrom', 'dateTo'].includes(key)) {
          continue;
        }
        // If it's not found in the widgets list of input variables, delete it
        if (this.widget.input_variables && !this.widget.input_variables.find(variable => key === variable.input_variable_name)) {
          delete updatedFilters[key];
        }
      }
    }
    // remove settingWidgetData, unneeded for request
    delete updatedFilters.settingWidgetData;
    // Need to send values as array if used with 'in' 'between' or 'incidr', and as string if not
    for (const key in updatedFilters) {
      if (updatedFilters.hasOwnProperty(key)) {
        // If the input variable is NOT used with an operator that works on an array of values ('in', etc.),
        // then just use the first value from the array, which should be a string
        // otherwise, send the array as is
        if (!this.isArrayOperator(key, this.widget.params.athenaStatement.standard[0].where_statement.filter)) {
          if (Array.isArray(updatedFilters[key])) {
            updatedFilters[key] = updatedFilters[key][0];
          }
        }
      }
    }
    this.fetchWidgetDataPost(updatedFilters);
  }


  fetchWidgetDataPost(widgetData, filters?): void {
    this.loading = true;
    if (!filters) {
      filters = this._filters ? this._filters : {};
    } else {
      this._filters = filters;
    }
    this.error = null;
    this.widgetService.put('widgets', `${this.widget.dashboard_id}/${this.widget.widget_id}/get-data`, null, filters)
      .then(res => {
        this.getDataSuccessHandler(res, filters);
      })
      .catch(err => {
        this.getDataErrorHandler(err);
      });
  }

  getDataSuccessHandler(res, filters) {
    if (res.status === 202) {
      return this.fetchWidgetData(filters);
    } else {
      this.browserOnly(() => {
        this.fetchTableData(res.url)
          .then((data: any[]) => {
            this.headerBuilder();
            this.tableComponent.data.next(data);
            this.cdr.detectChanges();
          })
          .catch((error: any) => {
            this.error = error.message;
            this.loading = false;
          });

        this.loading = false;
      });
    }
  }


  getDataErrorHandler(error: any): void {
    this.error = error.message;
    this.loading = false;
  }


  fetchWidgetData(filters?: object) {
    this.fetchWidgetDataPost(filters)
    // console.log('filters', filters)
    // this.loading = true;
    // if (!filters) {
    //   filters = this._filters ? this._filters : {};
    // } else {
    //   this._filters = filters;
    // }
    //
    // this.error = null;
    // this.widgetService.get('widgets', `${this.widget.dashboard_id}/${this.widget.widget_id}/get-data`, filters)
    //   .then(
    //     (res: any | { data: any[], series: string[] }) => {
    //       this.getDataSuccessHandler(res, filters);
    //     })
    //   .catch(
    //     (error: any) => {
    //       this.getDataErrorHandler(error);
    //     }
    //   );
  }

  private fetchTableData(url: string): Promise<any | any[]> {
    return this.http.get(url).pipe(takeUntil(this.destroyer$)).toPromise();
  }

  editWidget() {
    this.dashboardService.editWidget(this.widget, this.parentDashboard, this.newWidgetAction);
  }

  deleteDialog(): void {
    if (this.parentDashboard?.reports?.length) {
      const dialogRef: MatDialogRef<ReportsWarningComponent> = this.dialog.open(ReportsWarningComponent, {
        width: '800px',
        disableClose: true,
        autoFocus: false,
        maxHeight: '90vh',
        panelClass: 'ctl-panel-class'
      });
      dialogRef.componentInstance.reports = this.parentDashboard.reports;
      dialogRef.componentInstance.events.pipe(takeUntil(this.destroyer$)).subscribe(event => {
        if (event === 'CANCEL') {
          dialogRef.close();
        } else if (event === 'PROCEED') {
          dialogRef.close();
          this.dashboardService.deleteWidget(this.widget, this.newWidgetAction);
          this.setInputVariablesAndGetData(this._filters);
        } else if (event === 'DUPLICATE') {
          dialogRef.close();
          this.dashboardService.onDuplicate(this.parentDashboard);
        }
      });
    } else {
      this.dashboardService.deleteWidget(this.widget, this.newWidgetAction);
    }
  }

  private headerBuilder(): any {
    this.tableHeader.next(
      this.widget.params.athenaStatement.standard[0].select_statement.map((item) => {
        return {
          friendly: item.alias.toUpperCase(),
          name: item.alias,
          description: ''
        };
      })
    );
  }

  ngOnDestroy() {
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }
}

