<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<h2 mat-dialog-title>Edit User</h2>
<mat-dialog-content>
  <form [formGroup]="userForm">
  <mat-form-field class="example-full-width" fxFlexFill>
    <mat-label>Profile</mat-label>
    <input  matInput formControlName="profile" name="profile">
  </mat-form-field>

  <mat-form-field class="example-full-width" fxFlexFill>
    <mat-label>Username</mat-label>
    <input matInput formControlName="username" name="username">
  </mat-form-field>

  <mat-form-field class="example-full-width" fxFlexFill>
    <mat-label>First Name</mat-label>
    <input formControlName="given_name" matInput name="given_name">
  </mat-form-field>

  <mat-form-field class="example-full-width" fxFlexFill>
    <mat-label>Last Name</mat-label>
    <input formControlName="family_name" matInput name="family_name">
  </mat-form-field>

  <mat-form-field class="example-full-width" fxFlexFill>
    <mat-label>Email</mat-label>
    <input formControlName="email" matInput placeholder="Ex. pat@example.com" name="email">
  </mat-form-field>

  <mat-form-field fxFlexFill>
    <mat-label>Role(s)</mat-label>
    <mat-select multiple formControlName="gender">
      <mat-option *ngFor="let role of roles" [value]="role.value">
        {{role.friendly}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field fxFlexFill *ngIf="false">
    <mat-label>Permission(s)</mat-label>
    <mat-select multiple formControlName="gender">
      <mat-option *ngFor="let permission of permissions" [value]="permission.value">
        {{permission.friendly}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div  fxLayout="row" fxLayoutAlign="end center">
    <button mat-button color="warn" type="submit" (click)="onSubmit('cancel')">
      <mat-icon>cancel</mat-icon>
      Clear
    </button>
   <button mat-raised-button color="primary" type="submit" (click)="onSubmit('save')">
     <mat-icon>done</mat-icon>
     Submit</button>
  </div>
</form>
</mat-dialog-content>
