import {Component, forwardRef, Input} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {input} from '@aws-amplify/ui';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';


@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeComponent),
      multi: true
    }
  ]
})
export class DatetimeComponent implements ControlValueAccessor {

  // minDate: Date = new Date('01/01/1970');
  hideTime = false;
  disableMinute = false;
  enableMeridian = true;
  color = 'primary';
  touchUi = false;
  stepSecond = [1, 5, 10, 15, 20, 25];
  stepMinute = [1, 5, 10, 15, 20, 25];
  stepHour = [1, 2, 3, 4, 5];
  showSeconds = true;
  showSpinners = true;
  disabled = false;

  @Input() placeholder = '';
  @Input() _dateValue;
  @Input() minDate: Date;
  @Input() title: string;


  get dateValue() {
    // return moment(this._dateValue, 'YYYY/MM/DD HH:mm:ss');
    return this._dateValue;

  }

  set dateValue(val) {
    this._dateValue = val;
    this.propagateChange(this._dateValue);
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    // this.dateValue = moment(event.value, 'YYYY/MM/DD HH:mm:ss');
    this.dateValue = event.value;

  }

  writeValue(value: any) {
    if (value !== undefined) {
      // this.dateValue = moment(value, 'YYYY/MM/DD HH:mm:ss');
      this.dateValue = value;

    }
  }

  propagateChange = (_: any) => {
  };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }


}
