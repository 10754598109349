<section class="dialog-wrapper">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <div class="error" *ngIf="error">{{ error }}</div>

  <mat-dialog-content>
    <mat-horizontal-stepper #stepper (selectionChange)="selectionChange($event)">
      <mat-step [stepControl]="generalForm">
        <form [formGroup]="generalForm" *ngIf="generalForm">
          <ng-template matStepLabel>General</ng-template>
          <!-- company name -->
          <mat-form-field appearance="outline">
            <mat-label>Company Name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>

          <!-- company ID -->
          <mat-form-field appearance="outline">
            <mat-label>Company ID</mat-label>
            <input matInput formControlName="id" />
          </mat-form-field>

          <!-- Parent Company -->
          <mat-form-field appearance="outline">
            <mat-label>Parent Company</mat-label>
            <input matInput formControlName="parent" />
          </mat-form-field>

          <!-- Access Attributes -->
          <div class="control-wrapper">
            <h2>Access Attributes</h2>
            <mat-form-field>
              <mat-label>Access Attributes</mat-label>
              <mat-select formControlName="accessAttributes" multiple>
                <mat-option *ngFor="let attr of accessAttributes" [value]="attr">{{ attr }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="productForm">
        <form *ngIf="productForm">
          <ng-template matStepLabel>Products/Attributes</ng-template>
          <!-- Product tree -->
          <div class="product-tree">
            <app-product-node *ngFor="let product of productTree; let index=index" [product]="product" [index]="index" [expanded]="product.expanded">

            </app-product-node>
          </div>
        </form>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>IP/Host Settings</ng-template>
        <form [formGroup]="configForm">
          <div class="control-wrapper">
            <h2>IPv4 Networks</h2>
            <section class="add-more-container">
              <button mat-icon-button color="primary" type="button" (click)="addCidr()" [disabled]="cidrDisabled()">
                <mat-icon>add</mat-icon>
              </button>
              <mat-form-field>
                <mat-label>IPv4 Network (e.g. 192.168.1.0/24)</mat-label>
                <input matInput autocomplete="off" formControlName="sourceIPV4CIDRs" name="sourceIPV4CIDRs" />
              </mat-form-field>
            </section>
            <mat-chip-listbox class="chip-wrapper">
              <mat-chip-option *ngFor="let ip of sourceIPV4CIDRs" [removable]="true">{{ ip }}
                <mat-icon (click)="removeCidr(ip)">cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>

          <div class="control-wrapper">
            <h2>IPv4 Addresses</h2>
            <section class="add-more-container">
              <button mat-icon-button color="primary" type="button" (click)="addIp()" [disabled]="disabledIPv4()">
                <mat-icon>add</mat-icon>
              </button>
              <mat-form-field>
                <mat-label>IPv4 Address (e.g. 192.168.1.0)</mat-label>
                <input matInput autocomplete="off" formControlName="sourceIPs" name="sourceIPs" />
              </mat-form-field>
            </section>
            <mat-chip-listbox class="chip-wrapper">
              <mat-chip-option *ngFor="let source of sourceIPs" [removable]="true" color="primary">
                {{ source }}
                <mat-icon (click)="removeIp(source)">cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>

          <!-- Source hostnames -->
          <div class="control-wrapper">
            <h2>Hostnames</h2>
            <section class="add-more-container">
              <button mat-icon-button color="primary" type="button" (click)="addHostname()"
                [disabled]="disabledSourceHosts()">
                <mat-icon>add</mat-icon>
              </button>
              <mat-form-field>
                <mat-label>Hostname (e.g. myhost.example.com)</mat-label>
                <input matInput autocomplete="off" formControlName="sourceHosts" name="sourceHosts" />
              </mat-form-field>
            </section>
            <mat-chip-listbox class="chip-wrapper">
              <mat-chip-option *ngFor="let source of sourceHosts" [removable]="true">
                {{ source }}
                <mat-icon (click)="removeHostname(hostname)">cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
  <!-- action for the step -->
  <mat-dialog-actions>
    <button mat-button color="warn" (click)="close()">Cancel</button>
    <button mat-stroked-button type="button" (click)="next()" [disabled]="
        selectedIndex === 0 ? generalForm?.invalid : productForm?.invalid
      " *ngIf="selectedIndex < numSteps - 1">
      Next<mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button mat-stroked-button color="primary" (click)="submit()" [disabled]="configForm.invalid || loading"
      *ngIf="selectedIndex >= numSteps - 1">
      Submit
    </button>
  </mat-dialog-actions>
</section>
