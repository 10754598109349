<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<form [formGroup]='form' fxLayout='column'>
  <mat-toolbar *ngIf='title'>
    <h6 mat-dialog-title>{{title}}</h6>
    <span class="example-fill-remaining-space"></span>
  </mat-toolbar>

  <div fxLayout='row wrap' fxLayoutAlign='start'>
    <div fxFlex='100%' *ngIf='forgotPasswordMessage' fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='10px'>
      <p>{{forgotPasswordMessage}}</p>
    </div>
    <div fxFlex='100%' class='error' *ngIf='
        error' fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='10px'>
      <mat-icon>error</mat-icon>
      <span>{{error}}</span>
    </div>
    <div *ngFor='let field of fieldDetails' [fxFlex]='field.flex' fxFill>
      <!-- Input text field -->
      <mat-form-field class="example-full-width" *ngIf="field.component === 'input-text' && !field.hide" fxFlex fxFlexOffset='10px'>
        <mat-label>{{field.label}}</mat-label>
        <input class="bold-text" [autocomplete]="field.autocomplete" matInput [type]='field.type'
          [placeholder]="field.placeholder" [formControlName]="field.name" [required]='field.required'>
      </mat-form-field>

      <!-- Input text area -->
      <mat-form-field class="example-full-width" *ngIf="field.component === 'input-textarea'" fxFlex fxFlexOffset='10px'
        fxLayoutGap='50px'>
        <mat-label>{{field.label}}</mat-label>
        <textarea matInput [placeholder]="field.placeholder" [rows]="field.rows" [formControlName]='field.name'
          [required]='field.required'></textarea>
      </mat-form-field>

      <!-- Input Select -->
      <div *ngIf="!field.hide">
        <mat-form-field *ngIf="field.component === 'input-select'" fxFlex fxFlexOffset='10px'>
          <mat-label>{{field.label}}</mat-label>
          <mat-select [formControlName]='field.name' [multiple]='field.selectMultiple' [required]='field.required'>
            <div *ngIf="field.showSearch">
              <mat-option>
                <ngx-mat-select-search #sText [formControl]="field.showSearchFormControl"
                  [placeholderLabel]="'Search ' + field.label" noEntriesFoundLabel="No results found">
                </ngx-mat-select-search>
              </mat-option>

              <mat-checkbox [checked]="handleChecked(field.name, field.options)" class="mat-option" color="primary"
                *ngIf="field.showSelectAll && field.selectMultiple && field.options.length > 1"
                (click)="toggleAllOptions(field.name, field.options, $event)">{{handleChecked(field.name, field.options)
                ? 'Unselect All' : 'Select All'}}</mat-checkbox>
              <mat-option *ngFor="let option of field.options | inputSearch: sText.value" [value]="option.value">
                {{option.friendly}}
              </mat-option>

            </div>

            <div *ngIf="!field.showSearch">
              <mat-checkbox [checked]="handleChecked(field.name, field.options)" class="mat-option" color="primary"
                *ngIf="field.showSelectAll && field.selectMultiple && field.options.length > 1"
                (click)="toggleAllOptions(field.name, field.options, $event)">{{handleChecked(field.name, field.options)
                ? 'Unselect All' : 'Select All'}}</mat-checkbox>

              <mat-option *ngFor="let option of field.options" [value]="option.value">
                {{option.friendly}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
        <div class="clear-button" *ngIf="field.showClearButton">
          <mat-icon (click)=clear(field)>history</mat-icon>
        </div>
      </div>

      <!-- Input Multiple Select -->
      <mat-form-field *ngIf="field.component === 'input-select-group'" fxFlex fxFlexOffset='10px'>
        <mat-label>{{field.label}}</mat-label>
        <mat-select [formControlName]='field.name' [multiple]='field.selectMultiple'>
          <div *ngIf="field.showSearch">
            <mat-option>
              <ngx-mat-select-search [formControl]="field.showSearchFormControl"
                [placeholderLabel]="'Search ' + field.label" noEntriesFoundLabel="No results found">
              </ngx-mat-select-search>
            </mat-option>


            <mat-optgroup *ngFor="let group of field.groupOption" [label]="group.groupName | titlecase"
              [disabled]="group.disabled">
              <mat-option #allSelected (click)="toggleAllSelection(field.name, group.option)" [value]="0">Select All
              </mat-option>
              <mat-option *ngFor="let value of filterField(group.option, field.name)" [value]="value.value"
                (click)="toggleOne(field.name ,allSelected.viewValue, group.option)">
                {{value.friendly}}
              </mat-option>
            </mat-optgroup>
          </div>


          <div *ngIf="!field.showSearch">
            <mat-optgroup *ngFor="let group of field.groupOption" [label]="group.groupName | titlecase"
              [disabled]="group.disabled">
              <mat-option *ngFor="let value of group.option" [value]="value.value">
                {{value.friendly}}
              </mat-option>
            </mat-optgroup>

          </div>
          <div class="clear-button" *ngIf="field.showClearButton">
            <mat-icon (click)=clear(field)>history</mat-icon>
          </div>
        </mat-select>
      </mat-form-field>


      <!-- Input Checkbox -->
      <div *ngIf="field.component === 'input-checkbox'" fxFlex fxFlexOffset='10px'>
        <mat-checkbox [formControlName]='field.name' color="primary">{{field.label}}</mat-checkbox>
      </div>

      <!--- Date Time Input -->
      <div *ngIf="!field.hide" [ngClass]="field.hide == false ? 'show' : 'hide'">
        <mat-form-field *ngIf="field.component === 'input-date-time'" fxFlex fxFlexOffset='10px'>
          <mat-label>{{field.label}}</mat-label>
          <input [autocomplete]="field.autocomplete" [selectMode]="'range'" matInput [formControlName]="field.name"
            [required]='field.required' [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
            [placeholder]="field.placeholder">
          <owl-date-time #dt1></owl-date-time>
        </mat-form-field>
      </div>

      <!-- Datepicker -->
      <div *ngIf="field.component === 'input-datepicker'" fxFlex fxFlexOffset='10px'>
        <app-datetime-picker [label]="field.label" [defaultValue]="field.defaultValue" [appearance]="field?.appearance" [options]="field.options"
          (onDateSelected)="updateDatePicker($event)"></app-datetime-picker>
      </div>

      <!--- Radio Group Input -->
      <div *ngIf="field.component === 'input-radio-group'" fxLayout="column" fxFlexOffset='10px'>
        <h3>{{ field.label }}</h3>
        <mat-radio-group [formControlName]="field.name">
          <mat-radio-button *ngFor="let option of field.options" [value]="option.value" color="primary">
            {{option.friendly}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <ng-content></ng-content>

  <div fxLayout='row' [fxLayoutAlign]='actionButtons.flex' *ngIf="actionButtons">
    <div *ngFor='let button of actionButtons.buttons'>
      <div *ngIf='button.type === "basic"'>
        <button mat-button class="{{ button.class }}" [color]="button.color" [disabled]='loading'
          (click)='onActionClick(button.name)' [disabled]='handleDisabling(button)'>
          <mat-icon *ngIf='button.iconName'>{{button.iconName}}</mat-icon>
          {{button.label}}
        </button>
      </div>

      <div *ngIf='button.type === "stroked"'>
        <button class="{{ button.class }}" mat-stroked-button [color]="button.color" type='button'
          (click)='onActionClick(button.name)' *ngIf='!button.iconOnly' [disabled]='handleDisabling(button)'>
          <mat-icon *ngIf='button.iconName'>{{button.iconName}}</mat-icon>
          {{button.label}}
        </button>
        <button class="{{ button.class }}" mat-icon-button (click)='onActionClick(button.name)' *ngIf='button.iconOnly'
          [disabled]='handleDisabling(button)'>
          <mat-icon>{{button.iconName}}</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="actionButtons && actionButtons.menuButtons && actionButtons.menuButtons.length">
      <button mat-stroked-button [matMenuTriggerFor]="menu" class="menu_trigger_button" [disabled]="loading">
        <mat-icon>format_list_bulleted</mat-icon>Actions
      </button>
      <mat-menu #menu="matMenu">
        <ng-container *ngFor="let button of actionButtons.menuButtons">
          <div *ngIf='button.type === "basic"'>
            <button class="{{ button.class }}" mat-menu-item [color]="button.color" [disabled]='loading'
              (click)='onActionClick(button.name)' [disabled]='handleDisabling(button)'>
              <mat-icon *ngIf='button.iconName'>{{button.iconName}}</mat-icon>
              {{button.label}}
            </button>
          </div>

          <div *ngIf='button.type === "stroked"'>
            <button class="{{ button.class }}" mat-stroked-button [color]="button.color" type='button'
              (click)='onActionClick(button.name)' *ngIf='!button.iconOnly' [disabled]='handleDisabling(button)'>
              <mat-icon *ngIf='button.iconName'>{{button.iconName}}</mat-icon>
              {{button.label}}
            </button>

            <button class="{{ button.class }}" mat-icon-button (click)='onActionClick(button.name)'
              *ngIf='button.iconOnly' [disabled]='handleDisabling(button)'>
              <mat-icon>{{button.iconName}}</mat-icon>
            </button>
          </div>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</form>
