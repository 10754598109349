<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<section class="incident">
  <h2>Create Incident</h2>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput placeholder="Title" formControlName="title" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea
        matInput
        placeholder="Message"
        formControlName="message"
      ></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Company</mat-label>
      <input matInput placeholder="Company" formControlName="customerId" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Priority</mat-label>
      <mat-select formControlName="priority">
        <mat-option *ngFor="let priority of priorities" [value]="priority">{{
          priority
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox formControlName="status"
      >Set as active investigation</mat-checkbox
    >

    <section class="action-container">
      <button mat-stroked-button color="warn" type="button" (click)="dismiss()">
        Cancel
      </button>
      <button
        mat-raised-button
        color="priamry"
        type="submit"
        [disabled]="form.invalid || loading"
        (click)="createIncident()"
        [ngClass]="{ primary: form.valid && !loading }"
      >
        Create
      </button>
    </section>
  </form>
</section>
