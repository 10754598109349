import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { filter, skip, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { InputVariableService } from '../../services/input-variable.service';
import { RefreshService } from '../../services/refresh.service';
import { WidgetService } from '../../services/widget-service.service';

export const MIN_REFRESH_INTERVAL = 3000;
@Component({
  selector: 'app-widget-view',
  templateUrl: './widget-view.component.html',
  styleUrls: ['./widget-view.component.scss'],
})
export class WidgetViewComponent implements OnInit, OnDestroy {
  destroyer$ = new Subject();
  private apiCall = null;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  error$: BehaviorSubject<string> = new BehaviorSubject('');
  data$: BehaviorSubject<any> = new BehaviorSubject(null);
  widgetDetail$: BehaviorSubject<any> = new BehaviorSubject(null);
  widgetAction$: Subject<any> = new Subject();
  fetchDataSuccess$: Subject<any> = new Subject();
  widgetBaseReady$ = new Subject();
  headers: string[] = [];


  // private variables collecting all the input variables
  inputVariables: any = {
    company: null,
    dateFrom: null,
    dateTo: null
  };

  constructor(
    public widgetService: WidgetService,
    public inputVariableService: InputVariableService,
    public httpService: AppService<any[]>,
    public http: HttpClient,
    public refreshSrv: RefreshService
  ) { }

  ngOnInit(): void {
    this.widgetDetail$.pipe(filter((d: any) => !!d)).subscribe((wd: any) => {
      const wds = wd?.input_variables ?? [];
      this.inputVariables = wds.reduce((prev, next) => {
        prev[next.input_variable_name] = next?.default_value;
        return prev;
      }, this.inputVariables);
      this.widgetBaseReady$.next(true);
    })

    // listen to refresh from input variables and refresh
    this.inputVariableService.refetch$.subscribe(_ => {
      this.refresh();
    })

    // listen to widgetready signal whence we'd switchmap to receiving input vars and fetch data
    this.widgetBaseReady$
      .pipe(
        takeUntil(this.destroyer$),
        filter(signal => !!signal),
        switchMap(() => this.inputVariableService?.inputVariables)
      ).subscribe(val => {
        let shouldFetch = false;
        Object.keys(val).filter((val: string) => !!val).forEach((key: string) => {

          if (val[key] && Object.keys(this.inputVariables).includes(key) && val[key] !== this.inputVariables[key]) {
            this.inputVariables[key] = val[key];
            shouldFetch = true;
          }
        });
        if (shouldFetch) {
          this.fetchData();
        }

      })

    this.fetchDataSuccess$.pipe(
      takeUntil(this.destroyer$),
      switchMap((res: any) => this.http.get(res))
    ).subscribe((data: any[]) => {
      this.data$.next(data);
      this.loading$.next(false);
    })
  }


  fetchData(): void {
    if (Object.values(this.inputVariables).includes(null)) {
      return;
    }
    this.error$.next(null);
    this.loading$.next(true);
    const widgetDetails = this.widgetDetail$.getValue();
    if (!!!widgetDetails) {
      return;
    }

    this.apiCall = this.httpService.put('widgets', `${widgetDetails?.dashboard_id}/${widgetDetails?.widget_id}/get-data`, {}, this.inputVariables, true);
    this.apiCall.then(response => this.fetchDataSuccessHandler(response))
      .catch(error => {
        this.fetchDataErrorHandler(error);
        this.apiCall = null;
      })
  }

  fetchDataSuccessHandler(res: any): void {
    this.apiCall = null;
    if (res.status === 202) {
      this.fetchData();
    } else {
      this.fetchDataSuccess$.next(res.data.url);
    }
  }

  fetchDataErrorHandler(error): void {
    this.error$.next(error.message);
  }

  private ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        line += line !== '' ? ',' + array[i][head] : array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  exportCSV(displayedColumns: any): void {
    const data = this.data$.getValue().map(d => ({...d, Timestamp: new Date(d.Timestamp)}));
    const widgetDetails = this.widgetDetail$.getValue();
    let csvData = this.ConvertToCSV(data, displayedColumns);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", widgetDetails.title + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  refresh(): void {
    this.fetchData();
  }


  handleEvents(payload: any) {
    const widgetDetails = this.widgetDetail$.getValue();
    switch (payload?.type) {

      case 'edit': {
        this.widgetAction$.next({ name: 'EDIT_WIDGET', data: widgetDetails })
        break;
      }

      case 'deleteWidget': {
        this.widgetAction$.next({ name: 'DELETED_WIDGET', data: widgetDetails })
        break;
      }

      case 'exportCSV': {
        this.exportCSV(payload.headers);
        break;
      }

      case 'refresh': {
        this.refresh();
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();

    if (this.apiCall) {
      this.httpService.cancel(this.apiCall);
      this.apiCall = null;
    }
  }

}
