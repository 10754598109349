import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';

import { MapComponent } from '@sweet-shared/sweet-widgets/map/map.component';
import { PieComponent } from '@sweet-shared/sweet-widgets/pie/pie.component';
import { MultiBarComponent } from '@sweet-shared/sweet-widgets/multi-bar/multi-bar.component';
import { FuelGaugeComponent } from '@sweet-shared/sweet-widgets/fuel-gauge/fuel-gauge.component';
import { MultiLineComponent } from '@sweet-shared/sweet-widgets/multi-line/multi-line.component';
import { SolidGaugeComponent } from '@sweet-shared/sweet-widgets/solid-gauge/solid-gauge.component';
import { ActionTypeValue, IMenuOption } from '@sweet-shared/sweet-widgets/menu-bar/menu-bar.component';
import { SingleMetricComponent } from '@sweet-shared/sweet-widgets/single-metric/single-metric.component';
import { filter } from 'rxjs/operators';

export type WidgetComponentName = 'multi_bar' | 'multi_line';

const widgetMapping = {
  'map': MapComponent,
  'pie': PieComponent,
  'multi_bar': MultiBarComponent,
  'fuel_gauge': FuelGaugeComponent,
  'multi_line': MultiLineComponent,
  'solid_gauge': SolidGaugeComponent,
  'single_metric': SingleMetricComponent,
};

@Component({
  selector: 'app-widget-wrapper',
  templateUrl: './widget-wrapper.component.html',
  styleUrls: ['./widget-wrapper.component.scss']
})
export class WidgetWrapperComponent implements OnInit {
  private _height: number;
  private componentRef: ComponentRef<any>;
  dateRange: any = {};

  // define input needed
  @Input() widgetDetails: any;
  @Input() widgetType
  @Input() set height(h) {
    this._height = h;
  };

  get height() {
    return this._height;
  }

  @Output() widgetAction = new EventEmitter();

  // Hook up to the container
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  // Menu actions
  menuItems: IMenuOption[] = [
    {
      label: 'Info',
      icon: 'info',
      action: ActionTypeValue.info,
      visible: true,
    },
    {
      label: 'Edit',
      icon: 'edit',
      action: ActionTypeValue.edit,
      visible: true,
    },
    {
      label: 'Refresh',
      icon: 'refresh',
      action: ActionTypeValue.refresh,
      visible: true,
    },
    {
      label: 'Delete',
      icon: 'delete_forever',
      action: ActionTypeValue.delete,
      visible: true,
    },
    {
      label: 'Full Screen',
      icon: 'fullscreen',
      action: ActionTypeValue.fullScreen,
      visible: false,
    },
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    const component = widgetMapping[this.widgetType];
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = this.container.createComponent(factory) as ComponentRef<typeof component>;

    // Inject the inputs
    this.componentRef.instance.height = this.height;
    this.componentRef.instance.widgetDetails = this.widgetDetails;

    const ivars = this.componentRef.instance?.inputVariablesService.inputVariablesSubject.subscribe(vars => {
      this.dateRange = {
        from: vars?.dateFrom,
        to: vars?.dateTo
      }
    });

    // listen to notifications to refresh widget from app service shell
    this.componentRef.instance?.inputVariablesService.refetch$
      .pipe(
        filter((note: any) => note?.widget_id === this.widgetDetails?.widget_id)
      )
      .subscribe(_ => {
        this.componentRef.instance.refresh();
      })
  }

  handleMenuEvent(action: ActionTypeValue) {
    switch (action) {
      case ActionTypeValue.refresh:
        this.componentRef.instance.refresh();
        break;
      case ActionTypeValue.edit:
        this.widgetAction.emit({ action: action, data: this.widgetDetails });
        break;
      case ActionTypeValue.info:
        this.componentRef.instance.info();
        break;
      case ActionTypeValue.delete:
        this.widgetAction.emit({ action: ActionTypeValue.delete, data: this.widgetDetails });
        break;
    }
  }

}
