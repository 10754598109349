import { Action, createReducer, on } from '@ngrx/store';
import * as ReportActions from './report.actions';

export const reportFeatureKey = 'reports';

export interface State {
  loadingReports: boolean;
  loadingReportsError: string;
  reports: any[];
  deletingReport: boolean;
  deletingReportError: string;
}

export const initialState: State = {
  loadingReports: false,
  loadingReportsError: null,
  reports: [],
  deletingReport: false,
  deletingReportError: null,
};

const reportReducer = createReducer(
  initialState,

  on(ReportActions.loadReports, state => {
    return {
      ...state,
      loadingReports: true,
      loadingReportsError: null
    };
  }),
  on(ReportActions.loadReportsSuccess, (state, action) => {
    return {
      ...state,
      loadingReports: false,
      reports: action.reports
    };
  }),
  on(ReportActions.loadReportsFailure, (state, action) => {
    return {
      ...state,
      loadingReports: false,
      loadingReportsError: action.error
    };
  }),
  on(ReportActions.createReportSuccess, (state, action) => {
    return {
      ...state,
      reports: [...state.reports, action.report]
    };
  }),
  on(ReportActions.updateReportSuccess, (state, action) => {
    return {
      ...state,
      reports: [...state.reports.map(r => r.report_id === action.report.report_id ? action.report : r)]
    };
  }),
  on(ReportActions.deleteReport, state => {
    return {
      ...state,
      deletingReport: true,
      deletingReportError: null
    };
  }),
  on(ReportActions.deleteReportSuccess, (state, action) => {
    return {
      ...state,
      reports: [...state.reports.filter(r => r.report_id !== action.reportId)],
      deletingReport: false,
    };
  }),
  on(ReportActions.deleteReportFailure, (state, action) => {
    return {
      ...state,
      deletingReport: false,
      deletingReportError: action.error
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return reportReducer(state, action);
}
