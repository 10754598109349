import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRoutingModule } from './report-routing.module';
import { ReportGeneralFormComponent } from './components/report-general-form/report-general-form.component';
import { ReportSchedulingFormComponent } from './components/report-scheduling-form/report-scheduling-form.component';
import { ReportEmailFormComponent } from './components/report-email-form/report-email-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  declarations: [
    ReportGeneralFormComponent,
    ReportSchedulingFormComponent,
    ReportEmailFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSelectModule,
    ReportRoutingModule
  ],
  exports: [
    ReportEmailFormComponent,
    ReportGeneralFormComponent,
    ReportSchedulingFormComponent,
  ]
})
export class ReportModule { }
