import { selectProducts } from './products.selector';
import { Store, select } from '@ngrx/store';
import { AppService } from './../../app.service';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ProductActions from './products.actions';
import { State } from '../../reducers';
import { of } from 'rxjs';
import {Product} from './products.model';



@Injectable()
export class ProductsEffects {

  constructor(
    private actions$: Actions,
    private productsService: AppService<Product>,
    private store: Store<State>
  ) {}

  @Effect({dispatch: true})
  loadProducts$ = this.actions$.pipe(
    ofType(ProductActions.loadProducts),
    withLatestFrom(this.store.pipe(select(selectProducts))),
    switchMap(([action, products]) => {
      if (products) {
        return of(ProductActions.loadProductsSuccess({products}));
      } else {
        return this.productsService.get('products', '', null)
          .then((productsResponse) => ProductActions.loadProductsSuccess({products: productsResponse}))
          .catch((error: any) => ProductActions.loadProductsError({error: error.response.message}));
      }
    })
  );
}
