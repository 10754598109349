import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {PermissionService} from '../services/permission.service';

@Directive({
  selector: '[disableUnlessUserCan]'
})
export class DisableUnlessUserCanDirective implements OnInit {
  @Input('disableUnlessUserCan') requiredPermission: string;

  constructor(private elementref: ElementRef, private permissionService: PermissionService) { }

  ngOnInit() {
    if (this.requiredPermission && !this.permissionService.hasPermission(this.requiredPermission)) {
      this.elementref.nativeElement.style.opacity = 0.5;
      this.elementref.nativeElement.style.cursor = 'not-allowed';
      this.elementref.nativeElement.style.textDecoration = 'none';
    }
  }
}
