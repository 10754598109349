import { Injectable } from '@angular/core';
import {AppService} from '../../../app.service';
import {BehaviorSubject} from 'rxjs';
import {LogStreamInterface} from '../../../sweet-shared/models/logstream.model';
import {TableHeader} from '../../../sweet-shared/components/table/table.component';
import {SnackbarService} from '../../../shared-services/snackbar.service';
import {MatDialogRef} from '@angular/material/dialog';
import {LogStreamCreateComponent} from '../log-stream-create/log-stream-create.component';

@Injectable({
  providedIn: 'root'
})
export class LogstreamService {
  private companyLogstreamData: BehaviorSubject<LogStreamInterface[]> = new BehaviorSubject<LogStreamInterface[]>([]);
  private selectedcompanyLogstreamData: BehaviorSubject<LogStreamInterface> = new BehaviorSubject<LogStreamInterface>(null);
  private error: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private headerList: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(
    private appService: AppService<any>,
    private snackbarService: SnackbarService
  ) { }

  getCompanyLogstream() {
    return this.companyLogstreamData;
  }

  getSelectedCompanyLogstream() {
    return this.selectedcompanyLogstreamData;
  }

  logsError() {
    return this.error;
  }

  getLoading() {
    return this.loading;
  }

  // This returns all Logstreams for a company
  logstreamCompany(companyID) {
    this.loading.next(true);
    this.appService.get('logstream', `/${companyID}`, null)
      .then((logs: LogStreamInterface[]) => {
        this.companyLogstreamData.next(logs);
      })
      .catch((error) => {
        this.error.next(error.message);
      })
      .finally(() => {
        this.loading.next(false);
      });
  }

  // This returns info on selected logstream
  selectLogstream(companyID, logstreamID) {
    this.loading.next(true);
    this.appService.get('logstream', `/${companyID}/${logstreamID}`, null)
       .then((logs: LogStreamInterface) => {
         this.selectedcompanyLogstreamData.next(logs);
       })
      .catch((error) => {
        this.error.next(error.message);
      })
      .finally(() => {
        this.loading.next(false);
      });
  }

  getHeaderList() {
    return this.headerList;
  }

  createLogStream(logStreamData: LogStreamInterface, dialogRef: MatDialogRef<LogStreamCreateComponent>): void {
    this.loading.next(true);
    this.appService.post('logstream', '', null, logStreamData).then((res) => {
      dialogRef.close();
      const current = this.companyLogstreamData.value;
      this.companyLogstreamData.next([res, ...current]);
      this.snackbarService.open(`Log Stream '${res.id}' has been created.`);
    }).catch(() => {
      this.snackbarService.open('LogStream not created due to error');
    }).finally(() => {
      this.loading.next(false);
    });
  }

  updateLogStream(logStreamData: LogStreamInterface, id: string): Promise<LogStreamInterface> {
    this.loading.next(true);
    // 'id' required property
    const payload = {
      ...logStreamData,
      id
    };
    return this.appService.put('logstream', `/${logStreamData.customerId}/${id}`, null, payload).then((res) => {
      const current = [...this.companyLogstreamData.value];
      const index = current.findIndex(log => log.id === id);
      current.splice(index, 1);
      this.companyLogstreamData.next([res, ...current]);
      this.snackbarService.open(`Log Stream '${res.id}' has been updated.`);
      return res;
    }).catch(() => {
      this.snackbarService.open('LogStream not updated due to error');
    }).finally(() => {
      this.loading.next(false);
    });
  }

  deleteLogStream(id: string, companyId: string): Promise<any> {
    this.loading.next(true);
    return this.appService.delete('logstream', `/${companyId}/${id}`, null, null).then(res => {
      this.snackbarService.open(`Log Stream '${id}' has been deleted`);
      const current = [...this.companyLogstreamData.value];
      const index = current.findIndex(log => log.id === id);
      current.splice(index, 1);
      this.companyLogstreamData.next([...current]);
    }).catch(err => {
      this.snackbarService.open(`Log Stream '${id}' not deleted due to error`);
    }).finally(() => {
      this.loading.next(false);
    });
  }


  headerBuilder(): TableHeader[] {
    const logStreamHeaders = [
      {
        friendly: 'ID',
        name: 'id',
        description: ''
      },
      {
        friendly: 'Created At',
        name: 'createdAt',
        description: ''
      },
      {
        friendly: 'TLS Version',
        name: 'tlsVersion',
        description: ''
      },
      {
        friendly: 'Transport',
        name: 'transport',
        description: ''
      },
      {
        friendly: 'Source',
        name: 'deviceType',
        description: ''
      },
      {
        friendly: 'Target Host',
        name: 'targetHost',
        description: ''
      },
      {
        friendly: 'Target Port',
        name: 'targetPort',
        description: ''
      }
    ];
    this.headerList.next(logStreamHeaders);
    return;
  }

}
