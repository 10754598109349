import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {pluck} from 'rxjs/operators';
import {UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import { ReportingService, REPORT_STATUSES } from '../../services/forms/reporting.service';


@Component({
  selector: 'app-report-history',
  templateUrl: './report-history.component.html',
  styleUrls: ['./report-history.component.scss']
})
 export class ReportHistoryComponent implements OnInit {
   loadingReportHistory$: Observable<boolean>;
   loadingReportHistoryError$: Observable<string>;
   reportHistories$: Observable<any[]>;
   reportHistoryTableHeader$: Observable<any[]>;
  reportTitle: string;
  filterForm: UntypedFormGroup = new UntypedFormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      key: 'status',
      type: 'select',
      templateOptions: {
        label: 'Select Status',
        placeholder: 'Select Status',
        multiple: true,
        options: REPORT_STATUSES,
        change: (field: FormlyFieldConfig) => {
          if (!field.form.value.status) {
            return;
          }
          this.reportService.filterReportbyStatus(field.form.value.status);
        }
      }
    }
  ];
  loading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public reportService: ReportingService
  ) {
    this.loadingReportHistory$ = this.reportService.loadingReportHistories$;
    this.loadingReportHistoryError$ = this.reportService.reporthistoryError$;
    this.reportHistories$ = this.reportService.reportHistories$;
    this.reportHistoryTableHeader$ = this.reportService.historyTableHeaders$;
  }

  ngOnInit(): void {
    // Get report ID from route
    this.activatedRoute.paramMap.pipe(pluck('params', 'id')).subscribe((id: string) => {
      this.reportTitle = id;
      this.reportService.getReportHistory(id);
    });
  }

  downloadReport(reportStyle: string, row: any): void {
    this.reportService.downloadReport(reportStyle, row);
  }
}
