<form [formGroup]="form">
  <mat-slide-toggle formControlName="enabled"
    >Emails
    {{
      form.controls["enabled"].value ? "Enabled" : "Disabled"
    }}</mat-slide-toggle
  >

  <section class="mat-elevation-z3" *ngIf="form.controls['enabled'].value">
    <mat-form-field appearance="outline">
      <mat-label>Emails</mat-label>
      <mat-chip-grid #chipList aria-label="Emails">
        <mat-chip-row *ngFor="let email of emails" (removed)="remove(email)">
          {{ email }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>

        <input
          placeholder="New email..."
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-grid>
    </mat-form-field>
    <mat-label>Email on:</mat-label>
    <mat-slide-toggle formControlName="on_cancelled"
      >Report Cancellation</mat-slide-toggle
    >
    <mat-slide-toggle formControlName="on_completion"
      >Report Completion</mat-slide-toggle
    >
    <mat-slide-toggle formControlName="on_failure"
      >Report Failure</mat-slide-toggle
    >
  </section>
</form>
