import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { User } from './user.model';

export const loadUsers = createAction(
  '[User/API] Load Users',
  props<{ companyFilter: string[]}>()
);

export const loadUsersSuccess = createAction(
  '[User/API] Load Users Success',
  props<{users: User[]}>()
);

export const loadUsersError = createAction(
  '[User/API] Load Users Error',
  props<{error: string}>()
);

export const loadUser = createAction(
  '[User/API] Load User',
  props<{username: string}>()
);

export const loadUserSuccess = createAction(
  '[User/API] Load User Success',
  props<{user: User}>()
);

export const loadUserError = createAction(
  '[User/API] Load User Error',
  props<{error: string}>()
);

export const editUser = createAction(
  '[User/API] Edit User',
  props<{user?: User, path?: string}>()
);

export const editUserSuccess = createAction(
  '[User/API] Edit User Success',
  props<{user: User}>()
);

export const editUserError = createAction(
  '[User/API] Edit User Error',
  props<{error: string}>()
);

export const deleteUser = createAction(
  '[User/API] Delete User',
  props<{user: User, routePath: string}>()
);

export const deleteUserSuccess = createAction(
  '[User/API] Delete User Success',
  props<{user: User}>()
);

export const deleteUserError = createAction(
  '[User/API] Delete User Error',
  props<{error: string}>()
);

export const openDialog = createAction(
  '[User] Open Dialog'
);

export const forceLoadingOff = createAction(
  '[Users] Force Loading Off'
);

export const addUser = createAction(
  '[User/API] Create User ',
  props<{user: User}>()
);

export const setCurrentUser = createAction(
  '[User/API] Set Current User ',
  props<{user: User}>()
);
