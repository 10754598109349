import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { EventsService } from '@modules/events/services/events.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-worklog-form',
  templateUrl: './worklog-form.component.html',
  styleUrls: ['./worklog-form.component.scss']
})
export class WorklogFormComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  loading = false;
  eventHeaders: any[] = [];

  form: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl('', [Validators.required]),
    include: new UntypedFormControl(true, [Validators.required]),
    results: new UntypedFormControl(0, [])
  });
  eventResults: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { incident: any },
    private eventService: EventsService, public dialogRef: MatDialogRef<WorklogFormComponent>, private httpService: AppService<any>) {
    this.eventService.eventResults$.pipe(takeUntil(this.destroyer$)).subscribe(results => this.eventResults = results);
    this.httpService.get('eventParams', '', null).then(params => {
      const titles = ['Timestamp', 'Event Name', 'Event Message', 'Source Address', 'Source Port', 'Destination Address', 'Destination Port'];
      this.eventHeaders = params.fields.reduce((prev, next) => {
        if (titles.includes(next.friendly)) {
          prev.push({ ...next, name: next.name.toLowerCase() });
        }
        return prev;
      }, []);
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      results: this.eventResults.length,
      include: false
    });

  }

  dismissDialog(): void {
    this.dialogRef.close(null);
  }

  addWorklog(): void {
    this.loading = true;
    const formValue = this.form.value;
    let payload;

    // check if the results need to be added
    if (formValue.include && this.eventResults.length > 0) {
      // include the number of results set by the user
      const results = this.eventResults.slice(0, formValue.results);
      let reduce_events = [];
      let headers = this.eventHeaders.map(h => h.name);
      results.forEach(data => {
        const result = headers.reduce((prev, next) => {
          if (headers.includes(next)) {
            return { ...prev, [next]: data[next] };
          } else {
            return prev;
          }
        }, {});
        reduce_events.push(result);
      });

      // build the markdown
      let markdown = this.makeMarkdownTableFromArray(reduce_events, this.eventHeaders);
      payload = { message: formValue.message += markdown, title: formValue.title };
    } else {
      payload = { message: formValue.message, title: formValue.title };
    }

    this.httpService.put('investigation', this.data.incident.sys_id, { action: 'create-log' }, { value: payload })
      .then(() => {
        this.dialogRef.close()
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => this.loading = false)
  }

  private makeMarkdownTableFromArray(events, headers): string {
    if (events.length === 0) return '';

    let markdownTable = '| ';
    let headerCount = 0;

    headers.forEach((column: any) => {
      // don't add 'actions' column to markdown table
      if (column.name === 'actions') {
        return;
      }
      markdownTable += (column.friendly + ' | ');
      headerCount++;
    });
    markdownTable += '\n|';
    for (var i = 0; i < headerCount; i++) {
      markdownTable += '--------|';
    }

    markdownTable += '\n|';
    events.forEach((event) => {
      Object.keys(event).forEach(k => {
        markdownTable += event[k] + ' |';
      })
      markdownTable += '\n|';
    })

    return '\n\n' + markdownTable + '\n\n';
  }

  ngOnDestroy(): void {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }

}
