import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { AthenaFilterAndStatement, AthenaFilterOrStatement, AthenaFilterStatement } from '@sweet-shared/models/widget.model';
import { Rule, RuleSet } from 'angular2-query-builder';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { CompanyIndex, EventParameters, SearchPayload, SearchResponse, SortField } from '../interfaces/events';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private _event: Subject<any> = new Subject();
  private _eventResults: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private _eventFilters: BehaviorSubject<any> = new BehaviorSubject(null);
  private _searching: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _error: BehaviorSubject<string> = new BehaviorSubject(null);
  private _selectedEvent: BehaviorSubject<any> = new BehaviorSubject(null);

  eventResults$: Observable<any[]> = this._eventResults.asObservable();
  eventFilters$: Observable<any> = this._eventFilters.asObservable();
  searching$: Observable<boolean> = this._searching.asObservable();
  error$: Observable<string> = this._error.asObservable();
  selectedEvent$: Observable<any> = this._selectedEvent.asObservable();

  event$ = this._event.asObservable();

  operators: any = {
    '=': 'string',
    '!=': 'string',
    '<': 'string',
    '<=': 'string',
    '>': 'string',
    '>=': 'string',
    contains: 'string',
    like: 'string',
    in: 'array',
    incidr: 'array',
    'is null': null,
    'is not null': null,
    between: 'array'
  };

  constructor(
    private httpClient: AppService<any>,
    private client: HttpClient
  ) { }

  setSelectedEvent(event: any): void {
    this._selectedEvent.next(event);
  }

  setEventFilters(filters: any): void {
    this._eventFilters.next(filters);
  }

  setEventResults(results: any[]): void {
    this._eventResults.next(results);
  }

  setSearching(searching: boolean): void {
    this._searching.next(searching);
  }

  sendEvent(payload: any): void {
    this._event.next(payload);
  }

  fetchEventParams(): Observable<EventParameters> {
    return from(this.httpClient.get('eventParams', '', null));
  }

  fetchIndexes(companyId: string): Observable<CompanyIndex[]> {
    return from(this.httpClient.get('companyIndexes', '', { companyFilter: companyId }));
  }

  // TODO - Move this to the userService when it is ready.
  fetchUserPreference(): Observable<any> {
    return from(this.httpClient.get('userPreferences', '', null));
  }

  fecthUserFieldSet(indexId: string): Observable<any> {
    return from(this.httpClient.get('fieldSets', `/${indexId}`, null));
  }

  updateUserFieldSet(indexId: string, fields: string[]): Observable<any> {
    const payload = {
      index_id: indexId,
      headers: fields
    };

    return from(this.httpClient.post('fieldSets', '', null, payload));
  }

  search(payload: SearchPayload): Observable<SearchResponse> {
    return from(this.httpClient.post('search', '', null, payload));
  }

  searchResults(queryId: string, companyFilter?: string): Observable<{ url: string }> {
    return from(this.httpClient.get('search', `${queryId}/get-data`, { companyFilter }));
  }

  fetchDataFromS3(url: string, single: boolean = false): void {
    this.client.get(url).subscribe({
      next: (res: any) => {
        if (single) {
          this._selectedEvent.next(res.results);
        } else {
          this._eventResults.next(res.results);
        }
        this._searching.next(false);
      },
      error: (err) => {
        this._error.next(err.message);
        this._searching.next(false);
      }
    });
  }

  sentToMacaw(item: any): Observable<any> {
    return from(this.httpClient.post('macaw', `investigate`, null, item));
  }

  fetchIncidentAttributes(): Observable<any> {
    return from(this.httpClient.get('incidentAttributes', '', null));
  }

  createIncident(payload: any): Observable<any> {
    return from(this.httpClient.post('investigation', '', { action: 'create-incident' }, payload));
  }

  formatQueryForAthena(queryModel: RuleSet, beginningStatement = {}): AthenaFilterStatement {
    if (queryModel.rules.length === 0) { return null; }
    beginningStatement[queryModel.condition] = queryModel.rules.map(rule => {
      const comp = (rule as Rule).operator;

      if (rule.hasOwnProperty('condition') && rule.hasOwnProperty('rules')) {
        return this.formatQueryForAthena(rule as RuleSet);
      } else {
        const statement: Partial<AthenaFilterAndStatement | AthenaFilterOrStatement> | any = {
          field: (rule as Rule).field,
          comparison: comp
        };
        if (this.operators[comp] === 'string') {
          statement.value = (rule as Rule).value;
        } else if (this.operators[comp] === 'array') {
          console.log('between here ', rule)
          if (comp === 'between') {
            const val = (rule as Rule).value;
            const [minValue, maxValue] = val?.split(',') ?? [rule['min_value'], rule['max_value']];
            statement.min_value = minValue;
            statement.max_value = maxValue;
          } else {
            statement.values = (rule as Rule).value.split(',');
          }
        }
        return statement;
      }
    });
    return beginningStatement;
  }
}
