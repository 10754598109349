import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UrlService {
  private previousUrl = '';
  private currentUrl = '/profile';
  private incidentToggleValue = 'incidents';

  // infrastructure to share events data for the worklog
  eventsSubject$: Subject<any> = new BehaviorSubject<any>(null);
  eventsHeaderSubject$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  setPreviousUrl(previousUrl: string) {
    this.previousUrl = previousUrl;
  }

  setCurrentUrl(currentUrl: string) {
    this.setPreviousUrl(this.currentUrl);
    this.currentUrl = currentUrl;
    return this.currentUrl;
  }

  setToggleValue(value: string) {
    this.incidentToggleValue = value;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  getCurrentUrl() {
    return this.currentUrl;
  }
  getIncidentToggleValue() {
    return this.incidentToggleValue;
  }

  registerEvents(value: any) {
    this.eventsSubject$.next(value);
  }

  registerEventsHeader(headerSubject: BehaviorSubject<any[]>) {
    this.eventsHeaderSubject$ = headerSubject;
  }

  get events(): BehaviorSubject<any> {
    return (this.eventsSubject$ as BehaviorSubject<any>);
  }

  get eventsHeaders(): BehaviorSubject<any> {
    return this.eventsHeaderSubject$;
  }
}
