import { createAction, props } from '@ngrx/store';
import {Product} from './products.model';

// Load all the event fields
export const loadProducts = createAction(
  '[Products/API] Load Products'
);

// Load all the event field success
export const loadProductsSuccess = createAction(
  '[Products/API] Load Products Success',
  props<{products: Product[]}>()
);

// Load all the event field error
export const loadProductsError = createAction(
  '[Products/API] Load Products Error',
  props<{error: string}>()
);
