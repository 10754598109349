<div class="fuel-gauge" [ngStyle]="{height: height + 'px'}">
    <!-- div of the chart -->
    <div [id]="widgetDetails.widget_id" class="chart-container"></div>

    <!-- div of the legend -->
    <div class="legend-wrapper">
        <div id="{{widgetDetails.widget_id}}-legend" class="chart-legend"></div>
    </div>
    <div class="loader-pane" *ngIf="loading">
        <ngx-loading [show]="true"></ngx-loading>
    </div>
</div>
