import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@app/app.service';
import { DropDownGroupOption, DropdownOption, IncidentsService } from '@app/pages/incidents/services/incidents.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PreferencesService } from '../services/preferences.service';
import {Auth} from 'aws-amplify';
import {Incident} from '@app/pages/incidents/store/incident.model';

@Component({
  selector: 'app-incident-search-preference',
  templateUrl: './incident-search-preference.component.html',
  styleUrls: ['./incident-search-preference.component.scss']
})
export class IncidentSearchPreferenceComponent implements OnDestroy {
  private destroyer$ = new Subject();

  userPreferences: any = null;
  loading$: Observable<boolean>;
  error$: Observable<string>;

  allCompanyOptions: DropdownOption[] = [];
  companyOptions: DropdownOption[] = [];


  // selectOptions
  assigneeOptions: DropDownGroupOption[] = [];
  userFilterCtrl = new UntypedFormControl('');
  companyFilterCtrl: UntypedFormControl = new UntypedFormControl();

  classificationOptions: DropdownOption[] = [];
  tierOptions: DropdownOption[] = [];
  stateOptions: DropdownOption[] = [];
  priorityOptions: DropdownOption[] = [];
  closureOptions: DropDownGroupOption[] = [];
  assignmentGroupOptions: DropdownOption[] = [{ label: 'SoC Monitoring Group', value: 'MSS-CTL-SOC' }, { label: 'ANS', value: 'MSS-Portal-ANS' }, { label: 'SLM', value: 'MSS-Portal-SLM' }];



  form: UntypedFormGroup = new UntypedFormGroup({
    assignee: new UntypedFormControl([], []),
    classifications: new UntypedFormControl([], []),
    closureState: new UntypedFormControl([], []),
    companies: new UntypedFormControl([], []),
    priority: new UntypedFormControl([], []),
    state: new UntypedFormControl([], []),
    tier: new UntypedFormControl([], []),
    assignment_group: new UntypedFormControl([], []),
  });

  constructor(
    private preferenceService: PreferencesService,
    private incidentsService: IncidentsService,
    private appService: AppService<Incident>,

  ) {
    this.incidentsService.init();
    this.error$ = this.preferenceService.error$;
    this.loading$ = this.preferenceService.loading$;

    // fetch the form options
    combineLatest([
      this.incidentsService.incidentAssignees,
      this.incidentsService.incidentClassifications,
      this.incidentsService.incidentPriorities,
      this.incidentsService.incidentStates,
      this.incidentsService.incidentTiers,
      this.incidentsService.incidentClosureStates,
      this.preferenceService.userPreferences,
      this.appService.get('company', '', null),
      this.appService.get('eventParams', '', null),
      Auth.currentAuthenticatedUser()
    ]).pipe(
      takeUntil(this.destroyer$),
      filter((response: any) => {
        const check = response.reduce((prev, next) => {
          prev.push(!!next);
          return prev;
        }, []);
        return !check.includes(false);
      })
    ).subscribe(value => {

      this.assigneeOptions = value[0];
      this.classificationOptions = value[1];
      this.priorityOptions = value[2];
      this.stateOptions = value[3];
      this.tierOptions = value[4];
      this.closureOptions = value[5];

      this.userPreferences = value[6];
      // set the company options
      this.companyOptions = value[7].map(company => ({ label: company.name, value: company.id }));
      this.allCompanyOptions = value[7].map(company => ({ label: company.name, value: company.id }));

      // set the form with their values
      this.form.patchValue(value[6].incidentsSearch);
    });
  }

  toggleAllSelection(event, key: string): void {
    switch (key) {
      case 'company':
        this.form.patchValue({
          company: event.checked ? this.assigneeOptions.reduce((prev, next) => {
            prev = [...prev, ...next.option.map(v => v.value)];
            return prev;
          }, []) : []
        });
        break;
      case 'assignee':
        this.form.patchValue({
          assignee: event.checked ? this.assigneeOptions.reduce((prev, next) => {
            prev = [...prev, ...next.option.map(v => v.value)];
            return prev;
          }, []) : []
        });
        break;
      case 'classifications':
        this.form.patchValue({
          classifications: event.checked ? this.classificationOptions.map(v => v.value) : []
        });
        break;
      case 'tier':
        this.form.patchValue({
          tier: event.checked ? this.tierOptions.map(v => v.value) : []
        });
        break;
      case 'state':
        this.form.patchValue({
          state: event.checked ? this.stateOptions.map(v => v.value) : []
        });
        break;
      case 'priority':
        this.form.patchValue({
          priority: event.checked ? this.priorityOptions.map(v => v.value) : []
        });
        break;
      case 'closureState':
        this.form.patchValue({
          closureState: event.checked ? this.closureOptions.map((v: any) => v.value) : []
        });
        break;
      case 'assignment_group':
        this.form.patchValue({
          assignment_group: event.checked ? this.assignmentGroupOptions.map((v: any) => v.value) : []
        });
        break;
      default:
        break;
    }

  }

  updateIncidentPreference(): void {
    const payload = {
      ...this.userPreferences,
      incidentsSearch: this.form.value
    };

    this.preferenceService.updateUserPreference(payload);
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
