import { AuthService } from './../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit , OnDestroy {
  destroyer$: ReplaySubject<any> = new ReplaySubject<any>();
  form: UntypedFormGroup;
  message: string = null;
  error: string = null;
  loading = false;
  username: string = null;
  resetMessage: string = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.setupPage();
  }

  resendCode() {
    this.authService.accountRecovery(this.username);
  }

  onPasswordReset() {
    this.authService.resetPassword(this.username, this.form.value.code, this.form.value.password)
  }

  private setupPage() {
    this.authService.getAuthError().pipe(takeUntil(this.destroyer$)).subscribe(error => this.error = error);
    this.form = this.fb.group({
      code: [null, [Validators.required, Validators.minLength(3)]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(8)]]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.username && params.message) {
        this.username = params.username;
        this.message = params.message;
      } else {
        this.router.navigate(['/auth/login'], {});
      }
    });

    this.authService.getResetMessage().pipe(takeUntil(this.destroyer$)).subscribe(resetMessage => this.resetMessage = resetMessage);
  }

  ngOnDestroy() {
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }
}
