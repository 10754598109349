import {Injectable} from '@angular/core';
import {WidgetSource, WidgetTypes} from './widget-form-builder.model';
// @ts-ignore
import {FormlyFieldConfig} from '@ngx-formly/core';
import {Store} from '@ngrx/store';
import {State} from '../../../../reducers';
import {delay, filter, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, of, ReplaySubject} from 'rxjs';
import {Company} from '../../../../pages/admin/store/company.model';
import {AppService} from '../../../../app.service';


@Injectable({
  providedIn: 'root'
})
// @ts-ignore
export class WidgetFormBuilderService implements OnDestroy{
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject();
  private widgetIndex: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private error: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // fieldOption: BehaviorSubject<Company[]> = new BehaviorSubject(null);
  fieldOption: any;
  indexes: any


  // Y axis action that determine which action is allowed depending on the main field types
  yAxisActions = [
    {
      value: {
        value: 'count',
        label: 'Count'
      },
      typesSupported: ['String', 'TimeStamp', 'FloatingPoint', 'IPv6 address', 'Long', 'IPv4 Address',
        'MAC Address', 'Integer']
    },
    {
      value: {
        value: 'avg',
        label: 'Average'
      },
      typesSupported: ['FloatingPoint', 'Long', 'Integer']
    },
    {
      value: {
        value: 'min',
        label: 'Minimum'
      },
      typesSupported: ['FloatingPoint', 'Long', 'Integer', 'TimeStamp']
    },
    {
      value: {
        label: 'Maximum',
        value: 'max'
      },
      typesSupported: ['FloatingPoint', 'Long', 'Integer', 'TimeStamp']
    },
    {
      value: {
        value: 'sum',
        label: 'Sum'
      },
      typesSupported: ['FloatingPoint']
    }
  ];

  // cast as options, usually used in single metric widget
  castAsOptions = [
    {
      label: 'TIMESTAMP',
      value: 'timestamp'
    },
    {
      label: 'BOOLEAN',
      value: 'boolean'
    },
    {
      label: 'TINYINT',
      value: 'tinyint'
    },
    {
      label: 'SMALLINT',
      value: 'smallint'
    },
    {
      label: 'INTEGER',
      value: 'integer'
    },
    {
      label: 'REAL',
      value: 'real'
    },
    {
      label: 'DOUBLE',
      value: 'double'
    },
    {
      label: 'DECIMAL',
      value: 'decimal'
    },
    {
      label: 'VARCHAR',
      value: 'varchar'
    },
    {
      label: 'CHAR',
      value: 'char'
    },
  ];

// All types currently supported by dashboard
  widgetTypes: WidgetTypes[] = [
    {
      value: 'bar', label: 'Bar', visible: true,
    },
    {
      value: 'line', label: 'Line', visible: true,
    },
    {
      value: 'pie', label: 'Pie', visible: true,
    },
    {
      value: 'single_metric', label: 'Single Metric', visible: true,
    },
    {
      value: 'table', label: 'Table', visible: true,
    },
    {
      value: 'solid_gauge', label: 'Solid Gauge', visible: true,
    }
  ];

  widgetSources: WidgetSource[] = [
    {
      value: 'log', label: 'Log', visible: true,
    },
    {
      value: 'investigation', label: 'Incidents', visible: false,
    }
  ];

  widgetIntervals: any = [
    {
      value: 'minute', label: 'Minute', visible: true,
    },
    {
      value: 'hour', label: 'Hour', visible: true,
    },
    {
      value: 'day', label: 'Day', visible: true,
    },
    {
      value: 'year', label: 'Year', visible: true,
    }
  ];

  // attributes that are common throughout all widget types

/*{
  key: 'field',
  type: 'select',
  templateOptions: {
    label: 'Field',
    required: true,
    valueProp: (o) => o,
    // compareWith: ((o1, o2) =>  o1.value === o2.value),
    compareWith: (o1, o2) => {
      if (o1 === o2) {
      return true;
    }

    return o1 && o2 && o1.value === o2.value;
  },
  options: of([]),

}
},
{*/


  commonWidgetFields: FormlyFieldConfig[] = [
    {
      key: 'title',
      type: 'input',
      templateOptions: {
        label: 'Title',
        placeholder: 'Ex: Total Events',
        required: true,
      }
    },
    {
      key: 'description',
      type: 'input',
      templateOptions: {
        label: 'Description',
        placeholder: 'Ex: All Events with Event Type of "Clue"',
        required: true,
      }
    },
    {
      key: 'index',
      type: 'select',
      defaultValue: 'log',
      templateOptions: {
        label: 'Index',
        placeholder: 'Source of the data',
        required: true,
        // options: [],
        options: of([]),
        valueProp: (o) => o,
        compareWith: (o1, o2) => {
          if (o1 === o2) {
            return true;
          }
          return o1 && o2 && o1.value === o2.value;
        },


       }
    },
    {
      key: 'source',
      type: 'select',
      defaultValue: 'log',
      templateOptions: {
        label: 'Source',
        placeholder: 'Source of the data',
        required: true,
        options: this.widgetSources
      }
    },
    {
      key: 'type',
      type: 'select',
      templateOptions: {
        label: 'Type',
        placeholder: 'The type of widget',
        required: true,
        options: this.widgetTypes.filter((widgetType) => widgetType.visible).sort()
      }
    },
  ];


  private barChartWidgetFields: FormlyFieldConfig[] = [
    {
      key: 'field',
      type: 'select',
      templateOptions: {
        label: 'Field',
        required: true,
        options: [],
      }
    },
    {
      key: 'alias',
      type: 'input',
      defaultValue: '',
      templateOptions: {
        label: 'Alias',
        placeholder: 'Ex: Event Types Today',

      }
    },
    {
      key: 'yAxisAction',
      type: 'select',
      defaultValue: 'count',
      hideExpression: (
        (model, formState, field) => {
          // This is used to hide the Y Axis Action in the form if the field type is not String
          if (model && model?.field && model?.field?.type && model?.field?.type?.toLowerCase() === 'string'
          ) {
            return false;
          }
          return true;
        }
      ),
      templateOptions: {
        label: 'Y-Axis Action',
        required: true,
        options: [
          // since backend only supports count, hardcoded below
          {
            label: 'Count',
            value: 'count',
          },
        ],
      }
    },
    {
      key: 'interval',
      type: 'select',
      defaultValue: '',
      templateOptions: {
        label: 'Interval',
        placeholder: 'Data points broken down by minute, hour, day, month, etc',
        options: this.widgetIntervals
      }
    },
    {
      key: 'query',
      defaultValue: [],
      templateOptions: {type: 'hidden'}
    },
    {
      key: 'limit',
      type: 'checkbox',
      defaultValue: true,
      templateOptions: {
        label: 'Limit results',
        description: 'Limit results for speed optimization.',
      },
    },
  ];

  private lineChartWidgetFields: FormlyFieldConfig[] = [
    {
      key: 'field',
      type: 'select',
      templateOptions: {
        label: 'Field',
        required: true,
        options: [],
      }
    },
    {
      key: 'alias',
      type: 'input',
      defaultValue: '',
      templateOptions: {
        label: 'Alias',
        placeholder: 'Ex: Event Types Today',

      }
    },
    {
      key: 'yAxisAction',
      type: 'select',
      defaultValue: 'count',
      hideExpression: (
        // This is used to hide the Y Axis Action in the form if the field type is not String
        (model, formState, field) => {
          if (model && model?.field && model?.field?.type && model?.field?.type?.toLowerCase() === 'string'
          ) {
            return false;
          }
          return true;
        }
      ),
      templateOptions: {
        label: 'Y-Axis Action',
        required: true,
        options: [
          // since backend only supports count, hardcoded below
          {
            label: 'Count',
            value: 'count',
          },
        ],
      }
    },
    {
      key: 'interval',
      type: 'select',
      defaultValue: '',
      templateOptions: {
        label: 'Interval',
        placeholder: 'Data points broken down by minute, hour, day, month, etc',
        options: this.widgetIntervals
      }
    },
    {
      key: 'query',
      defaultValue: [],
      templateOptions: {type: 'hidden'}
    },
    {
      key: 'limit',
      type: 'checkbox',
      defaultValue: true,
      templateOptions: {
        label: 'Limit results',
        description: 'Limit results for speed optimization.',
      },
    },
  ];

  private pieChartWidgetFields: FormlyFieldConfig[] = [
    {
      key: 'field',
      type: 'select',
      templateOptions: {
        label: 'Field',
        required: true,
        options: [],
      }
    },
    {
      key: 'alias',
      type: 'input',
      defaultValue: '',
      templateOptions: {
        label: 'Alias',
        placeholder: 'Alias to replace the event name displayed. Ex: Event Types Today',
      }
    },
    {
      key: 'fieldTypeAction',
      type: 'select',
      defaultValue: 'count',
      hideExpression: (
        // This is used to hide the Y Axis Action in the form if the field type is not String
        (model, formState, field) => {
          if (model && model?.field && model?.field?.type && model?.field?.type?.toLowerCase() === 'string'
          ) {
            return false;
          }
          return true;
        }
      ),
      templateOptions: {
        label: 'Y-Axis Action',
        required: true,
        options: [
          // since backend only supports count, hardcoded below
          {
            label: 'Count',
            value: 'count',
          },
        ],
      }
    },
    {
      key: 'sliceCount',
      type: 'input',
      defaultValue: '10',
      templateOptions: {
        label: 'Slice Count',
        placeholder: 'How many slices in your pie chart?',
      }
    },
    {
      key: 'ordering',
      type: 'select',
      defaultValue: 'desc',
      templateOptions: {
        label: 'Ordering',
        placeholder: 'Would you like your results in Ascending or Descending order.',
        options: [
          {
            label: 'Ascending',
            value: 'asc'
          },
          {
            label: 'Descending',
            value: 'desc'
          }
        ]
      }
    },
    {
      key: 'query',
      defaultValue: [],
      templateOptions: {type: 'hidden'}
    },
    {
      key: 'limit',
      type: 'checkbox',
      defaultValue: true,
      templateOptions: {
        label: 'Limit results',
        description: 'Limit results for speed optimization.',
      },
    },
  ];

  private solidGaugeChartWidgetFields: FormlyFieldConfig[] = [
    {
      key: 'field',
      type: 'select',
      templateOptions: {
        label: 'Field',
        required: true,
        options: [],
      }
    },
    {
      key: 'alias',
      type: 'input',
      defaultValue: '',
      templateOptions: {
        label: 'Alias',
        placeholder: 'Alias to replace the event name displayed. Ex: Event Types Today',

      }
    },
    {
      key: 'fieldTypeAction',
      type: 'select',
      defaultValue: 'count',
      hideExpression: (
        // This is used to hide the Y Axis Action in the form if the field type is not String
        (model, formState, field) => {
          if (model && model?.field && model?.field?.type && model?.field?.type?.toLowerCase() === 'string'
          ) {
            return false;
          }
          return true;
        }
      ),
      templateOptions: {
        label: 'Y-Axis Action',
        required: true,
        options: [
          // since backend only supports count, hardcoded below
          {
            label: 'Count',
            value: 'count',
          },
        ],
      }
    },
    {
      key: 'sliceCount',
      type: 'input',
      defaultValue: '4',
      templateOptions: {
        label: 'Slice Count',
        placeholder: 'How many slices in your pie chart?',
      }
    },
    {
      key: 'ordering',
      type: 'select',
      defaultValue: 'desc',
      templateOptions: {
        label: 'Ordering',
        placeholder: 'Would you like your results in Ascending or Descending order?',
        options: [
          {
            label: 'Ascending',
            value: 'asc'
          },
          {
            label: 'Descending',
            value: 'desc'
          }
        ]
      }
    },
    {
      key: 'query',
      defaultValue: [],
      templateOptions: {type: 'hidden'}
    },
    {
      key: 'limit',
      type: 'checkbox',
      defaultValue: true,
      templateOptions: {
        label: 'Limit results',
        description: 'Limit results for speed optimization.',
      },
    },
  ];

  private singleMetricChartWidgetFields: FormlyFieldConfig[] = [
    {
      key: 'field',
      type: 'select',
      templateOptions: {
        label: 'Field',
        required: true,
        options: [],
      }
    },
    {
      key: 'alias',
      type: 'input',
      defaultValue: '',
      templateOptions: {
        label: 'Alias',
        placeholder: 'Ex: Event Types Today',
      }
    },
    {
      key: 'fx',
      type: 'select',
      defaultValue: 'count',
      hideExpression: (
        // This is used to hide the Y Axis Action in the form if the field type is not String
        (model, formState, field) => {
          if (model && model?.field && model?.field?.type && model?.field?.type?.toLowerCase() === 'string'
          ) {
            return false;
          }
          return true;
        }
      ),
      templateOptions: {
        label: 'Y-Axis Action',
        required: true,
        options: [
          // since backend only supports count, hardcoded below
          {
            label: 'Count',
            value: 'count',
          },
        ],
      }
    },
    {
      key: 'cast_as',
      type: 'select',
      defaultValue: 'integer',
      templateOptions: {
        label: 'Cast As',
        placeholder: 'How would you like your results to be casted as.',
        options: this.castAsOptions
      }
    },
    {
      key: 'query',
      defaultValue: [],
      templateOptions: {type: 'hidden'}
    },
  ];

  private tableChartWidgetFields: FormlyFieldConfig[] = [
    {
      key: 'fields',
      type: 'repeat',
      templateOptions: {
        addText: 'Add Field',
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'field',
            type: 'select',
            templateOptions: {
              label: 'Field',
              required: true,
              valueProp: (o) => o,
              // compareWith: ((o1, o2) =>  o1.value === o2.value),
              compareWith: (o1, o2) => {
                if (o1 === o2) {
                  return true;
                }

                return o1 && o2 && o1.value === o2.value;
              },
              options: of([]),

            }
          },
          {
            key: 'alias',
            type: 'input',
            defaultValue: '',
            templateOptions: {
              required: true,
              label: 'Alias',
              placeholder: 'Ex: Event Types Today',

            }
          },
          {
            key: 'yAxisAction',
            type: 'select',
            defaultValue: 'count',
            hideExpression: (
              // This is used to hide the Y Axis Action in the form if the field type is not String
              (model, formState, field) => {
                if (model && model?.field && model?.field?.type && model?.field?.type?.toLowerCase() === 'string'
                ) {
                  return false;
                }
                return true;
              }
            ),
            templateOptions: {
              label: 'Y-Axis Action',
              required: true,
              options: [
                // since backend only supports count, hardcoded below
                {
                  label: 'Count',
                  value: 'count',
                },
              ],
            }
          },
          {
            key: 'cast_as',
            type: 'select',
            defaultValue: 'integer',
            templateOptions: {
              label: 'Cast As',
              placeholder: 'How would you like your results to be casted as.',
              options: this.castAsOptions
            }
          },
          {
            key: 'fx_param',
            type: 'input',
            defaultValue: '',
            templateOptions: {
              label: 'Function Param',
              placeholder: 'Ex: Event Types Today',

            }
          },
        ]
      }
    },
    {
      key: 'interval',
      type: 'select',
      defaultValue: 'day',
      hideExpression: true,
      templateOptions: {
        label: 'Interval',
        placeholder: 'Data points broken down by minute, hour, day, month, etc',
        options: this.widgetIntervals
      }
    },
    {
      key: 'query',
      defaultValue: [],
      templateOptions: { type: 'hidden' }
    },
    {
      key: 'limit',
      type: 'checkbox',
      defaultValue: true,
      templateOptions: {
        label: 'Limit results',
        description: 'Limit results for speed optimization.',
      }
    }
  ];


  // Method that is pulling all of the params based on the chart type
  getWidgetFields(widgetType, fields) {
   switch (widgetType) {
      case 'bar':
        const barChartFields = this.barChartWidgetFields.map((item) => {
          if (item.key === 'field') {
            item.templateOptions.options = fields.map((item) => {
              return {
                value: item.value,
                label: item.friendly
              };
            });
          }
          return item;
        });
        return barChartFields;
      case 'line':
        const lineChartFields = this.lineChartWidgetFields.map((item) => {
          if (item.key === 'field') {
            item.templateOptions.options = fields.map((item) => {
              return {
                value: item.value,
                label: item.friendly
              };
            });
          }
          return item;
        });
        return lineChartFields;
      case 'pie':
        const pieChartFields = this.pieChartWidgetFields.map((item) => {
          if (item.key === 'field') {
            item.templateOptions.options = fields.map((item) => {
              return {
                value: item.value,
                label: item.friendly
              };
            });
          }
          return item;
        });
        return pieChartFields;

      case 'solid_gauge':
        const solidGaugeChartFields = this.solidGaugeChartWidgetFields.map((item) => {
          if (item.key === 'field') {
            item.templateOptions.options = fields.map((item) => {
              return {
                value: item.value,
                label: item.friendly
              }
            })
          }
          return item;
        });
        return solidGaugeChartFields;
        case 'single_metric':
        const singleMetricChartFields = this.singleMetricChartWidgetFields.map((item) => {
          if (item.key === 'field') {
            item.templateOptions.options = fields.map((item) => {
              return {
                value: item.value,
                label: item.friendly
              };
            });
          }
          return item;
        });
        return singleMetricChartFields;
      case 'table':

        // const tableChartFields = this.tableChartWidgetFields.map((item) => {
        //   if (item.fieldArray) {
        //     item.fieldArray.fieldGroup.forEach((item) => {
        //       if (item.key === 'field') {
        //         item.templateOptions.options = fields.map((item) => {
        //           return {
        //             value: item.value,
        //             label: item.friendly
        //           };
        //         });
        //       }
        //     });
        //   }
        //   return item;
        // });

        return this.tableChartWidgetFields;

      default:
        return [];
    }
  }

  constructor(private store: Store<State>, private widgetFormAppService: AppService<any>) {

    this.store.select((state) => state.eventParams).pipe(
      filter((evtParams) => !!evtParams.eventParams),
      takeUntil(this.destroyed$))
      .subscribe(eventParams => {
        this.tableChartWidgetFields.map((item) => {
          if (item.fieldArray) {
            item.fieldArray.fieldGroup.forEach((item) => {
              if (item.key === 'field') {
               item.templateOptions.options = eventParams.eventParams.fields.map((val) => {
                  return {
                    value: val.name,
                    label: val.friendly,
                    type: val.type
                  };
                });
             }
           });
          }
        });
      });
    this.indexWidget();

}


  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  indexWidget() {
    this.widgetFormAppService.get('index', '', null)
      .then((data) =>
        {
         this.commonWidgetFields.map((item) => {
           if (item.key === 'index') {
             item.templateOptions.options = data.map((item) => {
               return {
                 value: item.id,
                 label: item.friendly
               };
             });
           }
         });
        }
      )
      .catch((error) => {this.error.next(error.message); });
  }
}
