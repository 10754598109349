import { MatStepperModule } from '@angular/material/stepper';
import { FormlyModule } from '@ngx-formly/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardsRoutingModule } from './dashboards-routing.module';
import { DashboardComponent, DashboardCreateComponent } from './dashboard/dashboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDashboard from './store/dashboard.reducer';
import { DashboardEffects } from './store/dashboard.effects';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { DashboardDetailPageComponent } from './dashboard-detail-page/dashboard-detail-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetSharedModule } from '../../sweet-shared/sweet-shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GridsterModule } from 'angular-gridster2';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReportsComponent } from './reports/reports.component';
import { ReportsWarningComponent } from './reports-warning/reports-warning.component';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SettingWidgetFormComponent } from './dashboard-detail-page/components/setting-widget-form/setting-widget-form.component';
import { QueryBuilderModule } from 'angular2-query-builder';
import { GroupBySectionComponent } from './dashboard-detail-page/components/group-by-section/group-by-section.component';
import { SettingWidgetComponent } from './dashboard-detail-page/components/setting-widget/setting-widget.component';
import { DatetimeComponent } from './dashboard-detail-page/components/datetime/datetime.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { OptionStaticDropdownComponent } from './dashboard-detail-page/components/option-static-dropdown/option-static-dropdown.component';
import { ReportingModule } from '../reporting/reporting.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SweetWidgetsModule } from 'src/app/sweet-shared/sweet-widgets/sweet-widgets.module';
import { DatetimePickerModule } from 'src/app/sweet-shared/datetime-picker/datetime-picker.module';
import { WidgetsModule } from '@modules/widgets/widgets.module';
import { IncidentTableWidgetComponent } from './dashboard-detail-page/components/incident-table-widget/incident-table-widget.component';


@NgModule({
    declarations: [
        DashboardComponent,
        DashboardDetailPageComponent,
        DashboardCreateComponent,
        ReportsComponent,
        ReportsWarningComponent,
        SettingWidgetFormComponent,
        GroupBySectionComponent,
        SettingWidgetComponent,
        DatetimeComponent,
        OptionStaticDropdownComponent,
        IncidentTableWidgetComponent
    ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        FlexLayoutModule,
        StoreModule.forFeature(fromDashboard.dashboardsFeatureKey, fromDashboard.reducer),
        EffectsModule.forFeature([DashboardEffects]),
        FormlyModule.forRoot({
            types: [
                { name: 'groupBy', component: GroupBySectionComponent },
                { name: 'datetime', component: DatetimeComponent }
            ],
            validationMessages: [
                {
                    name: 'required',
                    message: 'This field is required',
                },
            ],
        }),
        QueryBuilderModule,
        MatInputModule,
        MatTableModule,
        MatButtonModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        DashboardsRoutingModule,
        MatIconModule,
        FormsModule,
        SweetSharedModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatCardModule,
        MatTooltipModule,
        MatFormFieldModule,
        GridsterModule,
        MatSelectModule,
        MatDialogModule,
        MatDividerModule,
        NgxMatTimepickerModule,
        MatListModule,
        MatStepperModule,
        NgxMatDatetimePickerModule,
        MatCheckboxModule,
        MatInputModule,
        MatExpansionModule,
        MatRadioModule,
        ReportingModule,
        NgxMatSelectSearchModule,
        SweetWidgetsModule,
        DatetimePickerModule,
        WidgetsModule
    ],
    providers: [
        MatDatepickerModule
    ],
    exports: [
        DashboardCreateComponent,
        GroupBySectionComponent,
        OptionStaticDropdownComponent
    ]
})
export class DashboardsModule {
}
