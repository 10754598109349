<div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
  <formly-field class="col" [field]="field"></formly-field>
  <div class="col-sm-2 d-flex align-items-center">
    <button mat-stroked-button color="warn" type="button" (click)="remove(i)">Remove</button>
<!--    <button mat-stroked-button color="warn" type="button" (click)="remove(i)" *ngIf='to.showRemoveBtn'>Remove</button>-->
  </div>
</div>
<div style="margin:30px 0;">
  <button mat-stroked-button type="button" (click)="add()" *ngIf='!to.maxItems || to.maxItems > field.fieldGroup.length'>{{ to.addText }}</button>
</div>
