import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Event, EventRequest, QueryParams} from './event.model';


export const fetchEvents = createAction(
  '[Event/API] Fetch Events',
  props<{ queryParams: QueryParams, eventRequest: EventRequest, forEventDetail?: boolean }>()
);

export const loadEvents = createAction(
  '[Event/API] Load Events.',
  props<{ eventBatchId: string, queryParams: QueryParams, forEventDetail?: boolean }>()
);

export const investigateEvent = createAction(
  '[Event/API] Investigate Event',
  props<{ event: any }>()
);

export const investigateEventSuccess = createAction(
  '[Event/API] Investigate Event Success',
  props<{ message: any }>()
);

export const investigateEventError = createAction(
  '[Event/API] Investigate Event Error',
  props<{ error: Error }>()
);


export const fetchSearchResults = createAction(
  '[Event/API] Fetch search result',
  props<{url: string, forEventDetail?: boolean}>()
);

export const loadEventsSuccess = createAction(
  '[Event/API] Load Events Success',
  props<{ events: object[] }>()
);

export const eventsError = createAction(
  '[Event/API] Fetch / Load Events Error.',
  props<{ error: string }>()
);

export const fetchEvent = createAction(
  '[Event/API] Fetch / Load Event.',
  props<{ searchId: string, eventId, companyId?: string, isoDate?: string }>()
);

export const fetchEventSuccess = createAction(
  '[Event/API] Fetch / Load Event Success.',
  props<{ event: object }>()
);

export const fetchEventError = createAction(
  '[Event/API] Fetch / Load Event Error.',
  props<{ error: string }>()
);

export const clearEvent = createAction(
  '[Event/API] Clear Event.'
);
