import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '../../guards/auth-gaurd.service';
import {PermissionsGuardService} from '../../guards/permissions-guard.service';
import {SecurityPostureComponent} from './components/security-posture/security-posture.component';


const routes: Routes = [
  {
    path: 'secure-dns',
    canActivate: [AuthGuardService, PermissionsGuardService],
    data: {
      requiredPermission: ['portal-access.enable-admin-securedns-tab'],
      any: false
    },
    component: SecurityPostureComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SecurityPostureRoutingModule { }
