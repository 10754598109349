<section class="permission-list">
  <mat-accordion *ngIf="!(loadingSystemPermissions$ | async) && ready">
    <mat-expansion-panel
      *ngFor="let permissionName of systemPermissionNames$ | async"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          >{{
            (systemPermission$ | async)[permissionName].friendly | titlecase
          }}
          ({{ countPermissions(permissionName) }}/{{
            (systemPermission$ | async)[permissionName].actions.length
          }})</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div class="permission-details">
        <p>{{ (systemPermission$ | async)[permissionName].description }}</p>

        <!-- Permission list -->
        <mat-slide-toggle
          [matTooltip]="action.description"
          matTooltipPosition="above"
          *ngFor="
            let action of (systemPermission$ | async)[permissionName].actions
          "
          [checked]="hasPermission(permissionName, action.name)"
          (change)="onPermissionChanged($event, permissionName, action.name)"
          [disabled]="!editable"
        >
          {{ action.friendly }}
        </mat-slide-toggle>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
