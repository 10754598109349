import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../guards/auth-gaurd.service';
import { EventDetailPageComponent } from './components/event-detail-page/event-detail-page.component';
import { PermissionsGuardService } from '../../guards/permissions-guard.service';
import { EventPageComponent } from '@modules/events/components/event-page/event-page.component';

const routes: Routes = [
  {
    path: 'events',
    canActivate: [AuthGuardService, PermissionsGuardService],
    data: {
      requiredPermission: ['portal-access.enable-analysis-section'],
      any: false
    },
    children: [
      {
        path: '',
        component: EventPageComponent
      },
      {
        path: 'drilldown',
        component: EventPageComponent
      },
      {
        path: 'details',
        component: EventDetailPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule {
}
