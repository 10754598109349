import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IncidentsRoutingModule } from './incidents-routing.module';
import { IncidentsPageComponent } from './components/incidents-page/incidents-page.component';
import { StoreModule } from '@ngrx/store';
import * as fromIncident from './store/incident.reducer';
import { EffectsModule } from '@ngrx/effects';
import { IncidentEffects } from './store/incident.effects';
import { SweetSharedModule } from 'src/app/sweet-shared/sweet-shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { IncidentDetailComponent } from './components/incident-detail/incident-detail.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { QueryBuilderComponent } from '../../sweet-shared/components/query-builder/query-builder.component';
import { ThreatHistoryWorklogComponent } from './components/threat-history-worklog/threat-history-worklog.component';
import { ThreatProfileWorklogComponent } from './components/threat-profile-worklog/threat-profile-worklog.component';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IncidentModule } from '@modules/incident/incident.module';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';


@NgModule({
    declarations: [IncidentsPageComponent, IncidentDetailComponent, ThreatHistoryWorklogComponent, ThreatProfileWorklogComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IncidentModule,
        IncidentsRoutingModule,
        SweetSharedModule,
        MatSelectModule,
        MatCheckboxModule,
        MatCardModule,
        StoreModule.forFeature(fromIncident.incidentsFeatureKey, fromIncident.reducer),
        EffectsModule.forFeature([IncidentEffects]),
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatInputModule,
        FlexModule,
        MatListModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MarkdownModule.forRoot({
                markedOptions: {
                    provide: MarkedOptions,
                    useValue: {
                        gfm: true,
                        tables: true,
                        breaks: true,
                        sanitize: true,
                    },
                }
            }
        ),
        FlexLayoutModule,
        MatMenuModule,
        MatButtonToggleModule,
        NgxMatSelectSearchModule,
    ],
})
export class IncidentsModule {
}
