import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

import { isArray } from '@amcharts/amcharts4/core';

import { ReportingService } from '../../services/forms/reporting.service';
import { atleastOneValidator } from '../../validators/atleast-one.directive';

export interface EmailFormData {
  enabled: boolean;
  emails: string[];
  on_failure: boolean;
  on_completion: boolean;
  on_cancelled: boolean;
}

@Component({
  selector: 'app-report-notification-information',
  templateUrl: './report-notification-information.component.html',
  styleUrls: ['./report-notification-information.component.scss']
})
export class ReportNotificationInformationComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  form: UntypedFormGroup;
  showNotificationChoices = false;
  @Output() formValid: EventEmitter<any> = new EventEmitter();
  // default value of the form if in edit mode.
  @Input() formData: EmailFormData = null;
  @Input() data;

  // test = ['jessi@gmail.com', 'test@email.com', 'blah@blau.com'];
  emailList: string[];

  constructor(private fb: UntypedFormBuilder, public reportingFormService: ReportingService) { }

  ngOnInit(): void {
    console.log('emails ', this.data?.params?.notifications?.emails)
    if (this.data) {
      this.emailList = [...this.data?.params?.notifications?.emails];
    }
    this.showNotificationChoices = this.data?.params?.notifications?.enabled || false;
    // setup the form for the step
    this.form = this.fb.group({
      enabled: this.data?.params?.notifications?.enabled ||  false,
      emails: this.fb.array(this.emailList || []),
      on_cancelled: [this.data?.params?.notifications?.on_cancelled || false, []],
      on_completion: [this.data?.params?.notifications?.on_completion || false, []],
      on_failure: [this.data?.params?.notifications?.on_failure || false, []]
    });
    this.addFormTriggers();
  }
  /**
   *
   * @param emails The default list of email addresses to pre-populate the form
   * @returns The form Array needed for the form to be generated.
   */
  generateEmailForm(emails: string[] | string): UntypedFormArray {
    const formArray = this.fb.array([]);

    if (emails && isArray(emails)) {
      console.log('Email are', emails);
      emails.forEach(email => formArray.push(this.fb.control(email))
      );
    } else {
      if (emails) {
        formArray.push(this.fb.control(emails));
      }
    }
    console.log('form array is', formArray)
    return formArray;
  }

  /**
   * addFormTriggers
   *
   * Add triggers to the form used to collect email informations.
   * In order for emails to be sent to the user, we need to user to have this enabled and provide atleast
   * an email. The triggers to be added will be once this feature is enabled, we need to force the user
   * to provide atleast an email otherwise we will have the user disabled it.
   */
  private addFormTriggers(): void {
    this.form.get('enabled').valueChanges.subscribe(enabled => {
      this.showNotificationChoices = enabled;
      if (enabled) {
        // set the email validators to force the user to provide atleast a single email
        this.form.controls['emails'].setValidators([atleastOneValidator()]);
      } else {
        // clear the validators
        this.form.controls['emails'].clearValidators();
      }
      // trigger the form update to naturally re-validate the form.
      this.form.get('emails').updateValueAndValidity();
    });

    this.form.valueChanges.subscribe(value => {
      let valid = this.form.valid;
      if (value.enabled) {
        valid = valid && (value.on_failure || value.on_completion || value.on_cancelled);
      }
      this.formValid.emit({ valid, data: { notifications: value } });
    });
    this.form.updateValueAndValidity();
  }

  get emails(): UntypedFormArray {
    return this.form.controls['emails'] as UntypedFormArray;
  }

  addEmail(event: MatChipInputEvent, form: UntypedFormGroup, name: string): void {
    const emailReg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const input = event.input;
    const value = event.input.value.trim();

    if (emailReg.test(value)) {
      // test to see if the email is valid and add it if not re
      this.emails.push(this.fb.control(value));
      input.value = '';
    }
  }

  removeEmail(form: any, index: number, name: string): void {
    form.get(name).removeAt(index);
  }

}
