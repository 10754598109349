import {AfterViewInit, Component, Input, NgZone, OnDestroy} from '@angular/core';
import {Worklog} from '../../store/incident.model';
import {DrawChartService} from '../../../../sweet-shared/services/draw-chart.service';
import {XYChart} from '@amcharts/amcharts4/charts';

@Component({
  selector: 'app-threat-history-worklog',
  templateUrl: './threat-history-worklog.component.html',
  styleUrls: ['./threat-history-worklog.component.scss']
})
export class ThreatHistoryWorklogComponent implements AfterViewInit, OnDestroy {
  @Input() worklog: Worklog | any;
  chart: XYChart;

  constructor(
    private drawChartService: DrawChartService,
    private ngZone: NgZone,
  ) { }

  ngAfterViewInit() {
    if (this.worklog?.chartsData?.length) {
      this.worklog?.chartsData.forEach((chartInfo, index) => {
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.chart = this.drawChartService.drawMinimalMultiLineChart(chartInfo, `${chartInfo.title + index}`);
          }, 500);
        });
      });
    }
  }

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      this.chart.dispose();
    });
  }

}
