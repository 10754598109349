import {AfterViewInit, Component, Input, NgZone, OnDestroy} from '@angular/core';
import {Worklog} from '../../store/incident.model';
import {XYChart} from '@amcharts/amcharts4/charts';
import {DrawChartService} from '../../../../sweet-shared/services/draw-chart.service';


@Component({
  selector: 'app-threat-profile-worklog',
  templateUrl: './threat-profile-worklog.component.html',
  styleUrls: ['./threat-profile-worklog.component.scss']
})

export class ThreatProfileWorklogComponent implements AfterViewInit, OnDestroy {
  @Input() worklog: Worklog | any;
  chart: XYChart;

  constructor(
    private drawChartService: DrawChartService,
    private ngZone: NgZone,
  ) { }

  ngAfterViewInit() {
    if (this.worklog?.chartsData[2]?.type === 'risk_score') {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.chart = this.drawChartService.drawFuelGaugeChart(this.worklog.chartsData[2].risk_score, this.chart, `${this.worklog.chartsData[2].title}`);
        }, 500);
      });
    }
    if (this.worklog?.chartsData[3]?.type === 'multi_line') {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.chart = this.drawChartService.drawMinimalMultiLineChart(this.worklog.chartsData[3], `${this.worklog.chartsData[3].title}`);
        }, 500);
      });
    }
  }

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      this.chart.dispose();
    });
  }
}
