<div class="card-title">
  <span><h2>List Management</h2></span>
</div>

<section class="container">
  <mat-card appearance="outlined">
    <div class="layout-padding">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Company Filter</mat-card-title>
        <div (click)="onHelpClick()" aria-label="List Help">
          <button mat-icon-button>
            <mat-icon>help</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-card-content>
      <!-- Company selector -->
      <mat-form-field>
        <mat-label>Company</mat-label>
        <mat-select [formControl]="companyControl">
          <mat-form-field class="select-with-search">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="filterCompany($event.target.value)" />
          </mat-form-field>
          <mat-option
            *ngFor="let company of filteredCompaniesOptions"
            [value]="company.id"
            >{{ company.name }} ({{ company.id }})</mat-option
          >
        </mat-select>
      </mat-form-field>

      <!-- search button for list -->
      <button mat-stroked-button (click)="fetchList()">Search</button>

      <!-- filter for list -->
<!--      <mat-form-field>-->
<!--        <mat-label>Filter</mat-label>-->
<!--        <input-->
<!--          matInput-->
<!--          (keyup)="listTable.applyFilter($event.target.value)"-->
<!--          autocomplete="off"-->
<!--          placeholder="Filter through results: Ex: Allow"-->
<!--        />-->
<!--      </mat-form-field>-->

      <!-- table holding the list -->
      <app-table
        [data]="dataList$"
        [headerStream]="listHeaderStream"
        [scrollable]="false"
        [pageSize]="10"
        [doubleScrollbar]="false"
        (events)="selectList($event)"
        #listTable
      >
      </app-table>
    </mat-card-content>
  </mat-card>

  <!-- mat card for list details -->
  <mat-card appearance="outlined" *ngIf="selectedList" class="card-style">
    <div class="space-between">
      <mat-card-title fxFlex>{{ selectedList.name }}</mat-card-title>
      <button
        *ifUserCan="
          [
            'list-mgmt.add-item',
            'list-mgmt.clear-list',
            'list-mgmt.view-items'
          ];
          any: true
        "
        mat-icon-button
        [matMenuTriggerFor]="listMenu"
        aria-label="Profile Menu"
        style="color: #404142"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #listMenu="matMenu">
        <button
          *ifUserCan="listManagementActions.createItemListPermission"
          mat-menu-item
          (click)="openAddListItemDialog('', 'ADD_LIST_ITEM')"
        >
          <mat-icon class="material-icons-round">add</mat-icon>
          <span>Add List Item</span>
        </button>
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          (click)="clearAllListItems()"
          *ifUserCan="'list-mgmt.clear-list'"
        >
          <mat-icon color="warn">delete_sweep</mat-icon>
          <span>Clear List</span>
        </button>
        <button
          mat-menu-item
          (click)="refreshAllListItems()"
          *ifUserCan="listManagementActions.viewItemListPermission"
        >
          <mat-icon color="warn">refresh</mat-icon>
          <span>Refresh List</span>
        </button>
      </mat-menu>
    </div>

    <app-table
      [data]="listDetails$"
      [headerStream]="detailListHeader"
      [showFilter]="true"
      [scrollable]="false"
      [pageSize]="10"
      [actionsTemplate]="listDetailTableActions"
      [doubleScrollbar]="false"
      #listDetailTable
      *ngIf="!loading"
    >
    </app-table>

    <ng-template #listDetailTableActions let-element>
      <div>
        <ng-container *ifUserCan="'list-mgmt.edit-item'">
          <button
            mat-menu-item
            [disabled]="disableButton"
            (click)="openAddListItemDialog(element, 'EDIT_LIST_DATA')"
          >
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button
            mat-menu-item
            [disabled]="disableButton"
            (click)="
              detailRowListEvent(
                'DELETE_LIST_DATA',
                element,
                listDetailTable.headers
              )
            "
            *ifUserCan="listManagementActions.deleteItemListPermission"
          >
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </ng-container>
      </div>
    </ng-template>
  </mat-card>
</section>
