<div class='sweet-loader' [ngClass]="{'full-screen': fullScreen}">
    <div class='sweet-loader-ib' *ngIf="type === 'SPINNER'">
        <mat-spinner color='primary'></mat-spinner>
        <p [class]="type">{{message}}</p>
    </div>

    <div *ngIf="type === 'PROGRESS'">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
