import {UserPreference} from './../pages/user-preference/store/user-preference.model';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import {environment} from '../../environments/environment';

// import each of the sub-store and register them in the big store.
import * as fromProfile from '../pages/profile/store/profile.reducer';
import * as fromIncident from '../pages/incidents/store/incident.reducer';
import * as fromUserPreference from '../pages/user-preference/store/user-preference.reducer';
import * as fromCompany from '../pages/admin/store/company.reducer';
import * as fromUser from '../pages/admin/store/user/user.reducer';
import * as fromEventParams from '../shared-stores/event-params/event-params.reducer';
import * as fromEvent from '../pages/events/store/event.reducer';
import * as fromProduct from '../shared-stores/products/products.reducer';
import * as fromDashboard from '../pages/dashboards/store/dashboard.reducer';
import * as fromReport from '../pages/reporting/store/report.reducer';
import {LOGOUT} from './actions';

export interface State {
  profile: fromProfile.State;
  incidents: fromIncident.State;
  userPreferences: fromUserPreference.State;
  companies: fromCompany.State;
  users: fromUser.State;
  eventParams: fromEventParams.State;
  events: fromEvent.State;
  products: fromProduct.State;
  dashboard: fromDashboard.State;
  reports: fromReport.State;
}

export const reducers: ActionReducerMap<State> = {
  profile: fromProfile.reducer,
  incidents: fromIncident.reducer,
  userPreferences: fromUserPreference.reducer,
  companies: fromCompany.reducer,
  users: fromUser.reducer,
  eventParams: fromEventParams.reducer,
  events: fromEvent.reducer,
  products: fromProduct.reducer,
  dashboard: fromDashboard.reducer,
  reports: fromReport.reducer
};

export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function clearStateFn(state: State, action: Action) {
    if (action.type === LOGOUT) {
      state = {} as State; // ==> Emptying state here
    }
    return reducer(state, action);
  };
}


export const metaReducers: MetaReducer<State>[] = [clearStateMetaReducer];

