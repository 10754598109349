<header>

    <section class="info">
        <div class='title'>
            {{title}}
            <ng-content select="[dateRange]"></ng-content>
        </div>

    </section>

    <section class="actions">
        <!-- menu -->
        <div>
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="eventBus.emit({'type': 'refresh'})">
                    <mat-icon>refresh</mat-icon>
                    <span>Refresh</span>
                </button>
                <button *ngIf="hideEdit" (click)="eventBus.emit({'type': 'edit'})" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>edit</span>
                </button>
                <button mat-menu-item (click)="eventBus.emit({'type': 'exportCSV', 'headers': dataHeadings})" *ngIf='enableDownload'>
                    <mat-icon>download</mat-icon>
                    <span>Download</span>
                </button>
                <button mat-menu-item (click)="eventBus.emit({'type': 'deleteWidget'})">
                    <mat-icon>delete_forever</mat-icon>
                    <span>Delete</span>
                </button>
            </mat-menu>
        </div>

    </section>
    
</header>
