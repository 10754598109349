import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectBoardService {

  redirect$ = new BehaviorSubject(false);
  source$ = new BehaviorSubject('');
  destination = new BehaviorSubject('');
  message$ = new BehaviorSubject('Redirecting to the Events Page');

  constructor() { }
}
