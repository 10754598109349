<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div>
  <div class="card-title">
    <span><h2>Report History</h2></span>
  </div>
</div>
<div class="layout-padding">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="start stretch">
    <button mat-stroked-button type="button" routerLink="/reporting" class="report_history--back">
      <mat-icon>keyboard_arrow_left</mat-icon>
      Back to Reports
    </button>
  </div>
  <!--    Uncomment when updated with correct statuses -->
  <!--  <mat-card fxFill>-->
  <!--    <mat-card-title>Filters</mat-card-title>-->
  <!--    <mat-card-content>-->
  <!--      <form [formGroup]="filterForm">-->
  <!--        <formly-form [form]="filterForm" [fields]="filterFormFields"></formly-form>-->
  <!--      </form>-->
  <!--    </mat-card-content>-->
  <!--  </mat-card>-->
  <mat-card appearance="outlined">
    <mat-card-content>
      <app-table
        [headerStream]="reportHistoryTableHeader$"
        [data]="reportHistories$"
        [pageSize]="25"
        [showFilter]="true"
        [loading]="loadingReportHistory$ | async"
        [actionsTemplate]="reportHistoryActionsTemplate"
        #reportsTable
      >
        <mat-form-field fxFlex>
          <input matInput
                 (keyup)="reportsTable.applyFilter($event.target.value)"
                 placeholder="Filter through results. Ex: Phishing Traffic Detected">
        </mat-form-field>
        <ng-template #reportHistoryActionsTemplate let-row>
          <button *ifUserCan="'report.view-history'" mat-menu-item (click)="downloadReport('csv', row)">
            <mat-icon>file_download</mat-icon>
            Download Report as CSV
          </button>
          <button *ifUserCan="'report.view-history'" mat-menu-item (click)="downloadReport('html', row)">
            <mat-icon>file_download</mat-icon>
            Download Report as HTML
          </button>
        </ng-template>
      </app-table>
    </mat-card-content>
  </mat-card>
</div>

