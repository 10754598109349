import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateRange, IncidentField } from '@app/shared-stores/event-params/event-params.model';
import { FieldInterface, FormBuilderComponent } from '@sweet-shared/components/form-builder/form-builder.component';
import { IncidentsSearchModel, UserPreference } from '../../../user-preference/store/user-preference.model';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/reducers';
import { Incident } from '../../store/incident.model';
import * as fromIncident from '../../store/incident.actions';
import * as userPreferenceActions from '../../../user-preference/store/user-preference.actions';
import { Auth } from 'aws-amplify';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, forkJoin, ReplaySubject, Subject } from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, startWith, takeUntil} from 'rxjs/operators';
import { TableComponent } from '@sweet-shared/components/table/table.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AppService } from '@app/app.service';
import { QueryBuilderComponent } from '@sweet-shared/components/query-builder/query-builder.component';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { AppEmitterService } from '@app/app-emitter.service';
import * as _ from 'lodash';
import { DropDownGroupOption, DropdownOption, IncidentsService } from '../../services/incidents.service';
import { PermissionService } from '@sweet-shared/services/permission.service';
import { ModalComponent } from '@sweet-shared/components/modal/modal.component';
import { HelpDialogModalComponent } from '@sweet-shared/components/help-dialog-modal/help-dialog-modal.component';
import { UrlService } from '@shared-services/url.service';
import { HeaderSortingService } from '@shared-services/header-sorting.service';
import { RefreshService } from '@sweet-shared/services/refresh.service';
import { IncidentFormComponent } from '@modules/incident/incident-form/incident-form.component';
import { EventsService } from '@modules/events/services/events.service';


export interface QueryData {
    rules: any[];
    ruleGroups?: any[];
    formatWithColon: boolean;
    page?: 'incident' | 'event' | 'dashboard';
}


@Component({
    selector: 'app-incidents-page',
    templateUrl: './incidents-page.component.html',
    styleUrls: ['./incidents-page.component.scss']
})
export class IncidentsPageComponent implements OnInit, OnDestroy {
    searchForm: UntypedFormGroup = new UntypedFormGroup({
        company: new UntypedFormControl([], []),
        category: new UntypedFormControl([], []),
        u_queue: new UntypedFormControl([], []),
        state: new UntypedFormControl([], []),
        priority: new UntypedFormControl([], []),
        close_code: new UntypedFormControl([], []),
        u_unlisted_affected_user: new UntypedFormControl([], []),
        query: new UntypedFormControl('', []),
        createdAt: new UntypedFormControl(null, [Validators.required]),
        assignment_group: new UntypedFormControl([], [])
    });
    selectAllControls: { [key: string]: boolean } = {
        company: false,
        classifications: false,
        priority: false,
        state: false,
        tier: false,
        assignmentGroup: false,
        closureState: false,
        assignee: false
    };
    defaultDatetime: any = null;
    searchInterval: number = 60 * 60;
    timerStopped = false;
    timeLeft = 0;
    loading = true;
    searching = false;
    currentSelectedTab = 'incidents';

    companyOptions: DropdownOption[] = [];
    classificationOptions: DropdownOption[] = [];
    priorityOptions: DropdownOption[] = [];
    stateOptions: DropdownOption[] = [];
    tiersOptions: DropdownOption[] = [];
    closureOptions: DropdownOption[] = [];
    userOptions: DropDownGroupOption[] = [];
    allUserOptions: any[] = [];
    allCompanyOptions: DropdownOption[] = [];
    datetimeOptions: any[] = [];
    activeInvestigationUserGroup: any[] = [];

    // TODO Remove hard coding when backend sends us the udated models
    // assignmentGroupOptions: { value: string; label }[] = [];
    assignmentGroupOptions: { value: string; label: string }[] = [{ label: 'SOC Monitoring - SLM', value: 'MSS-CTL-SOC' }, { label: 'ANS', value: 'MSS-Portal-ANS' }, { label: 'Non-Monitored (Catchall)', value: 'MSS-Portal-SLM' }];

    // filters for dropdown
    // userFilterCtrl: FormControl = new FormControl();
  userFilterCtrls: UntypedFormControl = new UntypedFormControl();

  companyFilterCtrl: UntypedFormControl = new UntypedFormControl();

    companies: any[] = [];


    dateRange$ = new BehaviorSubject(null);

    init$ = new BehaviorSubject(false);
    // Private destroyer used to kill all the subscription once the page is destroyed.
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject();

    private actionEvent$: Subject<any> = new Subject();
    hide: BehaviorSubject<{ flag: boolean, typ: string }> = new BehaviorSubject({ flag: true, typ: '' });

    // loader control for the search form
    loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // Due to the fact we have super user(s) in the system, and also that searches are restricted to
    // particular user, we need to let the user choose the company in which he/she would like to
    // perform a search from. The list will be empty for a start and then will be populated when available.
    allCompanies: any[] = [];

    // Date range is also something we need for the user to choose from. It will be passed down to the filter
    // component to populate the dropdown. Again it will be set to an empty list until populated.
    dataRanges: DateRange[] = [];

    // Form Details
    searchFieldDetails: FieldInterface[] = null;


    // field updater
    fieldUpdater: EventEmitter<any> = new EventEmitter<any>();

    // all incident fields available
    incidentFields: IncidentField[] = [];
    sortItems = new HeaderSortingService();

    // Because the header is subject to change when the user decide to add/remove columns he/she does not need
    // we need to signal the table to update its headers. For that to be possible, we will use a BehaviorSubject
    incidentsHeadersSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);

    // Data Stream to use to populate the table is also subject to change without the need of reloading the table
    // for that to be possible we will be using a behaviorSubject.
    incidentsDataStream: BehaviorSubject<Incident[]> = new BehaviorSubject([]);

    // Activate investigation holder
    activeInvestigation: Incident = null;

    // Incident data
    userPreferences: UserPreference = null;
    tierOptions: any[] = [];
    users: any[] = [];
    assignedIncidentSubject: BehaviorSubject<Incident> = new BehaviorSubject(null);
    // Currently, hard-coding, but may be added to userPreferences
    NEW_INCIDENT_MS = 3_600_000; // milliseconds in 1 hour

    // Dialog references
    dialogRef: any = null;

    // Updating user preferences
    updatingUserPreferences: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    showIncidentDisplayDetailsButton = true;

    userName: string;
    incidentPageUrl: string = null;
    pageSize = 25;


    nonClosedInvestigationOptions: string[];
    private currentRoute;
    private previousRoute;
    private eventResults: any[] = [];


    @ViewChild(FormBuilderComponent) formBuilderComponent: FormBuilderComponent;
    @ViewChild('search') search: FormBuilderComponent;
    @ViewChild('incidentsTable') incidentsTable: TableComponent;

    constructor(
        private store: Store<State>,
        private matDialog: MatDialog,
        private router: Router,
        private appService: AppService<Incident>,
        private activatedRoute: ActivatedRoute,
        private incidentService: AppService<Incident>,
        private appEmitterService: AppEmitterService,
        public incidentsService: IncidentsService,
        private permissionService: PermissionService,
        private eventService: EventsService,
        public dialog: MatDialog,
        public urlService: UrlService,
        public refreshSrv: RefreshService,
        private fb: UntypedFormBuilder
    ) {
        this.incidentsService.init();
        combineLatest([
            this.incidentsService.userPreference,
            this.incidentsService.incidentClassifications,
            this.incidentsService.incidentAssignees,
            this.incidentsService.incidentPriorities,
            this.incidentsService.incidentStates,
            this.incidentsService.incidentTiers,
            this.incidentsService.incidentClosureStates,
            this.appService.get('company', '', null),
            this.appService.get('eventParams', '', null),
            Auth.currentAuthenticatedUser()
        ]).pipe(
            takeUntil(this.destroyed$),
            filter((response: any) => {
                return !response.reduce((prev, next) => {
                    if (next?.length === 0) {
                        prev = true;
                    }
                    return prev;
                }, false);
            })
        ).subscribe((response: any[]) => {
            this.userPreferences = response[0];
            if (this.userPreferences) {
                // fetch activate investigation
                this.fetchActiveInvestigation(this.userPreferences.activeInvestigation !== '-' ? this.userPreferences.activeInvestigation : null);
            }

            this.classificationOptions = response[1];

            this.allUserOptions = response[2];
            if (this.userOptions.length === 0) {
                this.userOptions = response[2];
            }
            this.priorityOptions = response[3];
            this.stateOptions = response[4];
            this.tierOptions = response[5];
            this.closureOptions = response[6];

            // set the company options
            this.companyOptions = response[7].map(company => ({ label: company.name, value: company.id }));
            this.allCompanyOptions = response[7].map(company => ({ label: company.name, value: company.id }));

            // set incident fields
            this.incidentFields = response[8].incidentFields;
            const sortedFields = this.sortItems.sortList(this.incidentFields, 'incident');
            this.incidentsHeadersSubject.next(this.permissionService.hasPermission('response.add-worklog') ? [{ name: 'actions', friendly: 'Actions', description: '' }, ...sortedFields] : sortedFields);

            // Check if we have something in the localSto
            const where = JSON.parse(localStorage.getItem('incidentSearchPayload') || '{}');
            this.datetimeOptions = response[8].incidentDateRanges;
            this.defaultDatetime = where?.createdAt ?? this.userPreferences?.dateRange ?? this.datetimeOptions[0];
            this.searchForm.patchValue({
                ...this.userPreferences.incidentsSearch,
                createdAt: this.defaultDatetime,
                company: this.userPreferences?.incidentsSearch?.companies?.length ? this.userPreferences?.incidentsSearch?.companies : [response[9].attributes.profile],
                u_unlisted_affected_user: this.userPreferences.incidentsSearch.assignee,
                category: this.userPreferences.incidentsSearch.classifications,
                u_queue: this.userPreferences.incidentsSearch.tier,
                close_code: this.userPreferences.incidentsSearch.closureState,
                ...where
            });
            this.searchForm.updateValueAndValidity();

            // set the search interval
            try {
                this.searchInterval = parseInt(this.userPreferences.searchRefreshInterval.split(' ')[0]) * 60;
            } catch {
                this.searchInterval = 60 * 60;
            }
            this.loading = false;

            this.performSearch();
        });



        // attached to the response
        this.incidentsService.incidentResults.pipe(takeUntil(this.destroyed$)).subscribe(val => this.incidentsDataStream.next(val));

        // listening to ShowAssigned event emitted from header component
        this.appEmitterService
            .on('SHOW_ASSIGNED', (emitted) => {
                // when these 2 variables have values, it will act as flag to determine default values of controls
                this.userName = emitted.data.userName;
                this.nonClosedInvestigationOptions = emitted.data.nonClosedInvestigationOptions;
                // will assign control values and start search if the search component exists
                if (this.search) {
                    this.search.form.controls.assignee.setValue([this.userName]);
                    this.search.form.controls.state.setValue(this.nonClosedInvestigationOptions);
                    this.search.form.controls.companies.setValue([emitted.data.userCompany]);
                    // this.searchFilterActionHandler({ name: 'SEARCH', data: this.search.form.value });
                }
            }, this.destroyed$);


        // listen to the filters

        this.companyFilterCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
            this.companyOptions = this.allCompanyOptions.filter(user => (user.value + ' ( ' + user.label + ' ) ').toLowerCase().includes(val.toLowerCase()));
            //     this.filteredCompaniesOptions = this.companiesOptions.filter(c => (c.name + ' ( ' + c.id + ' )').toLowerCase().includes(searchTerm.toLowerCase()));
        });

        this.eventService.eventResults$.pipe(takeUntil(this.destroyed$)).subscribe(val => this.eventResults = val);
    }


    toggleAllSelection(value: any, key: string): void {
        this.selectAllControls[key] = !this.selectAllControls[key];

        switch (key) {
            case 'company':
                if (value.checked) {
                    this.searchForm.patchValue({ company: this.companyOptions.map(company => company.value) });
                } else {
                    this.searchForm.patchValue({ company: [] });
                }
                break;
            case 'classifications':
                if (value.checked) {
                    this.searchForm.patchValue({ category: this.classificationOptions.map(company => company.value) });
                } else {
                    this.searchForm.patchValue({ category: [] });
                }
                break;
            case 'tier':
                if (value.checked) {
                    this.searchForm.patchValue({ u_queue: this.tierOptions.map(company => company.value) });
                } else {
                    this.searchForm.patchValue({ u_queue: [] });
                }
                break;
            case 'state':
                if (value.checked) {
                    this.searchForm.patchValue({ state: this.stateOptions.map(company => company.value) });
                } else {
                    this.searchForm.patchValue({ state: [] });
                }
                break;
            case 'priority':
                if (value.checked) {
                    this.searchForm.patchValue({ priority: this.priorityOptions.map(company => company.value) });
                } else {
                    this.searchForm.patchValue({ priority: [] });
                }
                break;
            case 'closureState':
                if (value.checked) {
                    this.searchForm.patchValue({ close_code: this.closureOptions.map(company => company.value) });
                } else {
                    this.searchForm.patchValue({ close_code: [] });
                }
                break;
            case 'assignee':
                if (value.checked) {
                    this.searchForm.patchValue({
                        u_unlisted_affected_user: this.userOptions.reduce((prev, next) => {
                            prev = [...prev, ...next.option.map(val => val.value)];
                            return prev;
                        }, [])
                    });
                } else {
                    this.searchForm.patchValue({ u_unlisted_affected_user: [] });
                }
                break;
            case 'assignmentGroup':
                if (value.checked) {
                    this.searchForm.patchValue({ assignment_group: this.assignmentGroupOptions.map(company => company.value) });
                } else {
                    this.searchForm.patchValue({ assignment_group: [] });
                }
                break;
            default:
                break;
        }
    }

    updateDatePicker(evt): void {
        this.searchForm.patchValue({ createdAt: evt.selected });
    }

    performSearch(searchPayload = null): void {
        this.searching = true;
        this.timerStopped = true;
        const value = !searchPayload ? this.searchForm.value : searchPayload;
        const where: any = Object.keys(value).reduce((prev, next) => {
            if (value[next].length > 0 || value[next].type) {
                if (value[next].type) {
                    const { type, from, to } = value[next];
                    prev[next] = { gte: from, lte: to, dateType: type };
                } else {
                    prev[next] = value[next].constructor.name === 'Array' ? value[next].filter(val => val !== '--') : value[next];
                }
            } else {
                prev[next] = value[next];
            }
            return prev;
        }, {});

        const payload: any = { ...where };
        payload.createdAt = { type: where.createdAt.dateType, from: where.createdAt.gte, to: where.createdAt.lte };
        // persist the search criteria in localstorage
        localStorage.setItem('incidentSearchPayload', JSON.stringify(payload));
        const payloadBody = JSON.stringify(Object.keys(where).reduce((prev, next) => {
            if (where[next].length > 0) {
                prev[next] = where[next];
            } else if (next === 'createdAt') {
                if (where[next] !== undefined || where[next] !== '') {
                    prev[next] = where[next];
                }
            }
            return prev;
        }, {}));
        this.appService.get('investigation', '', {where: payloadBody})
            .then(response => {
                this.incidentsService.setIncidentResults(response.source);
            })
            .catch(error => {
                console.log('error', error);
            })
            .finally(() => {

                setTimeout(() => {
                    this.timerStopped = false;
                    this.restartRefreshTime(this.searchInterval);
                }, (1500));
                this.searching = false;
            });
    }

    fetchActiveInvestigation(activeInvestigationId: string): void {
        if (activeInvestigationId) {
            this.incidentService.get('investigation', activeInvestigationId, {})
                .then(response => {
                    this.appService.get('user', '', { companyFilter: this.companyOptions.map(c => c.value).join(',') })
                        .then(users => {
                            this.activeInvestigationUserGroup = this.computeGroupedUser(users);
                            this.activeInvestigation = response;
                            this.assignedIncidentSubject.next(response);
                        });

                })
                .catch(error => {
                    console.log('failed to catch active investigation', error);
                });
        } else {
            this.activeInvestigation = null;
            this.assignedIncidentSubject.next(null);
        }
    }


    restartRefreshTime(time: number): void {
        if (this.timerStopped) {
            this.timeLeft = this.searchInterval;
            return;
        }

        if (time === 0) {
            // perform the search
            this.timerStopped = true;
            this.performSearch();
        } else {
            setTimeout(() => {
                this.timeLeft = time - 1;
                return this.restartRefreshTime(this.timeLeft);
            }, 1000);
        }
    }

    formatTime(time: number): string {
        return `${Math.floor(time / 60)}m: ${time % 60}s`;
    }

    computeGroupedUser(users: any): any {
        return users.reduce((prev, next) => {
            // find the element if present
            const groupIndex = prev.findIndex(group => group.groupName === next.Attributes.profile);
            if (groupIndex >= 0) {
                prev[groupIndex].option.push({ label: `${next.Attributes.given_name} ${next.Attributes.family_name} (${next.Username})`, value: next.Username });
            } else {
                prev.push({ groupName: next.Attributes.profile, option: [{ label: `${next.Attributes.given_name} ${next.Attributes.family_name} (${next.Username})`, value: next.Username }] });
            }
            return prev;
        }, []);
    }


    ngOnInit() {
        this.showHideDateTime();
        this.incidentPageUrl = this.activatedRoute.snapshot['_routerState'].url;
        this.hide.subscribe(pkg => {
            if (!!this.searchFieldDetails) {
                this.searchFieldDetails = this.searchFieldDetails.map(s => {
                    if (s.name === 'dateTime') {
                        return { ...s, hide: pkg.flag };
                    }
                    return s;
                });
            }
        }
        );
        this.currentRoute = this.urlService.setCurrentUrl(this.router.url);
        this.previousRoute = this.urlService.getPreviousUrl();
        if (this.router.url.includes('/incidents/events')) {
            this.currentSelectedTab = 'events';
        }
    }



    handlePaginationChange(changes: any) {
        this.incidentsService.paginatorConfig = { ...changes?.payload };

    }

    saveIncidentsSearchPreferences(incidentsSearchValue: IncidentsSearchModel): void {
        const newUserPrefs: UserPreference = {
            ...this.userPreferences,
            incidentsSearch: incidentsSearchValue
        };
        this.store.dispatch(userPreferenceActions.updateUserPreferences({ userPreferences: newUserPrefs, notify: false }));
    }

    showHideDateTime(): void {
        this.actionEvent$.subscribe(event => {
            if (event.data && event.data.field === 'dateRange') {
                this.hide.next({ flag: event.data.value !== 'custom', typ: event.data.value });
            }
        });
    }

    onTableRowClick(event) {
        switch (event.type) {
            case 'ROW_CLICK':
                if (event.data.sys_id) {
                    const requiredPermission = 'response.view-inv-details';
                    if (this.permissionService.hasPermission(requiredPermission)) {
                        this.router.navigate([`/incidents/incident/${event.data.sys_id}`]).then(() => {
                        });
                    } else {
                        const dialogRef = this.matDialog.open(ModalComponent);
                        dialogRef.componentInstance.message = `
                <p>You are missing the required permission('${requiredPermission}') to view the investigation details.</p>
                <p>Please contact your admin for assistance.</p>
            `;
                        dialogRef.componentInstance.title = `Missing Required Permission`;
                    }
                }
                break;
            default:
                break;
        }
    }

    incidentActionHandler(event) {
        switch (event.type) {
            case 'DISMISS':
                // Should only remove from user preferences
                this.store.dispatch(userPreferenceActions.updateUserPreferences({
                    userPreferences: {
                        ...this.userPreferences,
                        activeInvestigation: '-'
                    },
                    notify: true
                }));
                break;
            case 'SHOW_DETAILS':
                if (event.incident.sys_id) {
                    this.router.navigate(['/incidents/incident', event.incident.sys_id]).then(() => {
                    });
                }
                break;
            case 'UPDATE':
                this.store.dispatch(fromIncident.updateIncident({ incidentId: event.incident.sys_id, value: event.data }));
                break;
            default:
                break;
        }
    }

    computeIncidentGrade(incident: Incident, key: string) {
        if (!incident) { return ''; }
        if (key && key !== 'number') {
            return '';
        }
        if (incident.priority.includes('1')) {
            return environment.gradeColors.grade1;
        } else if (incident.priority.includes('2')) {
            return environment.gradeColors.grade2;
        } else if (incident.priority.includes('3')) {
            return environment.gradeColors.grade3;
        } else if (incident.priority.includes('4')) {
            return environment.gradeColors.grade4;
        } else if (incident.priority.includes('5')) {
            return environment.gradeColors.grade5;
        } else {
            return '';
        }
    }

    highlightNewOrUpdatedIncidents(row: Incident): boolean {
        if (row && row.sys_updated_on) {
            const createdTime = moment(row.sys_updated_on).valueOf();
            const now = moment().valueOf();
            if ((now - createdTime) < this.NEW_INCIDENT_MS || row.u_contact_time.length > 0) {
                return true;
            }
        }
        return false;
    }

    onCreateIncident() {
        this.matDialog.open(IncidentFormComponent, { disableClose: true, minWidth: '70%' }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(
            response => {
                if (response) {
                    // redirect to the newly created ticket.
                    this.router.navigate(['/incidents/incident', response.sys_id]);
                }
            }
        );
    }

    downloadAsCSV() {
        const date = new Date().toLocaleDateString().replace(/\//g, '_');
        const format = this.incidentsTable.formatForCSVDownload();
        const csv = new Angular5Csv(format.data, `incidents-${date}`, format.options);
    }

    openQueryBuilder() {
        const data: QueryData = {
            rules: [{ field: '', operator: '=', value: '' }],
            formatWithColon: true,
            page: 'incident'
        };
        let sessionRules: any = sessionStorage.getItem(`${data.page}-query`);
        sessionRules ? sessionRules = JSON.parse(sessionRules) : sessionRules = [];
        (sessionRules[0] && sessionRules[0].rules && sessionRules[0].rules.length) ?
            data.ruleGroups = sessionRules :
            data.ruleGroups = [{ condition: 'AND', rules: data.rules }];
        const dialogRef = this.matDialog.open(QueryBuilderComponent, {
            disableClose: false,
            panelClass: 'ctl-panel-class',
            data
        });
        dialogRef.componentInstance.fields = this.incidentsHeadersSubject.value;
        dialogRef.componentInstance.inputData = data;

        dialogRef.componentInstance.queryBody.pipe(
            takeUntil(this.destroyed$)
        ).subscribe(event => {
            if (event.name === 'submit') {
                this.formBuilderComponent.form.controls.query.setValue(event.data);
                this.performSearch(this.formBuilderComponent.form.value);
                dialogRef.close();
            } else if (event.name === 'cancel') {
                dialogRef.close();
            }
        });
    }

    isClosed(row: Incident): boolean {
        return row.state === 'Closed';
    }

    urgency(cell: any): number {
        if (cell.u_queue === 'Tier 1') {
            return 1;
        } else if (cell.u_queue === 'Tier 2') {
            return 2;
        } else {
            return 3;
        }
    }

    onHelpClick() {
        // Opens up the Incident KB dialog with how to article
        const dialogRef = this.dialog.open(HelpDialogModalComponent);
    }

    onToggleClick(value: string) {
        this.urlService.setToggleValue(value);
        this.currentSelectedTab = value;
    }

    onAddWorklog(incident: Incident): void {
        this.incidentsService.addWorklog(incident);
    }

    handleDateSelection(payload: any) {
        this.dateRange$.next(payload?.selected);
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
