<div class="login-page-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card appearance="outlined">
    <app-portal-loader [loadingStatus]="isAuthenticating"></app-portal-loader>
    <mat-card-header>
      <mat-card-title>Change Password</mat-card-title>
      <mat-card-subtitle>Please enter you new password below.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="forceChangePassword" (submit)="updatePassword()" (keyup)="validator()" fxLayout="column">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>New Password</mat-label>
          <input type="password" matInput formControlName="newPassword">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Confirm New Password</mat-label>
          <input type="password" matInput formControlName="confirmNewPassword">
        </mat-form-field>
        <div class="error-wrapper">
          <ng-template #validIcon>
            <mat-icon>done</mat-icon>
          </ng-template>

          <p [ngClass]="noLowerCase ? 'error': 'valid'" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
            <mat-icon *ngIf="noLowerCase; else validIcon">clear</mat-icon>
            Password must contain a lower case letter
          </p>
          <p [ngClass]="noUpperCase ? 'error' : 'valid'" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
            <mat-icon *ngIf="noUpperCase; else validIcon">clear</mat-icon>
            Password must contain a upper case letter
          </p>
          <p [ngClass]="noSpecialChars ? 'error' : 'valid'" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
            <mat-icon *ngIf="noSpecialChars; else validIcon">clear</mat-icon>
            Password must contain a special character (@$#!%*?&)
          </p>
          <p [ngClass]="noNumbers ? 'error' : 'valid'" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
            <mat-icon *ngIf="noNumbers; else validIcon">clear</mat-icon>
            Password must contain a number
          </p>
          <p [ngClass]="lessThan14Chars ? 'error' : 'valid'" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
            <mat-icon *ngIf="lessThan14Chars; else validIcon">clear</mat-icon>
            Password must contain at least 14 characters
          </p>
          <p [ngClass]="passwordsDoNotMatch ? 'error' : 'valid'" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
            <mat-icon *ngIf="passwordsDoNotMatch; else validIcon">clear</mat-icon>
            Passwords must match
          </p>
        </div>
        <button mat-raised-button color="primary" [disabled]="!forceChangePassword.valid || passwordsDoNotMatch" type="submit">
          <mat-icon>public</mat-icon>
          Update Password
        </button>
      </form>

      <mat-divider></mat-divider>
      <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-around center">
        <button mat-button>About CenturyLink</button>
        <button mat-button>About MSS</button>
        <button mat-button>Legal</button>
        <button mat-button>Legal Notices</button>
        <button mat-button>Privacy Policies</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="center end">
        <p>© 2020 CenturyLink. All Rights Reserved.</p>
      </div>
    </mat-card-content>
  </mat-card>
</div>

