<section class="mat-elevation-z4" *ngIf="column">
  <span> {{ column.friendly }} </span>

  <span class="arrows" *ngIf="sorting">
    <span>Sort By: </span>
    <mat-radio-group [value]="sortOrder" (change)="updateSortDirection($event)">
      <mat-radio-button value="asc">ACS</mat-radio-button>
      <mat-radio-button value="desc">DESC</mat-radio-button>
    </mat-radio-group>
  </span>

  <button
    mat-icon-button
    (click)="onAction('REMOVE')"
    matTooltip="Remove Selected Column {{ column.friendly }}"
  >
    <mat-icon color="warm">remove</mat-icon>
  </button>
</section>
