import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { NgxLoadingModule } from 'ngx-loading';

import { MapComponent } from './map/map.component';
import { PieComponent } from './pie/pie.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { MultiBarComponent } from './multi-bar/multi-bar.component';
import { FuelGaugeComponent } from './fuel-gauge/fuel-gauge.component';
import { MultiLineComponent } from './multi-line/multi-line.component';
import { SolidGaugeComponent } from './solid-gauge/solid-gauge.component';
import { SingleMetricComponent } from './single-metric/single-metric.component';
import { WidgetWrapperComponent } from './widget-wrapper/widget-wrapper.component';


@NgModule({
  declarations: [
    MultiBarComponent,
    MenuBarComponent,
    MultiLineComponent,
    WidgetWrapperComponent,
    MapComponent,
    PieComponent,
    SingleMetricComponent,
    SolidGaugeComponent,
    FuelGaugeComponent
  ],
  imports: [
    CommonModule,
    NgxLoadingModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule
  ],
  exports: [
    MenuBarComponent,
    MultiBarComponent,
    MultiLineComponent,
    MapComponent,
    PieComponent,
    WidgetWrapperComponent
  ]
})
export class SweetWidgetsModule { }
