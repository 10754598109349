<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="error-event" *ngIf="error">
  <div class="error-message">{{ error }}</div>
</div>
<div
  class="layout-padding event_details-page"
  *ngIf="!loading && event && fields"
>
  <button mat-stroked-button type="button" (click)="goBack()">
    <mat-icon>keyboard_arrow_left</mat-icon>
    Back to List
  </button>
  <button
    *ifUserCan="'response.create-inv'"
    mat-stroked-button
    color="primary"
    class="add-button"
    (click)="onCreateIncident()"
  >
    <mat-icon>add</mat-icon>
    Create Incident
  </button>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="event_detail-header-container"
  >
    <mat-icon>bug_report</mat-icon>
    <h1>
      <strong>{{ event.JCEF_evtName | uppercase }}</strong>
    </h1>
  </div>
  <!--  <p>{{event.MESSAGE}}</p>-->

  <mat-list class="event_details">
    <!--    Iterates over each field property in Event Details-->
    <div fxLayout="row" *ngFor="let key of event | keyvalue">
      <!--      Only displays event detail properties if their VALUE is not empty-->
      <div
        fxFlex
        *ngIf="key.value?.length"
        class="event_details-list-item"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <h4 fxFlex="30">
          <strong>{{ getFriendly(key.key) }}: </strong>
        </h4>
        <p fxFlex>{{ getValueType(key.key, key.value) }}</p>
      </div>
    </div>
  </mat-list>
  <app-bottom-page-border></app-bottom-page-border>
</div>
