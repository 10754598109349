import {Action, createReducer, on} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {Profile} from './profile.model';
import * as ProfileActions from './profile.actions';

export const profilesFeatureKey = 'profile';

export interface State extends EntityState<Profile> {
  // additional entities state properties
  // This will be use for storing information about a selected user profile.
  userProfile: Profile;
  loadingProfile: boolean;
  loadProfileError: string;

  // Current user profile.
  currentUserProfile: Profile;
  currentUserProfileLoading: boolean;
  currentUserProfileError: string;

  // Current user company.
  currentUserCompany: any;
  currentUserCompanyLoading: boolean;
  currentUserCompanyError: string;
}

export const adapter: EntityAdapter<Profile> = createEntityAdapter<Profile>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  userProfile: null,
  loadingProfile: false,
  loadProfileError: null,

  currentUserProfile: null,
  currentUserProfileError: null,
  currentUserProfileLoading: false,

  currentUserCompany: null,
  currentUserCompanyLoading: false,
  currentUserCompanyError: null,
});

const profileReducer = createReducer(
  initialState,
  on(ProfileActions.loadCurrentUserCompany,
    (state, action) => {
      return {
        ...state,
        currentUserCompanyLoading: true,
        currentUserCompanyError: null,
        currentUserCompany: null
      };
    }
  ),
  on(ProfileActions.loadCurrentUserCompanySuccess, (state, action) => {
    return {
      ...state,
      currentUserCompanyLoading: false,
      currentUserCompanyError: null,
      currentUserCompany: action.currentUserCompany
    };
  }),
  on(ProfileActions.loadCurrentUserCompanyError, (state, action) => {
    return {
      ...state,
      currentUserCompanyLoading: false,
      currentUserCompanyError: action.error,
      currentUserCompany: null
    };
  }),
  on(ProfileActions.loadProfile,
    (state, action) => {
      return {
        ...state,
        loadingProfile: true,
        loadProfileError: null,
        userProfile: null
      };
    }
  ),
  on(ProfileActions.loadProfileSuccess, (state, action) => {
    return {
      ...state,
      loadProfileError: null,
      loadingProfile: false,
      userProfile: action.profile
    };
  }),
  on(ProfileActions.loadProfileError, (state, action) => {
    return {
      ...state,
      loadingProfile: false,
      userProfile: null,
      loadProfileError: action.error
    };
  }),
  on(ProfileActions.loadCurrentUserProfile, (state) => {
    return {
      ...state,
      currentUserProfileLoading: true,
      currentUserProfileError: null,
      currentUserProfile: null
    };
  }),
  on(ProfileActions.loadCurrentUserProfileSuccess, (state, action) => {
    return {
      ...state,
      currentUserProfile: action.profile,
      currentUserProfileError: null,
      currentUserProfileLoading: false
    };
  }),
  on(ProfileActions.loadCurrentUserProfileError, (state, action) => {
    return {
      ...state,
      currentUserProfileLoading: false,
      currentUserProfile: null,
      currentUserProfileError: action.error
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return profileReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
