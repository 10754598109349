import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { State } from '../../../reducers';
import { DevicesService } from '../services/devices.service';
import { TableHeader } from '../../../sweet-shared/components/table/table.component';
import * as eventParamActions from '../../../shared-stores/event-params/event-params.actions';
import * as userPreferenceActions from '../../user-preference/store/user-preference.actions';
import { dashboardGridsterConfig } from '../../../../environments/environment';
import { ConfirmationDialogComponent } from 'src/app/sweet-shared/components/confirmation-dialog/confirmation-dialog.component';
import { Devices } from '@app/pages/devices/devices/devices.component';


export interface DeviceModel {
  id: string;
  deviceId: string;
  name: string;
  friendlyName: string;
  version: string;
  deviceType: string;
  customerId: string;
  deviceCustomerId: string;
  physicalAddress: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  service: string;
  healthInformation?: string;
  lat?: string;
  lon?: string;
  // interfaces?: string | DeviceInterfaceModel[];
  interfaces?: DeviceInterfaceModel[];

  upstreamData?: string;
}

export interface DeviceInterfaceModel {
  gw: string;
  amon: any;
  ip: string;
  vlan: string;
  if_id: number;
  ext: any;
  mgmt: string;
  ifname: string;
  netmask: number;
  monitor: any;
}

export interface DevicePolicyResponseModel {
  id: string;
  policy: DevicePolicyModel;
  updatedAt: string;
  updatedBy: string;
}

export interface DevicePolicyModel {
  asa_policy: any;
  pan_policy: PanPolicyModel[];
  policy_type: string;
}

export interface PanPolicyModel {
  action: string;
  applications: string[];
  category: string[];
  description: string;
  destination: string[];
  deviceId: number;
  hipProfiles: string;
  logEnd: any;
  logSetting: any;
  logStart: any;
  ruleName: string;
  service: string[];
  source: string[];
  sourceUser: string[];
  tag: string;
  zoneFrom: string;
  zoneTo: string;
}

@UntilDestroy()
@Component({
  selector: 'app-device-detail-page',
  templateUrl: './device-detail-page.component.html',
  styleUrls: ['./device-detail-page.component.scss']
})
export class DeviceDetailPageComponent implements OnInit {
  devicePolicy: DevicePolicyResponseModel;
  deviceData: Devices;
  deviceTypeWithPolicyTab = ['Palo Alto 2000 Series Gateway',
    'Palo Alto PA-3050 Gateway with failover',
    'Palo Alto PA-3020 Gateway with failover'];
  loading: boolean;

  panPolicyDataStream: BehaviorSubject<PanPolicyModel[]> = new BehaviorSubject<PanPolicyModel[]>([]);
  panPolicyHeadersSubject: BehaviorSubject<TableHeader[]> = new BehaviorSubject<any[]>([]);
  panPolicyHeaders: TableHeader[] = [
    { name: 'action', friendly: 'ACTION' },
    { name: 'ruleName', friendly: 'RULENAME' },
    { name: 'description', friendly: 'DESCRIPTION' },
    { name: 'zoneTo', friendly: 'ZONETO' },
    { name: 'zoneFrom', friendly: 'ZONEFROM' },
    { name: 'source', friendly: 'SOURCE' },
    { name: 'destination', friendly: 'DESTINATION' },
    { name: 'sourceUser', friendly: 'SOURCEUSER' },
    { name: 'category', friendly: 'CATEGORY' },
    { name: 'applications', friendly: 'APPLICATIONS' },
    { name: 'service', friendly: 'SERVICE' },
    { name: 'tag', friendly: 'TAG' },
    { name: 'logStart', friendly: 'LOGSTART' },
    { name: 'logEnd', friendly: 'LOGEND' },
    { name: 'logSetting', friendly: 'LOGSETTING' }
  ];
  showPolicyCards = false;
  dateRanges: any[] = [];
  gridsterOptions = {
    ...dashboardGridsterConfig,
  };
  healthForm: UntypedFormGroup;
  infoForm: UntypedFormGroup;

  basicInfoForm: UntypedFormGroup;
  additionalInfoForm: UntypedFormGroup;

  interfaces: UntypedFormArray[];

  userPreferences: any;
  isEdit: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<State>,
    private devicesService: DevicesService,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.pageSetup();
  }

  private pageSetup() {
    this.isEdit = false;
    this.devicesService.getDeviceLoading().pipe(
      startWith(true),
      untilDestroyed(this)
    ).subscribe(isLoading => {
        this.loading = isLoading;
      });
    this.healthForm = new UntypedFormGroup({
      dateRange: new UntypedFormControl('')
    });

    this.panPolicyHeadersSubject.next(this.panPolicyHeaders);

    this.store.dispatch(userPreferenceActions.loadUserPreferences());
    this.store.select(state => state.userPreferences.userPreferences).pipe(filter(data => !!data),
      untilDestroyed(this)
    ).subscribe(userPreferences => {
        this.userPreferences = userPreferences;
        this.store.dispatch(eventParamActions.loadEventFields());
        this.store.select(state => state.eventParams.eventParams)
          .pipe(
            filter(data => !!data && !!data.dateRanges),
            untilDestroyed(this)
          ).subscribe(eventParams => {
            this.dateRanges = eventParams.dateRanges;
            this.healthForm.get('dateRange').setValue(this.userPreferences.dateRange);
          });
      });

    this.activatedRoute.params.subscribe((param) => {
      if (param) {
        this.getInfoDataTab(param);
        this.getDevicePolicy(param);
      }
    });
  }

  private getDevicePolicy(singleDeviceId) {
    this.devicesService.devicePolicy(singleDeviceId.customerId, singleDeviceId.deviceId);
    this.devicesService.getDevicePolicy().pipe(
      filter(data => !!data),
      map(data => {
        return {
          ...data,
          policy: JSON.parse(data.policy)
        };
      }),
      untilDestroyed(this)
    ).subscribe(res => {
      this.devicePolicy = res;
      if (this.devicePolicy?.policy?.pan_policy) {
        this.panPolicyDataStream.next(this.devicePolicy.policy.pan_policy);
      }
    });

  }

  // function used to capture all information related to a specific device
  private getInfoDataTab(singleDeviceId): void {
    this.devicesService.device(singleDeviceId.customerId, singleDeviceId.deviceId);
    this.devicesService.getDevice().pipe(filter(data => !!data),
      untilDestroyed(this)
    ).subscribe(
      ((device: Devices) => {
        this.basicInfoFormData(device);
        this.additionalInfoData(device);
        this.deviceData = device;
        this.healthInfoData();
      }
      ));
  }

  // Display the additional info tab to the left
  basicInfoFormData(deviceData): void {
    this.basicInfoForm = this.fb.group({
      deviceId: [{ value: deviceData.id || '', disabled: true }, [Validators.required]],
      deviceName: [{ value: deviceData.name || '', disabled: true }, [Validators.required]],
      friendlyName: [{ value: deviceData.friendlyName || '', disabled: true }, [Validators.required]],
      physicalAddress: [{ value: deviceData.physicalAddress || '', disabled: true }, [Validators.required]],
      latitude: [{ value: deviceData.lat || '', disabled: true }, [Validators.required]],
      longitude: [{ value: deviceData.lon || '', disabled: true }, [Validators.required]],
    });
  }

  // Display the additional info tab to the right
  additionalInfoData(deviceData): void {
    this.additionalInfoForm = this.fb.group({
      createdBy: [{ value: deviceData.createdBy || '', disabled: true }],
      updatedBy: [{ value: deviceData.updatedBy || '', disabled: true }],
      updatedAt: [{ value: deviceData.updatedAt || '', disabled: true }],
      version: [{ value: deviceData.version || '', disabled: true }],
      customerId: [{ value: deviceData.customerId || '', disabled: true }],
      service: [{ value: deviceData.service || '', disabled: true }],
      createdAt: [{ value: deviceData.createdAt || '', disabled: true }],
      healthInformation: [{ value: deviceData.healthInformation || '', disabled: true }],
      deviceType: [{ value: deviceData.deviceType || '', disabled: true }],
      deviceCustomerId: [{ value: deviceData.deviceCustomerId || '', disabled: true }],
      interfaces: this.fb.array((this.buildInterfaces(this.deviceData?.interfaces)))
    });
  }

  healthInfoData(): void {
    this.infoForm = this.fb.group({
      deviceId: [{ value: this.deviceData.id || '', disabled: true }, [Validators.required]],
      deviceName: [{ value: this.deviceData.name || '', disabled: true }, [Validators.required]],
      friendlyName: [{ value: this.deviceData.friendlyName || '', disabled: true }, [Validators.required]],
      physicalAddress: [{ value: this.deviceData.physicalAddress || '', disabled: true }, [Validators.required]],
      latitude: [{ value: this.deviceData.lat || '', disabled: true }, [Validators.required]],
      longitude: [{ value: this.deviceData.lon || '', disabled: true }, [Validators.required]],
      createdBy: [this.deviceData.createdBy, [Validators.required]],
      updatedBy: [this.deviceData.updatedBy, [Validators.required]],
      version: [this.deviceData.version, [Validators.required]],
      customerId: [this.deviceData.customerId, [Validators.required]],
      service: [this.deviceData.service, [Validators.required]],
      createdAt: [this.deviceData.createdAt, [Validators.required]],
      healthInformation: [this.deviceData.healthInformation, [Validators.required]],
      deviceType: [this.deviceData.deviceType, [Validators.required]],
      deviceCustomerId: [this.deviceData.deviceCustomerId, [Validators.required]],
      interfaces: this.fb.array((this.buildInterfaces(this.deviceData?.interfaces)))
    });
  }

  buildInterfaces(intrfcs: DeviceInterfaceModel[]): UntypedFormGroup[] {
    if (!intrfcs || !intrfcs.length) {
      return [this.createInterface()];
    }
    return intrfcs.map(val => {
      return this.createInterface(val);
    });
  }

  createInterface(intrfc?: DeviceInterfaceModel): UntypedFormGroup {
    return this.fb.group({
      gw: [{ value: intrfc?.gw || '', disabled: false }, []],
      amon: [{ value: intrfc?.amon || '', disabled: false }, []],
      ip: [{ value: intrfc?.ip || '', disabled: false }, []],
      vlan: [{ value: intrfc?.vlan || '', disabled: false }, []],
      if_id: [{ value: intrfc?.if_id || '', disabled: false }, []],
      ext: [{ value: intrfc?.ext || '', disabled: false }, []],
      mgmt: [{ value: intrfc?.mgmt || '', disabled: false }, []],
      ifname: [{ value: intrfc?.ifname || '', disabled: false }, []],
      netmask: [{ value: intrfc?.netmask || '', disabled: false }, []],
      monitor: [{ value: intrfc?.monitor || '', disabled: false }, []]
    });
  }

  getControls() {
    return (this.infoForm.get('interfaces') as UntypedFormArray).controls;
  }

  onDownload(device) {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'ctl-panel-class',
    });
    confirmationDialog.componentInstance.title = 'Download Device';
    confirmationDialog.componentInstance.message
      = `Are you sure you would like to download: ${device.name}`;
    confirmationDialog.afterClosed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.devicesService.downloadDevice(device);
      }
    });
  }

  onDelete(device) {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'ctl-panel-class',
    });
    confirmationDialog.componentInstance.title = 'Delete Device';
    confirmationDialog.componentInstance.message
      = `Are you sure you would like to delete: ${device.name}`;
    confirmationDialog.afterClosed().subscribe(isConfirmed => {

      if (isConfirmed) {
        this.devicesService.deleteDevice(device);
      }
    });
  }

  cancelBasicInfo() {
    this.basicInfoForm.reset();
    this.basicInfoForm.disable();
    this.isEdit = false;
  }

  editBasicInfo() {
    console.log('Editing form', this.basicInfoForm.getRawValue());

    this.isEdit = !this.isEdit;
    if (this.isEdit) {
      this.basicInfoForm.controls.friendlyName.enable();
      this.basicInfoForm.controls.physicalAddress.enable();
      this.basicInfoForm.controls.latitude.enable();
      this.basicInfoForm.controls.longitude.enable();
    } else {
      const deviceUpdate = {
        friendlyName: this.basicInfoForm.controls.friendlyName.value,
        id: this.basicInfoForm.controls.deviceId.value,
        lat: this.basicInfoForm.controls.latitude.value,
        lon: this.basicInfoForm.controls.longitude.value,
        physicalAddress: this.basicInfoForm.controls.physicalAddress.value
      };
      this.basicInfoForm.disable();
      this.devicesService.updateDevice(deviceUpdate, this.deviceData.customerId, this.deviceData.id);
    }
  }

}
