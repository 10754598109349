import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';


@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent extends FieldType implements OnInit {

  searchCtrl = new UntypedFormControl('');
  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
