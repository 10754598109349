import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReportParameters } from '../interfaces/report';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private reportParametersSubject: BehaviorSubject<ReportParameters> = new BehaviorSubject<ReportParameters>(null);
  private reportParameterLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private reportParameterErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // Getter for all report subject
  reportParameters: Observable<ReportParameters> = this.reportParametersSubject.asObservable();
  reportParametersLoading: Observable<boolean> = this.reportParameterLoadingSubject.asObservable();
  reportParametersError: Observable<string> = this.reportParameterErrorSubject.asObservable();

  constructor(private httpClient: AppService<any>) {
    this.fetchReportParameters();
  }

  fetchReportParameters(): void {
    this.reportParameterErrorSubject.next(null);
    this.reportParameterLoadingSubject.next(true);
    this.httpClient.get('eventParams', '', null)
      .then((response: ReportParameters) => this.reportParametersSubject.next(response))
      .catch(error => this.reportParameterErrorSubject.next(error.message))
      .finally(() => this.reportParameterLoadingSubject.next(false));
  }
}
