import { Component, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { TABLE_SETTINGS } from '@modules/list-managements/constants/list-management.constants';
import { ListManagementsService } from '@modules/list-managements/services/list-managements.service';
import { SnackbarService } from '@shared-services/snackbar.service';
import { ActionButtonInterface, FieldInterface, FormBuilderComponent } from '@sweet-shared/components/form-builder/form-builder.component';
import { Auth } from 'aws-amplify';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {Company} from '@modules/companies/interfaces/companies';
import {take} from 'rxjs/operators';
import {HelpDialogModalComponent} from "@sweet-shared/components/help-dialog-modal/help-dialog-modal.component";

@Pipe({
  name: 'companyFilter',
  pure: false
})
export class CompanyFilter implements PipeTransform {
  transform(items: any[], filter: string) {
    if (filter.trim().length === 0) return items;
    return items.filter(item => item.name.toLowerCase().includes(filter.trim().toLowerCase()));
  }
}


@Component({
  selector: 'app-lists-management',
  templateUrl: './list-management.component.html',
  styleUrls: ['./list-management.component.scss']
})
export class ListManagementComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();

  companyControl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  listFilterControl: UntypedFormControl = new UntypedFormControl('');

  companies$: Observable<any[]>;
  dataList$: Observable<any>;
  listDetails$: BehaviorSubject<any> = new BehaviorSubject([]);
  displayedColumns: string[] = ['name', 'description'];
  selectedList: any = null;
  companiesOptions: Company[] = [];
  filteredCompaniesOptions: Company[] = [];


  // table specification
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tableSettings = TABLE_SETTINGS;
  listHeaderStream = new BehaviorSubject(this.tableSettings.tableHeaders);
  detailListHeader = new BehaviorSubject(this.tableSettings.listDetailsHeaders);
  listManagementActions;
  loading = false;

  constructor(
    private listManagementService: ListManagementsService,
    private router: Router,
    private dialog: MatDialog,
    private matDialog: MatDialog,
    private snackbarService: SnackbarService,
  ) {
    this.companies$ = this.listManagementService.companies$;
    this.listManagementService.companies$.subscribe((val) => {
      this.filteredCompaniesOptions = val;
      this.companiesOptions = val;
      // Do something with companiesOptions here
    });

    // this.companiesOptions = this.listManagementService.companies$.subscribe(val => {return val });

    this.dataList$ = this.listManagementService.listManagementData$;
    this.listManagementActions = this.listManagementService.actionPermissions;

    Auth.currentAuthenticatedUser()
      .then(user => this.companyControl.setValue(user.attributes.profile))
      .catch(err => this.router.navigate(['/']))
  }

  ngOnInit(): void {
    // add the filter function
    this.fetchList();
  }

  onHelpClick() {
    // Opens up the Incident KB dialog with how to article
    const dialogRef = this.dialog.open(HelpDialogModalComponent);
  }

  fetchList(): void {
    this.listManagementService.fetchCustomerList(this.companyControl.value)
  }

  selectList(row): void {
    this.loading = true;
    this.selectedList = row.data;
    this.listManagementService.fetchCustomerListDetails(this.companyControl.value, row.data.id)
      .then((response: any) => {
        this.detailListHeader.next(response.columns.reduce((prev, next) => {
          prev.push({ friendly: next.name, name: next.id });
          return prev;
        }, [{ friendly: 'Action', name: 'actions', description: '' }]));
        this.listDetails$.next(response.data);
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => this.loading = false)
  }

  openAddListItemDialog(data: any, eventType?): void {
    const dialogRef: MatDialogRef<FormBuilderComponent> = this.matDialog.open(FormBuilderComponent, {
      minWidth: '300px',
      minHeight: '100px',
      disableClose: true,
    });

    const fields: FieldInterface[] = this.selectedList.columns.map(column => {

      return {
        label: column.name,
        name: column.id,
        component: 'input-text',
        defaultValue: data ? data[column['id']] : null,
        validators: [Validators.required, Validators.pattern(this.setPattern(column.type))],
        type: 'text',
        flex: '100%',
        placeholder: column.name
      };
    });

    const formButtons: ActionButtonInterface = {
      flex: 'flex-end',
      buttons: [
        {
          iconName: null,
          color: 'warn',
          label: 'Cancel',
          name: 'CANCEL',
          type: 'basic'
        },
        {
          iconName: null,
          color: 'primary',
          label: 'Submit',
          name: eventType,
          type: 'stroked'
        }
      ]
    };

    dialogRef.componentInstance.title = eventType === 'ADD_LIST_ITEM' ? `Add New List Item` : `Edit List Item`;
    dialogRef.componentInstance.fieldDetails = fields;
    dialogRef.componentInstance.actionButtons = formButtons;
    dialogRef.componentInstance.actionEvents.subscribe(event => {
      if (event.name === 'CANCEL') {
        this.selectedList.data = {};
        dialogRef.close();
      } else if (event.name === 'ADD_LIST_ITEM') {
        dialogRef.componentInstance.loading = true;
        dialogRef.componentInstance.loadingSubject.next(true);
        const updatePayload = this.listDetails$.value.reduce((prev, next) => {
          if (next.pk === data.pk) {
            prev.push(Object.keys(event.data).reduce((p, n) => {
              p.push(event.data[n]);
              return p;
            }, []));
          } else {
            prev.push(Object.keys(next).reduce((p, n) => {
              if (n !== 'pk') {
                p.push(next[n]);
              }
              return p;
            }, []));
          }
          return prev
        }, [Object.keys(event.data).reduce((p, n) => {
          p.push(event.data[n]);
          return p;
        }, [])]);
        this.listManagementService.updateCustomerList(this.companyControl.value, this.selectedList.id, updatePayload)
          .then((response: any) => {
            this.listDetails$.next(response.data);
            dialogRef.close();
          })
          .catch(err => this.snackbarService.open(err));
      }
      else if (event.name === 'EDIT_LIST_DATA') {
        dialogRef.componentInstance.loading = true;
        dialogRef.componentInstance.loadingSubject.next(true);
        const updatePayload = this.listDetails$.value.reduce((prev, next) => {
          if (next.pk === data.pk) {
            prev.push(Object.keys(event.data).reduce((p, n) => {
              p.push(event.data[n]);
              return p;
            }, []));
          } else {
            prev.push(Object.keys(next).reduce((p, n) => {
              if (n !== 'pk') {
                p.push(next[n]);
              }
              return p;
            }, []));
          }
          return prev
        }, []);
        this.listManagementService.updateCustomerList(this.companyControl.value, this.selectedList.id, updatePayload)
          .then((response: any) => {
            this.listDetails$.next(response.data);
            dialogRef.close();
          })
          .catch(err => {
            this.snackbarService.open(err);
            dialogRef.componentInstance.loading = false;
            dialogRef.componentInstance.loadingSubject.next(false);
          })
      }
    });
  }

  // Sets the Regex patter for validating list items based on the column 'type'
  setPattern(value: string): RegExp {
    if (value === 'integer') {
      return /^[0-9]*$/;
    } else if (value === 'decimal') {
      return /^\d+(?:[.]\d+)?$/;
    } else if (value === 'ipv4') {
      return /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    } else if (value === 'ipv4cidr') {
      return /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(3[0-2]|[1-2][0-9]|[0-9]))$/;
    } else if (value === 'ipv6') {
      return /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
    } else if (value === 'ipv6cidr') {
      return /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/(12[0-8]|1[0-1][0-9]|[1-9][0-9]|[0-9]))$/;
    } else {
      return /[\s\S]*/;
    }
  }

  clearAllListItems(): void {
    this.listManagementService.updateCustomerList(this.companyControl.value, this.selectedList.id, [])
      .then((response: any) => {
        this.listDetails$.next(response.data);
      })
      .catch(err => {
        this.snackbarService.open(err);
      })
  }

  filterCompany(searchTerm: string): void {
    this.filteredCompaniesOptions = this.companiesOptions.filter(c => c.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }


  refreshAllListItems(): void {
    this.selectList({ data: this.selectedList });
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

}
