<section class="column-manager">
  <h2>Customize Column Display</h2>
  <p>
    Select or deselect columns to view in the table. Click and drag the labels
    or use the arrows to reorder the columns in the table
  </p>

  <div class="main-actions">
    <div>
      <mat-checkbox
        [checked]="isAllColumnsSelected()"
        (change)="onSelectAllColumns($event)"
        >{{
          isAllColumnsSelected() ? "Unselect All" : "Select All"
        }}</mat-checkbox
      >
    </div>
  </div>

  <div class="column-items-wrapper">
    <div cdkDropList (cdkDropListDropped)="drop($event)" class="selected-cols">
      <mat-label class="column-details">
        Selected Columns: ({{ selectedColumns.length }}/{{
          availableColumns.length
        }})
      </mat-label>
      <div
        cdkDrag
        *ngFor="let column of selectedColumns; let i = index"
        class="drageable-container"
      >
        <app-column-item
          [column]="getField(column)"
          [position]="i"
          [sorting]="sorting.value"
          (actionHandler)="indexItemActionHandler($event)"
        ></app-column-item>
      </div>
      <div
        class="add-column"
        *ngIf="
          addColumnCtrl && selectedColumns.length < availableColumns.length
        "
      >
        <mat-form-field appearance="outline">
          <mat-label>Select Field</mat-label>
          <mat-select (selectionChange)="addSelectedColumn($event)">
            <mat-form-field class="select-with-search">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="filterField($event.target.value)" />
            </mat-form-field>
            <mat-option *ngFor="let column of columnsToAdd" [value]="column">
              {{ column.friendly }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        class="add-column-button"
        *ngIf="selectedColumns.length < availableColumns.length"
      >
        <button mat-icon-button color="primary" (click)="onAddColumnClick()">
          <mat-icon *ngIf="!addColumnCtrl">add</mat-icon>
          <mat-icon *ngIf="addColumnCtrl">cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <section class="action-container">
    <button mat-stroked-button color="warn" (click)="dismiss()">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      (click)="updateFieldSet()"
      [disabled]="loading"
    >
      Update
    </button>
  </section>
</section>
