<div>
  <form [formGroup]="form" (submit)="submitEvent()">
    <div fxLayout="column">
      <mat-form-field>
        <label for="emailField"></label>
        <input id="emailField" matInput placeholder="Email" formControlName="email" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls.email.hasError('email')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="form.controls.email.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>

        <label for="given-name"></label>
        <input id="given-name" matInput placeholder="First Name" formControlName="given_name" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls.given_name.hasError('minlength')">
          First Name is too short
        </mat-error>
        <mat-error *ngIf="form.controls.email.hasError('required')">
          First Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>

        <label for="last-name"></label>
        <input id="last-name" matInput placeholder="Last Name" formControlName="family_name" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls.family_name.hasError('minlength')">
          Last Name is too short
        </mat-error>
        <mat-error *ngIf="form.controls.family_name.hasError('required')">
          Last Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="end end">
        <button mat-button type="button" (click)="cancelEvent()">Cancel</button>
        <button mat-flat-button type="submit" color="primary" [disabled]="form.invalid || !form.dirty">Update</button>
      </div>
    </div>

  </form>
</div>
