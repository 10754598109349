<mat-card appearance="outlined">
  <mat-card-title>{{ widget.title }}</mat-card-title>
  <mat-card-content>
    <section *ngIf="formReady && !error; else loading">
      <query-builder
        [(ngModel)]="queryBuilderModel"
        [config]="queryBuilderConfig"
        (ngModelChange)="setQueryAthenaStatement($event)"
      ></query-builder>
    </section>

    <ng-template #loading>
      <section class="loading">
        <mat-progress-spinner
          mode="indeterminate"
          color="primary"
        ></mat-progress-spinner>
      </section>
    </ng-template>

    <div class="query-string" *ngIf="queryString">
      <p><strong>Query: </strong>{{ queryString }}</p>
    </div>
  </mat-card-content>
  <mat-card-actions class="action-card">
    <button mat-stroked-button mat-button (click)="clear()">Clear</button>
    <div>
      <button mat-dialog-close mat-button color="warn" (click)="cancel()">
        Cancel
      </button>
      <button mat-stroked-button color="primary" (click)="saveWidgetQuery()">
        Save
      </button>
    </div>
  </mat-card-actions>
</mat-card>
