<mat-card appearance="outlined">
  <mat-card-content>
    <!-- Information and title -->
    <form [formGroup]="loginForm" (submit)="login()" fxLayout="column">
      <p *ngIf="formLoading">loading...</p>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Username</mat-label>
        <input matInput formControlName="username">
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Region</mat-label>
        <mat-select formControlName='region'>
          <mat-option *ngFor="let val of regions" [value]="val.name">
            {{val.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p class='error' *ngIf='error' fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
        {{error}}
      </p>
      <button mat-raised-button color="primary" [disabled]="loginForm.invalid" type="submit">
        <mat-icon>public</mat-icon>
        Login
      </button>
    </form>
      <div fxLayout="row" fxLayoutAlign="center center" class="login-options-divider" >
        <mat-divider inset ></mat-divider>
      </div>
    <div fxLayout="row" fxLayoutAlign="end end" style="margin:10px 0;">
      <button mat-button color="warn" (click)="forgotPasswordDialog()">Forgot Password</button>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-around center">
      <button mat-button>About Lumen</button>
      <button mat-button>About MSS</button>
      <button mat-button>Legal</button>
      <button mat-button>Legal Notices</button>
      <button mat-button>Privacy Policies</button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center end">
      <p>© 2021 Lumen. All Rights Reserved.</p>
    </div>
  </mat-card-content>
</mat-card>
