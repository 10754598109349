import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuardService} from '../../guards/auth-gaurd.service';
import {KnowledgeBasePageComponent} from './components/knowledge-base-page/knowledge-base-page.component';


const routes: Routes = [
  {
    path: 'knowledge-base',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: KnowledgeBasePageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KnowledgeBaseRoutingModule {
}
