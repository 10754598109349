<form #queryForm="ngForm"
      (ngSubmit)="onSubmit(queryForm, formatWithColon)"
      (keyup)="setFilter($event, formatWithColon)"
      [ngClass]="{'dialog-wrapper': isDialog}" >
  <mat-toolbar>
    <h6 mat-dialog-title>Query Builder</h6>
    <button (click)="addGroup()" type="button" mat-stroked-button><mat-icon>add</mat-icon>Add Group</button>
    <button (click)="clearQuery()" type="button" mat-stroked-button><mat-icon>clear</mat-icon>Clear Query</button>
  </mat-toolbar>
  <mat-dialog-content style="margin: 10px 5px;">
    <ng-container *ngFor="let group of ruleGroups; let groupIndex = index">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field fxFlex>
          <mat-label>Operators</mat-label>
          <mat-select [(ngModel)]="group.condition" [name]="'group'+groupIndex+'op'" required>
            <mat-option *ngFor="let operator of operators" [value]="operator">{{operator}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          *ngIf="ruleGroups.length > 1"
          (click)="removeGroup(groupIndex)"
          type="button"
          mat-stroked-button><mat-icon>remove</mat-icon>Remove Group
        </button>
        <button
          (click)="addRule(groupIndex)"
          type="button"
          mat-stroked-button><mat-icon>add</mat-icon>Add Rule
        </button>
      </div>
      <div
        style="margin-left: 25px;"
        fxLayout='row wrap'
        fxLayoutAlign='start'
        *ngFor="let rule of ruleGroups[groupIndex].rules; let ruleIndex = index">
        <div fxLayout="row" class="q-connector">
          <mat-form-field>
            <mat-label>Field Name</mat-label>
            <mat-select (selectionChange)="setFilter($event, formatWithColon)" [(ngModel)]="rule.field" [name]="'[' + groupIndex + '][' + ruleIndex + ']-field'" required>
              <app-auto-complete  (searchText)="onTextInput($event, groupIndex, ruleIndex)"></app-auto-complete>
              <mat-option *ngFor="let field of fields | inputSearch: searchText[groupIndex + '-' + ruleIndex]" [value]="field.friendly">{{field.friendly}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Comparison</mat-label>
            <mat-select (selectionChange)="setFilter($event, formatWithColon)" [(ngModel)]="rule.operator" [name]="'[' + groupIndex + '][' + ruleIndex + ']-op'" required>
              <mat-option *ngFor="let comparator of comparators" [value]="comparator">{{comparator}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              required
              matInput
              [(ngModel)]="rule.value"
              [name]="'[' + groupIndex + '][' + ruleIndex + ']-val'"
              name="Value"
              [placeholder]="'Value'"
            >
          </mat-form-field>
          <mat-icon
            *ngIf="ruleGroups[groupIndex].rules.length > 1"
            (click)="removeRule(ruleIndex, groupIndex)">delete
          </mat-icon>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <div>
    <p>
      <code class='query-preview'>
        <strong>Preview: </strong>
        <small *ngIf='eventQuery.length > 0; else noPreview'>{{queryPreview}}</small>
        <ng-template #noPreview>
          <small>No Preview</small>
        </ng-template>
      </code>
    </p>
  </div>
  <mat-toolbar *ngIf="isDialog" style="background: white;" fxLayout="row" fxLayoutAlign="flex-end">
      <mat-dialog-actions>
        <button
          color="warn"
          mat-button
          mat-dialog-close
        >Cancel
        </button>
        <button
          color="primary"
          type="submit"
          mat-stroked-button
          [disabled]="queryForm.invalid"
        >Submit
        </button>
      </mat-dialog-actions>
  </mat-toolbar>
</form>

