import {Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AppEmitterService} from '../../../app-emitter.service';
import {filter} from 'rxjs/operators';
import {MatSidenav} from '@angular/material/sidenav';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit {
  @Input() isAuthenticated = false;
  @ViewChild('drawer') drawer: MatSidenav;

  smallScreen = window.innerWidth < 599 ? true : false;
  sidebarOpened = JSON.parse(localStorage.getItem('sidebarOpened') || 'true');
  links = [
    {
      name: 'dashboard',
      friendly: 'Dashboard',
      navigation: '/dashboard',
      icon: 'dashboard',
      toolTip: 'Dashboard',
      requiredPermission: 'portal-access.enable-dashboard-section',
    },
    {
      name: 'incidents',
      friendly: 'Incidents',
      navigation: '/incidents',
      icon: 'dynamic_feed',
      toolTip: 'Incidents',
      requiredPermission: 'portal-access.enable-response-section'
    },
    {
      name: 'events',
      friendly: 'Events',
      navigation: '/events',
      icon: 'assessment',
      toolTip: 'Events',
      requiredPermission: 'portal-access.enable-analysis-section'
    },
    {
      name: 'reporting',
      friendly: 'Reporting',
      navigation: '/reporting',
      icon: 'report',
      toolTip: 'Reporting',
      requiredPermission: 'portal-access.enable-reporting-section',
    },
    {
      name: 'devices',
      friendly: 'Devices',
      navigation: '/devices',
      icon: 'devices',
      toolTip: 'Devices',
      requiredPermission: 'portal-access.enable-devices-section',
    },
    {
      name: 'log-stream',
      friendly: 'Log Stream',
      navigation: '/log-stream',
      icon: 'menu_book',
      toolTip: 'Log Stream',
      requiredPermission: 'portal-access.enable-admin-logstream-tab',
    },
    {
      name: 'security-posture',
      friendly: 'Security Posture',
      navigation: '/secure-dns',
      icon: 'security',
      toolTip: 'Security Posture',
      requiredPermission: 'portal-access.enable-admin-securedns-tab',
    },
    {
      name: 'list',
      friendly: 'List Management',
      navigation: '/list',
      icon: 'list',
      toolTip: 'List Management',
      requiredPermission: 'portal-access.enable-admin-lists-tab',
    }
  ];

  constructor(private appEmitterService: AppEmitterService) {
  }

  ngOnInit() {

    this.appEmitterService.emitter.pipe(
      filter(val => val.name === 'TOGGLE_SIDENAV')
    ).subscribe(val => this.drawer.toggle());

    this.sidebarOpened = JSON.parse(localStorage.getItem('sidebarOpened') || 'true');

  }

  toggleSidebar() {
    window.dispatchEvent(new Event('resize'));
    this.sidebarOpened = !this.sidebarOpened;
    localStorage.setItem('sidebarOpened', JSON.stringify(this.sidebarOpened));
  }

  @HostListener('window: resize')
  windowSize() {
    if (window.innerWidth < 599) {
      this.smallScreen = true;
      this.sidebarOpened = false;
      localStorage.setItem('sidebarOpened', JSON.stringify(this.sidebarOpened));
    } else {
      this.smallScreen = false;
    }
  }
}
