import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '@app/guards/auth-gaurd.service';
import { PermissionsGuardService } from '@app/guards/permissions-guard.service';
import { IncidentsPageComponent } from './components/incidents-page/incidents-page.component';
import { EventPageComponent } from '@modules/events/components/event-page/event-page.component';
import { IncidentDetailComponent } from './components/incident-detail/incident-detail.component';


const routes: Routes = [
  {
    path: 'incidents',
    canActivate: [AuthGuardService, PermissionsGuardService],
    data: {
      requiredPermission: ['portal-access.enable-response-section'],
      any: false
    },
    children: [
      {
        path: '',
        component: IncidentsPageComponent,
        canActivate: [PermissionsGuardService],
        data: {
          requiredPermission: ['response.run-search'],
          any: false
        },
        children: [
          {
            path: 'events',
            component: EventPageComponent
          }
        ]
      },
      {
        path: 'incident/:id',
        component: IncidentDetailComponent,
        canActivate: [PermissionsGuardService],
        data: {
          requiredPermission: ['response.view-inv', 'response.view-inv-details'],
          any: false
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IncidentsRoutingModule {
}
